import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import styles from "./form.module.scss";
import CustomButton from "../../../../Components/CustomButton";
import { useNavigate } from "react-router";
import InputField from "../../../../Components/Input";
import SelectInput from "../../../../Components/SelectInput";
import LogoImage from "../../../../assets/images/Logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { signUp } from "../../../../services/authService";
import { listAllLanguages } from "../../../../services/languageService";
import { Language } from "../../../../models/language";
import { listAllMajor } from "../../../../services/majorsServise";
import { listAllEducationLevel } from "../../../../services/educationLevelService";
import { listAllAreaInterest } from "../../../../services/areaInterestservice";
import { useDispatch } from "react-redux";
import moment from "moment";
import { listAllCities, listAllCountries } from "../../../../services/locationService";
import { addAlertInfo } from "../../../../store/alertSlice";
import UploadeProfileImage from "./uploadeProfileImage";
import { validatePasswordTrue, validatePassword } from "../../../../helpers/validation";
import { validateEmail } from "../../../helpers";
import { CAPTCHACLINTSECRET } from "../../../../environments/environment";

const SignUp = () => {
  const captchaRef = useRef(null);
  const formData = new FormData();
  const [file, setImagefile] = useState<File>();

  const [userData, setUserData] = useState<any>();
  const [radioValue, setRadioValue] = useState("yes");
  const [radioGender, setRadioGender] = useState("");
  const [graduationMonth, setGraduationMonth] = useState<any>(null);
  const [graduationYear, setGraduationYear] = useState<any>(null);
  const [Languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [majors, setMajors] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [allAreaInterest, setAllAreaInterest] = useState([]);
  const [loadingAreaInterest, setloadingAreaInterest] = useState(true);
  const [loadingLanguages, setLoadingLanguages] = useState(true);
  const [loadingMajors, setLoadingMajors] = useState(true);
  const [loadingEducationLevels, setLoadingEducationLevels] = useState(true);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<any>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  function handleClick() {
    navigate("/professionals-home");
  }
  function handleClickLogin() {
    navigate("/");
  }
  const validateList = ["firstName", "lastName", "email", "fluentLanguage", "country", "city", "educationLevel", "password"];
  const validateListNames = ["First name", "Last name", "Email", "Language fluency", "Country", "City", "Education level", "Password"];

  const validateForm = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return false;
    }

    if (!validateEmail(userData?.email)) return false;
    if (!agreeToTerms) return false;
    if (radioValue === "yes" && !userData?.major) return false;
    if (radioValue === "no" && !userData?.areaOfInterest) return false;
    if (radioValue === "yes" && !graduationYear) return false;
    if (radioValue === "yes" && !graduationMonth) return false;
    if (userData?.secondaryEmail && userData?.email == userData?.secondaryEmail) return false;
    if (!validatePasswordTrue(userData?.password)) return false;
    if (userData?.password != userData?.newPasswordConfirmation) return false;

    return true;
  };
  const getErrorMessage = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) {
        if (!userData[validateList[i]]) return validateListNames[i] + " is required";
        // return 'You left some of the required fields empty'
      }
    }
    if (!validateEmail(userData?.email)) return "Invalid email format";
    if (!agreeToTerms) {
      return "Agreement to the Terms of Use and the Privacy Policy is required";
    }
    if (radioValue === "yes" && !userData?.major) {
      return "Major is required";
    }
    if (radioValue === "no" && !userData?.areaOfInterest) {
      return "Area of interest is required";
    }
    if (radioValue === "yes" && !graduationYear) {
      return "Graduation year is required";
    }
    if (radioValue === "yes" && !graduationMonth) {
      return "Graduation month is required";
    }
    if (userData?.secondaryEmail && userData?.email == userData?.secondaryEmail) {
      return "You can't use your primary email as a secondary email";
    }
    if (!validatePasswordTrue(userData?.password)) {
      return validatePassword(userData?.password);
    }
    if (userData?.password != userData?.newPasswordConfirmation) {
      return "Your passwords does not match";
    }
    return true;
  };
  async function handleSignUp(e: any) {
    const element: any = document.getElementById("sign_up_form");
    e.preventDefault();
    setSubmitClicked(true);
    for (const property in userData) {
      if (property != "agreeToTerms" && property != "newPasswordConfirmation")
        if (typeof userData[property] === "string" || userData[property] === "boolean") {
          userData[property] && formData.append(property, userData[property]);
        } else {
          JSON.stringify(userData[property]) && formData.append(property, JSON.stringify(userData[property]));
        }
    }
    file && formData.append("profileImage", file);
    formData.append("model", "Student");
    agreeToTerms && formData.append("agreeToTerms", "true");
    formData.append("enrolledInHighSchool", `${radioValue === "yes" ? "true" : "false"}`);
    radioValue === "yes" &&
      graduationYear &&
      formData.append("graduationDate", moment(graduationYear).format("yyyy") + "-" + moment(graduationMonth).format("MM-DD"));
    formData.append("gender", radioGender);
    if (validateForm()) {
      setLoadingSubmit(true);
      const res = await signUp(formData);
      if (res.status === 201) {
        navigate(`/mailVerification?userId=${res.data.data.user._id}`);
        setLoadingSubmit(false);
      } else {
        setUserData((_data: any) => ({ ..._data, captchaValue: null }));
        const captcha: any = document.getElementById("captcha") || "";
        captcha?.remove();
        dispatch(
          addAlertInfo({
            show: true,
            message: res.data.error,
            type: "error",
          })
        );
        setLoadingSubmit(false);
        const captchaView: any = document.getElementById("captcha-view") || "";
        captchaView.appendChild(captcha);
      }
    } else {
      dispatch(
        addAlertInfo({
          show: true,
          message: getErrorMessage(),
          type: "error",
        })
      );

      // element.scrollTo(0, 0);
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "enrolledInHighSchool") {
      setRadioValue(e.target.value);
    }
    if (e.target.name === "agreeToTerms") {
      setAgreeToTerms(!agreeToTerms);
    }
    if (e.target.name === "gender") {
      setRadioGender(e.target.value);
    } else {
      setUserData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
    }
  };
  const handleSelectChange = (e: any) => {
    const isArray = Array.isArray(e.value);
    if (e.name == "city") {
      setSelectedCity(e?.value);
    }
    if (isArray) {
      setUserData((_data: any) => ({ ..._data, [e.name]: e?.value ? e.value.map((item: any) => item.id) : null }));
    } else {
      setUserData((_data: any) => ({ ..._data, [e.name]: e?.value ? e.value.id : null }));
    }
  };
  const handleSelectContry = (e: any) => {
    handleSelectChange(e);
    setSelectedCity(null);
    setUserData((_data: any) => ({ ..._data, city: null }));
    if (e.value) {
      getAllCities(e.value.id);
    } else {
      setCities([]);
    }
  };
  // list All Languages
  const getAllLanguages = async () => {
    const response = await listAllLanguages();
    if (response) {
      const languages = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setLanguages(languages);
      setLoadingLanguages(false);
    }
  };
  const getAllCountries = async () => {
    const response = await listAllCountries();
    if (response) {
      const countries = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCountries(countries);
      setLoadingCountries(false);
    }
  };
  const getAllCities = async (countryId: string) => {
    setLoadingCities(true);
    const response = await listAllCities(countryId);
    if (response) {
      const citiesOfCountry = response.data.data;
      const cities = citiesOfCountry.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCities(cities);
      setLoadingCities(false);
    }
  };
  // list Education Levels
  const getAllEducationLevel = async () => {
    const response = await listAllEducationLevel();
    if (response) {
      const educationLevels = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setEducationLevels(educationLevels);
      setLoadingEducationLevels(false);
    }
  };
  // list All Area Interest
  const getAllAreaInterest = async () => {
    const response = await listAllAreaInterest();
    if (response) {
      const allAreaInterest = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setAllAreaInterest(allAreaInterest);
      setloadingAreaInterest(false);
    }
  };
  // list All Majors
  const getAllMajors = async () => {
    const response = await listAllMajor();
    if (response) {
      const majors = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setMajors(majors);
      setLoadingMajors(false);
    }
  };
  const handleChangeCaptcha = (e: string) => {
    setUserData((_data: any) => ({ ..._data, captchaValue: e }));
  };
  useEffect(() => {
    getAllLanguages();
    getAllMajors();
    getAllEducationLevel();
    getAllAreaInterest();
    getAllCountries();
  }, []);

  return (
    <div className={styles["page-content"]} id="sign_up_form">
      <div className={styles["signUp-form"]}>
        <div className={styles["form"]}>
          <div className={styles.logo} onClick={handleClick}>
            <img src={LogoImage} alt="logo" />
          </div>
          <div className="header-underline">
            <h3 className="title">Students & Early Career Professionals</h3>
            <p className="main-description">Tell us a little about you so we can customize your experience</p>
          </div>
          <UploadeProfileImage setImagefile={setImagefile} />
          <form onSubmit={handleSignUp} id="">
            <span className={styles.requarid}>Fields marked with an * are required.</span>
            <div className="form-item">
              <InputField
                label="First name"
                name="firstName"
                required={true}
                placeholder="First name"
                Change={handleChange}
                error={!userData?.firstName && submitClicked}
              />
            </div>
            <div className="form-item">
              <InputField label="Middle name" name="middleName" placeholder="Middle name" Change={handleChange} required={false} />
            </div>
            <div className="form-item">
              <InputField
                label="Last name"
                name="lastName"
                required={true}
                placeholder="Last name"
                Change={handleChange}
                error={!userData?.lastName && submitClicked}
              />
            </div>
            <div className="form-item">
              <InputField
                label="Primary email"
                name="email"
                required={true}
                placeholder="Primary email"
                labelHint="New to Edqan? It is recommended to enter your school email if your schools is signed up on edqan as you may get access to more features.
                                If you are not part of a school, feel free to sign up with your personal email address.
                                You also have the option to change your email later."
                Change={handleChange}
                error={!userData?.email && submitClicked}
              />
              {userData?.email && !validateEmail(userData?.email) && submitClicked && (
                <span className="text-red-500 text-sm">Invalid email format</span>
              )}
            </div>
            <div className="form-item">
              <InputField
                label="Secondary email"
                name="secondaryEmail"
                Change={handleChange}
                required={false}
                placeholder="Secondary email"
                labelHint="This could be your personal email. Your primary email will be your school email which you used to register with."
                error={userData?.secondaryEmail == userData?.email && submitClicked}
                errorMessage="You can't use your primary email as a secondary email"
              />
            </div>
            <div className="form-item">
              <InputField
                labelHint="Choose a strong password - at least 12 characters long, has a combination of upper and lowercase letters, numbers, and special symbols."
                label="Password"
                name="password"
                // value={formik.values.password}
                required={true}
                type="password"
                placeholder="Password"
                Change={handleChange}
                error={validatePassword(userData?.password) && submitClicked}
                errorMessage={validatePassword(userData?.password)}
              />
            </div>
            <div className="form-item">
              <InputField
                label="Confirm your password"
                required={true}
                name="newPasswordConfirmation"
                Change={handleChange}
                placeholder="Confirm your password"
                type="password"
                error={userData?.password != userData?.newPasswordConfirmation && submitClicked}
                errorMessage="Your passwords does not match"
              />
            </div>
            <Grid container columnSpacing="5">
              <Grid item xs={12}>
                <div className="form-item ">
                  <SelectInput
                    name="country"
                    required={true}
                    label="Select your country"
                    options={countries}
                    Change={handleSelectContry}
                    loading={loadingCountries}
                    error={!userData?.country && submitClicked}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-item ">
                  <SelectInput
                    name="city"
                    required={true}
                    label="Select your city (region/state/province options will appear with the city)"
                    labelHint="Select your country first"
                    options={cities}
                    Change={handleSelectChange}
                    loading={loadingCities}
                    value={selectedCity?.id ? selectedCity : null}
                    error={!userData?.city && submitClicked}
                    disabled={!userData?.country}
                  />
                </div>
              </Grid>
            </Grid>
            <div className="form-item">
              <FormControl className={styles.radioGroup}>
                <div className="input-label">
                  {" "}
                  <label className="text-[#000000]">* Gender</label>
                </div>
                <RadioGroup name="gender" value={radioGender} onChange={handleChange} row>
                  <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                  <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="form-item">
              <SelectInput
                labelHint="You can select the language you are fluent in."
                name="fluentLanguage"
                required={true}
                label="Select Language Fluency"
                multiple={true}
                options={Languages}
                Change={handleSelectChange}
                loading={loadingLanguages}
                error={!userData?.fluentLanguage && submitClicked}
                submitClicked={submitClicked}
              />
            </div>
            <div className="form-item">
              <SelectInput
                labelHint="You can select the language you are proficient in."
                name="proficientLanguage"
                required={false}
                label="Select Language Proficiency"
                multiple={true}
                options={Languages}
                Change={handleSelectChange}
                submitClicked={submitClicked}
              />
            </div>
            <div className="form-item">
              <FormControl className={styles.radioGroup}>
                <label>
                  <strong>Are you currently enrolled in higher education?</strong>
                </label>
                <RadioGroup name="enrolledInHighSchool" value={radioValue} onChange={handleChange} row>
                  <FormControlLabel value="yes" control={<Radio size="small" />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                </RadioGroup>
              </FormControl>
            </div>
            {radioValue === "no" && (
              <div className="form-item">
                <SelectInput
                  required={radioValue === "no"}
                  name="areaOfInterest"
                  Change={handleSelectChange}
                  label="Area of interest"
                  options={allAreaInterest}
                  loading={loadingAreaInterest}
                  submitClicked={submitClicked}
                />
              </div>
            )}
            {radioValue === "yes" && (
              <div className="form-item">
                <SelectInput
                  multiple
                  loading={loadingMajors}
                  options={majors}
                  required={radioValue === "yes"}
                  error={radioValue === "yes" && !userData?.major && submitClicked}
                  label="Major/Area of study"
                  name="major"
                  Change={handleSelectChange}
                  submitClicked={submitClicked}
                />
              </div>
            )}
            <div className="form-item">
              <SelectInput
                required={true}
                label="Level of education"
                name="educationLevel"
                Change={handleSelectChange}
                multiple={false}
                options={educationLevels}
                loading={loadingEducationLevels}
                error={radioValue === "yes" && !userData?.major && submitClicked}
                submitClicked={submitClicked}
              />
            </div>
            <div className="form-item">
              <label>Graduation date (estimation accepted)</label>
              <Grid container columnSpacing="5">
                <Grid item xs={12} md={6}>
                  <DatePicker
                    label={`${radioValue === "yes" ? "*" : ""}Month`}
                    openTo="month"
                    views={["month"]}
                    value={graduationMonth}
                    onChange={(newValue) => {
                      setGraduationMonth(newValue);
                    }}
                    inputFormat="MMMM"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "",
                        }}
                        //required={radioValue === 'yes'}
                        error={radioValue === "yes" && !graduationMonth && submitClicked}
                        fullWidth
                        margin="none"
                        helperText={null}
                      />
                    )}
                  />
                  {radioValue === "yes" && !graduationMonth && submitClicked && (
                    <span className="text-red-500 text-sm">{"This field is required"}</span>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <DatePicker
                    views={["year"]}
                    label={`${radioValue === "yes" ? "*" : ""}Year`}
                    openTo="year"
                    value={graduationYear}
                    onChange={(newValue) => {
                      setGraduationYear(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "",
                        }}
                        // required={radioValue === 'yes'}
                        error={radioValue === "yes" && !graduationYear && submitClicked}
                        fullWidth
                        margin="none"
                        helperText={null}
                      />
                    )}
                  />
                  {radioValue === "yes" && !graduationYear && submitClicked && (
                    <span className="text-red-500 text-sm">{"This field is required"}</span>
                  )}
                </Grid>
              </Grid>
            </div>
            <div id="captcha-view">
              <div className="form-item m-t-20 text-underline mt-4 md:mt-0" id="captcha">
                <ReCAPTCHA sitekey={CAPTCHACLINTSECRET} ref={captchaRef} onChange={(e: any) => handleChangeCaptcha(e)} />
              </div>
            </div>
            <div className="mb-4">
              <Checkbox
                sx={{ ".MuiCheckbox-root": { paddingLeft: 0 } }}
                name="agreeToTerms"
                defaultChecked
                checked={agreeToTerms}
                onChange={handleChange}
              />
              *I agree to the{" "}
              <a href="https://edqan.com/terms-of-use/" target="_blank" className="main-link">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href="https://edqan.com/privacy-policy/" target="_blank" className="main-link">
                Privacy Policy
              </a>
              .
            </div>
            {!agreeToTerms && submitClicked && (
              <span className="text-[#ff000e] text-md bg-[#ffdbdb] p-2 rounded my-4 block">
                Agreement to the Terms of Use and the Privacy Policy is required.
              </span>
            )}
            <p className={styles.notificationsHint}>
              You will automatically receive edqan email notifications, you can unsubscribe at any time from your Notification Preferences page.
            </p>
            <p className={styles.hint}>
              Already have an account? <a onClick={handleClickLogin}>Login here</a>.{" "}
            </p>
            <div className={styles.actions}>
              <CustomButton text="Continue" type="submit" loading={loadingSubmit} />
            </div>
          </form>
        </div>
      </div>
      {/* <Footer noMargin={true} /> */}
    </div>
  );
};
export default SignUp;
