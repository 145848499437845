import { Chip, Stack } from "@mui/material";
import React, { useEffect } from "react";
export default function BackgroundInfo(user: any) {
  useEffect(() => {
    if (user?.user?.subModel?.background) {
      let descriptionID = document.getElementById('description') as HTMLElement
      descriptionID.innerHTML = user?.user?.subModel?.background

    }
  }, [user])
  return (
    <div className=" text-grey ">
      <div className=" flex">
        <div className="w-1/2">
          <h5 className="font-semibold mb-1 main-description ">Number of Employees</h5>
          <span className="main-description ">{user?.user?.subModel?.companySize.toLocaleString()} </span>

        </div>
        <div className="w-1/2">
          <h5 className="font-semibold mb-1 main-description ">Headquarters</h5>
          <span className="main-description ">
            {user?.user?.subModel?.headQuarters?.city?.nameEn},  {user?.user?.subModel?.headQuarters?.country?.nameEn}
          </span>

        </div>
      </div>

      <div className="my-4 ">
        <h5 className="font-semibold mb-1 main-description ">Industries</h5>
         <div className={`grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-4`}>

        {user?.user?.subModel?.industries?.map((industry: any, index: number) => {
          return (
            <div >
              <h5 className=" mb-2 main-description ">{industry?.industry?.nameEn}</h5>
              <div className="flex align-center">
                <Stack direction="row" gap={1} className="flex-wrap">
                  {
                    industry?.subIndustries?.map((subIndustry: any, index: number) => {
                      return (
                        <Chip label={subIndustry?.nameEn}

                        />
                      )
                    })}
                </Stack>

              </div>
            </div>
          )
        })}

      </div>
      </div>
     
      <p className=" main-description m-b-50" id="description">
      </p>

    </div>
  );
}
