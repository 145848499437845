import { Container } from '@mui/system'
import React, { ChangeEvent, useEffect, useState } from 'react'
import InputField from '../../../Components/Input'
import CustomizedButtons from '../../../Components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateUserProfile } from '../../../services/authService'
import { addAlertInfo } from '../../../store/alertSlice'
import { addAuthInfo } from '../../../store/authSlice'
import { getUserNewInfo } from '../../../services/userService'

export default function MessagingSetting() {
  const formData = new FormData()
  const dispatch = useDispatch()

  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [jobApplicationMessages, setJobApplicationMessages] = useState<any>({})
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [newData, setUserNewData] = useState<any>();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setJobApplicationMessages((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };
  function handleSubmit() {
    const applicationMessages = {
      received: jobApplicationMessages.received || newData?.subModel?.jobApplicationMessages?.received || userInfo?.subModel?.jobApplicationMessages?.received,
      notSelected: jobApplicationMessages.notSelected || newData?.subModel?.jobApplicationMessages?.notSelected || userInfo?.subModel?.jobApplicationMessages?.notSelected,
      hired: jobApplicationMessages.hired || newData?.subModel?.jobApplicationMessages?.hired || userInfo?.subModel?.jobApplicationMessages?.hired
    }

    formData.append('jobApplicationMessages', JSON.stringify(applicationMessages));


    setLoadingSubmit(true)
    setTimeout(async () => {
      const response = await UpdateUserProfile(userInfo?._id, formData)
      if (response.status === 200) {
        setLoadingSubmit(false)
        dispatch(addAlertInfo(
          {
            show: true,
            message: response?.data?.data?.message,
          }
        ))
        setShowHint(true)
      }
      else {
        setLoadingSubmit(false)
        dispatch(addAlertInfo(
          {
            show: true,
            message: response.data.error,
            type: 'error'
          }
        ))
      }


    }, 1000);
  }
  const getNewData = async () => {
    const response = await getUserNewInfo(userInfo?._id)
    if (response.status === 200) {
      setUserNewData(response?.data?.data)
      // setLoadingPage(false)
    }

  }
  useEffect(() => {
    if (token) {
      if (userInfo?.newUpdate) {
        getNewData()
      }
      else { }
    }

  }, [token]);
  return (
    <Container>
      <h3 className='title header-underline p-b-20'>Job Application - Messaging </h3>
      {(userInfo?.newUpdate || showHint) &&
        <div className="text-[14px] bg-[#f26403] font-[500] p-2 rounded  text-white mb-4 ">
          <p className=" text-white">You updated some of your data and still waiting for the admin approval,  this information visible only for you in your settings page, it wil be visible for others and in your profile after admin approval.
          </p>
        </div>}
      <form className='md:w-3/4 sm:w-full'>
        <div className='form-item'>
          <InputField
            defaultValue={newData?.subModel?.jobApplicationMessages?.received || userInfo?.subModel?.jobApplicationMessages?.received}
            Change={handleChange}
            name='received'
            required={false}
            textArea={true}
            label='Application Received'
            multiline={true}
            rows={4}
            placeholder='Customize your generic message to applicant here ex. "Thank you, we have received your application."'
          />
        </div>
        <div className='form-item'>
          <InputField
            defaultValue={newData?.subModel?.jobApplicationMessages?.notSelected || userInfo?.subModel?.jobApplicationMessages?.notSelected}
            Change={handleChange}
            name='notSelected'
            required={false}
            textArea={true}
            label='Not Selected'
            multiline={true}
            rows={4}
            placeholder='Customize your generic message to applicant here ex. "We thank you for time, but your application does not meet the specified job criteria."'
          />
        </div>
        <div className='form-item'>
          <InputField
            defaultValue={newData?.subModel?.jobApplicationMessages?.hired || userInfo?.subModel?.jobApplicationMessages?.hired}
            Change={handleChange}
            name='hired'
            required={false}
            label='Hired'
            textArea={true}
            multiline={true}
            rows={4}
            placeholder="No message will be sent. Employer can send message via their edqan inbox and it will go to user's email and to their edqan inbox. Employers can customize these messages accordingly."
          />
        </div>
        <div className='form-item'>
          <div className='actions-end'>
            <CustomizedButtons text="Save" loading={loadingSubmit} click={handleSubmit} />
          </div>
        </div>
      </form>
    </Container>
  )
}
