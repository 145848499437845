import { Container, Grid, IconButton } from "@mui/material";
import styles from "./footer.module.scss";

import LogoImage from "../../assets/images/Logo.png";
import Moment from "react-moment";
interface FooterProps {
  noMargin?:boolean
}

const Footer = (props: FooterProps) => {
  return (
    <div className={`${styles["footer"]} ${props.noMargin&&styles["no-margin"]}`}>
      <Container>
        {/* <div className={styles.upperFooter}>
          <Grid container>
            <Grid item xs={12} lg={3} className={styles.LogoImage}>
              <img src={LogoImage} alt="logo" />
            </Grid>
            <Grid item xs={12} md={9} lg={9} className={styles.Content}>
              <Grid container className={styles.footerContent}>
                <div className={styles.footer_list}>
                  <ul>
                    <h5>Job Finders</h5>
                    <li>Students/Early Career Professionals FAQs</li>
                    <li>Employers Hiring</li>
                    <li>Events</li>
                    <li>How it works?</li>
                  </ul>
                </div>
                <div className={styles.footer_list}>
                  <ul>
                    <h5>Educational Institutions</h5>
                    <li>Contact Us for Demo</li>
                    <li>Educational Institutions FAQs</li>
                  </ul>
                </div>
                <div className={styles.footer_list}>
                  <ul>
                    <h5>Employers</h5>
                    <li>Employers FAQs</li>
                  </ul>
                </div>
                <div className={styles.footer_list}>
                  <ul>
                    <h5>Mentorship Program</h5>
                    <li>Mentorship & Mentee FAQs</li>
                  </ul>
                </div>
                <div className={styles.footer_list}>
                  <ul>
                    <h5>Company</h5>
                    <li>About Us</li>
                    <li>Contact Us</li>
                    <li>Careers</li>
                    <li>Blog</li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div> */}

        <div className={styles.buttomFooter}>
          <div className={styles.Content}>
            <div className={styles.links}>
              <a href="https://edqan.com/privacy-policy/" target="_blank">Privacy Policy</a>
              <a href="https://edqan.com/terms-of-use/" target="blank">Terms of Use</a>
            </div>

            <p className={styles.copyrights}>
              ©<Moment format="YYYY"></Moment> edqan. All rights reserved.
            </p>
            {/* <ul>
              <li>
                <svg
                  width="8"
                  height="17"
                  viewBox="0 0 8 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.184 16.0293V8.76263H7.552L7.936 5.89596H5.248V4.09596C5.184 3.2293 5.376 2.69596 6.528 2.69596H8V0.16263C7.68 0.0959635 6.848 0.0292969 5.824 0.0292969C3.712 0.0292969 2.304 1.36263 2.304 3.76263V5.8293H0V8.69596H2.368V16.0293H5.184Z"
                    fill="white"
                  />
                </svg>
              </li>
              <li>
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7033 1.3694C14.4534 1.49123 14.2036 1.55214 13.9537 1.61305C14.2661 1.30848 14.4534 0.882089 14.5784 0.516607C14.5784 0.394779 14.5784 0.333865 14.5159 0.272952C14.4534 0.212038 14.3285 0.212038 14.2661 0.272952C13.7664 0.57752 13.2042 0.821175 12.5796 0.943003C11.9549 0.394779 11.1429 0.0292969 10.3309 0.0292969C8.58193 0.0292969 7.08282 1.43031 7.08282 3.19681C7.08282 3.31864 7.08282 3.44046 7.08282 3.62321C4.89662 3.37955 2.8978 2.34402 1.46115 0.638434C1.39869 0.57752 1.33623 0.516607 1.27376 0.516607C1.2113 0.516607 1.14884 0.57752 1.08637 0.638434C0.77406 1.12574 0.649134 1.67397 0.649134 2.22219C0.649134 2.95316 0.898986 3.68412 1.39869 4.23234C1.27376 4.23234 1.08637 4.17143 0.961449 4.11052C0.898986 4.0496 0.77406 4.0496 0.711597 4.11052C0.649134 4.11052 0.586671 4.23234 0.586671 4.29326C0.586671 4.29326 0.586671 4.29326 0.586671 4.35417C0.586671 5.51153 1.2113 6.48615 2.14825 7.03437C2.08578 7.03437 1.96086 7.03437 1.89839 6.97346C1.83593 6.97346 1.711 6.97346 1.64854 7.03437C1.58608 7.09529 1.58608 7.1562 1.58608 7.27803C1.96086 8.37448 2.83534 9.16635 3.95967 9.41001C3.08519 9.95823 2.08578 10.2628 0.961449 10.2628C0.711597 10.2628 0.524208 10.2628 0.274356 10.2019C0.14943 10.2019 0.0869675 10.2628 0.0245045 10.3846C-0.0379584 10.5065 0.0245045 10.6283 0.14943 10.6892C1.52362 11.542 3.14765 12.0293 4.83415 12.0293C8.08223 12.0293 10.1435 10.5065 11.3303 9.28818C12.7669 7.70442 13.579 5.63336 13.579 3.56229C13.579 3.50138 13.579 3.37955 13.579 3.31864C14.0787 2.83133 14.5784 2.34402 14.9532 1.73488C15.0156 1.67397 15.0156 1.55214 14.9532 1.43031C14.8907 1.3694 14.7658 1.3694 14.7033 1.3694Z"
                    fill="white"
                  />
                </svg>
              </li>
              <li>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.66566C0 1.18081 0.1875 0.817176 0.5 0.514145C0.8125 0.211115 1.25 0.0292969 1.8125 0.0292969C2.3125 0.0292969 2.75 0.150509 3.125 0.453539C3.4375 0.75657 3.625 1.18081 3.625 1.66566C3.625 2.15051 3.4375 2.51415 3.125 2.81718C2.8125 3.12021 2.375 3.30202 1.8125 3.30202C1.25 3.30202 0.8125 3.12021 0.5 2.81718C0.1875 2.51415 0 2.15051 0 1.66566ZM0.1875 13.9687V4.57475H3.375V13.9687H0.1875ZM5.1875 13.9687H8.375V8.75657C8.375 8.45354 8.4375 8.15051 8.5 7.96869C8.625 7.66566 8.8125 7.36263 9.125 7.18081C9.375 6.93839 9.75 6.87778 10.1875 6.87778C11.25 6.87778 11.8125 7.60505 11.8125 8.99899V14.0293H15V8.63536C15 7.24142 14.6875 6.21112 14 5.48384C13.3125 4.75657 12.4375 4.39293 11.3125 4.39293C10.0625 4.39293 9.125 4.93839 8.375 5.96869V4.57475H5.1875C5.1875 4.87778 5.1875 5.78687 5.1875 7.36263C5.1875 8.93839 5.1875 11.1202 5.1875 13.9687Z"
                    fill="white"
                  />
                </svg>
              </li>
              <li>
                <svg
                  width="19"
                  height="14"
                  viewBox="0 0 19 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.6042 2.0412C18.3667 1.26739 17.7333 0.648345 16.9417 0.416202C15.4375 0.0292969 9.5 0.0292969 9.5 0.0292969C9.5 0.0292969 3.5625 0.0292969 2.05833 0.416202C1.26667 0.648345 0.633333 1.26739 0.395833 2.0412C0 3.51144 0 6.5293 0 6.5293C0 6.5293 0 9.54715 0.395833 11.0174C0.633333 11.7912 1.26667 12.4102 2.05833 12.6424C3.5625 13.0293 9.5 13.0293 9.5 13.0293C9.5 13.0293 15.4375 13.0293 16.9417 12.6424C17.7333 12.4102 18.3667 11.7912 18.6042 11.0174C19 9.54715 19 6.5293 19 6.5293C19 6.5293 19 3.51144 18.6042 2.0412ZM7.6 9.31501V3.74358L12.5083 6.5293L7.6 9.31501Z"
                    fill="white"
                  />
                </svg>
              </li>
            </ul> */}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
