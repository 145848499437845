import { ChangeEvent, useRef, useState } from "react";

import CustomButton from "../../../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../Components/Input";

import { useDispatch, useSelector } from 'react-redux';
import FormErrorMessage from "../../../../Components/FormErrorMessage";
import { addAlertInfo } from "../../../../store/alertSlice"
import { UserRestPassword } from "../../../../services/userService";

const ForgotPassword = (props: any) => {

  const [loginData, SetLoginData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showSend, setShowSend] = useState(false)


  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    SetLoginData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };

  const RestPassword = async () => {
    // e.preventDefault()
    setLoading(true)
    setErrorMessage('')
    const res = await UserRestPassword(loginData.email.toLowerCase().trim())
    if (res.status === 200) {
      setShowSend(true)
    } else {
      setErrorMessage("it looks like you entered a wrong email please check and try again")

    }
    setLoading(false)
  }

  return (
    <div >
      <h1 className="title">Forgot password</h1>
      {errorMessage && (
        <FormErrorMessage errorMessage={errorMessage} />
      )}

      <div className='form-item'>
        <InputField
          label='Enter your email to send reset link'
          name="email" required={true}
          placeholder='Enter your email'
          Change={handleChange} />
        {showSend && <p className="border bg-light-orange mt-4 rounded-lg p-2 colored text-center">Edqan sent a password reset link to "{loginData.email}".  If you don't see the email, check your spam folder.</p>}
      </div>


      <a className="main-link flex" onClick={() => props.setShowForgotPassword(false)}>
        Back to login
      </a>
      <div className="actions-end ">
        <CustomButton type="button" loading={loading} text={`Continue`} click={RestPassword}/>
      </div>
    </div>
  );
};

export default ForgotPassword;
