import React, { useEffect, useRef, useState } from "react";
import { Container } from "@mui/system";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import styles from "./account-setting.module.scss";
import CustomButton from "../../../Components/CustomButton";

import InputField from "../../../Components/Input";
import SelectInput from "../../../Components/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { Language } from "../../../models/language";
import { UpdateMentorProfile } from "../../../services/authService";
import { ListAllIndustry } from "../../../services/industry";
import { ListAlltitutions } from "../../../services/institutionService";
import { listAllCountries, listAllCities } from "../../../services/locationService";
import { listAllMajor } from "../../../services/majorsServise";
import { ListAllMentorShipTypes } from "../../../services/mentorshipService";
import { listCompanies } from "../../../services/students/companies";
import { addAlertInfo } from "../../../store/alertSlice";
import { listAllLanguages } from "../../../services/languageService";
import { listAllAreaInterest } from "../../../services/areaInterestservice";
import { listAllEducationLevel } from "../../../services/educationLevelService";
import UploadeProfileImage from "./components/uploadeProfileImage";
import moment from "moment";
import PasswordPopUp from "../../Student/AccountSetting/components/passwordPopup";
import { getUserInfo } from "../../../services/userService";
import { addAuthInfo } from "../../../store/authSlice";

export default function AccountSetting() {
  const validateList = [
    "firstName",
    "lastName",
    "email",
    "currentTitle",
    "linkedInUrl",
    "country",
    "city",
    // "mentorShipTypes",
    "areaOfInterest",
    "about",
    "industries",
  ];

  const validateListNames = [
    "First name",
    "Last name",
    "Email",
    "Current title",
    "Professional Website or LinkedIn Profile",
    "Country",
    "City",
    // "How you want to mentor",
    "Tell us how you want to mentor",
    "Description that will appear on your profile for mentees",
    "Industries",
  ];

  const validateForm = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return false;
    }
    if (!userData?.major && radioValue === true) return false;
    if (!graduationMonth && radioValue === true) return false;
    if (!graduationYear && radioValue === true) return false;
    if (!userData?.areaOfInterest) return false;
    return true;
  };
  const getErrorMessage = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return validateListNames[i] + " is required";
    }
    if (!userData?.major && radioValue === true) return "Major is required";
    if (!graduationMonth && radioValue === true) return "Graduation month is required";
    if (!graduationYear && radioValue === true) return "Graduation year is required";
    if (!userData?.areaOfInterest) return "Tell us how you want to mentor is required";

    return true;
  };
  const user = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [userMajors, setUserMajors] = useState<any>();
  // const navigate = useNavigate();
  const [privacy, setPrivacy] = useState("community");
  const [show, setShow] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [type, setType] = useState("");
  const UploadLogo = () => {
    setType("image");
    setShow(true);
  };
  const UploadVideo = () => {
    setShowVideo(true);
  };

  function handleClick() {}

  const handleChangePassword = () => {
    setOpen(true);
  };
  const [loadingPage, setLoadingPage] = React.useState(true);

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const captchaRef = useRef(null);
  const [userData, setUserData] = useState<any>();
  const [radioValue, setRadioValue] = useState(true);
  const [radioGender, setRadioGender] = useState("");

  const [industry, setAllIndustry] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [majors, setMajors] = useState([]);

  const [loadingIndustry, setloadingIndustry] = useState(true);
  const [loadingMajors, setLoadingMajors] = useState(true);

  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);

  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [companiesList, setCompaniesList] = React.useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);

  const [loadingnstitutions, setILoadingnstitutions] = React.useState(true);
  const [institutionsList, setInstitutionsList] = useState([]);

  const [loadingnMentorShipType, setLoadingnMentorShipType] = React.useState(true);
  const [mentorShipTypeList, setMentorShipTypeList] = useState([]);
  const [Languages, setLanguages] = useState([]);
  const [loadingLanguages, setLoadingLanguages] = useState(true);
  const [educationLevels, setEducationLevels] = useState([]);
  const [allAreaInterest, setAllAreaInterest] = useState([]);

  const [loadingEducationLevels, setLoadingEducationLevels] = useState(true);
  const [loadingAreaInterest, setloadingAreaInterest] = useState(true);

  const [graduationMonth, setGraduationMonth] = useState<any>();
  const [graduationYear, setGraduationYear] = useState<any>();
  const [selectedCity, setSelectedCity] = useState<any>();

  const getUserData = async () => {
    const response = await getUserInfo(user._id);
    if (response) {
      dispatch(
        addAuthInfo({
          token: token,
          user: response.data.data,
        })
      );
    }
  };
  async function handleSignUp(e: any) {
    const formData = new FormData();
    setSubmitClicked(true);

    if (validateForm()) {
      formData.append("accountVisibility", privacy);
      formData.append("enrolledInHighSchool", radioValue.toString());
      formData.append("gender", radioGender);
      formData.append(
        "graduationDate",
        graduationYear ? moment(graduationYear).format("yyyy") + "-" + moment(graduationMonth).format("MM-DD") : "null"
      );

      for (const property in userData) {
        if (property != "enrolledInHighSchool" && property != "accountVisibility" && property != "graduationDate" && property != "gender") {
          if (Array.isArray(userData[property])) {
            if (userData[property].length !== 0) {
              formData.append(property, JSON.stringify(userData[property].map((item: any) => (item.id ? item.id : item))));
            }
          } else if (typeof userData[property] == "object") {
            formData.append(property, userData[property]?.id ? userData[property]?.id : userData[property]);
          } else {
            formData.append(property, userData[property]);
          }
        }
      }
      setLoadingSubmit(true);

      setTimeout(async () => {
        const res = await UpdateMentorProfile(user._id, formData);
        if (res.status === 200) {
          getUserData();
          dispatch(
            addAlertInfo({
              show: true,
              message: "Data updated successfully",
            })
          );

          setLoadingSubmit(false);
        } else {
          dispatch(
            addAlertInfo({
              show: true,
              message: res.data.error,
              type: "error",
            })
          );

          setLoadingSubmit(false);
        }
      }, 1000);
    } else {
      dispatch(
        addAlertInfo({
          show: true,
          message: getErrorMessage(),
          type: "error",
        })
      );
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "enrolledInHighSchool") {
      setRadioValue(!radioValue);
    }

    if (e.target.name === "gender") {
      setRadioGender(e.target.value);
    } else {
      setUserData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
    }
  };

  const handleSelectChange = (e: any) => {
    if (e.name == "major") {
      setUserMajors(e.value);
    }
    const isArray = Array.isArray(e.value);

    if (isArray) {
      setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.length > 0 ? e.value.map((item: any) => item.id) : null }));
    } else {
      setUserData((_data: any) => ({
        ..._data,
        [e.name]: e?.value ? (e.name == "company" ? e.value : e.value.id) : null,
      }));
    }
  };

  const handleSelectContry = (e: any) => {
    handleSelectChange(e);

    setSelectedCity(null);
    setUserData((_data: any) => ({ ..._data, city: null }));

    if (e.value) {
      getAllCities(e.value.id);
    } else {
      setCities([]);
    }
  };
  // list All Languages
  const getAllLanguages = async () => {
    const response = await listAllLanguages();
    if (response) {
      const langs = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setLanguages(langs);
      setLoadingLanguages(false);
    }
  };

  const getAllMentorShipTypes = async () => {
    const response = await ListAllMentorShipTypes();
    if (response) {
      const data = response.data.data.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setMentorShipTypeList(data);
      setLoadingnMentorShipType(false);
    }
  };

  const getAllCountries = async () => {
    const response = await listAllCountries();
    if (response) {
      const countries = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCountries(countries);
      setLoadingCountries(false);
    }
  };

  const getAllCities = async (countryId: string) => {
    setLoadingCities(true);
    const response = await listAllCities(countryId);
    if (response) {
      const citiesOfCountry = response.data.data;
      const cities = citiesOfCountry.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCities(cities);
      setLoadingCities(false);
    }
  };

  // list All Majors
  const getAllMajors = async () => {
    const response = await listAllMajor();
    if (response) {
      const majors = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setMajors(majors);
      setLoadingMajors(false);
    }
  };
  const getCompaniesList = async () => {
    const response = await listCompanies({ currentPage: null });
    if (response) {
      const companies = response.data.data.queryResult.map((item: any) => item.subModel.name);
      setCompaniesList(companies);
      setLoadingCompanies(false);
    }
  };
  const getAlltitutions = async () => {
    const response = await ListAlltitutions();
    if (response) {
      const institutions = response.data.data.queryResult.map((item: any) => ({ label: item?.subModel?.name, id: item._id }));
      setInstitutionsList(institutions);
      setILoadingnstitutions(false);
    }
  };
  const getAllIndustry = async () => {
    setLoadingCities(true);
    const response = await ListAllIndustry();
    if (response) {
      const data = response.data.data.map((item: any) => ({ label: item.nameEn, id: item._id }));

      setAllIndustry(data);
      setloadingIndustry(false);
    }
  };
  // list Education Levels
  const getAllEducationLevel = async () => {
    const response = await listAllEducationLevel();
    if (response) {
      const educationLevels = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setEducationLevels(educationLevels);
      setLoadingEducationLevels(false);
    }
  };
  // list All Area Interest
  const getAllAreaInterest = async () => {
    const response = await listAllAreaInterest();
    if (response) {
      const allAreaInterest = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setAllAreaInterest(allAreaInterest);
      setloadingAreaInterest(false);
    }
  };
  useEffect(() => {
    if (token) {
      // console.log(user?.subModel?.company)
      setLoadingPage(false);
      getAllEducationLevel();
      getAllAreaInterest();
      getCompaniesList();
      getAllIndustry();
      getAllMajors();
      getAllCountries();
      getAlltitutions();
      getAllMentorShipTypes();
      getAllLanguages();
      // setUserData((_data: any) => ({ ..._data, 'model': 'mentor' }));
      setRadioValue(user?.subModel?.enrolledInHighSchool ? true : false);
      setGraduationMonth(user?.subModel?.graduationDate);
      setGraduationYear(user?.subModel?.graduationDate);
      setPrivacy(user?.subModel?.accountVisibility);
      setRadioGender(user?.subModel?.gender);
      setUserMajors(user?.subModel?.major ? { label: user?.subModel?.major?.nameEn, id: user?.subModel?.major?._id } : null);
      setUserData({
        firstName: user?.subModel?.firstName,
        lastName: user?.subModel?.lastName,
        middleName: user?.subModel?.middleName,
        email: user?.email,
        linkedInUrl: user?.subModel.linkedInUrl,
        about: user?.subModel?.about,
        currentTitle: user?.subModel?.currentTitle,
        // proficientLanguage: user?.subModel?.proficientLanguage.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id })),
        countries: user?.subModel?.countries.length > 0 ? user?.subModel?.countries.map((item: any) => ({ label: item.nameEn, id: item._id })) : [],
        company: user?.subModel?.company && user?.subModel?.company,
        country: user?.country?.nameEn ? { label: user?.country?.nameEn, id: user?.country?._id } : null,
        city: user?.city ? { label: user?.city?.nameEn, id: user?.city?._id } : null,
        almaMater: user?.subModel?.almaMater ? { label: user?.subModel?.almaMater?.subModel?.name, id: user?.subModel?.almaMater?._id } : null,

        mentoringLanguages: user?.subModel?.mentoringLanguages.map((item: Language) => ({ label: item.nameEn, id: item._id })),
        mentorShipTypes: user?.subModel?.mentorShipTypes.map((item: Language) => ({ label: item.nameEn, id: item._id })),
        industries:
          user?.subModel?.industries.length > 0 ? user?.subModel?.industries.map((item: any) => ({ label: item.nameEn, id: item._id })) : null,
        major: user?.subModel?.major ? { label: user?.subModel?.major?.nameEn, id: user?.subModel?.major?._id } : null,
        areaOfInterest: user?.subModel?.areaOfInterest
          ? user?.subModel?.areaOfInterest.map((item: any) => ({ label: item.nameEn, id: item._id }))
          : [],
        educationLevel: user?.subModel?.educationLevel
          ? { label: user?.subModel?.educationLevel?.nameEn, id: user?.subModel?.educationLevel?._id }
          : null,
        majors: user?.subModel?.majors.length > 0 ? user?.subModel?.majors.map((item: any) => ({ label: item.nameEn, id: item._id })) : [],
        // industries: user?.subModel?.industries.length > 0 ? user?.subModel?.industries.map((item: any) => ({ 'label': item.nameEn, 'id': item._id })) : [],
        video: user?.subModel?.video,
      });
    }
  }, [token]);

  return (
    <Container>
      {!loadingPage && (
        <>
          <div className={styles["form"]} id="form">
            <div className="m-b-50 main-b-border pb-6">
              <h3 className="title ">Account Settings</h3>
              <p className="secondary-title ">Tell us a little about you so we can customize your experience</p>
              <span className="required-hint">Fields marked with an * are required.</span>
            </div>
            <UploadeProfileImage />
            <form className="w-full ">
              <div className="lg:w-1/2 md:w-3/4">
                <div className="form-item">
                  <InputField
                    label="First name"
                    name="firstName"
                    required={true}
                    placeholder="First name"
                    Change={handleChange}
                    error={!userData?.firstName && submitClicked}
                    submitClicked={submitClicked}
                    value={userData?.firstName}
                  />
                </div>
                <div className="form-item">
                  <InputField
                    label="Middle name"
                    name="middleName"
                    required={false}
                    placeholder="Middle name"
                    Change={handleChange}
                    value={userData?.middleName}
                    submitClicked={submitClicked}
                  />
                </div>
                <div className="form-item">
                  <InputField
                    label="Last name"
                    name="lastName"
                    required={true}
                    placeholder="Last name"
                    Change={handleChange}
                    error={!userData?.lastName && submitClicked}
                    submitClicked={submitClicked}
                    value={userData?.lastName}
                  />
                </div>
                <div className="form-item">
                  <InputField
                    label="Email address"
                    name="email"
                    required={true}
                    placeholder="Primary email"
                    Change={handleChange}
                    error={!userData?.email && submitClicked}
                    submitClicked={submitClicked}
                    value={userData?.email}
                  />
                </div>
                <div className="form-item">
                  <InputField
                    required
                    label="Website/Profile Link"
                    placeholder="Linkedin Profile"
                    name="linkedInUrl"
                    Change={handleChange}
                    error={!userData?.linkedInUrl && submitClicked}
                    submitClicked={submitClicked}
                    value={userData?.linkedInUrl}
                  />
                </div>
                <div className="form-item">
                  <InputField
                    label="Current title"
                    name="currentTitle"
                    required={true}
                    placeholder="current title"
                    Change={handleChange}
                    submitClicked={submitClicked}
                    value={userData?.currentTitle}
                    error={!userData?.currentTitle && submitClicked}
                  />
                </div>
                <div className="form-item">
                  <label className="relative flex justify-between">
                    <span>* Add brief bio</span>
                    <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{userData?.about?.length || 0}/1500</p>
                  </label>
                  <textarea
                    maxLength={1500}
                    className="w-full border hover:border p-2 rounded-lg"
                    rows={4}
                    placeholder="Enter text here..."
                    name="about"
                    value={userData?.about}
                    onChange={(e: any) => handleChange(e)}
                  />
                  {!userData?.about && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
                </div>

                <div className="form-item">
                  {/* <SelectInput labelHint='If your company name is not in the list, select other and enter it in the field that will appear.' required={true} label='Company' disablePortal={true} options={options} /> */}
                  <SelectInput
                    label="Company"
                    name="company"
                    Change={handleSelectChange}
                    required={false}
                    options={companiesList}
                    loading={loadingCompanies}
                    disablePortal={true}
                    defaultValue={userData?.company}
                  />
                </div>
                <div className="form-item">
                  <SelectInput
                    required={false}
                    label="Alma Mater (this can connect you to students/early career professionals from your school)"
                    placeholder="Enter latest education here..."
                    disablePortal={true}
                    options={institutionsList}
                    loading={loadingnstitutions}
                    Change={handleSelectChange}
                    name="almaMater"
                    defaultValue={userData?.almaMater}
                  />
                </div>
                {/* <div className='form-item'>

                                    <SelectInput disablePortal={true} label='Your location' required={false} options={options} />
                                </div> */}
                <div className="form-item">
                  <Grid container>
                    <Grid item xs={12}>
                      <div className="form-item ">
                        <SelectInput
                          name="country"
                          required={true}
                          label="Select your country"
                          options={countries}
                          Change={handleSelectContry}
                          loading={loadingCountries}
                          submitClicked={submitClicked}
                          defaultValue={userData?.country}
                          error={!userData?.country && submitClicked}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="form-item ">
                        <SelectInput
                          name="city"
                          required={true}
                          labelHint="Select your country first"
                          label="Select your city (region/state/province options will appear with the city)"
                          options={cities}
                          Change={handleSelectChange}
                          loading={loadingCities}
                          value={selectedCity}
                          submitClicked={submitClicked}
                          defaultValue={userData?.city}
                          disabled={!userData.country}
                          error={!userData?.city && submitClicked}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {/* <SelectInput disablePortal={true} label='Your location' required={false} options={options} /> */}
                </div>
                {/* <div className='form-item'>

                                    <SelectInput disablePortal={true} label='Gender' required={true} options={['Female', 'Male']} />
                                </div> */}
                <div className="form-item">
                  <FormControl className={styles.radioGroup}>
                    <div className="input-label">
                      {" "}
                      <label className="text-[#000000]">Gender</label>
                    </div>
                    <RadioGroup name="gender" value={radioGender} onChange={handleChange} row>
                      <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                      <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="form-item">
                  <SelectInput
                    required={false}
                    label="Select preferred language for mentoring "
                    multiple={true}
                    options={Languages}
                    defaultValue={userData?.mentoringLanguages}
                    Change={handleSelectChange}
                    loading={loadingLanguages}
                    submitClicked={submitClicked}
                    name="mentoringLanguages"
                  />
                </div>
                <div className="form-item">
                  <SelectInput
                    required={false}
                    label="Which countries are you familiar living in?"
                    multiple={true}
                    options={countries}
                    Change={handleSelectChange}
                    loading={loadingCountries}
                    submitClicked={submitClicked}
                    defaultValue={userData?.countries}
                    name="countries"
                  />
                </div>
                <div className="form-item">
                  <SelectInput
                    required={true}
                    error={!userData?.areaOfInterest && submitClicked}
                    name="areaOfInterest"
                    Change={handleSelectChange}
                    label="Tell us how you want to mentor"
                    options={allAreaInterest}
                    loading={loadingAreaInterest}
                    submitClicked={submitClicked}
                    defaultValue={userData?.areaOfInterest}
                    multiple={true}
                  />
                </div>
                <div className="form-item">
                  <SelectInput
                    required={false}
                    label="Enter majors you can mentor in"
                    placeholder="Enter major"
                    multiple={true}
                    loading={loadingMajors}
                    options={majors}
                    name="majors"
                    Change={handleSelectChange}
                    submitClicked={submitClicked}
                    defaultValue={userData?.majors}
                  />
                </div>
                <div className="form-item">
                  <SelectInput
                    required={true}
                    label="Enter industries you can mentor in"
                    placeholder="Enter industry"
                    multiple={true}
                    Change={handleSelectChange}
                    options={industry}
                    loading={loadingIndustry}
                    name="industries"
                    error={!userData?.industries && submitClicked}
                    submitClicked={submitClicked}
                    defaultValue={userData?.industries}
                  />
                </div>
                <div className="form-item">
                  <SelectInput
                    required={false}
                    label="Level of education"
                    name="educationLevel"
                    Change={handleSelectChange}
                    multiple={false}
                    options={educationLevels}
                    loading={loadingEducationLevels}
                    // error={!userData?.educationLevel && submitClicked}
                    submitClicked={submitClicked}
                    defaultValue={userData.educationLevel}
                  />
                </div>
                {/* <div className='form-item'>

                                    <SelectInput placeholder='Browse' required={false} label='Upload profile photo' multiple={false} options={[]} />
                                    <div className='custom-action mt-4'>
                                        <CustomButton text="Upload" click={UploadLogo} />
                                    </div>


                                </div> */}
                <div className="form-item">
                  <InputField
                    Change={handleChange}
                    defaultValue={userData.video}
                    value={userData?.video}
                    placeholder="URL"
                    required={false}
                    name="video"
                    label="If you would like to add a video telling a mentor how you can support, please add the url here."
                  />
                </div>
                {/* 
                                <div className='form-item'>
                                    <FormControl className={styles.radioGroup}>
                                        <label  >
                                            <strong>Are you currently enrolled in higher education (eg. degree level)?
                                            </strong></label>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="enrolledInHighSchool"
                                            value={radioValue}
                                            onChange={handleChange}
                                            row
                                        >
                                            <FormControlLabel value={true} control={<Radio size='small' />} label="Yes" />
                                            <FormControlLabel value={false} control={<Radio size='small' />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                </div> */}

                <>
                  <label>Graduation date (estimation accepted)</label>
                  <Grid container columnSpacing="5">
                    <Grid item xs={12} md={6}>
                      <DatePicker
                        label={`${radioValue === true ? "*" : ""}Month`}
                        openTo="month"
                        views={["month"]}
                        value={graduationMonth}
                        onChange={(newValue) => {
                          setGraduationMonth(newValue);
                        }}
                        inputFormat="MMMM"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                            fullWidth
                            margin="normal"
                            helperText={null}
                          />
                        )}
                      />
                      {radioValue && !graduationMonth && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <DatePicker
                        views={["year"]}
                        label={`${radioValue === true ? "*" : ""}Year`}
                        openTo="year"
                        value={graduationYear}
                        onChange={(newValue) => {
                          setGraduationYear(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "",
                            }}
                            margin="normal"
                            helperText={null}
                          />
                        )}
                      />
                      {radioValue && !graduationYear && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
                    </Grid>
                  </Grid>
                </>

                {/* <p className={[styles.notificationsHint, 'mb-5'].join(" ")}>ou will automatically receive edqan email notifications, you can unsubscribe at any time from your Notification Preferences page.</p> */}
              </div>
              <div className="custom-action-fill">
                <CustomButton text="Change password" click={handleChangePassword} />
              </div>

              <PasswordPopUp show={open} setOpen={setOpen} />
              <div className="my-10">
                <h3 className="secondary-title">Privacy Settings</h3>
                <div className="main-border p-4">
                  <p className="main-description mb-4">Update Privacy</p>
                  <FormControl>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="female" name="radio-buttons-group">
                      <div className="flex items-start mb-4 cursor-pointer gap-2" onClick={() => setPrivacy("community")}>
                        <FormControlLabel value="community" control={<Radio size="small" checked={privacy === "community"} />} label="" />
                        <div>
                          <p className="list-title mb-2 ">
                            Students/Professionals & Mentors <span className="mx-2 bg-[#FFFAF7] p-1 text-[#f26400] ">Recommended</span>
                          </p>
                          <p className="main-description ">
                            Your profile will be visible to students, early career professionals and other mentors that are part of the edqan network
                            . You will be able to communicate with many of them across our network.{" "}
                          </p>
                        </div>
                      </div>
                      <div className="flex items-start mb-4 cursor-pointer gap-2" onClick={() => setPrivacy("hidden")}>
                        <FormControlLabel value="private" control={<Radio size="small" />} label="" checked={privacy === "hidden"} />
                        <div>
                          <p className="list-title mb-2 ">Private</p>
                          <p className="main-description ">Your profile will not be visible to the public.</p>
                        </div>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
              {/* <div className='my-10'>
                                <p className='secondary-title'>Calendar export</p>
                                <p className={'main-description'}>Add your edqan events, meetings, interviews to your calendar by using the following URL so your calendar can be automatically updated with edqan updates
                                    .
                                    <a className='main-link'>You can also download the static iCalendar file.</a> Your calendar will only show available time slots.</p>
                            </div> */}

              <p className={"main-description mb-6"}>
                You will automatically receive edqan email notifications, you can unsubscribe at any time from your Notification Preferences page.
              </p>
              {/* <div className='mb-4'>
                                <Checkbox {...label} sx={{ '.MuiCheckbox-root': { paddingLeft: 0 } }} />
                                *I agree to the <a className='main-link'>Terms of Use</a> and <a className='main-link'>Privacy Policy</a>.
                            </div> */}
              <div className="actions-end">
                <CustomButton text="Save updates" click={handleSignUp} loading={loadingSubmit} />
              </div>
            </form>
          </div>
        </>
      )}
    </Container>
  );
}
