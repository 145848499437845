import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import { Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNotificationMethods } from '../../../../../services/notificationService';
import { UpdateUserNotifications } from '../../../../../services/userService';
import { addAlertInfo } from '../../../../../store/alertSlice';
import CustomizedButtons from '../../../../../Components/CustomButton';
interface DynamicTableProps {
    columns: Array<string>;
    alignment: Array<any>
    checked?: any;
    mouseAction?: Array<any>;
    coloredHeader?: boolean
    list?: any
}


export default function NotificationsTable(props: DynamicTableProps) {
    const { columns, alignment, checked, mouseAction, coloredHeader, list } = props;
    const dispatch = useDispatch()
    const token = useSelector((state: any) => state.auth.token);
    const user = useSelector((state: any) => state.auth.user);
    const notificationSettings = user?.notificationSettings
    const [methodslist, setMethodslist] = React.useState<any>([])
    const [loading, setLoading] = React.useState(false)
    const [notificationSettingsList, setNotificationSettingsList] = React.useState<any>([])
    const [notificationUpdated, setNotificationUpdated] = React.useState<any>(false)
    const listNotificationTypes = async () => {
        const response = await getAllNotificationMethods()
        setMethodslist(response.data.data)
    }

    const handleNotificationChage = async (e: any, index: number, kind: number) => {
        setNotificationUpdated(false)
        const temp = notificationSettingsList[list[index].kind] ? [...notificationSettingsList[list[index].kind]] : []
        e.target.checked && temp.push(kind)
        !e.target.checked && temp.splice(temp.indexOf(kind), 1)
        setNotificationSettingsList((_data: any) => ({ ..._data, [list[index].kind]: temp }))
        setNotificationUpdated(true)
    }
    const updateUserNotification = async () => {
        setLoading(true)
        const notificationSettings = Object.keys(notificationSettingsList).map((item: any) => ({ 'notificationType': parseInt(item), 'notificationMethod': notificationSettingsList[item] }))
        const response = await UpdateUserNotifications(user._id, notificationSettings)
        if (response.status === 200) {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'Preferences updated successfully'
                }
            ))

        }
        else {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: 'error'
                }
            ))
        }

    }
    const typeKind = (index: number) => {
        return list[index].kind
    }
    const ifChecked = (index: number, kind: any) => {

        return notificationSettingsList[index]?.includes(kind)
    }

    React.useEffect(() => {
        if (token) {
            listNotificationTypes()
            for (let i = 0; i < notificationSettings.length; i++) {
                setNotificationSettingsList((_data: any) => ({ ..._data, [notificationSettings[i].notificationType]: notificationSettings[i].notificationMethod }))
            }

        }
    }, [token])

    // React.useEffect(() => {
    //     notificationUpdated && updateUserNotification()
    // }, [notificationSettingsList])
    return (
        <>
          <div className='small-btn actions-end'>
                <CustomizedButtons text="Save Changes" click={updateUserNotification} loading={loading}/>
            </div>
           <div className='main-table my-8 w-full'>
          

            <Table size="small" aria-label="simple table">
                <TableHead >
                    <TableRow className={coloredHeader ? "colored-header" : ''}>
                        {columns.map((el, index) => {
                            return (
                                <TableCell align={alignment[index]}>
                                    <p className='list-title py-2'>{el}</p>
                                </TableCell>
                            )
                        })}
                        {methodslist.map((el: any, index: number) => {
                            return (
                                <TableCell align='center'>
                                    <p className='list-title py-2'>{el?.name}</p>
                                </TableCell>
                            )
                        })}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {list?.map((item: any, rowindex: any) => {
                        return (
                            <TableRow
                                key={rowindex}
                                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                            >
                                <TableCell>{item?.name}</TableCell>


                                {methodslist.map((el: any, index: number) => {
                                    return (<TableCell align='center'>
                                        <Checkbox
                                            onChange={(e: any) => handleNotificationChage(e, rowindex, el.kind)}
                                            icon={<DoDisturbIcon sx={{ color: 'red' }} />}
                                            checkedIcon={<CheckIcon color="success" />}
                                            defaultChecked={ifChecked(typeKind(rowindex), el.kind)} />
                                    </TableCell>
                                    )
                                })}


                            </TableRow>
                        )
                    }
                    )
                    }
                </TableBody>
            </Table>

        </div>
        </>
     
    );
}