import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import alertSlice from './alertSlice';
import followSlice from './followSlice';
import savedJobsSlice from './savedJobsSlice';
import mesagesSlice from './mesagesSlice ';


export const store = configureStore({
  reducer: {
   auth: authSlice,
   alert:alertSlice,
   companies:followSlice,
   savedJobs:savedJobsSlice,
   chats:mesagesSlice
  },
});
