import { Container, Pagination } from "@mui/material";
import styles from "./posts.module.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux"
import Post from "../../../Components/CardsList/Post";
import { Job } from "../../../models/job";
import { ListSavedJobs } from "../../../services/jobsService";
import { CardLoadingSkeleton } from "../Access/Tabs";
import NoDataMessageImage from "../../../Components/NoDataMessageWithImage";
interface PostsProps { }

const SavedJobs = (props: PostsProps) => {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const majorId = user?.subModel?.major?._id
  const [jobs, setJobs] = useState<Array<Job>>([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)
  const getGobs = async () => {
    setLoading(true)
    const response = await ListSavedJobs(user._id, true)
    if (response.status === 200) {
      setJobs(response.data.data.savedJobs)
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 20))
      setLoading(false)
    } else {
      setLoading(false)
    }
  }
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  useEffect(() => {
    if (token) {
      getGobs()
    }
  }, [token, currentPage]);
  return (
    <Container>
      <div className="m-b-50 main-b-border pb-6 header-underline">
        <div className='title'>{user?.subModel?.firstName}'s saved jobs</div>
        <p className='main-description'>These are a jobs you are interested in:</p>
      </div>

      <div className={styles.jobs_list}>
        <div className="grid rid-cols--1 md:grid-cols-3 gap-4">
          {!loading ?
            jobs && jobs.length > 0 &&
            jobs.map((item: any, i: number) => {
              return (
                <div key={i}>

                  <Post data={item} index={i} getGobs={getGobs}/>
                </div>
              )
            })
            :
            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any) => {
              return (
                <div key={item}>
                  <CardLoadingSkeleton />
                </div>
              )
            })}
        </div>
        {!loading && (!jobs || jobs.length ===0) &&
          <NoDataMessageImage />
        }
      </div>
      {totlaPages > 1 &&
        <div className="flex justify-end my-6">
          <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      }
    </Container>
  );
};

export default SavedJobs;
