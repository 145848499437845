import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomizedButtons from "../../Components/CustomButton";

const NotFound = () => {
    const user: any = localStorage.getItem('userInfo')
    const userInfolocalStorage = JSON.parse(user)
    const userInfo = useSelector((state: any) => state.auth.user);
    const User = userInfo?.model || userInfolocalStorage?.model
    const navigate = useNavigate()
    const handleNavigateion = () => {
        if (User === "Student") {
            window.location.href="/professionals-home"
        }

        else if(User==="Mentor") {
            window.location.href="/mentor/home"
        }
        else if(User==='Institution'){
            window.location.href="/institution/home"
        }
        else if(User==='Company'){
            window.location.href="/institution/home"
        }

    }
    return (
        <section className="page_404">
            <div className="">
                <div className="row">
                    <div className="col-sm-12 ">
                        <div className="col-sm-10 col-sm-offset-1  text-center">
                            <div className="four_zero_four_bg">
                                <h1 className="text-center ">404</h1>


                            </div>

                            <div className="contant_box_404">
                                <h3 className="h2">
                                    Look like you're lost
                                </h3>

                                <p>the page you are looking for not available!</p>
                                <div className='custom-action-fill mt-4'>
                                    <CustomizedButtons text={"Go to Home"} click={handleNavigateion} />

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};



export default NotFound;