
import React, { ChangeEvent, useEffect, useState } from 'react'
import CustomButton from '../../../../Components/CustomButton';
import InputField from '../../../../Components/Input';
import LanguagesSelection from '../../../../Components/ItemSelect/Languages';
import MentorShipTypesSelection from '../../../../Components/ItemSelect/MentorShipType';
import { useFormik } from 'formik';

import { addAlertInfo } from '../../../../store/alertSlice';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import SelectInput from '../../../../Components/SelectInput';
import { listAllAreaInterest } from '../../../../services/areaInterestservice';
import { isUrlValid, validateEmail } from '../../../helpers';
interface RequestMentorProps {
  setRequestData: any,
  getAllMentors: any
  requestData: any
  setStep: any
}
export default function RequestMentor(props: RequestMentorProps) {
  const { setRequestData, getAllMentors, requestData, setStep } = props
  const [submitClicked, setSubmitClicked] = useState(false);
  const [allAreaInterest, setAllAreaInterest] = useState([]);
  const [loadingAreaInterest, setloadingAreaInterest] = useState(true);
  const dispatch = useDispatch()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRequestData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
    formik.handleChange(e)
  };
  const handleSelectChange = (e: any) => {
    const isArray = Array.isArray(e.value);
    if (e.value) {
      if (isArray) {
        setRequestData((_data: any) => ({ ..._data, [e.name]: e.value.map((item: any) => item) }));
      }
      else {
        setRequestData((_data: any) => ({ ..._data, [e.name]: e.value }));
      }
    }
    if (e.name === 'mentorShipType') {
      getAllMentors(e.value.id)
    }

    setTimeout(() => {
      formik.handleChange(e.event)
    }, 100);

  }
  // list All Area Interest
  const getAllAreaInterest = async () => {
    const response = await listAllAreaInterest()
    if (response) {
      const allAreaInterest = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setAllAreaInterest(allAreaInterest)
      setloadingAreaInterest(false)
    }
  }
  const formik = useFormik({
    initialValues: {
      mentorShipType: null,
      preferredLanguage: '',
      WhyYouWouldLikeMentorShip: '',
      whatIsOutcomeYouAreLookingFor: '',
      enterYourLinkedinOrSocialMediaSite: '',
      domain: '',
      startUpEmail: ''

    },
    onSubmit: function () {
    },
    validationSchema: Yup.object({
      mentorShipType: Yup.string()
        .when('val', () => {
          if (!requestData?.mentorShipType) {
            return Yup.string().required("This field is required")
          }
          return Yup.string().notRequired()
        },
        ),
      preferredLanguage: Yup.string()
        .when('val', () => {
          if (requestData?.preferredLanguage === undefined) {
            return Yup.string().required("This field is required")
          }
          return Yup.string().notRequired()
        }
        ),
      WhyYouWouldLikeMentorShip: Yup.string()
        .when('val', () => {
          if (requestData?.WhyYouWouldLikeMentorShip === undefined || requestData?.WhyYouWouldLikeMentorShip === '') {
            return Yup.string().required("This field is required")
          }
          return Yup.string().notRequired()
        }),
      whatIsOutcomeYouAreLookingFor: Yup.string()
        .when('val', () => {
          if (requestData?.whatIsOutcomeYouAreLookingFor === undefined || requestData?.whatIsOutcomeYouAreLookingFor === '') {
            return Yup.string().required("This field is required")
          }
          return Yup.string().notRequired()
        }),
      enterYourLinkedinOrSocialMediaSite: Yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url'
        ),
      domain: Yup.string()
        .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, 'Enter correct url'
        ),
      startUpEmail: Yup.string()
        .email("Startup email must be a valid email")
    })
  })
  const getErrorMessage = (text: any) => {
    const errors: any = formik.errors


    if (text == 'mentorShipType') return 'Mentorship type is required'
    if (text == 'preferredLanguage') return 'Preferred language do you prefer to communicate in is required'
    if (text == 'WhyYouWouldLikeMentorShip') return 'Tell us a little about you and why you would like mentorship is required'
    if (text == 'whatIsOutcomeYouAreLookingFor') return 'What is the outcome you are looking for from this mentorship program is required'
    else {
      if(text){
         return errors[text]
      }
      else{
        return 'Please fill out the form to continue'
      }
     

    }
    
  }
  const handleSubmit = () => {
    const item = Object.keys(formik.errors)[0]


    setSubmitClicked(true)
    if (Object.keys(formik.errors).length === 0 && Object.keys(requestData).length !== 0) {
      setStep(2)
      window.scrollTo(0, 0);
    }
    else {
      dispatch(addAlertInfo(
        {
          show: true,
          message: getErrorMessage(item),
          type: 'error'
        }
      ))
    }
  }
  useEffect(() => {
    getAllAreaInterest()

  }, []);

  const submit = (e: any) => {
    formik.handleSubmit()
    e.preventDefault()
    setTimeout(() => {
      handleSubmit()
    }, 400);
  }


  return (
    <div>
      <span className='required'>Fields marked with an * are required.</span>
      <p className='main-description mb-4'>If you would like a mentor to support you in prepping for an interview, resume writing, guiding your in your career or startup, you can request one from here. Please fill out the details below so we can match you with the right mentor.</p>

      <form className='w-full md:w-3/4 lg:w-3/4'
        onSubmit={submit}>
        <div className='form-item '>

          <SelectInput
            required={true}
            name='mentorShipType'
            Change={handleSelectChange}
            label="What would you like support in?"
            options={allAreaInterest}
            loading={loadingAreaInterest}
            formik={formik}
            submitClicked={submitClicked}
            defaultValue={requestData?.mentorShipType}
            multiple={false} />
          {!requestData?.mentorShipType && submitClicked &&
            <span className='text-red-500 text-sm'>{'This field is required'}</span>}
        </div>
        <div className='form-item '>
          <LanguagesSelection
            name="preferredLanguage"
            label="What preferred language do you prefer to communicate in?"
            multiple={false}
            required={true}
            handleChange={handleSelectChange}
            defaultValue={requestData?.preferredLanguage}
            formik={formik}
            submitClicked={submitClicked} />
          {!requestData?.preferredLanguage && submitClicked &&
            <span className='text-red-500 text-sm'>{'This field is required'}</span>}
        </div>
        <div className="form-item">

          <label className="relative flex justify-between">
            <span >* Tell us a little about you and why you would like mentorship</span>
            <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{requestData?.WhyYouWouldLikeMentorShip?.length || 0}/1500</p>

          </label>
          <textarea
            maxLength={1500}
            className="w-full border hover:border p-2 rounded-lg"
            rows={4}
            placeholder='Enter text here...'
            name="WhyYouWouldLikeMentorShip"
            defaultValue={requestData?.WhyYouWouldLikeMentorShip}
            onChange={(e: any) => handleChange(e)} />
          {!requestData?.WhyYouWouldLikeMentorShip && submitClicked &&
            <span className='text-red-500 text-sm'>{'This field is required'}</span>}

        </div>
        {/* <div className='form-item '>
          <InputField
            Change={handleChange}
            name="WhyYouWouldLikeMentorShip"
            label='Tell us a little about your and why you would like mentorship'
            required={true}
            multiline={true}
            placeholder='Enter text here....'
            rows={4}
            defaultValue={requestData?.WhyYouWouldLikeMentorShip}
            formik={formik}
            submitClicked={submitClicked} />
        </div> */}
        {/* <div className='form-item '>
          <InputField Change={handleChange}
            name="whatIsOutcomeYouAreLookingFor"
            label='What is the outcome you are looking for from this mentorship program.'
            required={true}
            multiline={true}
            rows={4}
            placeholder="Enter text here...."
            defaultValue={requestData?.whatIsOutcomeYouAreLookingFor}
            formik={formik} submitClicked={submitClicked}
          />

        </div> */}
        <div className="form-item">

          <label className="relative flex justify-between">
            <span >*What is the outcome you are looking for from this mentorship program.</span>
            <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{requestData?.whatIsOutcomeYouAreLookingFor?.length || 0}/1500</p>

          </label>
          <textarea
            maxLength={1500}
            className="w-full border hover:border p-2 rounded-lg"
            rows={4}
            placeholder='Enter text here...'
            name="whatIsOutcomeYouAreLookingFor"
            defaultValue={requestData?.whatIsOutcomeYouAreLookingFor}
            onChange={(e: any) => handleChange(e)} />
          {!requestData?.whatIsOutcomeYouAreLookingFor && submitClicked &&
            <span className='text-red-500 text-sm'>{'This field is required'}</span>}

        </div>
        <div className='form-item '>

          <InputField Change={handleChange}
            name="enterYourLinkedinOrSocialMediaSite"
            label='Let the mentor know more about you, enter your Linkedin or social media site that tells something about what you do.'
            required={false}
            placeholder='URL'
            defaultValue={requestData?.enterYourLinkedinOrSocialMediaSite}
            formik={formik}
            submitClicked={submitClicked}
          />
          {requestData?.enterYourLinkedinOrSocialMediaSite && (!isUrlValid(requestData?.enterYourLinkedinOrSocialMediaSite)) && submitClicked &&
            <span className='text-red-500 text-sm'>{'Invalid URL format'}</span>}


        </div>

        <div className='form-item '>
          <InputField name="domain" Change={handleChange} label='If you are a startup, please enter your domain and startup email below'
            required={false}
            placeholder='Domain'
            defaultValue={requestData?.domain}
            formik={formik} submitClicked={submitClicked}
          />
          {requestData?.domain && (!isUrlValid(requestData?.domain)) && submitClicked &&
            <span className='text-red-500 text-sm'>{'Invalid URL format'}</span>}


          <div className='spacer'></div>
          <InputField Change={handleChange} label=''
            required={false}
            placeholder='Enter startup email here....'
            name="startUpEmail"
            defaultValue={requestData?.startUpEmail}
            formik={formik} submitClicked={submitClicked}
          />
          {requestData?.startUpEmail && (!validateEmail(requestData?.startUpEmail)) && submitClicked &&
            <span className='text-red-500 text-sm'>{'Invalid email format'}</span>}


        </div>
        {/* <div className='form-item '>
          <InputField Change={handleChange} label='Any additional information you would like to add'
            required={false}
            multiline={true}
            rows={3}
            placeholder='Enter text here....'
            name="additionalInformation"
            defaultValue={requestData?.additionalInformation}
          />
        </div> */}
        <div className="form-item">

          <label className="relative flex justify-between">
            <span >Any additional information you would like to add</span>
            <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{requestData?.additionalInformation?.length || 0}/1500</p>

          </label>
          <textarea
            maxLength={1500}
            className="w-full border hover:border p-2 rounded-lg"
            rows={4}
            placeholder='Enter text here...'
            name="additionalInformation"
            defaultValue={requestData?.additionalInformation}
            onChange={(e: any) => handleChange(e)} />

        </div>
        <div className='form-item my-4'>
          <p className='main-description'>
            <strong>Note</strong>: Please provide enough information about yourself and why you need mentorship to get approval for mentorship.
          </p>
        </div>

        <div className="actions-end ">
          {/* <Button variant="outlined" >Continue</Button> */}
          <CustomButton text="Request a mentor" type="submit" />
        </div>
      </form>
    </div>

  )
}
