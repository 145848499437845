import { useSelector } from "react-redux";
import CustomizedButtons from "./Components/CustomButton";

export default function ErrorPage(props:any) {
    const user: any = localStorage.getItem('userInfo')
    const userInfolocalStorage = JSON.parse(user)
    const userInfo = useSelector((state: any) => state.auth.user);
    const User = userInfo?.model || userInfolocalStorage?.model

    const handleNavigateion = () => {
        if (User === "Student") {
            window.location.href="/professionals-home"
        }

        else if(User==="Mentor") {
            window.location.href="/mentor/home"
        }
        else if(User==='Institution'){
            window.location.href="/institution/home"
        }
        else if(User==='Company'){
            window.location.href="/institution/home"
        }

    }
    const handleNavigateionRefresh=()=>{
        window.location.reload()
    }
  
    return (
        
        <section className="page_404 error-page">
        <div className="w-full">
            <div className="row">
                <div className="col-sm-12 ">
                    <div className="col-sm-10 col-sm-offset-1  text-center">
                        <div className="four_zero_four_bg">
                          

                        </div>

                        <div className="contant_box_404">
                            <h3 className="h2">
                            Something went wrong while trying to perform your action
                            </h3>

                            {/* <p>the page you are looking for not available!</p> */}
                            <div className='custom-action-fill mt-4 space-x-2'>
                                <CustomizedButtons text={"Go to Home"} click={handleNavigateion} />
                                <CustomizedButtons text={"Refresh the page"} click={(handleNavigateionRefresh)} />

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        // <div className="flex bg-gray-300 d-flex justify-content-center align-items-center " style={{height:'100vh'}}>
        //     <div className="p-4 text-center bg-white" style={{border:'1px solid #dad6d6',borderRadius:'8px'}}>
        //     <div className="text-center text-danger mb-4" style={{fontSize:'30px',fontWeight:'bold'}} >Oops!</div>
        //     <div className={"message "} style={{fontSize:'18px'}}>Something went wrong while trying to perform your action, please try again later...</div>
        //     <button className="btn btn-bg-primary btn-small text-white mt-4 me-4" onClick={()=>window.location.href='/'}>Back to home</button>
        //     <button className="btn btn-bg-info btn-small text-white mt-4" onClick={()=>goBack()}>Back to previous page</button>
        //     </div>
        // </div>
    );
}