import React, { useEffect, useState } from "react";
import styles from "./interviewsAndMeetings.module.scss";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Container } from "@mui/system";

import EventIcon from '@mui/icons-material/Event';
import { useSelector } from "react-redux";
import { listInstitutionCalender } from "../../../services/institution/mettings";
import { listCompanyCalender } from "../../../services/company/mettings";
import { getCalenderData } from "../../../services/calenderService";
import MeetingModal from "../../Components/MeetingModal";
interface SimilarJobsProps {

}
export default function CompanyCalendar(props: SimilarJobsProps) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [id, setId] = useState('');
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [pendingSession, setPendingSession] = useState([])
  const [loadingPendingSession, setLoadingPendingSession] = useState(true)
  const [meetingType, setMeetingType] = useState('');
  const getAllPendingSessions = async () => {
    const response = await getCalenderData(user._id)
    if (response) {
      setPendingSession(response.data.data.queryResult)
      setLoadingPendingSession(false)
    }
  }
  useEffect(() => {
    if (token) {
      getAllPendingSessions()
    }
  }, [token]);

  const handleOpen = (eventInfo: any) => {

    setId(eventInfo.event._def.publicId)
    setTitle(eventInfo.event._def.title)
    setLink(eventInfo.event._def.extendedProps.link)
    setMeetingType(eventInfo.event._def.extendedProps.meetingType)
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const events = pendingSession.map((item: any) =>
  ({
    'title': `${item?.meetingType == 'meeting' ? 'meeting' : 'session'} with ${item?.other_user?.user?.subModel?.firstName || item?.other_user?.user?.subModel?.name} ${item?.other_user?.user?.subModel?.middleName || ''} ${item?.other_user?.user?.subModel?.lastName || ''}`,
    'id': item._id, 
    'start': new Date(item.startTime),
    'end': new Date(item.endTime),
    'link': item?.current_user?.meetingUrl,
    'meetingType': item?.meetingType
  }))

  return (
    <div className="page calender-page">
      {open && id &&
        <MeetingModal show={open} handleClose={handleClose} title={title} link={link} id={id} getAllPendingSessions={getAllPendingSessions} meetingType={meetingType} />
      }
      <Container >
        <div className="header header-underline">
          <h2 className="title">Calendar of Scheduled Interviews/Meetings</h2>
          <p className="main-description mb-2">
            These are your scheduled Sessions and meetings:
          </p>
        </div>

        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView='dayGridMonth'
          weekends={true}
          events={events}
          eventContent={renderEventContent}
          eventClick={handleOpen}
        />
      </Container>
    </div>

  );
}
function renderEventContent(eventInfo: any) {

  return (
    <>
      <div className={styles['event']} >
        <p>{eventInfo.event.title}</p>
        <h6>{eventInfo.timeText}m</h6>
      </div>
      <div className={styles['event-mobile']} >
        <EventIcon />
        <p>{eventInfo.timeText}m</p>
      </div>
    </>

  )
}
