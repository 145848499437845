import { Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'

interface CheckBoxListProps {
  list: Array<any>;
  styling: string;
  name?:string
  setListReselt?:any
  selectedList?:any

}

export default function CheckBoxList(props: CheckBoxListProps) {
  const { list, styling,name ,setListReselt,selectedList} = props;

  return (
    <div className={`grid grid-flow-${styling}`}>
      {list.map((element: any) => {
        return (
          <FormControlLabel
            control={<Checkbox size='small' name={name}
            checked={selectedList?.map((item:any)=>item?._id)?.includes(element?.id)||selectedList?.includes(element?.id)}
            onChange={(e: any) => {
             setListReselt(e.target.name,element.id)
            }}/>}
            label={<span style={{ fontSize: '12px' }}
            >{element?.name}</span>} />
        )
      })}
    </div>
  )
}
