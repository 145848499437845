import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "../../../Student/Home/Header/header.module.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CardImage from "../../../../assets/images/cardImage.png";
import { useState, useEffect } from "react";
import { listAllMentorSessionsForMentor } from "../../../../services/mentorshipService";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import moment from "moment";
interface HeaderProps { }

const Header = (props: HeaderProps) => {

  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [pendingSession, setPendingSession] = useState(0)
  const [pendingSessionTime, setPendingSessionTime] = useState(null) 
  const allChats = useSelector((state: any) => state.chats.data);
  const [loadingPendingSession, setLoadingPendingSession] = useState(true)
  const navigate = useNavigate()
  const getAllPendingSessions = async () => {
    const response = await listAllMentorSessionsForMentor(user?._id, 'pending')
    if (response) {
      setPendingSession(response.data.data.wholeModelDataCount)
      setPendingSessionTime(response?.data?.data?.queryResult[0]?.createdAt)
      setLoadingPendingSession(false)
    }
  }
  useEffect(() => {
    if (token) {
      getAllPendingSessions()
    }
  }, [token])
  return (
    <div className={styles["header"]}>
      <div className="mb-8">
        <Container className={styles.container}>
          <Grid container className={styles.grid}>
            <Grid item lg={7} md={7} xs={12} className={styles.header_info}>
              <p className={styles.header_text}>
                Serve as a source of knowledge and help career professionals in the region
              </p>
            </Grid>
            <Grid item xs={5} className={styles.header_info_box} >
              <div className="block w-full items-end space-y-4">
              {pendingSessionTime && 
              <Box className={styles.info}>
                <h6 >There are <span className="main-link" onClick={() => navigate('/mentor/mentorship-program')}>{pendingSession} mentee(s)</span> waiting for your approval.</h6>
               <div className="h-2"></div>
                <IconButton size="large" color="inherit" onClick={() => navigate('/mentor/mentorship-program')}>
                  <ArrowForwardIcon  />
                </IconButton>
              </Box>
          }
            {
              (allChats && allChats?.length > 0 && allChats[0]?.LastMessageStatus != 'read'&&!allChats[0]?.sentByMe) && 
            
              //  <Grid item xs={12} md={5} className={styles.header_info_box}>
                <Box className={styles.info}>
                  <h6>{allChats[0]?.otherUser?.name} contacted you, reply back</h6>
                  <p>
                    They sent you a message {moment(allChats[0]?.LastMessageDate).fromNow()}.
                  </p>
                  <IconButton size="large" color="inherit" onClick={() => navigate('/inbox')}>
                    <ArrowForwardIcon />
                  </IconButton>
                </Box>
              // </Grid>
           
              }
              </div>
           
  </Grid>
          </Grid>

          {/* <Grid container className={styles.univirsity_info}>
          <Card className={styles.card}>
            <CardActionArea className={styles.card}>
              <div className={styles.img_border}>
                {" "}
                <CardMedia component="img" height="140" image={CardImage} />
              </div>
              <CardContent className={styles.contint}>
                <div>
                  <h5>Mentor Resources</h5>
                  <Typography variant="body2" color="text.secondary">
                  Resources provided to you
                  </Typography>
                </div>
                <IconButton size="large" color="inherit">
                  <ArrowForwardIcon />
                </IconButton>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid> */}
        </Container>
      </div>
    </div>
  );
};

export default Header;
