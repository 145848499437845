
import moment from "moment";
import axios from "../axios";
import { BASEURL } from "../environments/environment";

export const listChatMessages = (chatID: string, page: number) => {
  var timeDifference = moment(new Date()).format('Z')

  return axios
    .get(`${BASEURL}message/${chatID}?page=${page}&pageSize=10&tz=${timeDifference}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const listAllChats = (id:string) => {
  return axios
    .get(`${BASEURL}chat/user/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const getMessagsNumber = () => {
  return axios
    .get(`${BASEURL}message/unread`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const sendMesage = (data: any) => {
  return axios
    .post(`${BASEURL}message`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
