import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import BackgroundInfo from "./BackgroundInfo";
import InistitutionHeader from "./InistitutionHeader";
import InistitutionInfo from "./InistitutionInfo";
import { useSelector } from "react-redux";
import { SingleInstitutions } from "../../../services/institutionService";
import { Institution } from "../../../models/institution";
import { useParams } from "react-router";
import { getUserInfo } from "../../../services/userService";


function Inistitution() {
  const parms = useParams()
  const { id } = parms
  const token = useSelector((state: any) => state.auth.token);
  const userInfo = useSelector((state: any) => state.auth.user);
  const [institution, setInstitution] = useState<Institution>()
  const [loading, setLoading] = useState(true)

  const instititutionID = id ? id : userInfo.student?.institution?._id

  const getInitutions = async () => {
    const response = await getUserInfo(instititutionID)
    if (response) {
      setInstitution(response.data.data)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (token) {
      getInitutions()
    }
  }, [token]);
  return (
    <Container>
      {!loading &&
        <div className="page">
          <InistitutionHeader institution={institution} />
          <InistitutionInfo institution={institution} />
          <BackgroundInfo institution={institution} />
        </div>
      }
    </Container>
  );
}

export default Inistitution;
