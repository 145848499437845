import React from "react";
import { Grid } from "@mui/material";
import MenteeCard from "./MenteeCard";
import { useNavigate, useParams } from "react-router";
import { getInitials } from "../../../helpers";

interface ListProps {
    Studentslist: any
    Cname: any
}

export default function Students(props: ListProps) {
    const { Studentslist, Cname } = props
    const navigate = useNavigate()
    const parms = useParams()
    const { id } = parms
    const handleClick = () => {
        if (id) {
            navigate(`/company-professionals/${id}/${Cname}`)
        }
        else {
            navigate('/company/students')
        }

    }

    return (
        <>
            {Studentslist.length > 0 &&
                <div className="m-m-50">
                    <h3 className='secondary-title'>Students/Professionals who have worked here</h3>
                    <div >
                        <Grid container spacing={2}  >
                            {Studentslist.map((student: any) => {
                                return (
                                    <Grid item md={3} sm={12} className="w-full">
                                        <MenteeCard
                                            student={student?.user}
                                            name={`${student?.user?.subModel?.firstName} ${student?.user?.subModel?.lastName}`}
                                            subName={getInitials(student?.user?.subModel?.firstName, student?.user?.subModel?.lastName)}
                                            title={`${student?.job?.name}`}
                                            jobType={`${student?.job?.jobType?.nameEn}`}
                                     
                                         userAvatar={student?.user?.profileImage}
                                        />
                                    </Grid>
                                )

                            }
                            )
                            }

                        </Grid>
                        <a className="main-link flex w-full justify-end mt-2" onClick={() => handleClick()}>See all</a>
                    </div>

                </div>
            }
        </>

    );
}
