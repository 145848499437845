import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import Moment from 'react-moment';
import NoDataMessage from '../../../../Components/NoDataMessage';
import {  latestStudentActiveSessions } from '../../../../services/analyticsService';

export default function LatestActiveSessionsForStudent(props: any) {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const getData = async () => {
    const response = await latestStudentActiveSessions(user._id, 0, 4)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token]);

  return (
    <Card {...props} className='post mt-16'>
      <CardHeader title={<p className='main-title'>Latest Active Mentorship Sessions</p>} />
      <div className='main-table'>
        {data?.length > 0 ?
         <Table>
         <TableHead>
           <TableRow>

             <TableCell sortDirection="desc">
               Mentorship Type
             </TableCell>
             <TableCell sortDirection="desc">
               Mentor
             </TableCell>
   
             <TableCell>
               Date
             </TableCell>
           </TableRow>
         </TableHead>
         <TableBody>

           {data.map((item: any) => (
             <TableRow
               hover
               key={item._id}
             >

               <TableCell>
                 {item?.mentorShipSession?.mentorShipType?.nameEn}
               </TableCell>
               <TableCell>
                 <a className='cursor-pointer' onClick={() => navigate(`/mentor/profile/${item?.user2?.user?._id}`)}>
                   {item?.user2?.user?.subModel?.firstName} {item?.user2?.user?.subModel?.lastName}
                 </a>

               </TableCell>
               {/* <TableCell>
               <a className='cursor-pointer' onClick={() => navigate(`/mentor/profile/${item?.user2?.user?._id}`)}>
                 {item?.user2?.user?.subModel?.firstName} {item?.user2?.user?.subModel?.lastName}
               </a>

             </TableCell> */}
               <TableCell>
                 <Moment format="MMMM DD, yyyy">{item?.date}</Moment>
               </TableCell>


             </TableRow>
           ))}
         </TableBody>
       </Table> :
          <NoDataMessage />}
      </div>

      {data?.length > 0 && <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <a
          className='colored cursor-pointer'
          onClick={() => navigate(`/professional-active-sessions`)}
        >
          View all<ArrowRightIcon />
        </a>
      </Box>}
    </Card>
  )
}
