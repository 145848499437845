import React, { useRef } from "react";
import CustomButton from "../../../../../../Components/CustomButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDispatch } from "react-redux";
import { addAlertInfo } from "../../../../../../store/alertSlice";

export const UploadeMedia = (props: any) => {
  const dispatch = useDispatch();
  const { setSelectImage, selectimage, setSelectFiles, selectFiles, maximumUploadMedia } = props;
  const inputUploadFile: any = useRef(null);

  const onFileChange = async (e: any) => {
    const maxUpload = 6 - maximumUploadMedia;
    const arr = selectimage ? [...selectimage] : [];
    const files = selectFiles ? [...selectFiles] : [];
    for (const key of Object.keys(e.target.files).filter((item: any) => e.target.files[item]?.size <= 1000000)) {
    
      if (
        Object.keys(e.target.files)
          .filter((item: any) => e.target.files[item]?.size <= 1000000)
          .indexOf(key) < maxUpload
      ) {
        const file = e.target.files[key];
        files.push(file);
        let imageDataUrl: any = await readFile(file);
        arr.push(imageDataUrl);
      }
    }

    setSelectImage(arr);
    setSelectFiles(files);

    if (e.target.files.length > maxUpload) {
      dispatch(
        addAlertInfo({
          type: "error",
          show: true,
          message: `You can only have 6 company media`,
        })
      );
    }
  };
  function readFile(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }
  const onSubmit = (e: any) => {
    e.preventDefault();
    var formData = new FormData();
    // for (const key of Object.keys(this.state.imgCollection)) {
    //     formData.append('imgCollection', this.state.imgCollection[key])
    // }
  };
  const changeFile = () => {
    inputUploadFile.current.click();
  };
  return (
    <form className="mb-2">
      <div className="custom-action" style={{ justifyContent: "right" }}>
        <CustomButton text={"Click here to upload"} icon={<FileUploadIcon />} click={changeFile}></CustomButton>

        <input type="file" accept="image/jpeg,image/gif,image/png,image/jpg" onChange={onFileChange} hidden ref={inputUploadFile} multiple />
      </div>
      <p className="my-5">
        <div className="">You can upload up to 6 image files, and each file must not exceed 1 MB in size, larger files will not be added.</div>
        <div>
          To replace existing media images, delete if you have over 6, save by submitting for review, then upload your new images, and save again.
        </div>
      </p>
    </form>
  );
};
