

import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import styles from './jobs.module.scss'
import CustomButton from '../../../../Components/CustomButton';
import React, { useEffect, useState } from 'react';
import "react-multi-carousel/lib/styles.css";
import SectionHeader from '../components/SectionHeader';
import CarouselList from '../../../../Components/CardsList';
import { useSelector } from 'react-redux';
import { listAlljobs, listjobsByMajor } from '../../../../services/jobsService';
import { listAllMajor } from '../../../../services/majorsServise';
import { useNavigate } from 'react-router';
import { CardLoadingSkeleton } from '../../Access/Tabs';
import NoDataMessageImage from '../../../../Components/NoDataMessageWithImage';

interface jobsProps {

}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const MoreJobs = (props: jobsProps) => {
    const navigate = useNavigate()

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        setValue(newValue);
        setSelectedMajor(majors[newValue]._id)

    };

    const token = useSelector((state: any) => state.auth.token);
    const user = useSelector((state: any) => state.auth.user);
    const [jobs, setJobs] = useState<Array<any>>([])
    const [majors, setMajors] = useState<Array<any>>([])
    const [selectedMajor, setSelectedMajor] = useState('')
    const [loadingMajors, setLoadingMajors] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleViewMore = () => {
        navigate('/professionals/more-interests-jobs')
    }

    const getGobs = async () => {
        setLoading(true)
        const response = await listAlljobs({ currentPage: 0, pageSize: 10 })
  
        if (response) {
            setJobs(response.data.data.queryResult)
            setLoading(false)

        }
    }
  
    useEffect(() => {
        if (token) {
             getGobs()
            // getAllMajors()
        }
    }, [token]);
    // useEffect(() => {
    //     if (selectedMajor) {
    //         getGobs(selectedMajor)
    //     }
    // }, [selectedMajor]);

    return (

        <div className={styles['jobs']}>
            <Container >
                <SectionHeader
                    title='Explore the latest opportunities'
                    description='Latest job posts by employers/organizations' />
                {/* <div className={styles.jobs_list}>
                    <Box>
                        {!loadingMajors ?
                            <>
                                {majors.length !== 0 &&
                                    <>  <Box className='desktop-view'>
                                        <Tabs
                                            sx={{
                                                '& .MuiTabs-indicator': { backgroundColor: '#F26400 ', color: "#F26400" },
                                                '& .Mui-selected': { color: "#F26400" },
                                            }}
                                            centered
                                            value={value}
                                            onChange={handleChange}
                                            className='tabs'>
                                            {majors.map((major, index) => {
                                                return (
                                                    <Tab className='tab' label={major?.nameEn} {...a11yProps(index)} />
                                                )
                                            })}

                                        </Tabs>
                                    </Box>
                                        <Box className='mobile-view'>
                                            <Tabs
                                                sx={{
                                                    '& .MuiTabs-indicator': { backgroundColor: '#F26400 ', color: "#F26400" },
                                                    '& .Mui-selected': { color: "#F26400" },
                                                }}

                                                variant="scrollable"
                                                allowScrollButtonsMobile

                                                value={value} onChange={handleChange}
                                                className='tabs'>
                                                {majors.map((major, index) => {
                                                    return (
                                                        <Tab className='tab' label={major?.nameEn} {...a11yProps(index)} />
                                                    )
                                                })}

                                            </Tabs>
                                        </Box>
                                    </>
                                }
                            </>
                            :
                            <div className="animate-pulse flex space-x-4 flex h-[50px] justify-center mb-6">
                                <div className="h-12 bg-gray-200 rounded w-[100px]"></div>
                                <div className="h-12 bg-gray-200 rounded w-[100px]"></div>
                                <div className="h-12 bg-gray-200 rounded w-[100px]"></div>
                                <div className="h-12 bg-gray-200 rounded w-[100px]"></div>

                            </div>
                        }
                     
                        {!loading ?

                            majors.map((major, index) => {
                                return (
                                    <TabPanel value={value} index={index}>
                                        <CarouselList list={jobs} />
                                    </TabPanel>
                                )
                            })

                            :
                            <div className="gap-4 space-y-4 columns-3">
                                {[1, 2, 3].map((item: any) => {
                                    return (
                                        <CardLoadingSkeleton />
                                    )
                                })}
                            </div>
                        }
                        {!loading && majors.length === 0 && <NoDataMessageImage />}

                    </Box>

                </div> */}
                {jobs && jobs.length !== 0 &&
                    <>
                        <div className={styles.jobs_list}>
                            <Box sx={{ width: '100%' }}>
                                <CarouselList list={jobs} loading={loading} />
                            </Box>
                        </div>
                        <div className={styles.actions}>
                            <CustomButton text="View All jobs" click={handleViewMore} />
                        </div>
                    </>
                }
                {/* {!loading && majors.length !== 0 && <div className={styles.actions}>
                    <CustomButton text="View more" click={handleViewMore} />
                </div>
                } */}
            </Container>

        </div>




    );
};



export default MoreJobs;