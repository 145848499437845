import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import TabThree from "./TabThree";

import { useState } from "react";
import { addReview } from "../../../../services/reviewsService";
import { addAlertInfo } from "../../../../store/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StepsTabs() {
  const dispatch = useDispatch()
  const navigate=useNavigate()
  const [value, setValue] = useState(0);
  const [reviewData, setReviewData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: any) => state.auth.user);

  const handleSubmit = async () => {
    dispatch(addAlertInfo(
      {
        show: false,
       
      }
    ))
    setLoading(true)
    const data = {
      "jobTitle": reviewData.jobTitle,
      "review": reviewData.review,
      "resourcesFindingJob": reviewData.resourcesFindingJob,
      "advicesForOthers": reviewData.advicesForOthers,
      "whatYouLikedAndLearned": reviewData.whatYouLikedAndLearned,
      "typeOfWork": reviewData.typeOfWork,
      "keyWays": reviewData.keyWays,
      "startDate": reviewData.startDate,
      "endDate": reviewData.endDate,
      "howOftenDidYouGetPaid": reviewData.howOftenDidYouGetPaid,
      "educationLevel": reviewData.educationLevel,
      "company": reviewData.company,
      "addToProfile": reviewData.addToProfile,
      "agreeToTerms": reviewData.agreeToTerms,
      "salary": reviewData.salary,
      "currency":reviewData?.currency,
      "didYouGetOffer": {
        "answer": reviewData.didYouGetOffer,
        "visibility": reviewData.didYouGetOfferVisability
      },
      "user":user._id
    }
    const response = await addReview(data)
    if (response.status === 201) {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Review sent successfully, it will be visible once the admin approve it',
        }
      ))
      navigate('/professionals/reviews')

    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <p className="title header-underline">Write a Review</p>
      <Box>
        <Tabs
          className="tabs"
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: '#F26400 ', color: "#F26400" },
            '& .Mui-selected': { color: "#F26400" },
          }}
          value={value}
        >
          <Tab  className="tab" label="Step 1 - Choose experience" {...a11yProps(0)}  onClick={() => setValue(0)} />
          <Tab className="tab" label="Step 2 - Tell us about your role" {...a11yProps(1)}  onClick={() => setValue(1)}/>
          <Tab className="tab" label="Step 3- Share your review" {...a11yProps(2)}  onClick={() => setValue(2)}/>
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TabOne setValue={setValue} setDataValues={setReviewData} reviewData={reviewData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabTwo setValue={setValue} setDataValues={setReviewData} reviewData={reviewData} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabThree  setValue={setValue} setDataValues={setReviewData} onSubmitReview={handleSubmit} reviewData={reviewData} loadingbtn={loading} />
      </TabPanel>
    </Box>
  );
}
