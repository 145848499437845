import axios from '../axios';
import {BASEURL} from '../environments/environment'
// const token = localStorage.getItem("userToken");


export const listAllCurrency = () => {
    return axios
      .get(`${BASEURL}currency`)
      .then((res: any) => res)
      .catch((err: any) => err?.response)
  }
  
  
  