import { Avatar } from "@mui/material";
import React from "react";
import CustomizedButtons from "../../../../Components/CustomButton";
import InterestCard from "../components/InterestsCard";
import PersonsalInfoCard from "../components/PersonalInfoCard/PersonsalInfoCard";
import SkillsCard from "../components/SkillsCard";
import StatisticsCard from "../components/StatisticsCard";
import { useNavigate } from "react-router";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useSelector } from "react-redux";
import Moment from "react-moment";
import LanguageCard from "../components/LanguageCard";
export default function LeftContent() {
  const user = useSelector((state: any) => state.auth.user);


  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/professionals/manage-documents')
  }
  return (
    <>
      {user?.subModel &&
        <div className="pr-0 md:pr-4 main-r-border-none main-r-border border-0 md:border-r-[1px]">
          <div className="flex border-[1.25px] p-4 rounded items-center ">
            <Avatar className='mr-4'
              src={user?.subModel?.profileImage || user?.profileImage} sx={{ width: 50, height: 50, bgcolor: user?.subModel?.profileImage || user?.profileImage ? "unset" : "#F26400" }} aria-label="avatar">
              <a className='capitalize text-white'>
                {user?.subModel.firstName?.charAt(0)}{user?.subModel.lastName?.charAt(0)}</a>
            </Avatar>
            <div>
              <p className="secondary-title">{user?.subModel?.firstName} {user?.subModel?.middleName || ''} {user?.subModel?.lastName}</p>

              {user?.subModel?.enrolledInHighSchool ?
                <p className="font-light mb-1 text-sm">Major(s): {user?.subModel?.major.map((item: any, index: number) => {
                  return (
                    <>
                      {item?.nameEn}{index != user?.subModel?.major.length - 1 ? ', ' : ''}
                    </>

                  )
                })}</p> :
                <p className="font-light mb-1 text-sm">Area of interest:{' '}
                  {user?.subModel?.areaOfInterest?.nameEn}
                </p>} {user?.subModel?.graduationDate && <p className="font-light text-sm">Graduation Date: <Moment format="MMMM YYYY">
                  {user?.subModel?.graduationDate}
                </Moment></p>}
              {user?.subModel?.gpa && parseInt(user?.subModel?.gpa) != 0 && <p className="font-light text-sm">GPA {user?.subModel?.gpa}</p>}
            </div>
          </div>
          <StatisticsCard data={user?.subModel?.profileCompletion} />
          <PersonsalInfoCard />
          {/* <InterestCard /> */}
          <SkillsCard label="Skills" itemsList={user?.subModel?.skills} type="skills" />
          <LanguageCard label="Language Fluency" itemsList={user?.subModel?.fluentLanguage} type="fluentLanguage" />
          <LanguageCard label="Language Proficiency" itemsList={user?.subModel?.proficientLanguage} type="proficientLanguage" />
          <div className="border-[1.25px] p-4 rounded my-6 ">
            <h4 className="list-title mb-2 flex justify-between">

              Documents
              <DocumentScannerIcon />
            </h4>
            <p className="text-[#222222] text-[14px] mb-4 ">
              You uploaded {user?.uploadedDocuments} document(s). You can add your CV, cover letters,
              portfolio and other relevant documents. To select which documents to
              show to the public, click on Manage.
            </p>
            <div className='custom-action small-btn my-2'>
              <CustomizedButtons text="Manage" click={handleClick} />
            </div>

          </div>
          <div className=" border-[1px] p-4 rounded ">
            <p className="text-[#222222] text-[14px] mb-4 ">
              You profile is currently visible to {user?.subModel?.accountVisibility}. You can communicate with many in the
              network.<br></br> You can make changes in your <span className="main-link" onClick={() => { navigate('/professionals/settings') }} >settings</span> to change who
              has visibility on your profile and who can communicate with you.
            </p>
            <div className='custom-action small-btn center  my-2 '>
              <CustomizedButtons text="See company/employer's view" click={() => navigate(`/professional/profile/${user?._id}`)} />
            </div>
          </div>
        </div>
      }
    </>

  );
}
