import React, { useState } from "react";

import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ImageCrop from "../../../../Components/ImageUpload";
import { addAlertInfo } from "../../../../store/alertSlice";
import CustomButton from "../../../../Components/CustomButton";
import { UpdateInstitutionProfile } from "../../../../services/institution/updateInstitution";
import { UpdateUser } from "../../../../services/authService";

export const UploadeBanner = (props:any) => {
    const {newData}=props
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [image, setSelectimage] = useState('')
    const [loading, setLoading] = useState(false)
    const user = useSelector((state: any) => state.auth.user);
    const formData = new FormData()
    const handleSubmit = (selectimage: any, imagefile: any) => {
        setSelectimage(selectimage)
        setLoading(true)
        formData.append("banner", imagefile);
        updateUserProfile()
    }
    const updateUserProfile = async () => {
        dispatch(addAlertInfo(
            {
                show: false,
            }
        ))
        const response = await UpdateUser(user?._id, formData)
        if (response.status === 200) {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response?.data?.data?.message,
                }
            ))
        }
        else {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: 'error'
                }
            ))
            setSelectimage('')
        }
    }
    return (
        <div className="flex items-center justify-end space-x-4">
            {show &&

                <ImageCrop show={show} setOpen={setShow} type='banner' handleSubmit={handleSubmit} />
            }
            {(newData?.subModel?.banner|| user?.subModel?.banner ||image)&&
                <div className="relative">
                    <img src={image ? image : newData?.subModel?.banner|| user?.subModel?.banner} style={{ width: '100px', height: "50px" }}  />
                </div>
            }
            <CustomButton text='Upload banner' click={() => setShow(true)} loading={loading} />
        </div>
    );
}

export default UploadeBanner;


