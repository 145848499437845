import React from "react";
import Moment from "react-moment";

interface ProjectElementProps {
  item: any
}
export default function ProjectElement(props: ProjectElementProps) {
  const { item } = props
  return (
    <div className="my-1 text-[#222222] m-b-20"  >
      <p className="font-semibold text-[16px] mb-2">{item?.name}</p>
      <a href={item?.url} target="blank" className="font-light text-[15px] my-1 main-link">{item?.url}</a>
      <p className="font-light text-[15px] my-1 ">{item?.role}</p>
      <p className="font-light text-[15px] my-1 ">{item?.owner}</p>
      <p className="font-light text-[15px] my-1"> <Moment format="MMMM YYYY">{item?.startingDate}</Moment> -  <Moment format="MMMM YYYY">{item?.endingDate}</Moment> ({item?.duration})</p>
      {item?.country?.nameEn  && <p className="font-light text-[15px] my-1">{item?.city?.nameEn}, {item?.country?.nameEn}</p>}
      <p className="font-light text-[15px] mt-4">
        {item?.description}
      </p>
    </div>
  );
}
