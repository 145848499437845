import { Container } from '@mui/system'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useEffect, useState } from 'react'
import CustomizedButtons from '../../../Components/CustomButton';

import LogoImage1 from "../../../assets/images/temp/4.png";
import LogoImage2 from "../../../assets/images/temp/2.png";
import LogoImage3 from "../../../assets/images/temp/3.png";
import { useNavigate } from 'react-router';
import { listNotifications, readAllNotifications } from '../../../services/notificationService';
import NoDataMessage from '../../../Components/NoDataMessage';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { addAlertInfo } from '../../../store/alertSlice';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
export default function Notifications() {
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.auth.user);
    const token = useSelector((state: any) => state.auth.token);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [notificationsList, setNotificationsList] = useState([])
    const [allNotificationsCount, setAllNotificationsCount] = useState(0)
    const [status, setStatus] = useState<any>(null)
    const handleClick = () => {
        navigate('/notification-Preferences');
    }
    const listAllNotifications = async () => {
        setLoading(true)
        const response = await listNotifications(user?._id, status)
        if (response.status === 200) {
            setLoading(false)
            setNotificationsList(response.data.data.queryResult)
            setAllNotificationsCount(response.data.data.allNotificationsLength)

        }
        else {
            setLoading(false)
        }
    }
    // 
    const handleMarkAllAsRead = async () => {
        setLoadingBtn(true)
        dispatch(addAlertInfo(
            {
                show: false,
            }
        ))
        const response = await readAllNotifications(user?._id)
        if (response.status === 200) {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'All notifications marked as read',
                }
            ))
            setLoadingBtn(false)
            listAllNotifications()
        }
        else {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: 'error'
                }
            ))
            setLoadingBtn(false)

        }
    }
    useEffect(() => {
        if (token) {
            listAllNotifications()
        }

    }, [token, status])
    return (
        <Container>
            <h3 className='title'>Notifications</h3>
            <div className='page'>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={4}>
                        <Grid xs={12} lg={3} md={4} >
                            <div className='main-border '>
                                <div className={`${status ? 'bg-colored text-white' : ''} p-3  flex justify-between  cursor-pointer`} onClick={() => setStatus(true)}>
                                    <p>Unread</p>
                                    <span className='bg-white colored  p-1 text-xs flex justify-center items-center rounded-full w-6 h-6'>
                                        {notificationsList.filter((item: any) => item.status === 0).length}

                                    </span>
                                </div>
                                <div className={`${!status ? 'bg-colored' : ''} p-3  flex justify-between  cursor-pointer`} onClick={() => setStatus(null)}>
                                    <p>All</p>
                                    <span className='bg-white colored  p-1 text-xs flex items-center justify-center rounded-full main-orange-border w-6 h-6'> {allNotificationsCount}</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid xs={12} lg={9} md={8}>
                            <div className='flex justify-between flex-col md:flex-row md:flex-row'>
                                <div className='mb-4'>
                                    <CustomizedButtons text='Edit Preferences' click={handleClick} />

                                </div>
                                <div className=' custom-action-fill'>
                                    <CustomizedButtons text='Mark all as read' loading={loadingBtn} click={handleMarkAllAsRead} />

                                </div>
                            </div>


                            <div className='my-8 p-4 notificatios'>
                                {loading ?
                                    '' :
                                    notificationsList.length > 0 ?
                                        notificationsList.map((item: any) => {
                                            return (
                                                <div className='flex items-center notification-item'>
                                                     {item?.status===0&& <div className='h-2 w-2 bg-[#f26400] rounded mr-1'></div>}
                                                    <NotificationAddIcon className='mr-2 ' />
                                                    <div className='text-sm flex text-wrap ' >{item.text}</div>

                                                </div>

                                            )
                                        }) :
                                        <NoDataMessage text="No notification for you" />}


                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>

        </Container>
    )
}
