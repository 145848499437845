import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./form.module.scss";
import CustomButton from "../../../../Components/CustomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputField from "../../../../Components/Input";
import ReCAPTCHA from "react-google-recaptcha"
import LogoImage from '../../../../assets/images/Logo.png'
import { UserResetPassword, resetPassword, signIn } from "../../../../services/authService";
import { useDispatch, useSelector } from 'react-redux';
import { addAlertInfo } from "../../../../store/alertSlice";

import verificationImage from '../../../../assets/images/Confirmed-cuate.svg'
import { SentMailVerification } from "../../../../services/userService";
import { CircularProgress } from "@mui/material";
interface LoginProps { }
interface LoginData {
  email: ""
  password: ""
}
const MailVerificationForm = (props: LoginProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [passwordData, setPasswordData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSendMail = async () => {
    setLoading(true)

    const res = await SentMailVerification(searchParams.get("userId"))
    if (res.status === 200) {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: `Mail sent successfully please check your mailbox`,
        }
      ))
      // navigate('/')
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          type: 'error',
          show: true,
          message: `something went wrong please try again`,
        }
      ))

      // navigate('/')
    }
  }




  return (
    <div className={styles["login-form"]}>
      <div className={styles["form"]}>
        <div className={styles.logo}>
          <img src={LogoImage} alt="logo" />
        </div>
        <div className="block text-center">
          <img src={verificationImage} alt="mail verification" className="w-[500px] m-auto" />
          <p>Just one more step to begin your journey with us. Please verify your email now to log in to edqan.</p>
          <a className="main-link flex justify-end" onClick={() => handleSendMail()}>
            {!loading ?
              'Send mail again' :
              <CircularProgress size="20px" />
            }</a>

        </div>

      </div>
    </div>
  );
};

export default MailVerificationForm;
