import { Grid } from "@mui/material";
import styles from "./login.module.scss";
import AuthBanner from "../Components/AuthBanner";
import LoginForm from "./LoginForm";
import ResetPasswordForm from "./ResetPasswordForm";

interface LoginProps {}

const ResetPassword = () => {
  return (
    <div className={styles["login-page"]}>
      <Grid container className={styles["login-page-row"]}>
        <Grid item xs={3} className={styles["left-col"]}>
          <AuthBanner />
        </Grid>
        <Grid
          item
          lg={9}
          md={9}
          sm={12}
          xs={12}
          className={styles["right-col"]}
        >
          <ResetPasswordForm />
        </Grid>
      </Grid>
    
    </div>
  );
};

export default ResetPassword;
