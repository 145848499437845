import React, {useState} from 'react';
import { Container, Typography, Button } from '@mui/material';
// import Header from './Header';

import Posts from './JobsPosts';
import styles from './home.module.scss'
import Jobs from './RecomendsJops';
import MoreJobs from './MoreJops';
import LatestInteractionsWithEmployersForStudents from './components/latest-interaction-with-employer-for-srudent';
import LatestActiveSessionsForStudent from './components/latest-studentActiveSessions';
import "../Home/home.module.scss";
import DialogModalStudent from './PopUp';

const Header = React.lazy(() => import('./Header'));

interface HomeProps {

}

const Home = (props: HomeProps) => {
    const [openModal, setOpenModal] = useState<boolean>(true)
    const [openPopup, setOpenPopup] = useState<boolean>(false)
    const handleOpenDialog = () => {
        setOpenPopup(true)
    }
  return (
    <div className={styles['home-page']}>
     <Header/>
    
     <MoreJobs/>
     <Posts/>
     {/* <Jobs/> */}
   
     <Container>
     <LatestInteractionsWithEmployersForStudents/>
     <LatestActiveSessionsForStudent/>
     </Container>
        {
            openPopup && (
                <DialogModalStudent open={openPopup} setOpenPop={setOpenPopup} />
            )
        }
        {
            openModal && (
                <Container id="ToolTip">
                    <div className="helpContainer">
                        <Typography className="guidance">Do you need guidance on using our platform?</Typography>
                        <Button className="button" onClick={handleOpenDialog}>
                            Yes
                        </Button>
                        <Button className="button" onClick={() => setOpenModal(false)}>No, thanks</Button>
                        <Button className="closeButton" onClick={() => setOpenModal(false)}>&times;</Button>
                    </div>
                </Container>
            )
        }
    </div>
  );
};

export default Home;
