import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Box, Grid, Modal } from '@mui/material';
import styles from './card.module.scss'
import CustomButton from '../../../../Components/CustomButton';
import Moment from 'react-moment';
import moment from 'moment';
import { changeMentorShipSessionStatus } from '../../../../services/mentors';
import { useDispatch, useSelector } from 'react-redux';
import { addAlertInfo } from '../../../../store/alertSlice';
import dayjs, { Dayjs } from 'dayjs';
import { CreateMeetingWithUser } from '../../../../services/calenderService';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;

}
interface MentorCardProps {
    data: any
    listAll: any
}

export default function MenteeCard(props: MentorCardProps) {
    const dispatch = useDispatch()
    const { data, listAll } = props
    const [loading, setLoading] = React.useState(false)
    const [loadingDecline, setLoadingDecline] = React.useState(false)

    const handleClose = () => setOpen(false);
    const [open, setOpen] = React.useState(false)
    const navigate=useNavigate()
    const handleCLick = async (id: any, status: string) => {
        dispatch(addAlertInfo(
            {
                show: false,

            }
        ))
        if (status === 'ongoing') {
            setLoading(true)
        }
        else {
            setLoadingDecline(true)
        }
        const data = {
            "status": status
        }
        const response = await changeMentorShipSessionStatus(id, data)
        if (response.status === 200) {
            if (status === 'ongoing') {
                setLoading(false)
            }
            else {
                setLoadingDecline(false)
            }
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'Done'
                }
            ))
            listAll()
        }
        else {
            setLoadingDecline(false)
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: 'error'
                }
            ))
        }

    }
    const handleDecline = () => {
        handleCLick(data._id, 'declined')
    }
    const handleApprove = () => {
        handleCLick(data._id, 'ongoing')
    }

    return (
        <Card className='post bg-light-green border-green'>
            {open &&
                <Application show={open} handleClose={handleClose} data={data} />
            }
            <div  >
                <div className='flex justify-between'>
                    <CardHeader
                    className='cursor-pointer'
                    onClick={()=>navigate(`/professional/profile/${data?.user1?.user?._id}`)}
                        avatar={
                            data?.user1?.user?.profileImage ?
                                <Avatar sx={{ width: 60, height: 60 }}
                                    aria-label="avatar" src={data?.user1?.user?.profileImage}>

                                </Avatar> :
                                <Avatar sx={{ width: 60, height: 60, bgcolor: "#F26400" }} aria-label="avatar">
                                    {data?.user1?.user?.subModel?.firstName.charAt(0)}{data?.user1?.user?.subModel?.lastName.charAt(0)}
                                </Avatar>


                        }

                        title={`${data?.user1?.user?.subModel?.firstName} ${data?.user1?.user?.subModel?.middleName||''} ${data?.user1?.user?.subModel?.lastName}`}
                        subheader={
                            <div>
                                <p><strong className='font-[500]'>Requesting mentorship in:</strong> {data?.mentorShipSession?.mentorShipType?.nameEn} </p>
                            </div>
                        }
                    />
                    <div className={styles['card-info']}>
                        {/* <span> <strong className='text-[12px] font-[600]'>Request for:</strong> {data?.mentorShipSession?.mentorShipType?.nameEn}

                        </span> */}
                        {/* December 2, 2023  */}
                        <span>

                            <strong className='text-[12px] font-[600]'>  Date of Request:</strong> <Moment format="MMMM DD, yyyy hh:mm A">{data?.startTime}</Moment></span>
                        <a className='main-link text-[11px] w-full' onClick={() => setOpen(true)}>
                            View mentee's application
                        </a>
                    </div>
                </div>


                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                        Sent you a mentoring request on <Moment format="MMMM DD, yyyy">{data?.createdAt}</Moment> <span className='colored'>({moment(data?.createdAt).fromNow()})</span>
                    </Typography>
                </CardContent>

                <div className='custom-action custom-action-fill gap-2'>
                    <div className='decline'>
                        <CustomButton text={'Decline'} loading={loadingDecline} click={handleDecline} />
                    </div>
                    <div className='approve'>
                        <CustomButton text={'Approve'} loading={loading} click={handleApprove} />
                    </div>

                </div>
            </div>

        </Card>
    );
}
function Application(props: any) {
    const { show, handleClose, resiverName, resiverEmail, id, data } = props;
    const dispatch = useDispatch()
    const [date, setValueDate] = React.useState<any>(dayjs(new Date()));
    const [from, setValueFrom] = React.useState<any>(dayjs(new Date()));
    const [to, setValueTo] = React.useState<any>(dayjs(new Date(addMinutes(new Date(), 30))))

    const [minTimeFrom, setMinTimeFrom] = React.useState<Dayjs>(dayjs(new Date()));
    const [minTimeTo, setMinTimeTo] = React.useState<Dayjs>(dayjs(new Date(addMinutes(new Date(from), 30))));


    const UserId = useSelector((state: any) => state.auth.user._id);
    const [loading, setLoading] = React.useState(false)
    // const handleClose = () => setOpen(false);

    const handleClick = async () => {
        setLoading(true)

        const data: any = {
            "user2": {
                "user": id
            },
            "meetingType": "meeting",

            "date": moment(date).format('YYYY-MM-DD'),
            "startTime": moment(from).format('hh:mm'),
            "endTime": moment(to).format('hh:mm')
        }
        const response = await CreateMeetingWithUser(UserId, data)
        if (response.status === 201) {

            handleClose()
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'Meeting Created successfully',
                }
            ))
        }
        else {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: 'error'
                }
            ))
        }


    }
    function addMinutes(date: any, minutes: any) {
        date.setMinutes(date.getMinutes() + minutes);

        return date;
    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        height: '90vh',
        overflow: "auto",
        bgcolor: "background.paper",
        borderRadius: '16px',
        boxShadow: 0,
        p: 4,
    };
    return (
        <div>
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style}>
                    <div className="flex justify-end " onClick={handleClose}>
                        <CloseIcon className="cursor-pointer" />

                    </div>
                    <p className="font-semibold text-lg text-orange">Application details</p>

                    <div className="flex my-6">

                        <div className="w-full">
                            <Grid container>
                                <Grid item md={12}>
                                    <div className='form-item '>
                                        <label>Mentee name : <span className='font-[300] colored'>{data?.user1?.user?.subModel?.firstName} {data?.user1?.user?.subModel?.middleName || ""} {data?.user1?.user?.subModel?.lastName}</span></label>

                                    </div>
                                </Grid>
                                <Grid item md={6}>
                                    <div className='form-item '>
                                        <label>Meeting Date : <span className='font-[300]'><Moment format="MMMM DD, yyyy">{data?.date}</Moment></span></label>

                                    </div>
                                </Grid>


                                <Grid item md={6}>
                                    <div className='form-item w-100 text-end'>
                                        <label>Meeting Type : <span className='font-[300]'>{data?.meetingType}</span></label>

                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <div className='form-item '>
                                        <label> Linkedin or social media site :
                                            <span className='font-[300] ml-1'>
                                                {data?.mentorShipSession?.enterYourLinkedinOrSocialMediaSite ? <a
                                                    className='main-link'
                                                    href={data?.mentorShipSession?.enterYourLinkedinOrSocialMediaSite || 'N/A'}
                                                    target='_blank'
                                                >

                                                    {data?.mentorShipSession?.enterYourLinkedinOrSocialMediaSite || 'N/A'}
                                                </a> :
                                                    <span className='text-red-500'>  Not provided</span>}
                                            </span></label>

                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <div className='form-item '>
                                        <label>Domain :
                                            <span className='font-[300] ml-1'>
                                                {data?.mentorShipSession?.domain ?
                                                    <a
                                                        className='main-link'
                                                        href={data?.mentorShipSession?.domain || 'N/A'}
                                                        target='_blank'
                                                    >
                                                        {' '}
                                                        {data?.mentorShipSession?.domain || 'N/A'}
                                                    </a>
                                                    :
                                                    <span className='text-red-500'>  Not provided</span>
                                                }
                                            </span></label>

                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <div className='form-item'>
                                        <label>Startup email :
                                            <span className='font-[300] ml-1'>
                                                {data?.mentorShipSession?.startUpEmail ?
                                                    <a
                                                        className='main-link'
                                                        href={`mailto:${data?.mentorShipSession?.startUpEmail}` || 'N/A'}
                                                        target='_blank'
                                                    >
                                                        {data?.mentorShipSession?.startUpEmail}
                                                    </a> :
                                                    <span className='text-red-500'>  Not provided</span>
                                                }
                                            </span></label>

                                    </div>
                                </Grid>
                                <div className='text-underline h-1 w-full'></div>

                                <Grid item md={12}>
                                    <div className='form-item  text-underline'>
                                        <label className=''>What Would Student/Professional Like Support In? </label>
                                        <p className='font-[300]'>{data?.mentorShipSession?.mentorShipType?.nameEn || <span className='text-red-500'>  Not provided</span>}</p>
                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <div className='form-item text-underline'>
                                        <label className=''> In Which Language Student/Professional Prefer To Communicate?</label>
                                        <p className='font-[300] bg-orange-300'>{data?.mentorShipSession?.preferredLanguage?.nameEn || <span className='text-red-500'>  Not provided</span>}</p>
                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <div className='form-item text-underline'>
                                        <label className=''> Why student/professional would like mentorship?</label>
                                        <p className='font-[300]'>{data?.mentorShipSession?.WhyYouWouldLikeMentorShip || <span className='text-red-500'>  Not provided</span>}</p>
                                    </div>
                                </Grid>
                                <Grid item md={12}>
                                    <div className='form-item text-underline'>
                                        <label className=''>What Is The Outcome Student/Professional Is Looking For?</label>
                                        <p className='font-[300]'>{data?.mentorShipSession?.whatIsOutcomeYouAreLookingFor || <span className='text-red-500'>  Not provided</span>}</p>
                                    </div>
                                </Grid>

                                <Grid item md={12}>
                                    <div className='form-item '>
                                        <label className=''>Additional Information</label>
                                        <p className='font-[300]'>{data?.mentorShipSession?.additionalInformation || <span className='text-red-500'>  Not provided</span>}</p>
                                    </div>
                                </Grid>

                            </Grid>


                        </div>
                    </div>

                </Box>
            </Modal>
        </div>
    );
}