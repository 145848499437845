import axios from "../../axios";
import { BASEURL } from "../../environments/environment";

export const listAllInstitutionStudent = (id: string,page:number,majorFilter?:string) => {
  return axios
    .get(`${BASEURL}institution/${id}/student-list?page=${page}&pageSize=50${majorFilter?`&major=${majorFilter}`:""}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const studentSheetFormat = () => {
  return axios
    .get(`${BASEURL}institution/students_excel`, {responseType: "blob" })
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const uploadStudentsListSheet = (data: any) => {
  return axios
    .post(`${BASEURL}institution/upload-students`,data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};