import styles from "./sectionHeader.module.scss";
interface HeaderProps {
  title: string;
  description: string;
}

export default function SectionHeader(props: HeaderProps) {
  const { title, description } = props;
  return (
    <div className={styles.section_header}>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.description}>{description}</p>
    </div>
  );
}
