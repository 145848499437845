import React, { useEffect, useState } from "react";
import styles from "./companyinfo.module.scss";
import CarouselList from "../../../../Components/CardsList";
import { Job } from "../../../../models/job";
import { listjobsByMajor } from "../../../../services/jobsService";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from 'react-router';
interface SimilarJobsProps {
  nested?: boolean
  selectedJobMajorId: string
  loading: boolean
  selectedJobId: string
}
export default function SimilarJobs(props: SimilarJobsProps) {
  const { id } = useParams()
  const { selectedJobMajorId, nested, loading, selectedJobId } = props
  const [jobs, setJobs] = useState<Array<Job>>([])
  const [loadingPost, setLoadingPost] = useState(true)
  const token = useSelector((state: any) => state.auth.token);
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const getGobs = async () => {
    const response = await listjobsByMajor(selectedJobMajorId, id || selectedJobId,0)
    if (response) {
      setJobs(response.data.data.queryResult)
      setLoadingPost(false)
    }
  }

  useEffect(() => {
    if (token && selectedJobMajorId) {
      getGobs()
    }
  }, [token, selectedJobMajorId, pathname]);
  return (
    <div className={[styles["container"]].join(" ")}>

      {!loading && jobs.length > 0 && <h3 className="secondary-title">Other Similar Jobs</h3>}
      <CarouselList defaultCount={nested ? 2 : null} list={jobs} loading={loadingPost || loading} listAllData={getGobs}/>
      {!loading && jobs.length > 0 && 
      <div className="w-100 flex justify-end"><a
      className='main-link cursor-pointer'
      onClick={() => navigate(`/other-similar-jobs/${id||selectedJobId}/${selectedJobMajorId}`)}
    >
      View all
    </a>
    </div>
      }
    </div>
  );
}
