import Moment from "react-moment";

interface EducationElementProps {
  item: any
}
export default function EducationElement(props: EducationElementProps) {
  const { item } = props

  return (
    <div className="flex">
      {/* <img src={Logo} width={60} height={60} className='h-fit mx-4 small-logo' alt={item?.university}/> */}
      <div className="mb-1 text-[#222222]">
        <p className="font-semibold text-[16px] my-2">{item?.university}</p>
        <p className="font-light text-[15px] my-1 ">{item?.educationLevel?.nameEn}, {item?.major?.nameEn}</p>
        {item?.startingDate && <p className="font-light text-[15px] my-1"><Moment format="MMMM YYYY">{item?.startingDate}</Moment> - <Moment format="MMMM YYYY">{item?.endingDate}</Moment></p>}
        <p className="font-light text-[15px] my-1">
          Minor/Concentration:{" "}
          <span className="font-light">{item?.minor || "N/A"}</span>
        </p>
        {item?.graduationDate && <p className="font-light text-[15px] my-1">
          Graduation date: <Moment format="MMMM YYYY">{item?.graduationDate}</Moment>
        </p>}
        {item?.gpa && <p className="font-light text-[15px] my-1">
          GPA:{" "}
          <span className="font-light">{item?.gpa}</span>
        </p>}
      </div>
    </div>
  );
}
