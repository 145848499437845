import { Input, TextField } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../Components/CustomButton';
import styles from '../inbox.module.scss'
import AddLinkIcon from '@mui/icons-material/AddLink';
import CloseIcon from '@mui/icons-material/Close';
import { sendMesage } from '../../../../services/mesages';
import { useDispatch } from 'react-redux';
import { addAlertInfo } from '../../../../store/alertSlice';
import CustomizedButtons from '../../../../Components/CustomButton';
import axios from 'axios';
import SendIcon from '@mui/icons-material/Send';
interface MessageProps {
    receiverId: string
    setMyMesagesList: any
    myMessagesList: any
    getChatInfo: any
    isMobile?: any
}
function ChatInbox(props: MessageProps) {
    const { receiverId, setMyMesagesList, myMessagesList, getChatInfo, isMobile } = props
    const [fileList, setFileList] = useState<any>([]);
    const [validUrls, setValidUrls] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [allText, setAllText] = useState<any>([]);
    const [isFocused, setIsFocused] = useState<any>(false);

    const [message, setmessage] = useState('');
    const dispatch = useDispatch()
    const formData = new FormData()
    formData.append("receiver", receiverId)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        let temp = [...fileList]
        temp.push(e.target.files)
        setFileList(temp);
        formData.append("attachment", JSON.stringify(temp))
    };

    const RemoveItem = (index: number) => {
        let temp = [...fileList]
        temp.splice(index, 1)
        setFileList(temp)
        formData.append("attachment", JSON.stringify(temp))
    }
    const isWhitespace = (text: any) => {
        return text.replace(/\s/g, '')
    }
    const handleSendMesage = async () => {
        let mesagesList = [...myMessagesList]

        if (message && isWhitespace(message) !== '') {
            setLoading(true)
            formData.append("message", message)
            const response = await sendMesage(formData)
            if (response.status === 201) {
                mesagesList.push(message)
                setmessage('')
                setMyMesagesList(mesagesList)

                const ChatInfo = await getChatInfo(false)
                setMyMesagesList([])

            }
            else {

                dispatch(addAlertInfo(
                    {
                        show: true,
                        message: response.data.error,
                        type: 'error'
                    }
                ))
            }
            setLoading(false)
        }

    }
    const HandleKeyDown = (e: any) => {
        if (e.ctrlKey && e.keyCode === 13) {
            handleSendMesage()
        }
        else { return }
    }
    const isValidUrl = (urlString: any) => {
        const temp = []
        try {
            if (Boolean(new URL(urlString))) {
                temp.push(urlString)
                setValidUrls(temp)
            }
        }
        catch {

        }

    }
    const checkUrl = () => {
        let words = message.replace(/\n/g, " br00cbr#* ");
        const array = words.split(/[ ]/);

        const temp = []
        for (let i = 0; i < array.length; i++) {
            isValidUrl(array[i])
            temp.push(array[i] == '' ? ' ' : array[i])
            setAllText(temp)
        }
    }

    useEffect(() => {
        checkUrl()
        // getLinkPreview()
    }, [message]);
    const ref = useRef(null);


    return (
        <div >
            <div className={styles['message']}>
                <div className={styles['message-attachements']}>
                    {fileList.length > 0 &&
                        <ul>
                            {fileList.map((file: any, i: number) => (
                                <li key={i}>
                                    <button onClick={() => RemoveItem(i)}>
                                        <CloseIcon />
                                    </button>

                                    {file[0].name}
                                </li>
                            ))}
                        </ul>}
                </div>
                <div className={styles["new-message"]}>
                    <div className={styles['input-message']}>
                        <div ref={ref} className='send_box' id="send_box"
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                        >
                            {/* <p className={styles['text-area']}>

                                {allText.map((item: any, index: number) => {
                                    return (
                                        <>
                                            {item == ' ' || item == '' ?
                                                '' :
                                                item == 'br00cbr#*' ?
                                                    <div className='block w-full' />
                                                    :
                                                    <p className={`${validUrls.includes(item) ? 'main-link' : ''} ${index != allText.length - 1 ? 'mr-[5px]' : ''}  `} >
                                                        {validUrls.includes(item) ?
                                                            <a href={item} target="_blank" className='main-link z-50'>{item}</a> :
                                                            item}</p>
                                            }


                                       
                                            {index == allText.length - 1 && isFocused &&
                                                <div className="animate_pulse flex ">
                                                    <span className="relative inline-flex rounded-full h-4 w-[1px] bg-slate-700"></span>
                                                </div>}
                                        </>

                                    )
                                })}



                            </p> */}
                            <TextField
                                value={message}
                                className={styles["new-message-input"]}
                                placeholder="Write a message here..."
                                multiline
                                rows={3}
                                maxRows={20}
                                sx={{ borderRadius: '16px', fontSize: '14px' }}
                                onChange={e => setmessage(e.target.value)}
                                onKeyDown={e => HandleKeyDown(e)} />
                        </div>

                        <p className='text-left text-[11px] md:text-sm mt-2 flex' >Type your message then click Send or <div className='keybord-key'> Ctrl</div> + <div className='keybord-key'> Enter</div> </p>
                    </div>
                    {!isMobile ?
                        <CustomizedButtons text={!isMobile ? "Send" : ''} click={handleSendMesage}
                            disabled={!message || isWhitespace(message) === '' || loading} /> :
                        <button onClick={handleSendMesage} disabled={!message || isWhitespace(message) === '' || loading} className="rounded-lg">
                            <SendIcon />
                        </button>}
                </div>

            </div>



        </div>



    );
}
export default ChatInbox;