import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import Moment from 'react-moment';
import NoDataMessage from '../../../../Components/NoDataMessage';
import { latestInteractionsWithEmployersForStudent } from '../../../../services/analyticsService';

export default function LatestInteractionsWithEmployersForStudents(props: any) {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const getData = async () => {
    const response = await latestInteractionsWithEmployersForStudent(user._id, 0, 4)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token]);
  const getApplicationStatus = (status: string) => {

    if (status == '0') return 'Pending'
    if (status == '1') return 'Hired'
    if (status == '2') return 'Declined'
  }
  return (
    <Card {...props} className='post mt-16'>
      <CardHeader title={<p className='main-title'>Latest interactions with employers</p>} />
      <div className='main-table'>
        {data?.length > 0 ?
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc">
                  Employer
                </TableCell>
                {/* <TableCell sortDirection="desc">
                  Employer Name
                </TableCell> */}
                <TableCell sortDirection="desc">
                  Job Posting
                </TableCell>
                <TableCell align='center'>
                  Application Status
                </TableCell>
                <TableCell sortDirection="desc">
                  Latest update
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item: any) => (
                <TableRow
                  hover
                  key={item._id}
                >

                  <TableCell>
                    <a className='cursor-pointer' onClick={() => navigate(`/company-information/${item?.company?._id}`)}>
                      {item?.company?.subModel?.name} 
                    </a>

                  </TableCell>
                  {/* <TableCell>
                    <a className='cursor-pointer' onClick={() => navigate(`/company/profile/${item?.company?._id}`)}>
                      {item?.company?.subModel?.name}
                    </a>

                  </TableCell> */}
                  <TableCell>
                    <a className='main-link' onClick={() => navigate(`/job-details/${item.job?._id}`)}>{item?.job?.name}</a>
                  </TableCell>
                  <TableCell>
                    <div className={`${getApplicationStatus(item?.status)}_label text-center`}>
                      {getApplicationStatus(item?.status)}
                    </div>

                  </TableCell>
                  <TableCell>
                    <Moment format="MMMM DD, yyyy">{item?.updatedAt}</Moment>

                  </TableCell>

                </TableRow>
              ))}
            </TableBody>

          </Table> :
          <NoDataMessage />}
      </div>

      {data?.length > 0 && <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <a
          className='colored cursor-pointer'
          onClick={() => navigate(`/interactions-with-employers`)}
        >
          View all<ArrowRightIcon />
        </a>
      </Box>}
    </Card>
  )
}
