import React, { useEffect, useState } from "react";
import { Container, Pagination } from "@mui/material";
import { useSelector } from "react-redux";
import CompanyCard from "../../../Components/CompanyCard";
import NoDataMessage from "../../../Components/NoDataMessage";
import { listCompaniesThatHiresFromSchool } from "../../../services/students/companies";
import { CardLoadingSkeleton } from "../Access/Tabs";
import { listFollowedCompanies } from "../../../services/followingService";
import NoDataMessageImage from "../../../Components/NoDataMessageWithImage";

export default function FollwedCompanies() {
  const token = useSelector((state: any) => state.auth.token);
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)
  const user = useSelector((state: any) => state.auth.user);
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page-1)
  }
  const getCompaniesList = async () => {
    const response = await listFollowedCompanies(user._id,currentPage,9)
    if (response) {
       setLoading(false)
      setCompanies(response.data.data.followedCompanies)
       setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9))
    }
 
  }
  useEffect(() => {
    if (token) {
      getCompaniesList()
    }
  }, [token]);

  return (
    <Container>
      <div className="m-b-50 main-b-border pb-6 header-underline">
        <div className='title'>{user?.subModel?.firstName}'s followed companies</div>
        {/* <p className='main-description'>These are a jobs you are interested in</p> */}
      </div>
      {!loading ?
        <div>
          {companies&&companies.length > 0 ?
            <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">

              {companies.map((item, i) => (
                <CompanyCard data={item} index={i}/>
              ))
              }
            </div>
            :
            <NoDataMessageImage />
          }
        
        </div>
        :
        <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {[1, 2, 3].map((item: any) => {
            return (
              <CardLoadingSkeleton />
            )
          })}
        </div>
      }
        {totlaPages > 1 &&
            <div className="flex justify-end my-6">

              <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
            </div>
          }
    </Container>

  );
}
