import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "../../../Student/Home/Header/header.module.scss";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CardImage from "../../../../assets/images//temp/2.png";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { InistitutionCardLoadingSkeleton } from "../../../../Components/Loaders/InstitutionCard";
import moment from "moment";
interface HeaderProps { }

const Header = (props: HeaderProps) => {
  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const allChats = useSelector((state: any) => state.chats.data);
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const navigateTo = (url: string) => {
    navigate(url)
  }
  useEffect(() => {
    if (token) {
      setLoading(false)
    }
  }, [token]);
  return (
    <div className={styles["header"]}>
      <Container className={styles.container}>
        <Grid container className={styles.grid}>
          <Grid item lg={7} md={7} xs={12} className={styles.header_info}>
            <p className={styles.header_text}>
              Find the right students and career professionals early on in their career path
            </p>
          </Grid>
 
          {
            (allChats && allChats?.length > 0 && allChats[0]?.LastMessageStatus != 'read'&&!allChats[0]?.sentByMe) && <Grid item xs={12} md={5} className={styles.header_info_box}>
              <Box className={styles.info}>
                <h6>{allChats[0]?.otherUser?.name} contacted you, reply back</h6>
                <p>
                  They sent you a message {moment(allChats[0]?.LastMessageDate).fromNow()}. 
                </p>
                <IconButton size="large" color="inherit" onClick={() => navigate('/inbox')}>
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            </Grid>}
        </Grid>
        <Grid container className={styles.univirsity_info}>
          {loading ?
            <InistitutionCardLoadingSkeleton /> :
            <Card className={styles.card} onClick={() => navigateTo('/company/information')}>
              <CardActionArea className={styles.card}>
                {userInfo?.subModel?.logo &&
                  <div className={styles.img_border}>
                    <img src={userInfo?.subModel?.logo} />
                  </div>
                }
                <CardContent className={styles.contint}>
                  <div>
                    <h5>{userInfo?.subModel?.name}</h5>
                  </div>
                  <IconButton size="large" color="inherit">
                    <ArrowForwardIcon />
                  </IconButton>
                </CardContent>
              </CardActionArea>
            </Card>
          }
        </Grid>
      </Container>
    </div>
  );
};

export default Header;
