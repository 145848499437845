import { Chip, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import CustomizedButtons from "../../../../../Components/CustomButton";
import styles from './card.module.scss'
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from "../../../../../services/authService";
import { addAlertInfo } from "../../../../../store/alertSlice";
import LanguagesSelection from "../../../../../Components/ItemSelect/Languages";
interface SkillsCardProps {
  label: string;
  itemsList: Array<any>
  type: string
}
export default function LanguageCard(props: SkillsCardProps) {
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.auth.user);
  const { label, itemsList, type } = props;
  const [keyword, setKeyword] = useState('')
  const [list, setList] = useState<Array<any>>([...itemsList])
  const [setSelected, setSetSelected] = useState<Array<any>>([])

  const [loadingbtns, setLoadingbtns] = useState<any>({});
  const formData = new FormData()
  const handleLanguageChange = (e: any) => {
    setSetSelected(e.value)
    addToList(e.value)
  }
  const addToList = (list:any) => {
    Update_UserProfile(type,list.map((lang:any)=>lang.id))
  }

  const Update_UserProfile = async (key: string,dataList:any) => {
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))
    setLoadingbtns((_data: any) => ({ ..._data, [key]: true }))

   if (type === 'proficientLanguage') {
      formData.append("proficientLanguage", JSON.stringify(dataList));
    }
    else if (type === 'fluentLanguage') {
      formData.append("fluentLanguage", JSON.stringify(dataList));
    }
    const response = await UpdateUserProfile(user._id, formData)
    if (response.status === 200) {

      setLoadingbtns((_data: any) => ({ ..._data, [key]: false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
    }
    else {
      setLoadingbtns((_data: any) => ({ ..._data, key: false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }

  return (
    <div className=" border-[1px] p-4 rounded my-6 ">
      <p className="font-semibold text-[18px] ">{label}</p>
      <div className="flex items-center mt-4">
        <LanguagesSelection
        multiple={true}
          placeholder={`select ${label}`}
          handleChange={(e: any) => handleLanguageChange(e)}
          defaultValue={list.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))}
        />
        {/* <TextField
          fullWidth
          id="outlined-basic"
          variant="outlined"
          hiddenLabel
          placeholder={`Enter ${label}`}
          size="small"
          onChange={e => setKeyword(e.target.value)}
          value={keyword}
        />*/}
      </div>
      {/* {list && list.length > 0 &&

        <div className={[styles['selected-jobs'], 'mt-2'].join(' ')}>
          <p className="description my-2">You are currently open to the below, you can add from + and delete by clicking on -</p>

          <Stack direction="row" gap={1} className="flex-wrap">
            {
              list.map((item: any, index: number) => {
                return (
                  <Chip label={item.nameEn ? item.nameEn : item}
                    onClick={() => RemoveItem(index)}
                    onDelete={() => RemoveItem(index)}
                    deleteIcon={<RemoveIcon />} />
                )
              })}
          </Stack>
        </div>
      } */}
      <div className='custom-action  my-2 flex gap-x-4'>
        {/* {list.length > 0 &&
          <CustomizedButtons text="Cancel" click={emptyList} loading={loadingbtns['empty']}/>
        } */}
        {/* <CustomizedButtons text="Add" click={addToList} loading={loadingbtns[type]} /> */}
      </div>
    </div>
  );
}
