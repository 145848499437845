import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import CompanyCard from "../../../../../Components/CompanyCard";
import { listCompanies, listCompaniesThatHiresFromSchool } from "../../../../../services/students/companies";
import { CardLoadingSkeleton } from "..";
import { useSelector } from "react-redux";
import NoDataMessage from "../../../../../Components/NoDataMessage";

export default function TabOne() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page-1)
  }
  const getCompaniesList = async () => {
    setLoading(true)
    const response = await listCompaniesThatHiresFromSchool({ currentPage: currentPage, id: user?._id })
    if (response) {
       setLoading(false)
      setCompanies(response.data.data.queryResult)
      // setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9))
    }
 
  }
  useEffect(() => {
    if (token) {
      getCompaniesList()
    }
  }, [token]);

  return (
    <>
      {!loading ?
        <div>
          {companies&&companies.length > 0 ?
            <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">

              {companies.map((item, i) => (
                <CompanyCard data={item} index={i}/>
              ))
              }
            </div>
            :
            <NoDataMessage />
          }
     
        </div>
        :
        <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          {[1, 2, 3].map((item: any) => {
            return (
              <CardLoadingSkeleton />
            )
          })}
        </div>
      }
           {totlaPages > 1 &&
            <div className="flex justify-end my-6">

              <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
            </div>
          }
    </>

  );
}
