import React from "react";
import './popup.scss';
import { Dialog, DialogTitle, DialogContent, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router";
interface PopupProp {
    open: boolean
    setOpenPop: React.Dispatch<React.SetStateAction<boolean>>;
}
const DialogModalStudent = ({ open, setOpenPop }: PopupProp) => {
    const handleClose = () => {
        setOpenPop(false)
    }
    const navigate = useNavigate();
    return (

        <Dialog className="dialog" open={open} >
            <DialogTitle >
                <div className="title">
                    <div><b>Enhance your experience as a job seeker:</b></div>
                    <div><label onClick={handleClose} id= "closePopup"> &times;</label></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    1. Complete your profile for personalized opportunities.<br />
                    2. Explore job listings tailored to your skills and interests.<br />
                    3. Connect with mentors and industry professionals.<br />
                    4. Upload CV, portfolio, cover letters, and much more.<br />
                    5. Gain valuable insights.<br />
                </Typography>
                <br/>
                <Typography><b>Quick actions:</b></Typography>
                <div className="buttons">
                    <Button id="button1" size="small" className="btn" onClick={() => navigate("/professionals/my-profile")}>
                        Complete my profile
                    </Button>
                    <Button id="button2" size="small" className="btn" onClick={() => navigate("/professionals/jobs")}>
                        Explore jobs
                    </Button>
                    <Button id="button3" size="small" className="btn" onClick={() => navigate("/employers")}>
                        Connect with employers
                    </Button>

                    <Button id="button4" size="small" className="btn" onClick={() => navigate("/professionals/career-interests")}>
                        Specify career interests
                    </Button>
                    <Button id="button5" size="small" className="btn" onClick={() => navigate("/professionals/request-mentor")}>
                        Find a mentor
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default DialogModalStudent;
