
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from './Footer';
import Navbar from './Navbar';
import styles from './layout.module.scss'
import { getUserInfo } from '../services/userService';
import { addAuthInfo } from '../store/authSlice';
import AlertMessage from '../Components/Alert';
import { addAlertInfo } from '../store/alertSlice';
import { listAllChats } from '../services/mesages';
import { addMesages } from '../store/mesagesSlice ';
import { addDataSaved } from '../store/savedJobsSlice';
import { ListSavedJobs } from '../services/jobsService';
import { useLocation } from 'react-router-dom';
type LayoutProps = {
    children: React.ReactNode; // 👈️ type children
};
const ScrollRestoration = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [pathname]);
    return null;
};
const Layout = (props: LayoutProps) => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const token = localStorage.getItem('userToken')
    const userInfo: any = localStorage.getItem('userInfo')
    const user = JSON.parse(userInfo)
   
    const [loading, setLoading] = useState(true)

    const getUserData = async () => {
        const response = await getUserInfo(user._id)
        if (response) {
            dispatch(addAuthInfo(
                {
                    token: token,
                    user: response.data.data
                }
            ))
        }
    }
    const getAllChats = async () => {
        const response = await listAllChats(user._id)
        if (response.status === 200) {
            dispatch(addMesages(
                {
                    data: response.data.data
                }
            ))
        }
    }
    const getSavedJobs = async () => {
        const response = await ListSavedJobs(user._id)
        if (response.status === 200) {
            dispatch(addDataSaved(
                { savedJobs: response?.data?.data?.savedJobs?.map((item:any)=>item?._id) }
            ))

        }
    }
    useEffect(() => {
        if (token) {
            getUserData()
            getAllChats()
            setLoading(false)
        }
    }, [token,pathname]);
    useEffect(() => {
        if (!token) {
            setTimeout(() => {
                setLoading(false)
              }, 2000);
           
        }
    }, [token]);
    useEffect(() => {
        if (token && user.model === 'Student') {
            getSavedJobs()
        }
    }, [token,pathname]);


    const { children } = props
    
    return (
        <div className={styles['home-page']}>
            {!loading &&
                <>
                    <ScrollRestoration />
                    <Navbar />
                    <div className={styles['page']}>


                        {children}
                    </div>
                    <Footer />
                </>
            }
            {loading &&
                <div className={styles['lds-spinner']}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
        </div>
    );
};

export default Layout;