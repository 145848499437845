import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import WorkIcon from '@mui/icons-material/Work';
import CustomizedButtons from '../../../../../Components/CustomButton';
import PeopleIcon from '@mui/icons-material/People';
import PopUpSendMesage from '../../../../../Components/PopUp';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
interface MentorCardProps {
    name: string
    subName: string
    title: string
    userAvatar?: string
    position?: string
    student?: any
    jobType?: any
}

export default function MenteeCard(props: MentorCardProps) {
    const navigate = useNavigate()
    const { name, subName, title, userAvatar, student, jobType } = props
    const user = useSelector((state: any) => state.auth.user);
    const [open, setOpen] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState<any>({});
    const handleSendMesage = () => {

        setOpen(true)
    }

    return (
        <Card className='post bg-light-green border-green h-full'>
            {open &&
                <PopUpSendMesage
                    user={student}
                    show={open}
                    setOpen={setOpen} resiverName={`${student?.subModel?.firstName} 
              ${student?.subModel?.lastName}`}
                    resiverEmail={student?.email}
                    id={student?._id} />} 
            <div >
                <CardHeader
                    avatar={
                        !userAvatar ?
                            <Avatar sx={{ width: 60, height: 60, bgcolor: "#F26400" }} aria-label="avatar" onClick={() => navigate(`/professional/profile/${student?._id}`)}>
                                {subName && subName?.toUpperCase()}
                            </Avatar>
                            :
                            <Avatar sx={{ width: 60, height: 60 }}
                                aria-label="avatar" src={userAvatar} onClick={() => navigate(`/professional/profile/${student?._id}`)}>
                            </Avatar>

                    }

                    title={
                        
                        <a className='cursor-pointer block' onClick={() => navigate(`/professional/profile/${student?._id}`)}>
                            <span className='block font-semibold'> {name}</span>

                        </a>
                    }
                />
                <div>
                    <span className='block text-gray-600 text-sm'>
                        <PeopleIcon className='mr-1 text-gray-600 text-[14px]' />
                        {title}</span>
                    <span className='block text-gray-600 text-sm'>
                        <WorkIcon className='mr-1 text-gray-600 text-[14px]' />
                        {jobType}</span>
                </div>

                {user._id !== student?._id &&
                    <div className={`actions-center rounded-action custom-action-fill w-full mt-4`}>
                        <CustomizedButtons
                            text={'Message'}
                            click={() => handleSendMesage()} />
                    </div>
                }

            </div>

        </Card>
    );
}