import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import styles from "./navbar.module.scss";
import LogoImage from "../../assets/images/Logo.png";
import { Container, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import NotificationMenu from "./components/notificationMenu";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector } from "react-redux";
import { getMessagsNumber } from "../../services/mesages";
import Avatar from "@mui/material/Avatar";

export default function Navbar() {
  const userInfo = useSelector((state: any) => state.auth.user);
  const [messagsNumber, setMessagsNumber] = React.useState(0)
  const User = userInfo?.model
  const handleNavigateion = () => {
    if (User === "Student") {
      window.location.href = "/professionals-home"
    }

    else if (User === "Mentor") {
      window.location.href = "/mentor/home"
    }
    else if (User === 'Institution') {
      window.location.href = "/institution/home"
    }
    else if (User === 'Company') {
      window.location.href = "/company/home"
    }

  }

  const GetMessagsNumber = async () => {
    const response = await getMessagsNumber()
    setMessagsNumber(response?.data?.data?.message)
  }
  React.useEffect(() => {
    setTimeout(() => {
      GetMessagsNumber()
    }, 1500);

  }, [window.location.href])

  const navItems =
    User === 'Student' ? [
      {
        name: 'Jobs',
        link: '/professionals/jobs'
      },
      {
        name: 'Employers',
        link: '/employers'
      },

      // {
      //   name: 'Cairo University',
      //   link: '/professionals/institutions'
      // }
    ]
      : User === 'Company' ?
        [
          // {
          //   name: 'Jobs',
          //   link: '/professionals/jobs'
          // },
          {
            name: 'Students/Professionals',
            link: '/professionals-list'
          },
          {
            name: userInfo?.subModel?.name,
            link: `/company/information`
          }
          // {
          //   name: 'Educational Institutions',
          //   link: '/'
          // }
        ]
        : User === 'Institution' ?
          [
            {
              name: 'Jobs',
              link: '/institution/jobs'
            },
            {
              name: 'Employers',
              link: '/employers'
            },
            {
              name: userInfo?.subModel?.name,
              link: `/institution/${userInfo?._id}`
            }
          ]
          :
          [
          ]

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const settingsUser = [
    {
      name: 'Home',
      url: '/professionals-home'
    },
    {
      name: 'Profile',
      url: '/professionals/my-profile'
    },
    {
      name: 'Documents',
      url: '/professionals/manage-documents'
    },
    {
      name: 'Job Applications',
      url: '/professionals/job-application'
    },
    {
      name: 'Saved Jobs',
      url: '/professionals/saved-jobs'
    },
    {
      name: 'Followed Companies',
      url: '/professionals/followed-companies'
    },

    {
      name: 'Interviews & Meetings',
      url: '/calendar'
    },
    {
      name: 'Career Interests',
      url: '/professionals/career-interests'
    },
    {
      name: 'Reviews',
      url: '/professionals/reviews'
    }, {
      name: 'Mentorship Sessions',
      url: '/professionals/mentorship-sessions'
    },
    {
      name: 'Request a mentor',
      url: '/professionals/request-mentor'
    },

    {
      name: 'Notification Preferences',
      url: '/notification-preferences'
    },
    {
      name: 'Settings',
      url: '/professionals/settings'
    },

  ];
  const settingsEmployers = [
    {
      name: 'Home Dashboard',
      url: '/company/home'
    },
    {
      name: 'Company Information',
      url: '/company/information'
    },
    {
      name: 'Job Posts',
      url: '/company/add-information?tap=1'
    },
    {
      name: 'Create Job Post',
      url: '/company/add-information?tap=2'
    },

    // {
    //   name: " Admin Profile",
    //   url: '/company/profile'
    // },

    {
      name: 'Messaging Settings',
      url: '/company/messaging-setting'
    },

    {
      name: 'Calendar',
      url: '/calendar'
    },
    {
      name: 'Notification Preferences',
      url: '/notification-preferences'
    },
    {
      name: 'Settings',
      url: '/company/add-information?tap=0'
    },
    {
      name: "User Profile",
      url: '/company/profile'
    },
  ];
  const settingsInstitution = [
    {
      name: 'Home Dashboard',
      url: '/institution/home'
    },

    {
      name: 'Students List',
      url: '/institution/students-list'
    },
    {
      name: 'Student Profiles',
      url: '/institution/student-profiles'
    },
    {
      name: "Public Profile",
      url: '/institution/profile'
    },
    // {
    //   name: "Admin Profile",
    //   url: '/institution/admin-profile'
    // },
    {
      name: 'Calendar',
      url: '/calendar'
    },
    {
      name: 'Notification Preferences',
      url: '/notification-preferences'
    },
    {
      name: 'Settings',
      url: '/institution/school-information'
    },
    // {
    //   name: 'Settings',
    //   url: '/institution/settings'
    // },

  ];
  const settingsMentor = [
    {
      name: 'Home Dashboard',
      url: '/mentor/home'
    },
    {
      name: "Public Profile",
      url: '/mentor/profile'
    },
    {
      name: "Mentorship Program",
      url: '/mentor/mentorship-program'
    },
    {
      name: "Add Availability Times",
      url: '/mentor/add-availability'
    },
    {
      name: "Connect with Mentors",
      url: '/mentor/connect-mentors'
    },

    {
      name: 'Calendar',
      url: '/calendar'
    },
    {
      name: 'Notification Preferences',
      url: '/notification-preferences'
    },
    {
      name: 'Settings',
      url: '/mentor/settings'
    },

  ];



  const settings = User === 'Student' ?
    settingsUser
    : User === 'Mentor' ? settingsMentor
      : User === 'employer' ?
        settingsEmployers
        : User === 'Institution' ?
          settingsInstitution : settingsEmployers;
  const settingsMobile = [
    {
      name: 'Jobs',
      url: '/professionals/jobs'
    },
    {
      name: 'Employers',
      url: '/employers'
    },
    {
      name: 'Cairo University',
      url: '/professionals/institutions'
    },
    {
      name: 'Job Applications',
      url: '/professionals/job-application'
    },
    {
      name: 'Saved Jobs',
      url: '/professionals/saved-jobs'
    },
    {
      name: 'Interviews & Meetings',
      url: '/calendar'
    },
    {
      name: 'Reviews',
      url: '/professionals/reviews'
    },
    {
      name: 'Mentorship Sessions',
      url: '/professionals/mentorship-sessions'
    },
    {
      name: 'Career Interests',
      url: '/professionals/career-interests'
    },
    {
      name: 'Request a mentor',
      url: '/professionals/request-mentor'
    },

    {
      name: 'Messaging Settings',
      url: '/company/messaging-setting'
    },
    {
      name: 'Notification Preferences',
      url: '/notification-preferences'
    },
    {
      name: 'Settings',
      url: '/professionals/settings'
    },
  ];
  const logout = () => {
    localStorage.clear();
    window.location.href = '/'
  }
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {

    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const navigate = useNavigate();
  function handleClick(href: string) {
    navigate(href);
    handleCloseUserMenu()
    handleMenuClose()
  }
  const menuId = "primary-search-account-menu";
  const menuIdLang = "primary-lang-menu";
  // const renderMenu = (
  //   <Menu
  //     sx={{ mt: '45px' }}
  //     anchorEl={anchorEl}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     id={menuIdLang}
  //     keepMounted
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={handleMenuClose}>Arabic</MenuItem>
  //     <MenuItem onClick={handleMenuClose}>English</MenuItem>
  //   </Menu>
  // );
  const words = (str: any, pattern = /[^a-zA-Z-]+/) =>
    str?.split(pattern).filter(Boolean);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <div >
      <Menu
        sx={{ mt: '45px' }}
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={() => handleClick('/inbox')} selected={"/inbox" === window.location.pathname}>
          <IconButton
            className={styles["no-left-padding"]}
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={messagsNumber} color="warning">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        <MenuItem
          onClick={() => handleClick('/notifications')} selected={"/notifications" === window.location.pathname}>
          <IconButton
            id="basic-button"
            className={styles["no-left-padding"]}
            size="large"
            color="inherit"
          >
            <Badge color="warning">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        {User === 'Student' &&
          <>
            <MenuItem onClick={() => handleClick('/professionals/my-profile')} selected={"/professionals/my-profile" === window.location.pathname}>
              <IconButton
                size="large"
                color="inherit"
                className={styles["no-left-padding"]}
              >
                {/*<AccountCircle />*/}
                {userInfo.profileImage || userInfo?.subModel?.logo ? (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Avatar alt="User Avatar" src={userInfo.profileImage || userInfo?.subModel?.logo} sx={{width: 24, height: 24}}/>
                </div>
                ) : <AccountCircle />}
              </IconButton>

              {userInfo?.subModel?.firstName && <p className="uppercase font-medium">{userInfo?.subModel?.firstName?.charAt(0) + userInfo?.subModel?.lastName?.charAt(0)}</p>}
              {userInfo?.subModel?.name && <p className="uppercase font-medium">
              {words(userInfo?.subModel?.name)[0]?.charAt(0)}{words(userInfo?.subModel?.name)[1]?.charAt(0)}
              </p>}

            </MenuItem>

            {userInfo?.subModel?.institution && <MenuItem onClick={() => handleClick(`/professionals/institution/${userInfo?.subModel?.institution?._id}`)} selected={`/professionals/institution/${userInfo?.subModel?.institution?._id}` === window.location.pathname}>
              <Typography textAlign="center" >
                {userInfo?.subModel?.institution?.subModel?.name}
              </Typography>
            </MenuItem>}
            <MenuItem onClick={() => handleClick('/professionals/jobs')} selected={"/professionals/jobs" === window.location.pathname}>
              <Typography textAlign="center" >
                Jobs
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleClick('/employers')} selected={"/employers" === window.location.pathname}>
              <Typography textAlign="center" >
                Employers
              </Typography>
            </MenuItem>
          </>}
        {User === 'Institution' &&
          <>
            <MenuItem onClick={() => handleClick(`/institution/${userInfo?._id}`)} selected={`/institution/${userInfo?._id}` === window.location.pathname}>
              <Typography textAlign="center" >
                {userInfo?.subModel?.name}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleClick('/institution/jobs')} selected={"/institution/jobs" === window.location.pathname}>
              <Typography textAlign="center" >
                Jobs
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleClick('/employers')} selected={"/employers" === window.location.pathname}>
              <Typography textAlign="center" >
                Employers
              </Typography>
            </MenuItem>
          </>}
        {User === 'Company' &&
          <>
            <MenuItem onClick={() => handleClick(`/company/information`)} selected={`/company/information` === window.location.pathname}>
              <Typography textAlign="center" >
                {userInfo?.subModel?.name}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => handleClick('/professionals-list')} selected={"/professionals-list" === window.location.pathname}>
              <Typography textAlign="center" >
                Students/Professionals
              </Typography>
            </MenuItem>
            {/* <MenuItem onClick={() => handleClick('/employers')} selected={"/employers" === window.location.pathname}>
            <Typography textAlign="center" >
            Employers
            </Typography>
          </MenuItem> */}
          </>}
        {settings.map((setting) => (
          <MenuItem key={setting.name} onClick={() => handleClick(setting.url)}
            selected={setting.url === window.location.pathname}
          >
            <Typography textAlign="center" >
              {setting.name}
            </Typography>
          </MenuItem>

        ))}
      </Menu>
    </div>

  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" className={styles.nav}>
        <Container>
          <Toolbar className={styles.navbar}>
            <a className={styles.navLogo} onClick={handleNavigateion} >
              <img src={LogoImage} />
            </a>

            <Box sx={{ flexGrow: 1 }} />
            <ul className={[styles.navList, "desktop-view"].join(" ")}>
              {navItems.map((item) => (
                <li onClick={() => handleClick(item.link)} className="cursor-pointer">
                  <a>{item.name}</a>
                </li>
              ))}
              {
                User === 'Student' && userInfo?.subModel?.institution &&
                <li onClick={() => handleClick(`/professionals/institution/${userInfo?.subModel?.institution?._id}`)}
                  className="max-w-[150px] cursor-pointer truncate m-auto">
                  <span className={styles.link} >
                    {userInfo?.subModel?.institution?.subModel?.name}
                  </span>
                </li>

              }

            </ul>

            <Box
              sx={{ display: { xs: "none", md: "flex" } }}
              className={styles.navIcons}
            >
              <IconButton
                size="small"
                aria-label="show 4 new mails"
                color="inherit"
                onClick={() => handleClick('/inbox')}
              >
                <Badge badgeContent={messagsNumber} color="warning">
                  <MailIcon />
                </Badge>
              </IconButton>

              <button><NotificationMenu /></button>

              {/*<IconButton*/}
              {/*  size="small"*/}
              {/*  edge="end"*/}
              {/*  aria-label="account of current user"*/}
              {/*  aria-controls={menuId}*/}
              {/*  aria-haspopup="true"*/}
              {/*  color="inherit"*/}
              {/*  className="mr-2"*/}
              {/*  onClick={handleOpenUserMenu}*/}
              {/*>*/}


              {/*  {userInfo?.subModel?.firstName && <span className="uppercase font-medium">{userInfo?.subModel?.firstName?.charAt(0) + userInfo?.subModel?.lastName?.charAt(0)}</span>}*/}
              {/*  {userInfo?.subModel?.name && <span className="uppercase font-medium">  {words(userInfo?.subModel?.name)[0]?.charAt(0)}{words(userInfo?.subModel?.name)[1]?.charAt(0)}</span>}*/}
              {/*  <ArrowDropDownIcon />*/}
              {/*</IconButton>*/}

              <IconButton
                  size="small"
                  // edge="end"
                  aria-controls={menuIdLang}
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleOpenUserMenu}
                  className="mr-2"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "40px",
                    marginTop: "0",
                  }}
              >
                {
                  userInfo.profileImage || userInfo?.subModel?.logo ? (
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Avatar
                            alt="User Avatar"
                            src={userInfo.profileImage || userInfo?.subModel?.logo}
                            sx={{
                              width: 24, height: 24,
                              // mt: userInfo?.subModel?.firstName ? 3 : 0
                            }}
                        />
                        <div style={{ flex: 1 }}></div>
                        <ArrowDropDownIcon
                            // style={{
                            //   // marginTop: userInfo?.subModel?.firstName ? "25px" : 0
                            // }}
                        />
                      </div>
                  ) : (
                      <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            // paddingLeft: "10px",
                            // paddingRight: "10px",
                          }}
                      >
                        <div>
                          {userInfo?.subModel?.firstName && (
                              <span className="uppercase font-medium">
                            {userInfo?.subModel?.firstName?.charAt(0) +
                            userInfo?.subModel?.lastName?.charAt(0)}
                                <ArrowDropDownIcon />
                          </span>

                          )}
                        </div>
                        <div>
                          {userInfo?.subModel?.name && (
                              <span className="uppercase font-medium">
                            {userInfo.profileImage ? <ArrowDropDownIcon /> : ""}
                          </span>
                          )}
                        </div>
                      </div>
                  )
                }

              </IconButton>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (

                  <MenuItem key={setting.name} onClick={() => handleClick(setting.url)}>
                    <Typography textAlign="center">
                      {setting.name}
                    </Typography>
                  </MenuItem>

                ))}

                <MenuItem onClick={() => logout()}>
                  <Typography textAlign="center">
                    Sign out
                  </Typography>
                </MenuItem>
              </Menu>

              <IconButton
                size="small"
                edge="end"

                aria-controls={menuIdLang}
                aria-haspopup="true"
                color="inherit"
                className="mr-2"
                onClick={handleOpenMenu}
              >


                <span className="">EN</span>
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                id={menuIdLang}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>Arabic</MenuItem>
                <MenuItem onClick={handleMenuClose}>English</MenuItem>
              </Menu>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton

              >
                <div style={{ fontSize: 14 }}>

                  <a className="main-link" onClick={() => logout()}>Sign out</a>
                </div>
              </IconButton>

              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <MenuIcon className={styles["mobile-menu-icon"]} />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div className="mobile-view">
        {renderMobileMenu}
      </div>

      {/* {renderMenu} */}
    </Box >
  );
}
