
import React, { useEffect, useState } from "react";
import CustomizedButtons from "../../../../../Components/CustomButton";
import DateRangePickerComponent from "../../../../../Components/DatePicker";
import InputField from "../../../../../Components/Input";
import SelectInput from "../../../../../Components/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { UpdateUserProfile } from "../../../../../services/authService";
import { addAlertInfo } from "../../../../../store/alertSlice";
import { Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { listAllCities, listAllCountries } from "../../../../../services/locationService";
import { listCompanies } from "../../../../../services/students/companies";
interface ProjectFormProps {
  isShown: boolean;
  onCancel: any
  getUserData: any
  selectedvolunteers: any
}
export default function WorkForm(props: ProjectFormProps) {
  const feilds = ["company", "description", "role", "startingDate", "endingDate"]
  const dispatch = useDispatch()
  const { isShown, onCancel, getUserData, selectedvolunteers } = props;
  const user = useSelector((state: any) => state.auth.user);

  const token = useSelector((state: any) => state.auth.token);
  const [educationLevels, setEducationLevels] = useState<any>([]);
  const [loadingEducationLevels, setLoadingEducationLevels] = useState(true);
  const [defultCompanyOut, setDefultCompanyOut] = useState('');
  const [defultCompany, setDefultCompany] = useState('');
  const [school, setSchool] = useState<any | never>({});
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [companyIsText, setCompanyIsText] = useState('');
  const [companySelected, setCompanySelected] = useState<any>('');
  const [selectedCity, setSelectedCity] = useState<any>()
  const getAllCountries = async () => {
    const response = await listAllCountries()
    if (response) {
      const countries = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setCountries(countries)
      setLoadingCountries(false)
    }
  }
  const getAllCities = async (countryId: string) => {
    setLoadingCities(true)
    const response = await listAllCities(countryId)
    if (response) {
      const citiesOfCountry = response.data.data
      const cities = citiesOfCountry.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setCities(cities)
      setLoadingCities(false)
    }
  }

  const getCompaniesList = async () => {
    const response = await listCompanies({ currentPage: null })
    if (response) {
      const companies = response.data.data.queryResult.map((item: any) => (item.subModel.name))
      setEducationLevels(companies)
      if (selectedvolunteers !== null) {

        companies.includes(user.subModel.volunteers[selectedvolunteers].company) ?
          setDefultCompany(user.subModel.volunteers[selectedvolunteers].company) :
          setDefultCompanyOut(user.subModel.volunteers[selectedvolunteers].company)
      }
      setLoadingEducationLevels(false)
      setLoadingData(false)
    }
  }

  const handleSelectChange = (e: any) => {
 
    if (e.name === 'company') {
      // setCompanyIsText(false)
      setCompanySelected(e.value)
      setCompanyIsText('')
    }
    setSchool((_data: any) => ({ ..._data, [e.name]: e?.value?.id ? e?.value?.id : e?.value }));
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {


    setSchool((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };
  const handleChangeCompanyText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyIsText(e.target.value)
    setCompanySelected('')
    setSchool((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };
  const handleSelectContry = (e: any) => {
    handleSelectChange(e)
    setSelectedCity(null)
    setSchool((_data: any) => ({ ..._data, 'city': null }));
    if (e.value) {
      getAllCities(e.value.id)
    }
    else {
      setCities([])
    }
  }
  useEffect(() => {
    if (token) {
      getCompaniesList()
      getAllCountries()
    }
  }, [token]);
  useEffect(() => {
    if (user) {

      if (selectedvolunteers !== null) {
        setSelectedCity(user?.subModel?.volunteers[selectedvolunteers]?.city ? { 'label': user?.subModel?.volunteers[selectedvolunteers]?.city?.nameEn, 'id': user.subModel.volunteers[selectedvolunteers]?.city?._id } : null)
        setSchool(
          {
            'company': user.subModel.volunteers[selectedvolunteers].company,
            'description': user.subModel.volunteers[selectedvolunteers].description,
            'role': user.subModel.volunteers[selectedvolunteers].role,
            'startingDate': moment(user.subModel.volunteers[selectedvolunteers]?.startingDate),
            'endingDate': moment(user.subModel.volunteers[selectedvolunteers]?.endingDate),
            'city': user.subModel.volunteers[selectedvolunteers]?.city ? { 'label': user.subModel.volunteers[selectedvolunteers]?.city?.nameEn, 'id': user.subModel.volunteers[selectedvolunteers]?.city?._id } : null,
            'country': user.subModel.volunteers[selectedvolunteers]?.country ? { 'label': user.subModel.volunteers[selectedvolunteers]?.country?.nameEn, 'id': user.subModel.volunteers[selectedvolunteers]?.country?._id } : null,
          }
        )

      }
      else {
        setSchool((_data: any) => ({ ..._data, 'startingDate': moment(new Date()).format('yyyy-MM-DD') }));
        setSchool((_data: any) => ({ ..._data, 'endingDate': moment(new Date()).format('yyyy-MM-DD') }));
        setLoadingData(false)
      }
    }


  }, [user]);

  const handleClick = () => {
    setErrors(false)
    for (let i = 0; i < feilds.length; i++) {

      if (!school[feilds[i]]) {
        setErrors(true)
      }
      else {
        setErrors(false)
        if (i === feilds.length - 1) {
          UpdateUserInfo()
        }
      }
    }

  }

  const handleCancel = () => {
    onCancel()
  }

  const UpdateUserInfo = async () => {
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))

    const Updateddata: any = {}
    const data = [...user.subModel['volunteers']]
    if (selectedvolunteers !== null) {
      data.splice(selectedvolunteers, 1)
    }

    Updateddata['volunteers'] = data.map((item: any) => (
      {

        'company': item.company,
        'description': item.description,
        'role': item.role,
        'startingDate': moment(item?.startingDate).format('yyyy-MM-DD'),
        'endingDate': moment(item?.endingDate).format('yyyy-MM-DD'),
        'city': item?.city?._id,
        'country': item?.country?._id,
      }

    ))
    const updatedSchool = {
      'company': school.company,
      'description': school.description,
      'role': school.role,
      'startingDate': school.startingDate,
      'endingDate': school.endingDate,
      'city': school?.city?.id ? school?.city?.id : school?.city,
      'country': school?.country?.id ? school?.country?.id : school?.country,
    }
    Updateddata['volunteers'].push(updatedSchool)
    const formData = new FormData()
    for (const property in Updateddata) {
      if (typeof Updateddata[property] === 'string' || Updateddata[property] === 'boolean') {
        formData.append(property, Updateddata[property]);
      }
      else {
        formData.append(property, JSON.stringify(Updateddata[property]));
      }
    }
    setLoading(true)
    const response = await UpdateUserProfile(user._id, formData)

    if (response.status === 200) {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
      handleCancel()
      getUserData()
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  return (
    <div>
      {isShown && (
        !loadingData &&
        <form>
          <p className=" required my-1">Fields marked with an * are required</p>
          <div className="form-item">
            <InputField
              label="Job title/Role"
              required={true}
              placeholder='Enter'
              Change={handleChange}
              error={errors && !school.role}
              value={school.role}
              name="role"
            />

          </div>
          <div className="form-item">
            <SelectInput
              label={'Employer'}
              required={true}
              multiple={false}
              placeholder='Select'
              options={educationLevels}
              loading={loadingEducationLevels}
              name="company"
              Change={handleSelectChange}
              value={companySelected}
              error={errors && !school.company}
              defaultValue={defultCompany}
            />
          </div>
          <div className="form-item">
            <InputField
              label="If your employer is not listed above enter it below"
              required={false}
              placeholder='Enter company name'
              Change={handleChangeCompanyText}
              error={errors && !school.company}
              name="company"
              defaultValue={defultCompanyOut}
              value={companyIsText}
            />

          </div>
          <div className="mt-6 form-item">
            <Grid container columnSpacing='5'>
              <Grid item xs={6} >
                <DatePicker

                  label="From"
                  value={school.startingDate}
                  onChange={(newValue) => {
                    setSchool((_data: any) => ({ ..._data, 'startingDate': moment(newValue).format('yyyy-MM-DD') }));
                  }}
                  renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                />

              </Grid>
              <Grid item xs={6}>
                <DatePicker

                  label="To"
                  value={school.endingDate}
                  onChange={(newValue) => {
                    setSchool((_data: any) => ({ ..._data, 'endingDate': moment(newValue).format('yyyy-MM-DD') }));
                  }}
                  renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                />
              </Grid>
            </Grid>
          </div>
          <div className="form-item">
            <Grid container columnSpacing='5'>
              <Grid item xs={12}>
                <div className='form-item '>
                  <SelectInput
                    name="country"
                    required={false}
                    label='Select your country'
                    options={countries}
                    Change={handleSelectContry}
                    loading={loadingCountries}
                    defaultValue={school.country}
                  />

                </div>
              </Grid>
              <Grid item xs={12}>
                <div className='form-item '>
                  <SelectInput
                    name="city"
                    required={false}
                    label='Select your city (region/state/province options will appear with the city)'
                    labelHint='Select your country first'
                    options={cities}
                    Change={handleSelectChange}
                    loading={loadingCities}
                    disabled={!school.country}
                    defaultValue={selectedCity}
                  />

                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-item">
            <label className="relative flex justify-between">
              <span >* Description of Work</span>
              <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{school.description?.length || 0}/280</p>

            </label>
            <textarea
              name="description"
              maxLength={280}
              className="w-full border hover:border p-2 rounded-lg"
              rows={4}
              required={false}
              placeholder="Enter text here...."
              defaultValue={school.description}
              onChange={(e: any) => handleChange(e)}

            />
            {/* <InputField
              label="Description of Work"
              required={true}
              placeholder='Description'
              multiline={true}
              rows={3}
              Change={handleChange}
              error={errors && !school.description}
              name="description"
              defaultValue={school?.description}
            /> */}
          </div>

          <div className="flex small-btn justify-end my-6 gap-x-4">
            <CustomizedButtons text="Cancel" click={handleCancel} />
            <CustomizedButtons text="Save" click={handleClick} loading={loading} />
          </div>
        </form>
      )}
    </div>
  );
}
