
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import BasicTable from "./components";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadeDocumentPopUp from "../../../Components/UploadeDocumentPopUp";
import CustomizedButtons from "../../../Components/CustomButton";
import { UpdateUserAttachment, listAllDocuments } from "../../../services/documentsService";
import { useDispatch, useSelector } from "react-redux";
import NoDataMessage from "../../../Components/NoDataMessage";
import { TableLoadingSkeleton } from "../../../Components/Loaders/TableLoader";
import { addAlertInfo } from "../../../store/alertSlice";
export default function Documents() {
    const dispatch = useDispatch()
    const token = useSelector((state: any) => state.auth.token);
    const userInfo = useSelector((state: any) => state.auth.user);

    const [cvsList, setCvsList] = useState([])
    const [coverLetterList, setCoverLetterList] = useState([])
    const [otherAttachmentList, setOtherAttachmentList] = useState([])

    const [loadingCvs, setLoadingCvs] = useState(true)
    const [loadingCoverLetter, setLoadingCoverLetter] = useState(true)
    const [loadingOtherAttachment, setLoadingOtherAttachment] = useState(true)
    const [attachmentType, setAttachmentType] = useState({})

    const getCvsList = async () => {
        const response = await listAllDocuments(userInfo._id, 'cv')
        if (response) {
            setCvsList(response.data.data)
            setLoadingCvs(false)
        }

    }
    const getCoverLetterList = async () => {
        const response = await listAllDocuments(userInfo._id, 'coverLetter')
        if (response) {
            setCoverLetterList(response.data.data)
            setLoadingCoverLetter(false)
        }

    }
    const getOtherAttachmentList = async () => {
        const response = await listAllDocuments(userInfo._id, 'other')
        if (response) {
            setOtherAttachmentList(response.data.data)
            setLoadingOtherAttachment(false)
        }

    }
    const [open, setOpen] = useState(false);
    const handleClick = (type: string) => {
        const DocumentsCount = cvsList.length + coverLetterList.length + otherAttachmentList.length
        if (DocumentsCount < 9) {
            setAttachmentType({ label: 'CV', id: 'cv' })
            if (type === 'cv') setOpen(true);
        }
        else {
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'You can only upload up to 9 documents at a time.',
                    type: "error"
                }
            ))
        }

    };
    const handleUpdateUserAttachment = async (id: string, visibility: any) => {
        const formData = new FormData()
        const data = { 'visibility': visibility }
        const response = await UpdateUserAttachment(id, data)
        if (response.status === 200) {
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'Done'
                }
            ))
        }
        else {
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: "error"
                }
            ))
        }
    }
    const listAllData = () => {
        getCvsList()
        getCoverLetterList()
        getOtherAttachmentList()
    }
    useEffect(() => {
        if (token) {
            listAllData()
        }
    }, [token]);

    return (
        <Container>
            {open && <UploadeDocumentPopUp show={open} setOpen={setOpen}
                listAllData={listAllData}
                attachmentType={attachmentType} />}
            <div className='m-b-50'>
                <div className="header-underline">
                    <h2 className="title">
                        Manage Documents
                    </h2>
                    <p className="main-description">

                        Here you can upload documents that you can use when applying for jobs.
                    </p>
                    <p className="main-description">
                        Please note that you can only upload a maximum of 9 documents at a time. If you need to upload additional documents, you'll need to delete one of the existing documents before adding a new one.
                    </p>
                 
                </div>
                <div className="flex justify-end">
                    <CustomizedButtons text="Upload Document" icon={<FileUploadIcon />} click={() => handleClick('cv')} />

                </div>
                <div className="mt-4 m-b-50">
                    <div className="flex justify-between mb-4 items-baseline">
                        <h5 className="secondary-title">CVs/Resumes
                        </h5>

                    </div>

                    {loadingCvs ?
                        <TableLoadingSkeleton /> :
                        cvsList.length > 0 ?
                            <BasicTable data={cvsList} listAllData={listAllData} handleUpdateUserAttachment={handleUpdateUserAttachment} /> :
                            <NoDataMessage />
                    }

                </div>
                <div className="mt-4 m-b-50">
                    <div className="flex justify-between mb-4  items-baseline">
                        <h5 className="secondary-title">Cover Letters</h5>
                        {/* <CustomizedButtons text="Upload Document" icon={<FileUploadIcon />} click={() => handleClick('cover')} /> */}
                    </div>

                    {loadingCoverLetter ?
                        <TableLoadingSkeleton />
                        : coverLetterList.length > 0 ?
                            <BasicTable data={coverLetterList} listAllData={listAllData} handleUpdateUserAttachment={handleUpdateUserAttachment} />
                            :
                            <NoDataMessage />
                    }
                </div>
                <div className="mt-4 m-b-50">
                    <div className=" items-baseline">
                        <h5 className="secondary-title"> Other Relevant Documents</h5>
                        <p className="description mb-4">
                            This can include documents like your portfolio
                        </p>
                    </div>

                    {loadingOtherAttachment ?
                        <TableLoadingSkeleton /> :
                        otherAttachmentList.length > 0 ?
                            <BasicTable data={otherAttachmentList} listAllData={listAllData} handleUpdateUserAttachment={handleUpdateUserAttachment} /> :
                            <NoDataMessage />
                    }

                </div>
                {/* <div className="flex justify-end my-4">
                    <CustomizedButtons text="Upload Document" icon={<FileUploadIcon />} click={() => handleClick('other')} />

                </div> */}

            </div>
        </Container>
    );
}
