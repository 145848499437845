import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const listAllInstitutions = () => {
  return axios
    .get(`${BASEURL}user?model=Institution`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};
export const SingleInstitutions = (id:string) => {
  return axios
    .get(`${BASEURL}institution/${id}`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const ListAlltitutions = () => {
  return axios
    .get(`${BASEURL}user?model=Institution`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

