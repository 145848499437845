import axios from "../axios";
import { BASEURL } from "../environments/environment";

export const listAllReviews = (id:any,page:number,limit:number) => {
  return axios
    .get(`${BASEURL}review?user=${id}&page=${page}&pageSize=${limit}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const listAllCompanyReviews = (id:any,page:number,limit:number) => {
  return axios
    .get(`${BASEURL}review?company=${id}&page=${page}&pageSize=${limit}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const addReview = (data: any) => {
  return axios
    .post(`${BASEURL}review`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
