import styles from "./header.module.scss";
import LogoImage from "../../../../assets/images/temp/1.png";
import { NumberBox } from "../../../../Components/AnalysicBox/numberBox";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from "react";
import { getNumberOfJobViews } from "../../../../services/analyticsService";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
interface HeaderProps {
  nested?: boolean
  loading: boolean
  selectedJob: any
}

const Header = (props: HeaderProps) => {
  const { loading, nested, selectedJob } = props
  const { id } = useParams()
  const isCompany = window.location.href.includes('company')
  const user = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [data, setData] = useState<any>({});

  const numberOfJobViews = async () => {
    const response = await getNumberOfJobViews(user._id, id)
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfJobViews': response?.data?.data?.views }))
    }
  }
  // useEffect(() => {
  //   if (isCompany && token) {
  //     numberOfJobViews()
  //   }
  // }, [isCompany, token])

  return (
    <>
      {loading ?
        <HeaderLoading nested={nested} /> :
        <div className={[styles["header"], 'pb-4'].join(' ')}>
          <h3 className="title mb-4">{selectedJob.name}</h3>
          <div className={styles['job-name']}>
          </div>

          <div className="flex w-full flex-col mb-6 lg:flex-row  ">
            <div className="md:w-1/2">
              <p className="text-sm text-gray-600">{selectedJob?.jobType?.nameEn} - {selectedJob?.workType?.nameEn}</p>
              {selectedJob?.country && <p className="text-sm  text-gray-600"> {selectedJob?.country?.nameEn}, {selectedJob?.city?.nameEn} </p>}
              {selectedJob?.industry && <p className="text-sm mb-4 text-gray-600"> <span className="font-semibold">Industry:</span> {selectedJob?.industry?.nameEn},  {selectedJob?.subIndustry?.nameEn} </p>}
            
              {/* {selectedJob?.industry&&  <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1 ">Industry</h5>
              <p className="text-base text-sm"> {selectedJob?.industry?.nameEn}</p>
            </div>}
            {selectedJob?.subIndustry&&    <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1 ">Sub industry</h5>
              <p className="text-base text-sm">{selectedJob?.subIndustry?.nameEn}</p>
            </div>} */}
              <div className="flex items-center">
                <img src={selectedJob?.company?.subModel?.logo} className={styles['logo']} alt="logo" />
                <div className="flex justify-between">
                  <p className="text-base font-semibold">{selectedJob.company?.subModel?.name}</p>
                </div>
              </div>
            </div>
            {(user?.model!="Company") ?
              <div className={`${nested ? 'gap-x-8' : "w-full"} 
        flex justify-between md:flex-row lg:lex-row flex-col md:w-1/2`}>
                <div className="pt-4 sm:mb-6">
                  <p className="text-base font-semibold mb-1">Company Address:</p>
                  {selectedJob?.company?.subModel?.addresses?.map((address: any) => {
                    return (
                      <p className="text-sm">{address}</p>
                    )
                  })}


                </div>
              </div>
              :
              <NumberBox value={selectedJob?.views || 0} text="Views" icon={<VisibilityIcon />} color="green" />

            }
          </div>

        </div>
      }
    </>

  );
};
const HeaderLoading = (props: any) => {
  return (

    <div className={[styles["header"], 'pb-4 animate-pulse'].join(' ')}>
      <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
      <div className={styles['job-name']}>
      </div>

      <div className="flex w-full flex-col mb-6 lg:flex-row  ">
        <div className="md:w-1/2">
          <div className="h-2 bg-gray-200 rounded col-span-1 w-1/2 mb-2"></div>
          <div className="h-2 bg-gray-200 rounded col-span-1 w-3/4 mb-4"></div>
          <div className="flex items-center">
            <div className="rounded-full bg-gray-200 h-10 w-10"></div>
            <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 ml-2 mb-2"></div>
          </div>
        </div>
        <div className={`${props.nested ? 'gap-x-8' : "w-full"} 
        flex justify-between md:flex-row lg:lex-row flex-col md:w-1/2`}>
          <div className="pt-4 sm:mb-6 w-[200px]">
            <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
            <div className="h-2 bg-gray-200 rounded col-span-1 w-1/2 mb-2"></div>
            <div className="h-2 bg-gray-200 rounded col-span-1 w-1/2 mb-2"></div>
            <p className="text-sm"> <div className="h-2 bg-gray-200 rounded col-span-1 w-1/2 mb-2"></div></p>
          </div>
        </div>

      </div>

    </div>
  );
};

export default Header;
