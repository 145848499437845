import axios from "../axios";
import { BASEURL } from "../environments/environment";

// const token = localStorage.getItem("userToken");

export const signIn = (loginData: { email: string; password: string }) => {
  return axios
    .post(`${BASEURL}user/login`, loginData)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const signUp = (payload: any) => {
  return axios
    .post(`${BASEURL}user`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const resetPassword = (passwordData: any) => {
  return axios
    .patch(
      `${BASEURL}user/change-password`,
      {
        oldPassword: passwordData?.oldPassword,
        newPassword: passwordData?.newPassword,
      },
     
    )
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const UserResetPassword = (id:any,passwordData: any) => {
  return axios
    .post(
      `${BASEURL}user/reset-password/${id}`,
      passwordData
    )
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};


export const UpdateUser = (id:string,payload: any) => {
  return axios
  .patch(`${BASEURL}user/${id}`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const UpdateUserProfile = (id:string,payload: any) => {
  return axios
    .patch(`${BASEURL}user/${id}`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const UpdateMentorProfile = (id:string,payload: any) => {
  return axios
    .patch(`${BASEURL}user/${id}`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

