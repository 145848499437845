import * as React from "react";
import Box from "@mui/material/Box";
import TabTwo from "./TabTwo";

export default function StepsTabs() {
  return (
    <Box sx={{ width: "100%" }}>
      <h3 className="title header-underline ">Mentorship Sessions</h3>
      <TabTwo/>
    </Box>
  );
}
