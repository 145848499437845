
import React, { useEffect, useState } from "react";
import CustomizedButtons from "../../../../../Components/CustomButton";
import InputField from "../../../../../Components/Input";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from "../../../../../services/authService";
import { Grid, TextField } from "@mui/material";
import SelectInput from "../../../../../Components/SelectInput";
import { DatePicker } from "@mui/x-date-pickers";
import { listAllCountries, listAllCities } from "../../../../../services/locationService";
import { addAlertInfo } from "../../../../../store/alertSlice";
interface ProjectFormProps {
  isShown: boolean;
  onCancel: any
  selectedProject: any,
  getUserData: any
}
export default function ProjectForm(props: ProjectFormProps) {
  const feilds = ["name", "owner", "description", "role", "startingDate", "endingDate"]
  const dispatch = useDispatch()
  const { isShown, onCancel, getUserData, selectedProject } = props;
  const user = useSelector((state: any) => state.auth.user);

  const token = useSelector((state: any) => state.auth.token);
  const [school, setSchool] = useState<any>({});
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);

  const getAllCountries = async () => {
    const response = await listAllCountries()
    if (response) {
      const countries = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setCountries(countries)
      setLoadingCountries(false)
    }
  }
  const getAllCities = async (countryId: string) => {
    setLoadingCities(true)
    const response = await listAllCities(countryId)
    if (response) {
      const citiesOfCountry = response.data.data
      const cities = citiesOfCountry.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setCities(cities)
      setLoadingCities(false)
    }
  }

  const handleSelectChange = (e: any) => {
    setSchool((_data: any) => ({ ..._data, [e.name]: e.value.id }));
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSchool((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (user) {
      if (selectedProject !== null) {
        if (user.subModel.projects[selectedProject]?.country) { getAllCities(user.subModel.projects[selectedProject]?.country?._id) }
        setSchool(
          {
            'name': user.subModel.projects[selectedProject].name,
            'url': user.subModel.projects[selectedProject].url,
            'owner': user.subModel.projects[selectedProject].owner,
            'description': user.subModel.projects[selectedProject].description,
            'role': user.subModel.projects[selectedProject].role,
            'startingDate': user.subModel.projects[selectedProject].startingDate,
            'endingDate': user.subModel.projects[selectedProject].endingDate,
            'city': user.subModel.projects[selectedProject]?.city ? { 'label': user.subModel.projects[selectedProject].city.nameEn, 'id': user.subModel.projects[selectedProject].city._id } : null,
            'country': user.subModel.projects[selectedProject]?.country ? { 'label': user.subModel.projects[selectedProject].country.nameEn, 'id': user.subModel.projects[selectedProject].country._id } : null,
          }
        )
        setLoadingData(false)

      }
      else {
        setSchool((_data: any) => ({ ..._data, 'startingDate': moment(new Date()).format('yyyy-MM-DD') }));
        setSchool((_data: any) => ({ ..._data, 'endingDate': moment(new Date()).format('yyyy-MM-DD') }));
        setLoadingData(false)
      }
    }

  }, [user]);

  const handleClick = () => {
    setErrors(false)

    for (let i = 0; i < feilds.length; i++) {
      if (!school[feilds[i]]) {
        setErrors(true)
      }
      else {
        setErrors(false)
        if (i === feilds.length - 1) {
          UpdateUserInfo()
        }
      }
    }

  }

  const handleCancel = () => {
    onCancel()
  }

  const UpdateUserInfo = async () => {
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))

    const Updateddata: any = {}
    const data = [...user.subModel['projects']]
    if (selectedProject !== null) {
      data.splice(selectedProject, 1)
    }
    Updateddata['projects'] = data.map((item: any) => (
      {

        'name': item.name,
        'url': item.url,
        'owner': item.owner,
        'description': item.description,
        'role': item.role,
        'startingDate': moment(item?.startingDate).format('yyyy-MM-DD'),
        'endingDate': moment(item?.endingDate).format('yyyy-MM-DD'),
        'city': item?.city?._id,
        'country': item?.country?._id,

      }

    ))
    const updatedSchool = {
      'name': school.name,
      'url': school.url,
      'owner': school.owner,
      'description': school.description,
      'role': school.role,
      'startingDate': school.startingDate,
      'endingDate': school.endingDate,
      'city': school?.city?.id ? school?.city?.id : school?.city,
      'country': school?.country?.id ? school?.country?.id : school?.country,
    }
    Updateddata['projects'].push(updatedSchool)

    const formData = new FormData()
    for (const property in Updateddata) {
      if (typeof Updateddata[property] === 'string' || Updateddata[property] === 'boolean') {
        formData.append(property, Updateddata[property]);
      }
      else {
        formData.append(property, JSON.stringify(Updateddata[property]));
      }
    }
    setLoading(true)
    const response = await UpdateUserProfile(user._id, formData)
    if (response.status === 200) {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
      handleCancel()
      getUserData()
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  const handleSelectContry = (e: any) => {
    handleSelectChange(e)
    if (e.value) {
      getAllCities(e.value.id)
    }
    else {
      setCities([])
    }
  }
  useEffect(() => {
    if (token) {
      getAllCountries()

    }
  }, [token]);
  return (
    <div>
      {isShown && (
        !loadingData ?
          <form>
            <p className=" required my-1">Fields marked with an * are required</p>
            <div className="form-item">
              <InputField
                label="Project/Product Name"
                required={true}
                placeholder='Project/Product Name'
                Change={handleChange}
                error={errors && !school.name}
                value={school.name}
                name="name"
              />
            </div>
            <div className="form-item">
              <InputField
                label="Role"
                required={true}
                placeholder='Role'
                Change={handleChange}
                error={errors && !school.role}
                value={school.role}
                name="role"
              />
            </div>
            <div className="form-item">
              <InputField
                label="URL (if any)"
                required={false}
                placeholder='URL'
                Change={handleChange}
                value={school.url}
                name="url"
              />
            </div>
            <div className="form-item">
              <InputField
                label="Owner"
                labelHint="Specify the project owner, such as a company, school, or other relevant entity."
                required={true}
                placeholder='owner'
                Change={handleChange}
                error={errors && !school.owner}
                value={school.owner}
                name="owner"
              />
            </div>
            <div className="mt-6 form-item">
              <Grid container columnSpacing='5'>
                <Grid item xs={6} >
                  <DatePicker

                    label="From"
                    value={school.startingDate}
                    onChange={(newValue) => {
                      setSchool((_data: any) => ({ ..._data, 'startingDate': moment(newValue).format('yyyy-MM-DD') }));
                    }}
                    renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                  />

                </Grid>
                <Grid item xs={6}>
                  <DatePicker

                    label="To"
                    value={school.endingDate}
                    onChange={(newValue) => {
                      setSchool((_data: any) => ({ ..._data, 'endingDate': moment(newValue).format('yyyy-MM-DD') }));
                    }}
                    renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="form-item">
              <Grid container columnSpacing='5'>
                <Grid item xs={12}>
                  <div className='form-item '>
                    <SelectInput
                      multiple={false}
                      name="country"
                      required={false}
                      label='Select your country'
                      options={countries}
                      Change={handleSelectContry}
                      loading={loadingCountries}
                      // error={errors && !school.country}
                      defaultValue={school.country}
                    />

                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='form-item '>
                    <SelectInput
                      name="city"
                      required={false}
                      label='Select your city (region/state/province options will appear with the city)'
                      labelHint="Select your country first"
                      options={cities}
                      Change={handleSelectChange}
                      loading={loadingCities}
                      // error={errors && !school.city}
                      disabled={!school.country}
                      defaultValue={school.city}
                    />

                  </div>
                </Grid>
              </Grid>
            </div>
            {/* <div className="form-item">
              <InputField
                label="Description"
                required={true}
                placeholder='Description'
                multiline={true}
                rows={3}
                Change={handleChange}
                error={errors && !school.description}
                value={school.description}
                name="description"
              />
            </div> */}
            <div className="form-item">
            <label className="relative flex justify-between">
              <span >* Description </span>
              <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{school.description?.length || 0}/280</p>

            </label>
            <textarea
              name="description"
              maxLength={280}
              className="w-full border hover:border p-2 rounded-lg"
              rows={4}
              required={false}
              placeholder="Enter text here...."
              defaultValue={school.description}
              onChange={(e: any) => handleChange(e)}

            />
            
          </div>
            <div className="flex small-btn justify-end my-6 gap-x-4">
              <CustomizedButtons text="Cancel" click={handleCancel} />
              <CustomizedButtons text="Save" click={handleClick} loading={loading} />
            </div>
          </form>
          : ''

      )}
    </div>
  );
}
