import React, { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface AlertMessageProps{
    type:any;
    title:string;
    description?:string;
    timing?:number;
}


export default function AlertMessage(props:AlertMessageProps) {
  const {type,title,description,timing} = props;
  const [alert, setAlert] = useState(true);
      
  useEffect(() => {
    setTimeout(() => {
      setAlert(false);
    }, timing);
  }, []); 

  return (
    <div>
        {alert &&(
        <Alert severity={type} variant="filled">
        <AlertTitle className='text-white'>{title}</AlertTitle>
         <strong  className='text-white'>{description}</strong>
        </Alert>
        )}
    </div>
  )
}
