

import React, { useEffect, useState } from "react";
import { followedCompany } from "../../../services/followingService";
import { useDispatch, useSelector } from "react-redux";
import { addDataInfo } from "../../../store/followSlice";
import { Checkbox, CircularProgress } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import styles from "./style.module.scss";
import { ListSavedJobs, SaveJob } from "../../../services/jobsService";
import { addDataSaved } from "../../../store/savedJobsSlice";
import { addAlertInfo } from "../../../store/alertSlice";
interface CompanyProps {
  id: string
  index?: number
  getGobs?:any
}
export default function SavingJob(props: CompanyProps) {
  const dispatch = useDispatch()
  const { id,getGobs } = props
  const user = useSelector((state: any) => state.auth.user);
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)
  const savedJobs = useSelector((state: any) => state.savedJobs.savedJobs);
  // console.log(savedJobs, 'savedJobs')
  const getSavedJobs = async () => {
    const response = await ListSavedJobs(user._id)
    if (response.status === 200) {
      getGobs&&getGobs()
      dispatch(addDataSaved(
        { savedJobs: response.data.data.savedJobs.map((item:any)=>item?._id) }
      ))
    }
  }
  const handleSaveJob = async () => {
    setLoading(true)
    const response = await SaveJob(id, user._id)
    if (response.status === 200) {
     const dae= await getSavedJobs()
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Done'
        }
      ))
      setLoading(false)
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }


  const isSaved = () => {
    if (savedJobs?.includes(id)) {
      setSaved(true)
    }
  }
  useEffect(() => {
    if (savedJobs) {
      isSaved()
    }
  }, [savedJobs]);

  return (

    <div className={styles.icon}>
      {loading ?
        <CircularProgress size="20px" /> :
        <Checkbox
          icon={<BookmarkBorderIcon />}
          checkedIcon={<BookmarkIcon />}
          onChange={handleSaveJob}
          checked={savedJobs?.includes(id)}
        />
      }
    </div>

  )
}