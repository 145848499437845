
import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const getAppState= () => {
    return axios 
      .get(`${BASEURL}appState`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
