import React from "react";
import Moment from "react-moment";

interface WorkElementProps {
  item: any
}
export default function WorkElement(props: WorkElementProps) {
  const { item } = props
  return (
    <div className="my-1 text-[#222222]">
      <p className="font-semibold text-[16px] my-2">{item?.company}</p>
   
      <p className="font-light text-[15px] my-1 ">{item?.role}</p>
      <p className="font-light text-[15px] my-1">
        <Moment format="MMMM YYYY">{item?.startingDate}</Moment> -  <Moment format="MMMM YYYY">{item?.endingDate}</Moment> ({item?.duration})
      </p>
     
      {item?.country?.nameEn && <p className="font-light text-[15px] my-1">{item?.city?.nameEn}, {item?.country?.nameEn}</p>}
      {item?.description&& <p className="font-light text-[15px] my-1">{item?.description}</p>}
    </div>
  );
}
