import { Box, Container, Pagination, Tab, Tabs, Typography } from "@mui/material";
import styles from "./jobs.module.scss";
import React, { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { listAlljobs, listjobsByMajor } from "../../../../services/jobsService";
import { listAllMajor } from "../../../../services/majorsServise";
import Post, { CardLoadingSkeleton } from "../../../../Components/CardsList/Post";
import NoDataMessageImage from "../../../../Components/NoDataMessageWithImage";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const MoreInterestsJobs = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentPage(0);
    setValue(newValue);
    setSelectedMajor(majors[newValue]._id);
  };

  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [jobs, setJobs] = useState<Array<any>>([]);
  const [majors, setMajors] = useState<Array<any>>([]);
  const [selectedMajor, setSelectedMajor] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMajors, setLoadingMajors] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totlaPages, setTotlaPages] = useState(0);

  const getGobs = async () => {
    setLoading(true);
    const response = await listAlljobs({ currentPage: currentPage });
    if (response) {
      setJobs(response.data.data.queryResult);
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9));
      setLoading(false);
    }
  };
  //     const getAllMajors = async () => {
  //         if (user?.subModel?.jobMajors) {
  //             // setLoadingMajors(true)
  //             setMajors(user?.subModel?.jobMajors)
  //             setSelectedMajor(user?.subModel?.jobMajors[0]._id)
  //             getGobs(user?.subModel?.jobMajors[0]._id)
  //             setLoadingMajors(false)
  //         }
  //         else{
  //             setLoadingMajors(false)
  //         }

  // }
  // useEffect(() => {
  //     if (token) {
  //         getAllMajors()
  //     }
  // }, [token]);

  useEffect(() => {
    getGobs();
  }, [currentPage]);

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };
  return (
    <Container>
      <div className="m-b-50 main-b-border pb-6 header-underline">
        <div className="title">Explore the latest opportunities</div>
        <p className="main-description">Latest job posts by employers/organizations</p>
      </div>
      <div className={styles.jobs_list}>
        {!loading ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              {jobs &&
                jobs.length > 0 &&
                jobs.map((item: any, i: number) => {
                  return <Post data={item} index={i} />;
                })}
            </div>
          </>
        ) : (
          <div className="gap-4 space-y-4 columns-3">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any) => {
              return <CardLoadingSkeleton />;
            })}
          </div>
        )}
        {totlaPages > 1 && (
          <div className="flex justify-end my-6">
            <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
          </div>
        )}

        {!loading && jobs.length === 0 && <NoDataMessageImage noBg={true} />}
      </div>
    </Container>
  );
};
export default MoreInterestsJobs;
