import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import CustomizedButtons from '../../../../Components/CustomButton';
import { useState } from 'react';
import { deleteDocument } from '../../../../services/documentsService';
import { addAlertInfo } from '../../../../store/alertSlice';
import { useDispatch } from 'react-redux';
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: '16px',
  boxShadow: 0,
  p: 4,
};

export default function DeleteModal(props: any) {
const dispatch=useDispatch()
  const [loadingBtn, setloadingBtn] = useState(false)
  const handleDelete = async () => {
    setloadingBtn(true)
    const response =await deleteDocument(props.selectedItem)
    if(response.status===204){
      setloadingBtn(false)
      props.handleClose()
      dispatch(addAlertInfo(
        {
          show: true,
          message: `Your attachment deleted successfully`
        }
      ))
      props.listAllData()
    }
    else {
      setloadingBtn(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-end " onClick={props.handleClose}>
            <CloseIcon className="cursor-pointer" />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this document

          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action cannot be reverted
          </Typography>
          <div className="custom-action gap-4">
          <CustomizedButtons text="Cancel" click={props.handleClose} />
          <CustomizedButtons text="Delete Document" click={handleDelete} loading={loadingBtn} />
        </div>
        </Box>
      

      </Modal>
    </div>
  );
}

