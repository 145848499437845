import React, { useEffect, useState } from "react";
import AlertMessage from "../../../../../Components/Alert";
import CustomizedButtons from "../../../../../Components/CustomButton";
import InputField from "../../../../../Components/Input";
import SelectInput from "../../../../../Components/SelectInput";
import { liskeyWays } from "../../../../../services/keyWaysServise";
import { useSelector } from "react-redux";
import { Language } from "../../../../../models/language";

interface TabTwoProps {
  setValue: any;
  setDataValues: any;
  onSubmitReview: any
  reviewData: any
  loadingbtn: boolean

}

export default function TabThree(props: TabTwoProps) {
  const { loadingbtn, setDataValues, onSubmitReview, reviewData } = props;
  const [alert, setAlert] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataValues((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };

  const handleSelectChange = (e: any) => {

    const isArray = Array.isArray(e.value);
    if (e.value) {
      if (isArray) {
        setDataValues((_data: any) => ({ ..._data, [e.name]: e.value.map((item: any) => item.id) }));
      }
      else {
        setDataValues((_data: any) => ({ ..._data, [e.name]: e.value.id }));
      }
    }
  }

  const handleSubmit = () => {
    onSubmitReview()
  }
  const token = useSelector((state: any) => state.auth.token);
  const [optionsList, setOptionsList] = useState([]);
  const [defaultOptionsList, setDefaultOptionsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getOptionsList = async () => {
    const response = await liskeyWays()
    if (response) {
      const langs = response.data.data.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
      setOptionsList(langs)

      if (reviewData?.keyWays?.length > 0) {
        let temp: any = []
        for (let i = 0; i < reviewData?.keyWays.length; i++) {
          temp.push(langs[langs.map(((item: any) => item?.id)).indexOf(reviewData?.keyWays[i])])
          setDefaultOptionsList(temp)
        }

      }
      setLoading(false)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false)
    }, 800);
    if (token) { getOptionsList() }
  }, [token]);


  return (
    <>
      {
        !loadingPage &&
        <div>
          <h3 className="secondary-title">Job Review <br />
            <p className="required">Fields marked with an * are required.</p>
          </h3>

          <form>
            <div className="form-item">
              <SelectInput
                required={true}
                name='keyWays'
                Change={handleSelectChange}
                label="Key takeaways"
                disablePortal={true}
                options={optionsList}
                multiple={true}
                loading={loading}
                defaultValue={defaultOptionsList}
              />


            </div>
            <div className="form-item">
            <label className="relative flex justify-between">
                <span >* What you liked and learned</span>
                <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{reviewData?.whatYouLikedAndLearned?.length || 0}/800</p>

              </label>
              <textarea
                maxLength={800}
                className="w-full border hover:border p-2 rounded-lg"
                rows={4}
                required={true}
                placeholder="Enter text here...."
                name="advicesForOthers"
                defaultValue={reviewData?.whatYouLikedAndLearned}
                onChange={(e: any) => setDataValues((_data: any) => ({ ..._data, 'whatYouLikedAndLearned': e.target.value }))}
              />
              {/* <InputField
                label="What you liked and learned"
                name="whatYouLikedAndLearned"
                Change={handleChange}
                required={true}
                multiline={true}
                rows={3}
                placeholder="Add text"
                defaultValue={reviewData?.whatYouLikedAndLearned}
              /> */}

            </div>

            <div className="form-item">
              {/* <InputField
            label="Advice for others"
            required={true}
            name="advicesForOthers"
            Change={handleChange}
            multiline={true}
            rows={3}
            placeholder="Add text"

          /> */}
              <label className="relative flex justify-between">
                <span >* Advice for others</span>
                <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{reviewData?.advicesForOthers?.length || 0}/800</p>

              </label>
              <textarea
                maxLength={800}
                className="w-full border hover:border p-2 rounded-lg"
                rows={4}
                required={true}
                placeholder="Enter text here...."
                name="advicesForOthers"
                defaultValue={reviewData?.advicesForOthers}
                onChange={(e: any) => setDataValues((_data: any) => ({ ..._data, 'advicesForOthers': e.target.value }))}
              />
            </div>
            <div className="actions-end mt-4">
              <CustomizedButtons loading={loadingbtn} text="Submit" click={handleSubmit} disabled={!(reviewData.advicesForOthers && reviewData.whatYouLikedAndLearned && reviewData.keyWays && reviewData?.keyWays?.length !== 0)} />
            </div>
          </form>


        </div>
      }
    </>
  );
}
