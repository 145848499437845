
import React from "react";
import CreateJobForm from "../../components/CreateJobPost";

export default function TabThree(props:any) {
  return (
    <div>
    <CreateJobForm setTap={props.setTap} id={props.id}/>
    </div>
  );
}
