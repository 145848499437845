import { Avatar } from "@mui/material";
import React, { useState } from "react";
import CustomizedButtons from "../../../../Components/CustomButton";
import styles from "./header.module.scss"
import { useParams } from "react-router-dom";
import FollowingButton from "../../../Components/FollowingButton";
import { useSelector } from "react-redux";
export default function Header(user: any) {
  const parms = useParams()
  const { id } = parms
  const userInfo = useSelector((state: any) => state.auth.user);
  const [isFollow, setIsFollow] = useState(false)
  const handleIsFollowAction = () => {
    setIsFollow(!isFollow)
  }
  return (
    <div className={`header-underline pb-6 `}>

      <div
        style={{ backgroundImage: `url(${user?.user?.subModel?.coverPhoto})` }}
        className=" bg-no-repeat bg-cover h-80 bg-center bg-[#8b8b8b47]"
      >
        
      </div>
      <div className={`flex items-center px-4 ${styles["header"]}`}>
        <Avatar
        className="object-contain" 
          sx={{ width: 120, height: 120, bgcolor: user?.user?.subModel?.logo?'#ffffff':"#F26400" }}
          src={user?.user?.subModel?.logo}

        />
        <h4 className="title mx-4">
          {user?.user?.subModel?.name}
        </h4>
        {id && userInfo?.model==='Student' &&
          <FollowingButton followText='Follow company' unFollowText='Unfollow' id={user?.user?._id} />
        // <div className={`actions-center rounded-action custom-action ${isFollow && 'custom-action-fill'} w-full`}>
        //   <CustomizedButtons 
        //     text={!isFollow ? 'Follow company' : "Following"}
        //     click={handleIsFollowAction} />
        // </div>
        }
      </div>

    </div>


  );
}
