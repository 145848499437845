import { Checkbox, FormControlLabel, Radio, RadioGroup, Select, TextField, FormControl, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import CompanyCard from "../../../../../Components/CompanyCard";
import CustomizedButtons from "../../../../../Components/CustomButton";
import MaterialUIPickers from "../../../../../Components/DatePicker";
import InputField from "../../../../../Components/Input";
import SelectInput from "../../../../../Components/SelectInput";
import { listCompanies } from "../../../../../services/students/companies";
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { listDidYouGetOffer, listResourcesFindingJob } from "../../../../../services/didYouGetOfferService";
import { listAllCurrency } from "../../../../../services/currencyService";
interface TabTwoProps {
  setValue: any
  setDataValues: any
  reviewData: any
}

export default function TabTwo(props: TabTwoProps) {
  const { setValue, setDataValues, reviewData } = props;
  const [company, setCompany] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [companiesList, setCompaniesList] = React.useState([]);
  const [addToProfile, setAddToProfile] = React.useState<boolean>(false)
  const token = useSelector((state: any) => state.auth.token);
  const [companies, setCompanies] = useState([])
  const [DidYouGetOffer, setlistDidYouGetOffer] = useState<any>([])
  const [resourcesFindingJob, setListResourcesFindingJob] = useState<any>([])

  const [loading, setLoading] = useState(true)
  const [loadingPage, setLoadingPage] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)
  const [startDate, setStartDate] = React.useState<any>(new Date());

  const [endDate, setEndDate] = React.useState<any>(null);
  const [offerVisabilty, setOfferVisabilty] = React.useState(true);
  const [otherText, setOtherText] = useState(null)
  const [currencies, setCurrencies] = useState([]);
  const [loadingCurrencies, setLoadingCurrencies] = useState(false);

  const handleChangeResources = (e: any) => {
    if (e.target.name === 'otherRes') {
      setOtherText(e.target.value)

    }
    if (e.target.value === 'Other') {
      setDataValues((_data: any) => ({ ..._data, 'resourcesFindingJob': otherText }));
      // setDataValues(otherText, 'resourcesFindingJob');
    }
    else {
      // setDataValues(e);
      setDataValues((_data: any) => ({ ..._data, 'resourcesFindingJob': e.target.value }));
    }
  };

  const getCompaniesList = async () => {
    const response = await listCompanies({ currentPage: null })

    if (response) {
      const companies = response.data.data.queryResult.map((item: any) => ({ 'label': item.subModel.name, 'id': item._id }))
      setCompanies(companies)
      setLoading(false)
    }
  }
  const getDidYouGetOffer = async () => {
    const response = await listDidYouGetOffer()
    if (response) {
      const list = response.data.data
      setlistDidYouGetOffer(list)
    }
  }
  const getResourcesFindingJob = async () => {
    const response = await listResourcesFindingJob()
    if (response) {
      const list = response.data.data
      setListResourcesFindingJob(list)
    }
  }
  // 
  // 
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, type = '') => {

    setDataValues((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));

  };

  const handleSelectChange = (e: any) => {
    const isArray = Array.isArray(e.value);
    if (e.value) {
      if (isArray) {
        setDataValues((_data: any) => ({ ..._data, [e.name]: e.value.map((item: any) => item.id) }));
      }
      else {
        setDataValues((_data: any) => ({ ..._data, [e.name]: e.value.id }));
      }
    }
  }

  const handleNext = () => {
    setValue(2)
    window.scrollTo(0, 0)
  }
  const handleBack = () => {
    setValue(0)
  }

  const handleAddToProfileChange = (e: any) => {
    setAddToProfile(!addToProfile)
    setDataValues(e)
  }
  const getAllCurrency = async () => {
    const response = await listAllCurrency()
    if (response) {
      const Currency = response.data.data.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setCurrencies(Currency)
      setLoadingCurrencies(false)
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setLoadingPage(false)
    }, 800);
    if (token) {
      getAllCurrency()
      getCompaniesList()
      getDidYouGetOffer()
      getResourcesFindingJob()
      setDataValues((_data: any) => ({ ..._data, 'startDate': moment(new Date()).format('yyyy-MM-DD') }));
      setDataValues((_data: any) => ({ ..._data, 'addToProfile': true }));
      setDataValues((_data: any) => ({ ..._data, 'didYouGetOfferVisability': true }));


    }
  }, [token]);

  return (
    <>
      {!loadingPage &&
        <div>
          <h3 className="secondary-title">Tell us about your role <br />
            <p className="required">Fields marked with an * are required.</p>
          </h3>

          <form>
            <div className="form-item">
              <InputField
                label="Job Title"
                required={true}
                placeholder="Job title*"
                name="jobTitle"
                Change={handleChange}
                defaultValue={reviewData?.jobTitle}
              />
            </div>
            <div className="form-item">
              <SelectInput
                label="Company/Employer"
                name="company"
                Change={handleSelectChange}
                required={true}
                options={companies}
                loading={loading}
                defaultValue={companies[companies.map(((item: any) => item?.id)).indexOf(reviewData?.company)]}
              />
            </div>

            <div className="form-item">
              {/* <MaterialUIPickers isFlex={true} isLabeled={true} Change={handleChange} /> */}
              <Grid container columnSpacing='5'>
                <Grid item xs={6} >
                  <label>* Start Date</label>
                  <DatePicker

                    value={reviewData?.startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                      setDataValues((_data: any) => ({ ..._data, 'startDate': moment(newValue).format('yyyy-MM-DD') }));
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                  />

                </Grid>
                <Grid item xs={6}>
                  <label>End Date</label>
                  <DatePicker
                    minDate={startDate}
                    value={reviewData?.endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                      setDataValues((_data: any) => ({ ..._data, 'endDate': moment(newValue).format('yyyy-MM-DD') }));
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                  />
                </Grid>
              </Grid>
            </div>
            {/* <FormControlLabel
        name="addToProfile"
        value={addToProfile}
        onClick={handleAddToProfileChange}
        control={<Checkbox defaultChecked size="small" />}
        label={<span className="text-[15px]">Add this experience to my profile</span>}
      /> */}
            <p className="main-description">The salary information is for edqan team's knowledge only and it will not be visible to others. It is optional.</p>
            <div className="my-4 flex gap-8 w-full flex-col md:flex-row ">
              <div className="form-item w-1/3">
                <InputField
                  type="number"
                  label="Salary (optional)"
                  required={false}
                  placeholder='Salary'
                  name="salary"
                  Change={handleChange}
                  defaultValue={reviewData?.salary}
                />
              </div>
              <div className="form-item w-1/3">
                <SelectInput
                  label='Currency'
                  required={(reviewData?.salary) ? true : false}
                  multiple={false}
                  name='currency'
                  options={currencies}
                  loading={loadingCurrencies}
                  disabled={reviewData?.salary == null || reviewData?.salary == 0}
                  Change={handleSelectChange}
                  defaultValue={currencies[currencies.map(((item: any) => item?.id)).indexOf(reviewData?.currency)]}

                // error={(reviewData?.salary != null && reviewData?.salary != 0) && !reviewData?.currency && submitClicked}
                // value={reviewData?.currency}
                // submitClicked={submitClicked}
                // defaultValue={reviewData?.currency}
                />
              </div>
              <div className="form-item w-1/3">
                <InputField
                  label="How often did you get paid? (optional)"
                  required={false}
                  placeholder=''
                  name="howOftenDidYouGetPaid"
                  Change={handleChange}
                  defaultValue={reviewData?.howOftenDidYouGetPaid}
                />
              </div>

            </div>
          </form>

          <p className="main-description colored">Please note that the answer to this question, along with the following one is for our internal use and will not be displayed on your public profile.</p>
          <div className="my-4">
            <p className="secondary-title">* Did you get an offer to continue with them?</p>
            <FormControl className="w-1/2">
              <RadioGroup
                name="didYouGetOffer"
                onChange={handleChange}
              >
                {DidYouGetOffer.map((item: any) => {
                  return (
                    <FormControlLabel value={item.nameEn} control={<Radio size='small' checked={reviewData?.didYouGetOffer == item.nameEn} />}
                      label={<span className="text-[15px]">{item.nameEn}</span>} />
                  )
                })}
              </RadioGroup>
              {/* <FormControlLabel
          name="didYouGetOfferVisability"
          control={<Checkbox defaultChecked size="small" />}
          label={<span className="text-[15px]">Show this on my profile</span>}
          onChange={(e: any) => handleChange(e)} /> */}

            </FormControl>

          </div>
          <div className="my-6">
            <p className="secondary-title">* Resources you used to find the job</p>
            <FormControl className="w-1/2">
              <RadioGroup
                name="resourcesFindingJob"
                onChange={handleChangeResources}
              >
                {resourcesFindingJob.map((item: any) => {
                  return (
                    <FormControlLabel name="resourcesFindingJob"
                      value={item.nameEn} control={<Radio size='small' checked={reviewData?.resourcesFindingJob == item.nameEn} />}
                      label={<span className="text-[15px]">{item.nameEn}</span>} />
                  )
                })}


                <div className="flex justify-between gap-4">
                  <FormControlLabel
                    onChange={handleChangeResources}
                    value="Other"
                    name="resourcesFindingJob"
                    control={<Radio size='small' />} label={<span className="text-[15px]">Other</span>} />
                  <InputField
                    label={''}
                    required={false}
                    name="otherRes"
                    Change={handleChangeResources}
                    placeholder="Enter text .."
                  />
                </div>

              </RadioGroup>
            </FormControl>

          </div>
          <div className=" w-full custom-action">
            <CustomizedButtons text="Back" click={handleBack} />
            <div className=" ml-4">
              <CustomizedButtons text="Next" click={handleNext}
                disabled={!(reviewData?.jobTitle && reviewData.resourcesFindingJob && reviewData.company && reviewData.company && reviewData.didYouGetOffer)} />
            </div>


          </div>
        </div>}
    </>
  );
}
