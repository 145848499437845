

export default function NoDataMessage(props:any) {
    return (

        <div className="block text-center justify-center py-8 bg-light-orange mt-12 rounded-md p-2">
         <p>
         {props.text?props.text: 'No data available in this section'}
            </p>
         {props.description&&  <p className="block colored-gray">{props.description}</p>}
        </div>
    );
}