import React from 'react'
import { TextField } from '@mui/material'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
interface InputFieldProps {
  label?: string;
  labelHint?: string;
  required: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  placeholder?: string;
  textArea?: boolean;
  Change?: any;
  value?: any;
  KeyDown?: any;
  name?: string
  defaultValue?: string;
  formik?: any
  submitClicked?: boolean;
  error?: boolean
  max?: number
}
export default function InputPhone(props: InputFieldProps) {
  const { label,
    required,
    name,
    labelHint,
    Change,
    value,
    defaultValue,
    formik,
    submitClicked,
    error,
    max

  } = props;

  const handleOnChange = (value: any) => {
    Change(value)
  }
  return (
    <>
      {(labelHint || label) && <div className='input-label'>
        {label && <label >
          {required &&
            <span className='star'>*</span>
          }
          {label}</label>
        }
        {labelHint &&
          <p >{labelHint}</p>
        }

      </div>
      }
      <PhoneInput
        inputProps={{
          name: name,
          required: true,
          autoFocus: true
        }}
        country="ae"
        value={value}
        onChange={(e:any)=>Change(e)}
      />
      {name && formik && submitClicked && formik.errors[name] && (
        <span className='text-[#ff000e] text-sm'>{formik.errors[name]}</span>
      )}
      {error &&
        <span className='text-[#ff000e] text-sm'>This field id required</span>}
    </>
  )
}
