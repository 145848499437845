import React from "react";
import './popup.scss';
import { Dialog, DialogTitle, DialogContent, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router";
interface PopupProp {
    open: boolean
    setOpenPop: React.Dispatch<React.SetStateAction<boolean>>;
}
// const closePopup = (DialogModal (false));
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogModalInstite = ({ open, setOpenPop }: PopupProp) => {
    const handleClose = () => {
        setOpenPop(false)
    }
    const navigate = useNavigate();
    return (

        <Dialog className="dialog" open={open} >
            <DialogTitle >
                <div className="title">
                    <div><b>Empower your students’ career journeys as an educational institution:</b></div>
                    <div><label onClick={handleClose} id= "closePopup"> &times;</label></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    1. Connect your students with exciting opportunities.<br />
                    2. Contact employers and setup internship opportunities for your students.<br />
                    3. Interact with students in your school using our platform, and much more.<br />
                    4. Gain valuable insights.<br />
                </Typography>
                <br/>
                <Typography><b>Quick actions:</b></Typography>
                <div className="buttons">
                    <Button id="button1" size="small" className="btn" onClick={() => navigate("/institution/school-information")}>
                        Update school’s public profile
                    </Button>
                    <Button id="button2" size="small" className="btn" onClick={() => navigate("/employers")}>
                        Contact employers
                    </Button>
                    <Button id="button3" size="small" className="btn" onClick={() => navigate("/institution/student-profiles")}>
                        Contact students
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default DialogModalInstite;
