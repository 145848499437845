import { Chip, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from './card.module.scss'

interface SkillsCardProps {
  label: string;
  itemsList: Array<any>
  type: string
}
export default function LanguageCard(props: SkillsCardProps) {
  const { label, itemsList, type } = props;

  const [list, setList] = useState<Array<any>>([...itemsList])



  return (
    <div className=" border-[1px] p-4 rounded my-6 ">
      <p className="font-semibold text-[18px] ">{label}</p>
      <div className="flex items-center mt-4">
       
         {list && list.length > 0 &&

<div className={[styles['selected-jobs'], 'mt-2'].join(' ')}>

  <Stack direction="row" gap={1} className="flex-wrap">
    {
      list.map((item: any, index: number) => {
        return (
          <Chip label={item.nameEn ? item.nameEn : item}
            />
        )
      })}
  </Stack>
</div>
}
      </div>

    </div>
  );
}
