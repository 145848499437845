import { Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import ReviewsElement from './components/Reviews'
import { useNavigate } from 'react-router';
import CustomizedButtons from '../../../Components/CustomButton';
import { listAllReviews } from '../../../services/reviewsService';
import { useSelector } from 'react-redux';
import { Pagination } from '@mui/material';


export default function Reviews() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [list, setList] = useState([])

  const [userRating, setUserRating] = useState({})
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  const navigate = useNavigate();

  const handleCLick = () => {
    navigate('/professionals/write-review')
  }

  const geAllReviews = async () => {
    setLoading(true)
    const response = await listAllReviews(user._id,currentPage, 2)

    if (response) {
      
      setList(response.data.data.queryResult)
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 2))
      //  setUserRating(response?.data?.data?.queryResult[0]?.user?.subModel)
      setLoading(false)

    }
  }

  useEffect(() => {
    if (token) {
      geAllReviews()
    }
  }, [token, currentPage]);
  return (
    <Container>
      <div>
        <div className='header-underline'>
          {list.length === 0 ?
            <h3 className='title'>Tell us about your experience</h3> :
            <h2 className='title'>These are the reviews you posted</h2>}
        </div>
        <div className='actions-end w-full mb-4'>

<CustomizedButtons text='Write your review' click={handleCLick} />
</div>
        <div className='grid grid-cols-4  gap-10'>


          <div className="no-comments col-span-4 lg:col-span-3 md:col-span-2 flex-col">
            {!loading ? 
            <div className='w-full'>
              {list.length === 0 ?
                (<div className='flex items-center flex-col '>
                  <p className='main-description colored-gray '>You have not yet written any company/employer reviews.</p>
                  <div className='main-border p-6 my-10'>
                    <p className='main-description'>You can write a review for companies you have worked for, volunteered or interned with so other students can learn from your experience. </p>
                  </div>
                </div>
                )
                :
                (<ReviewsElement list={list}  />)
              }
             

            </div>
              :
              <><ReviewLoading />
                <ReviewLoading />
              </>

            }
             {totlaPages > 1 &&
                <div className="flex justify-end my-6">

                  <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                </div>
              }
            <div className='actions-end w-full mt-4'>

              <CustomizedButtons text='Write your review' click={handleCLick} />
            </div>
          </div>



          <div className='col-span-4  lg:col-span-1 md:col-span-2'>
            <div className='main-border p-4'>
            <p className='list-title mb-7'>For Privacy and reviews</p>
            <p className='main-description my-4 text-justify' style={{wordSpacing:'-2px'}}>
            When you submit a review,
            it will be visible on both your profile and the employer's profile page.
            This means that the employer, students/professionals, and educational institution (if you are associated with one)
            will be able to see what you wrote. </p>
            <p className='main-description text-justify' style={{wordSpacing:'-2px'}}>
            By proceeding with a review, you are agreeing to make your feedback public and visible to others. We value your feedback and aim to maintain a transparent environment for everyone involved. If you have any concerns about privacy or the content of your review, feel free to reach out to our support team for assistance. Thank you for contributing to our community with your reviews and helping others make informed decisions. 
            </p>
            </div>
           
          </div>


        </div>

      </div>
    </Container>
  )
}
const ReviewLoading = (props: any) => {
  const { nested } = props

  return (
    <div className="border  border-[#efeeee] rounded-md p-4 w-full mx-auto h-full">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-gray-200 rounded"></div>
          <div className="rounded-full bg-gray-200 h-10 w-10"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">

              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};