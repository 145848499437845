import "./App.css";
import './styles/global.scss';
import AppRouter from "./router";
import AlertMessage from "./Components/Alert";
import { useEffect, useState } from "react";
import { addAlertInfo } from "./store/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAppState } from "./services/main";
import Logo from "./assets/images/Logo.png"
import MaintenancePng from "./assets/images/Maintenance-pana.png"
import StandardErrorBoundary from "./ErroeHandler";

function App() {
  const dispatch = useDispatch();
  const alert = useSelector((state: any) => state.alert);
  const [appState, setAppState] = useState<any>(null)
  useEffect(() => {
    if (alert?.show) {
      setTimeout(() => {
        dispatch(addAlertInfo(
          {
            show: false,
            message: null
          }
        ))
      }, 20000);

    }
  }, [alert]);
  const getTheAppState = async () => {
    const response = await getAppState()
    if (response) {
      setAppState(response?.data?.data?.inMaintenance)
      if (response?.data?.data?.inMaintenance) {
        localStorage.clear();
      }

    }

  }
  useEffect(() => {
    getTheAppState()

  }, []);
  return (
    <>
      {appState === false ?
        <>
          {alert?.show && <div style={{zIndex:'99999999'}} className='fixed z-50 right-5 bottom-10  border-green main-border'>
            <AlertMessage type={alert?.type === 'error' ? 'error' : 'success'} title={alert?.message} timing={20000} />
          </div>}
          <div className="App realtive">


            <StandardErrorBoundary>
              <AppRouter />
            </StandardErrorBoundary>
          </div>
        </>
        : appState === true ?
          <>

            <div className="flex justify-center items-center h-[100vh]">
              <div className="container p-[30px] flex flex-col justify-center items-center  ">
                <img src={Logo} />
                <div style={{ backgroundImage: `url(${MaintenancePng})` }} className=" bg-no-repeat bg-center bg-contain bg-cover w-[500px] h-[400px]">

                </div>
                <p className={`font-extrabold colored text-[30px]`}>Hang on! We are under maintenance </p>
                <span className={`animate-bounce block mt-[20px] font-bold text-[24px]`}>Coming soon...</span>
              </div>
            </div>
          </>
          :
          <div className='lds-spinner'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      }
    </>

  );
}

export default App;
