
import axios from '../axios';
import {BASEURL} from '../environments/environment'

// const token = localStorage.getItem('userToken')

export const listAllMentorSessions = (usetId:string,stutes:string,page:number) => {
  return axios
    .get(`${BASEURL}student/mentor_sessions/${usetId}?status=${stutes}&page=${page}&pageSize=10`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const listAllMentorSessionsStudent = () => {
  return axios
    .get(`${BASEURL}student/mentor_sessions`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};


export const ListAllMentorShipTypes = () => {
  return axios
    .get(`${BASEURL}mentorShipType`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};


export const listAllMentorSessionsForMentor = (userId:string,stutes:string) => {
  return axios
    .get(`${BASEURL}meeting/mentor?userId=${userId}&status=${stutes}`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const listAllMentorSessionsForMentorCalender = () => {
  return axios
    .get(`${BASEURL}meeting/mentor`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const MentorShipSessionRequest = (id:string,data:any) => {
  return axios
    .post(`${BASEURL}student/request_mentor?userId=${id}`,data)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};