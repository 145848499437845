import axios from "../../axios";
import { BASEURL } from "../../environments/environment";

export const listCompanyJobPosts = (id:string,page:any,pageSize=9, jobTypeFilterarm='',industryFilterParm='',almaFilterParm='',companyFilterParm='',searchTermFilterParm='') => {
    return axios
      .get(`${BASEURL}job?company=${id}&page=${page}&pageSize=${pageSize}${jobTypeFilterarm}${industryFilterParm}${almaFilterParm}${searchTermFilterParm}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  export const getSingleJobPosts = (id:string,jopId:string) => {
    return axios
      .get(`${BASEURL}job/${jopId}?company=${id}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  export const CreateNewJob = (id:string,data:any) => {
    return axios
      .post(`${BASEURL}job?companyId=${id}`,data)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  
  export const EditJob = (id:string,data:any,jopId:string) => {
    return axios
      .patch(`${BASEURL}job/${jopId}?companyId=${id}`,data)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  