import { useEffect, useState } from "react";
import styles from "./companyinfo.module.scss";
import { Chip, Grid, Stack } from "@mui/material";
import FollowingButton from "../../../Components/FollowingButton";
import MediaPlacement from "../../../../assets/svg/media.svg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
interface CompanyProps {
  loading: boolean
  selectedJob: any
}
export default function CompanyInfo(props: CompanyProps) {
  const userInfo = useSelector((state: any) => state.auth.user);
  const { selectedJob, loading } = props
  const [backgroundHight, setBackgroundHight] = useState(0);
  const [viewAll, setViewAll] = useState(false);
  
  const navigate = useNavigate()
  const navigateTo = (url: string) => {
    navigateTo(url)
  }
  useEffect(() => {
    if (!loading && selectedJob?.company?.subModel?.background) {

      let backgroundID = document.getElementById('background') as HTMLElement
      backgroundID.innerHTML = selectedJob?.company?.subModel?.background || ''
      var height: any = document.getElementById('background')?.scrollHeight;
      setBackgroundHight(height)

    }
  }, [loading])
  return (
    <>
      {loading ?
        <CompanyInfoLoading />
        :
        <div className={[styles["container"]].join(" ")}>
          <div className={[styles["Company-about"]].join(" ")}>
            <div className="flex justify-between mb-6 flex-col lg:flex-row md:flex-row">
              <h3 className="colored secondary-title">About {selectedJob?.company?.name} Company</h3>
              {userInfo?.model === 'Student' && <FollowingButton followText='Follow the company' unFollowText='Unfollow' id={selectedJob?.company?._id} />}
            </div>
            <div className={`grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-4`}>
              <div className={styles["info"]}>
                <h5 className="font-semibold mb-1">Number of Employees</h5>
                <span >{selectedJob?.company?.subModel?.companySize.toLocaleString()} </span>
              </div>

              <div className={styles["info"]}>
                <h5 className="font-semibold mb-1 ">Headquarters</h5>
                <span >
                  {selectedJob?.company?.subModel?.headQuarters?.city?.nameEn},  {selectedJob?.company?.subModel?.headQuarters?.country?.nameEn}
                </span>
              </div>

            </div>
            <div className=" mx-3 my-4 ">
              <h5 className="font-semibold mb-1">Industries</h5>
            </div>
            <div className={`grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-4`}>

              {selectedJob?.company?.subModel?.industries?.map((industry: any, index: number) => {
                return (
                  <div className={styles["info"]}>
                    <h5 className="font-semibold mb-2">{industry?.industry?.nameEn}</h5>
                    <div className="flex align-center">
                      <Stack direction="row" gap={1} className="flex-wrap">
                        {
                          industry?.subIndustries?.map((subIndustry: any, index: number) => {
                            return (
                              <Chip label={subIndustry?.nameEn}

                              />
                            )
                          })}
                      </Stack>

                    </div>
                  </div>
                )
              })}

            </div>
          </div>
          {selectedJob?.company?.subModel?.background &&
           <div className={[styles["Company-Background"], 'my-4'].join(" ")}>
            <div className={styles["header"]}>
              <h3 className="secondary-title">Company Background</h3>

            </div>
            <p id="background" className={`main-description ${viewAll?'':'h-[100px] overflow-hidden'} `}></p>
            {backgroundHight > 100 &&!viewAll&& <div className="main-link mb-6" onClick={()=>setViewAll(true)}>View full description of company</div>}
            {backgroundHight > 100 &&viewAll&& <div className="main-link mb-6" onClick={()=>setViewAll(false)}>View less description of company</div>}

           
            {/* <ShowMoreText
              // id="background"
              lines={4}
              more="View full description of company"
              less="Show less"
              anchorClass={styles["show"]}
              className="w-full main-description"
              expanded={false}
              truncatedEndingComponent={"... "}
            >
              {selectedJob?.company?.subModel?.background}
            </ShowMoreText> */}
          </div>
          }
          <div>

            
            <div className={styles['company-media']}>
              {selectedJob?.company?.subModel?.companyMedia?.length > 0 &&
            <>
              <h3 className="secondary-title">Company Media</h3>
                <Grid container spacing={3}  >
                  {selectedJob?.company?.subModel?.companyMedia.map((item: any, index: number) => {
                    return (
                      <Grid item md={4} sm={12} className="w-full" key={index}>
                        <div className="relative border rounded-xl bg-white" >
                          {item.includes('.jpeg') || item.includes('.gif') || item.includes('.png') || item.includes('.jpg') ?
                            <img src={item} className="h-[250px]  w-full" /> :
                            <video src={item} className="h-[250px]  w-full" controls />
                          }

                        </div>
                      </Grid>


                    )
                  })}
                </Grid>
                </>
              }

              {/* {selectedJob?.company?.subModel?.companyMedia?.length === 0 &&
                <div className="pb-4 border rounded-xl mt-4">
                  <img src={MediaPlacement} className="w-full h-[350px]" alt="media" />
                  <p className="text-center text-xl text-[#F26400]">No media added by {selectedJob?.company?.subModel?.name} yet</p>
                </div>
              } */}
              <a className="main-link small-text mt-2" onClick={() => navigate(`/company-information/${selectedJob?.company?._id}`)}>See full company profile</a>
            </div>
          </div>

        </div>
      }

    </>
  );
}
const CompanyInfoLoading = () => {
  return (
    <div className={[styles["container"], 'animate-pulse'].join(' ')}>

      <div className={[styles["job-description"]].join(" ")}>
        <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 mb-8"></div>
        <div className="flex justify-between space-x-2 mb-8">
          <div className="h-24 bg-gray-200 rounded col-span-1 w-1/3 mb-2"></div>
          <div className="h-24 bg-gray-200 rounded col-span-1 w-1/3 mb-2"></div>
          <div className="h-24 bg-gray-200 rounded col-span-1 w-1/3 mb-2"></div>
        </div>
      </div>


      <div className={[styles["job-description"]].join(" ")}>
        <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 mb-2"></div>
        <p className="main-description">
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
        </p>
      </div>

      <div>
        <div className={[styles["job-description"]].join(" ")}>
          <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 mb-8"></div>
          <div className="flex justify-between space-x-2 mb-8">
            <div className="h-48 bg-gray-200 rounded col-span-1 w-1/3 mb-2"></div>
            <div className="h-48 bg-gray-200 rounded col-span-1 w-1/3 mb-2"></div>
            <div className="h-48 bg-gray-200 rounded col-span-1 w-1/3 mb-2"></div>
          </div>
        </div>
      </div>
    </div>
  )
}