import { Avatar } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import styles from "./staff-profile.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getUserInfo } from "../../../services/userService";
import PopUpSendMesage from "../../../Components/PopUp";
import NoDataMessage from "../../../Components/NoDataMessage";

export default function StaffProfile() {
  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [user, setUserInfo] = useState<any>()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleSendMesage = () => {
    setOpen(true)
  }

  function handleClick(href: string) {
    navigate(href);
  }
  const parms = useParams()
  const { id } = parms

  const getUserData = async () => {
    const response = await getUserInfo(id)
    if (response.status === 200) {
      setLoading(false)
      setUserInfo(response.data.data)
    }
    else {

    }
  }

  useEffect(() => {
    if (token && !id) {
      setUserInfo(userInfo)
      setLoading(false)
    }
  }, [token])

  useEffect(() => {
    if (id) {
      getUserData()

    }
  }, [])
  useEffect(() => {
    if (user?.subModel?.background) {
      let descriptionID = document.getElementById('description') as HTMLElement
      descriptionID.innerHTML = user?.subModel?.background

    }
  }, [user])
  return (
    <Container>
      {!loading ?
        <div className="page">
          {open && <PopUpSendMesage
            user={user}
            show={open}
            setOpen={setOpen}
            resiverName={`${user?.subModel?.user?.firstName} 
              ${user?.subModel?.user?.middleName || ""} 
              ${user?.subModel?.user?.lastName}`}
            resiverEmail={user?.subModel?.user?.careerAdvisingEmail}
            id={user?._id} />}

          <div className="m-b-50 main-b-border pb-6">

            <h2 className="title">Profile</h2>
          </div>
          <div className="flex justify-center gap-20 mt-8  responsive-profile-col">
            <div className="block text-center info">
              <Avatar
                sx={{ width: 120, height: 120, marginBottom: "20px", marginRight: "auto", marginLeft: "auto" }}
                src={user?.subModel?.logo}
              />
              <h3 className="secondary-title "> {user?.subModel?.name}</h3>
              <span className="colored m-b-10 block">
                Institution
              </span>
            </div>
            <div className={styles['profile-info']}>
              <form>
                <div className="form-item">
                  <label>Career Advising</label>

                </div>

                <div className="form-item">
                  <label>About</label>
                  <p className="main-description" id="description">

                  </p>
                </div>
                <div className="form-item">
                  <label>Email</label>
                  <a href={user?.subModel?.careerAdvisingEmail} className="colored main-description block">
                    {user?.subModel?.user?.email}
                  </a>
                </div>

                <div className="form-item ">
                  <label>Phone #</label>
                  <p className="main-description">{user?.subModel?.careerAdvisingPhone}</p>
                </div>

                {/* <div className="form-item">
                  <label>Linkedin Profile</label>
                  <p className="main-description">https://www.linkedin.com</p>
                </div> */}
                {id &&
                  //  <p className="flex items-baseline">to schedule an appointment with {user?.subModel?.user.firstName} 
                  <a className="main-link  block small-text" onClick={() => handleSendMesage()}>Send a message</a>

                }


              </form>
            </div>

          </div>

        </div>
        :
        <NoDataMessage />
      }
    </Container>
  );
}
