import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import LeftContent from "./LefttContent";
import RightContent from "./RightContent";


export default function StudentProfile() {
  const token = localStorage.getItem('userToken')
  const user = useSelector((state: any) => state.auth.user);

  return (
    <Container>
      {user?.subModel &&
        <div className='page'>
          <Grid container spacing={3} >
            <Grid item xs={12} lg={4}>
              <LeftContent />
            </Grid>
            <Grid item xs={12}  lg={8}>
              <RightContent />
            </Grid>
            <Grid item lg={12}>
              <strong>Profile Page Tip</strong>
              <p className="main-description my-4">Please note: When you registered, you provided your Major/Area of Study or Area of Interest, which will be displayed in your 'Settings' page. If you wish to make changes, you can do so directly from there. These details are essential for initially matching students and professionals with suitable opportunities and mentors. However, for a broader range of opportunities, we recommend completing your 'Career Interests" Page and 'Profile' Page. Thank you for enhancing your profile!</p>
            </Grid>
          </Grid>
        </div>}
    </Container>

  );
}
