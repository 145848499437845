import React, { useState } from "react";

import { Avatar, CircularProgress } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ImageCrop from "../../../../../Components/ImageUpload";
import { UpdateUserProfile } from "../../../../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { addAlertInfo } from "../../../../../store/alertSlice";

export const UploadeProfileImage = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [image, setSelectimage] = useState('')
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: any) => state.auth.user);
  const formData = new FormData()
  const handleSubmit = (selectimage: any, imagefile: any) => {
    setSelectimage(selectimage)
    setLoading(true)
    formData.append("profileImage", imagefile);
    updateUserProfile()
  }
  const updateUserProfile = async () => {
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))
    const response = await UpdateUserProfile(user._id, formData)
    if (response.status === 200) {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  return (
    <div className="w-full flex items-center justify-center">
      {show &&

        <ImageCrop show={show} setOpen={setShow} type='image' handleSubmit={handleSubmit} />
      }
      <div className="relative">
        <Avatar src={image?image:user?.profileImage} sx={{ width: '150px', height: "150px" }}  >
        </Avatar>
        {loading ?
        <div className="absolute right-[8px] bottom-[2px]">
          <CircularProgress size="20px" />
          </div> :
          <button className="add-image" onClick={() => setShow(true)} >

            <AddIcon />

          </button>
        }
      </div>


    </div>
  );
}

export default UploadeProfileImage;


