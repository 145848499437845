import { Checkbox, Grid } from "@mui/material";
import styles from "./form.module.scss";
import CustomButton from "../../../../Components/CustomButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InputField from "../../../../Components/Input";
import SelectInput from "../../../../Components/SelectInput";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import LogoImage from "../../../../assets/images/Logo.png";
import VideoUpload from "../../../../Components/VideoUpload";

import { useDispatch } from "react-redux";
import { Language } from "../../../../models/language";
import { signUp } from "../../../../services/authService";
import { listAllCountries, listAllCities } from "../../../../services/locationService";
import { addAlertInfo } from "../../../../store/alertSlice";
import UploadeProfileImage from "./uploadeProfileImage";
import InputPhone from "../../../../Components/Input/phoneInput";
import { ListAllIndustry } from "../../../../services/industry";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { getAllSocialMedia } from "../../../../services/scoialMediaService";
import IndustriesUI from "../../../Employer/AddInformation/Tabs/TabOne/Industries";
import { validatePassword, validatePasswordTrue } from "../../../../helpers/validation";
import { isUrlValid, validateEmail } from "../../../helpers";
import { CAPTCHACLINTSECRET } from "../../../../environments/environment";

const SignUp = () => {
  const [showVideo, setShowVideo] = useState(false);
  const captchaRef = useRef(null);
  const formData = new FormData();
  const [file, setImagefile] = useState<File>();

  const [userData, setUserData] = useState<any>();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [employerCities, setEmployerCities] = useState([]);

  const [industry, setAllIndustry] = useState([]);
  const [loadingIndustry, setloadingIndustry] = useState(true);

  const [allSubIndustry, setAllSubIndustry] = useState<any>();

  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any>();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [socialMediaKeys, setSocialMediaKeys] = useState<any>();

  const [socialMediaLinksList, setSocialMediaLinksList] = useState<any>([]);
  const [addressList, setAddressList] = useState([]);
  const [address, setAddress] = useState("");

  const [headquarterCountry, SetheadquarterCountry] = useState<any>();
  const [headquarterCity, SetheadquarterCity] = useState<any>();

  const [guideLines, setGuideLines] = useState(false);
  const [mediaError, setMediaError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (headquarterCountry && headquarterCity) {
      setUserData((_data: any) => ({
        ..._data,
        headQuarters: {
          country: headquarterCountry?.id,
          city: headquarterCity?.id,
        },
      }));
    }
  }, [headquarterCountry, headquarterCity]);

  function handleClick() {
    navigate("/");
  }

  const validateList = ["name", "webSite", "email", "phoneNumber", "password", "industries", "companySize", "country", "city"];
  const validateListNames = ["Name", "Website URL", "Email", "Phone", "Password", "Industries", "Company size", "Country", "City"];

  const validateForm = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return false;
    }
    if (!validateEmail(userData?.email)) return false;
    if (!guideLines) return false;
    if (!headquarterCountry) return false;
    if (!headquarterCity) return false;
    if (!addressList || addressList.length == 0) return false;

    if (!file) return false;
    if (!validatePasswordTrue(userData?.password)) return false;
    if (userData?.password != userData?.newPasswordConfirmation) return false;
    return true;
  };
  const getErrorMessage = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) {
        return validateListNames[i] + " is required";
      }
    }
    if (!validateEmail(userData?.email)) return "Invalid email format";
    if (!isUrlValid(userData?.webSite)) return "Invalid webSite UEL format";

    if (!guideLines) return "Agreement to the Terms of Use and the Privacy Policy is required";
    if (!headquarterCountry) return "Headquarter country is required";
    if (!headquarterCity) return "Headquarter city is required";
    if (!addressList || addressList.length == 0) return "Address is required";

    if (!file) return "Logo is required";
    if (!validatePasswordTrue(userData?.password)) {
      return validatePassword(userData?.password);
    }
    if (userData?.password != userData?.newPasswordConfirmation) {
      return "Your passwords does not match";
    }
  };
  function handleSignUp(e: any) {
    dispatch(
      addAlertInfo({
        show: false,
      })
    );
    e.preventDefault();
    setSubmitClicked(true);
    for (const property in userData) {
      if (typeof userData[property] === "string" || typeof userData[property] === "boolean") {
        formData.append(property, userData[property]);
      } else {
        formData.append(property, JSON.stringify(userData[property]));
      }
    }

    file && formData.append("logo", file);
    formData.append("model", "Company");
    addressList.length > 0 && formData.append("addresses", JSON.stringify(addressList));

    const media = Object.keys(socialMediaLinksList)?.map((item: any) => ({ socialMedia: item, url: socialMediaLinksList[item] }));
    Object.keys(socialMediaLinksList).length > 0 && formData.append("socialMediaLinks", JSON.stringify(media));

    if (validateForm()) {
      setLoadingSubmit(true);
      setTimeout(async () => {
        const res = await signUp(formData);
        if (res.status === 201) {
          navigate(`/mailVerification?userId=${res.data.data.user._id}`);
          setLoadingSubmit(false);
        } else {
          setUserData((_data: any) => ({ ..._data, captchaValue: null }));
          const captcha: any = document.getElementById("captcha") || "";
          captcha?.remove();
          dispatch(
            addAlertInfo({
              show: true,
              message: res.data.error,
              type: "error",
            })
          );
          setLoadingSubmit(false);
          const captchaView: any = document.getElementById("captcha-view") || "";
          captchaView.appendChild(captcha);
        }
      }, 1000);
    } else {
      dispatch(
        addAlertInfo({
          show: true,
          message: getErrorMessage(),
          type: "error",
        })
      );
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "guideLines") {
      setGuideLines(!guideLines);
    } else {
      setUserData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeCaptcha = (e: string) => {
    setUserData((_data: any) => ({ ..._data, captchaValue: e }));
  };

  const handleSelectChange = (e: any) => {
    if (e.name === "headquarterCountry") {
      SetheadquarterCountry(e.value);
      if (e.value || e.value == null) {
        SetheadquarterCity(null);
      }
      e.value && getAllCities(e.value.id);
    }
    if (e.name === "headquarterCity") {
      SetheadquarterCity(e.value);
    } else {
      const isArray = Array.isArray(e.value);

      if (isArray) {
        setUserData((_data: any) => ({ ..._data, [e.name]: e?.value ? e.value.map((item: any) => item.id) : null }));
      } else {
        setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.id || null }));
      }
    }
  };

  const getAllIndustry = async () => {
    setloadingIndustry(true);
    const response = await ListAllIndustry();
    if (response) {
      const data = response.data.data.map((item: any) => ({ label: item.nameEn, id: item._id }));
      for (let i = 0; i <= response.data.data.length; i++) {
        const key = response.data.data[i]?._id;
        setAllSubIndustry((_data: any) => ({
          ..._data,
          [key]: response.data.data[i]?.subIndustrys.map((item: any) => ({ label: item.nameEn, id: item._id })),
        }));
      }

      setAllIndustry(data);
      setloadingIndustry(false);
    }
  };

  const handleSelectContry = (e: any) => {
    handleSelectChange(e);
    if (e.value) {
      setSelectedCity(null);
      getAllsetEmployerCities(e.value.id);
    } else {
      setCities([]);
    }
  };

  const getAllCountries = async () => {
    const response = await listAllCountries();
    if (response) {
      const countries = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCountries(countries);
      setLoadingCountries(false);
    }
  };

  const getAllCities = async (countryId: string) => {
    setLoadingCities(true);
    const response = await listAllCities(countryId);
    if (response) {
      const citiesOfCountry = response.data.data;
      const cities = citiesOfCountry.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCities(cities);
      setLoadingCities(false);
    }
  };
  const getAllsetEmployerCities = async (countryId: string) => {
    setLoadingCities(true);
    const response = await listAllCities(countryId);
    if (response) {
      const citiesOfCountry = response.data.data;
      const cities = citiesOfCountry.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setEmployerCities(cities);
      setLoadingCities(false);
    }
  };

  const getSocialMediaList = async () => {
    const response = await getAllSocialMedia();
    setSocialMediaList(response.data.data);
    for (let i = 0; i < response.data.data.length; i++) {
      setSocialMediaKeys((_data: any) => ({ ..._data, [response.data.data[i]._id]: response.data.data[i].nameEn }));
    }
  };
  useEffect(() => {
    getAllCountries();
    getAllIndustry();
    // getAllSubIndustry()
    getSocialMediaList();
  }, []);
  const handleChangePhone = (phone: any) => {
    setUserData((_data: any) => ({ ..._data, phoneNumber: `+${phone}` }));
  };
  const checkUrl = (url: string) => {
    let givenURL;
    try {
      givenURL = new URL(url);
    } catch (error) {
      return false;
    }
    return true;
  };
  const handleKeyDown = (socialMediaType: any, e: any) => {
    if (e.key === "Enter") {
      setMediaError(false);
      e.preventDefault();
      if (checkUrl(e.target.value)) {
        const temp: any = socialMediaLinksList[socialMediaType?._id] || [];
        temp.push(e.target.value);
        setSocialMediaLinksList((_data: any) => ({ ..._data, [socialMediaType._id]: temp }));
        e.target.value = "";
      } else {
        setMediaError(true);
      }
    }
  };
  const handleAddressKeyDown = (e?: any) => {
    const temp: any = [...addressList];
    if (e) {
      if (e.key === "Enter" && e.target.value !== "") {
        e.preventDefault();
        temp.push(e.target.value);

        setAddressList(temp);
        e.target.value = "";
        setAddress("");
      }
    } else {
      if (address !== "") {
        temp.push(address);

        setAddressList(temp);
        setAddress("");
      }
    }
  };
  const removeLocation = (e: any, item: string) => {
    e.preventDefault();
    const temp: any = [...addressList];
    temp.splice(temp.indexOf(item), 1);
    setAddressList(temp);
  };
  const removeSocialMedia = (e: any, item: string, url: string) => {
    e.preventDefault();
    const temp: any = socialMediaLinksList[item] ? [...socialMediaLinksList[item]] : [];
    temp.splice(temp.indexOf(url), 1);

    setSocialMediaLinksList((_data: any) => ({ ..._data, [item]: temp }));
  };
  const handleAdderssChange = (e: any) => {
    setAddress(e.target.value);
  };

  return (
    <div className={styles["signUp-form"]}>
      {showVideo && <VideoUpload show={showVideo} setOpen={setShowVideo} />}
      <div className={styles["form"]}>
        <div className="md:hidden" onClick={handleClick}>
          <img src={LogoImage} />
        </div>

        <div className="header-underline">
          <h3 className="title">Company/organization sign up</h3>
        </div>
        <UploadeProfileImage setImagefile={setImagefile} />
        {!file && submitClicked && <span className="text-[#ff000e] text-sm w-full block text-center mt-4">Logo is required</span>}
        <form onSubmit={handleSignUp}>
          <span className={styles.requarid}>Fields marked with an * are required.</span>

          <div className="form-item">
            <InputField
              label="Name of company/organization"
              placeholder="Name of company/organization"
              name="name"
              required={true}
              Change={handleChange}
              submitClicked={submitClicked}
              error={!userData?.name && submitClicked}
            />
          </div>
          <div className="form-item ">
            <InputField
              label="Website URL"
              placeholder="Website URL"
              name="webSite"
              required={true}
              Change={handleChange}
              submitClicked={submitClicked}
              error={!userData?.webSite && submitClicked}
            />
          </div>
          <div className="form-item ">
            <label>Contact information</label>

            <InputField
              label="Email"
              placeholder="email"
              name="email"
              required={true}
              Change={handleChange}
              submitClicked={submitClicked}
              error={!userData?.email && submitClicked}
            />
          </div>

          <div className="form-item ">
            <InputPhone label="Phone" required={true} name="phoneNumber" Change={handleChangePhone} submitClicked={submitClicked} />
            {!userData?.phoneNumber && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
          </div>
          <div className="form-item">
            <InputField
              labelHint="Choose a strong password - at least 12 characters long, has a combination of upper and lowercase letters, numbers, and special symbols."
              label="Password"
              name="password"
              required={true}
              type="password"
              placeholder="Password"
              Change={handleChange}
              submitClicked={submitClicked}
              error={validatePassword(userData?.password) && submitClicked}
              errorMessage={validatePassword(userData?.password)}
            />
          </div>
          <div className="form-item">
            <InputField
              label="Confirm your password"
              required={true}
              name="newPasswordConfirmation"
              Change={handleChange}
              placeholder="Confirm your password"
              type="password"
              submitClicked={submitClicked}
              error={userData?.password != userData?.newPasswordConfirmation && submitClicked}
              errorMessage="Your passwords does not match"
            />
          </div>
          <div>
            <div className="form-item ">
              <IndustriesUI
                required={true}
                Userindustries={null}
                handleSelectChange={handleSelectChange}
                submitClicked={submitClicked}
                setUserData={setUserData}
              />
              {(!userData?.industries || userData?.industries.length == 0) && submitClicked && (
                <span className="text-red-500 text-sm">{"This field is required"}</span>
              )}
            </div>

            <div className="form-item ">
              <label>* Headquarters location</label>
              <Grid container columnSpacing="5">
                <Grid item xs={12}>
                  <div className="form-item ">
                    <SelectInput
                      name="headquarterCountry"
                      required={true}
                      placeholder="Select headquarters country"
                      options={countries}
                      Change={handleSelectChange}
                      loading={loadingCountries}
                      submitClicked={submitClicked}
                    />
                    {!headquarterCountry && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form-item ">
                    <SelectInput
                      name="headquarterCity"
                      required={true}
                      label="Select city (region/state/province options will appear with the city)"
                      placeholder="Select headquarters city"
                      labelHint="Select country first"
                      options={cities}
                      Change={handleSelectChange}
                      loading={loadingCities}
                      value={headquarterCity?.id ? headquarterCity : null}
                      submitClicked={submitClicked}
                      disabled={!headquarterCountry}
                    />

                    {!headquarterCity && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className="form-item  ">
              <InputField
                type="number"
                name="companySize"
                required={true}
                label="Number of employees"
                placeholder="ex. 500"
                Change={handleChange}
                error={!userData?.companySize && submitClicked}
                submitClicked={submitClicked}
              />
            </div>

            <div className="form-item">
              {/* <label>Employer's location</label> */}
              <Grid container columnSpacing="5">
                <Grid item xs={12}>
                  <div className="form-item ">
                    <SelectInput
                      name="country"
                      required={true}
                      placeholder="Select your country’s office"
                      options={countries}
                      Change={handleSelectContry}
                      loading={loadingCountries}
                      error={!userData?.country && submitClicked}
                      submitClicked={submitClicked}
                      label="Select your country’s office"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form-item ">
                    <SelectInput
                      name="city"
                      required={true}
                      placeholder="Select your city’s office"
                      labelHint="Select country first"
                      options={employerCities}
                      Change={handleSelectChange}
                      loading={loadingCities}
                      // value={selectedCity?.id ? selectedCity : null}
                      submitClicked={submitClicked}
                      disabled={!userData?.country}
                      label="Select your city’s office (region/state/province options will appear with the city)"
                      error={!userData?.city && submitClicked}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="form-item">
              <InputField
                name="address"
                required={true}
                label="Addresses"
                labelHint="Press Enter or Add Address button to add address"
                placeholder="You can add more than one address for your company/organization"
                Change={handleAdderssChange}
                KeyDown={(e: any) => handleAddressKeyDown(e)}
                value={address}
              />
              {(!addressList || addressList.length == 0) && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
              <div className="small-btn block actions-end">
                <CustomButton text={"Add Address"} click={(e: any) => handleAddressKeyDown()}></CustomButton>
              </div>
            </div>
            {addressList.length > 0 && (
              <div className="p-4 bg-light-orange main-border border-orangr mb-2">
                <div>
                  <p className="text-center mb-4">You can add more than one address for your company/organization</p>
                </div>

                <ul className="space-y-2">
                  {addressList.map((location: any) => {
                    return (
                      <li className="flex space-x-2 w-1/2 location-item">
                        <div className="flex">
                          <LocationOnIcon sx={{ color: "#f26400" }} />
                          <div>{location}</div>
                        </div>
                        <button type="button" onClick={(e) => removeLocation(e, location)}>
                          <CloseIcon sx={{ color: "#e2e2e2", cursor: "pointer" }} />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="form-item mt-4">
              <div className="input-label">
                <label>Social media sites</label>
                <p className="">Press Enter To add link</p>
              </div>

              <div className="md:flex  items-center gap-4">
                {socialMediaList.map((item: any) => {
                  return (
                    <Grid item xs={12} md={6}>
                      <div className="form-item ">
                        <InputField required={false} label={item.nameEn} placeholder="URL" KeyDown={(e: any) => handleKeyDown(item, e)} />
                      </div>
                    </Grid>
                  );
                })}
              </div>
              {mediaError && <span className="text-red-500 text-sm">{"Please enter a valid url"}</span>}
            </div>

            {Object.keys(socialMediaLinksList).length > 0 && (
              <div className="p-4 bg-light-orange main-border border-orangr mb-2">
                {Object.keys(socialMediaLinksList).map((item: any) => {
                  return (
                    <>
                      {socialMediaLinksList[item].length > 0 && (
                        <>
                          <h4 className="mt-4 mb-2">{socialMediaKeys[item]} </h4>
                          <ul className="space-y-2">
                            {socialMediaLinksList[item]?.map((url: any) => {
                              return (
                                <li className="flex space-x-2 w-1/2 location-item">
                                  <div className="flex">
                                    {/* <LocationOnIcon sx={{ color: "#f26400" }} /> */}
                                    <div>{url}</div>
                                  </div>
                                  <button type="button" onClick={(e) => removeSocialMedia(e, item, url)}>
                                    <CloseIcon sx={{ color: "#e2e2e2", cursor: "pointer" }} />
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            )}
          </div>
          <div id="captcha-view">
            <div className="form-item m-t-20 text-underline mt-4 md:mt-0" id="captcha">
              <ReCAPTCHA sitekey={CAPTCHACLINTSECRET} ref={captchaRef} onChange={(e: any) => handleChangeCaptcha(e)} />
            </div>
          </div>

          <div className="mb-4">
            <Checkbox
              sx={{ ".MuiCheckbox-root": { paddingLeft: 0 } }}
              name="guideLines"
              defaultChecked
              checked={guideLines}
              onChange={handleChange}
            />
            *I agree to the{" "}
            <a href="https://edqan.com/terms-of-use/" target="_blank" className="main-link">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="https://edqan.com/privacy-policy/" target="_blank" className="main-link">
              Privacy Policy
            </a>
            .
          </div>
          {!guideLines && submitClicked && (
            <span className="text-[#ff000e] text-md bg-[#ffdbdb] p-2 rounded my-4 block">
              Agreement to the Terms of Use and the Privacy Policy is required.
            </span>
          )}
          <p className={styles.notificationsHint}>Note: You will be able to update your profile once you are registered.</p>

          <div className={styles.actions}>
            <CustomButton disabled={!userData?.captchaValue} text="Continue" type="submit" loading={loadingSubmit} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
