import React, { ChangeEvent, useEffect, useState } from "react";
import { Container } from "@mui/system";
import TextEditor from "../../../Components/Editor";
import InputField from "../../../Components/Input";
import CustomButton from "../../../Components/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import UploadeLogo from "./Upload/logo";
import UploadeBanner from "./Upload/banner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addAlertInfo } from "../../../store/alertSlice";
import { UpdateInstitutionProfile } from "../../../services/institution/updateInstitution";
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// import InputPhone from "../../../Components/Input/phoneInput";
import { Grid } from "@mui/material";

import { Language } from "../../../models/language";
import { listAllCountries, listAllCities } from "../../../services/locationService";
import SelectInput from "../../../Components/SelectInput";
import InputPhone from "../../../Components/Input/phoneInput";
import { UpdateUser } from "../../../services/authService";
import { getUserNewInfo } from "../../../services/userService";
import PasswordPopUp from "../../Student/AccountSetting/components/passwordPopup";

function SchoolInformation() {
  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);

  const [userData, setUserData] = useState<any>({});
  const [newData, setUserNewData] = useState<any>();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any>();
  const [textLength, setTextLength] = useState<any>(0);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleChangePassword = () => {
    setOpen(true);
  };
  const validateList = ["schoolUrl", "country", "city", "careerAdvisingPhone", "careerAdvisingEmail", "background"];
  const validateListNames = ["URL of school", "Country", "City", "Career Advising Phone", "Career Advising Email", "Background"];

  const getErrorMessage = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return validateListNames[i] + " is required";
    }
    if (textLength - 1 > 2400)
      return "You have exceeded the maximum number of characters allowed for background field. Please remove the extra characters to continue";
    // if (!headquarterCountry) return 'Headquarter country id required'
    // if (!headquarterCity) return 'Headquarter city id required'
    if (userData?.careerAdvisingPhone == "+") return "Phone number id required";
    return true;
  };
  const formik = useFormik({
    initialValues: {},
    onSubmit: function (values) {},
    validationSchema: Yup.object({
      schoolUrl: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url"
        )
        .when("userData", () => {
          if (userData.schoolUrl === undefined || userData.schoolUrl === null || userData.schoolUrl === "") {
            return Yup.string().required("This field is required");
          }
          return Yup.string().notRequired();
        }),
      city: Yup.string().when("userData", () => {
        if (userData.city === undefined || !userData.city || userData.city === "") {
          return Yup.string().required("This field is required");
        }
        return Yup.string().notRequired();
      }),
      country: Yup.string().when("userData", () => {
        if (userData.country === undefined || userData.country === null || userData.country === "") {
          return Yup.string().required("This field is required");
        }
        return Yup.string().notRequired();
      }),
      // background: Yup.string()
      //     .when('userData', () => {
      //         console.log(userData)
      //         if (userData.background === undefined || userData.background === '') {
      //             return Yup.string().required('This field is required')
      //         }
      //         return Yup.string().notRequired()
      //     }
      //     ),
      careerAdvisingPhone: Yup.string().when("userData", () => {
        if (userData.careerAdvisingPhone === undefined || userData.careerAdvisingPhone === "" || userData.careerAdvisingPhone === null) {
          return Yup.string().required("This field is required");
        }
        return Yup.string().notRequired();
      }),

      careerAdvisingEmail: Yup.string()
        .email()
        .when("userData", () => {
          if (userData.careerAdvisingEmail === undefined || userData.careerAdvisingEmail === null || userData.careerAdvisingEmail === "") {
            return Yup.string().required("Email field is required");
          }
          return Yup.string().notRequired();
        }),
    }),
  });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
    setTimeout(() => {
      formik.handleChange(e);
    }, 100);
  };
  const handleChangePhone = (phone: any) => {

    setUserData((_data: any) => ({ ..._data, careerAdvisingPhone: phone }));
  };
  const handleTextEditorChange = (text: any, textLength = 0) => {
    setTextLength(textLength);
    setUserData((_data: any) => ({ ..._data, background: text }));
    const event = new Event("change");
    setTimeout(() => {
      formik.handleChange(event);
    }, 100);
  };
  const getAllCountries = async () => {
    const response = await listAllCountries();
    if (response) {
      const countries = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCountries(countries);
      setLoadingCountries(false);
    }
  };
  const getAllCities = async (countryId: string) => {
    setLoadingCities(true);
    const response = await listAllCities(countryId);
    if (response) {
      const citiesOfCountry = response.data.data;

      const cities = citiesOfCountry.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setCities(cities);
      setLoadingCities(false);
    }
  };
  const handleSelectContry = (e: any) => {
    if (e.value) {
      setSelectedCity(null);
      setUserData((_data: any) => ({ ..._data, city: null }));
    }

    handleSelectChange(e);
    if (e.value) {
      getAllCities(e.value.id);
    } else {
      // setCities([])
    }
  };
  const getNewData = async () => {
    const response = await getUserNewInfo(userInfo?._id);
    if (response.status === 200) {
      setUserNewData(response?.data?.data);

      getAllCities(response?.data?.data?.subModel?.careerAdvisingLocation?.country?._id);
      setSelectedCity({
        label: response?.data?.data?.subModel?.careerAdvisingLocation?.city?.nameEn,
        id: response?.data?.data?.subModel?.careerAdvisingLocation?.city?._id,
      });
      setUserData({
        schoolUrl: response?.data?.data?.subModel?.schoolUrl,
        careerAdvisingPhone: response?.data?.data?.subModel?.careerAdvisingPhone,
        careerAdvisingEmail: response?.data?.data?.subModel?.careerAdvisingEmail,
        city: {
          label: response?.data?.data?.subModel?.careerAdvisingLocation?.city?.nameEn,
          id: response?.data?.data?.subModel?.careerAdvisingLocation?.city?._id,
        },
        country: {
          label: response?.data?.data?.subModel?.careerAdvisingLocation?.country?.nameEn,
          id: response?.data?.data?.subModel?.careerAdvisingLocation?.country?._id,
        },
        background: response?.data?.data?.subModel?.background,
      });
    }
  };
  useEffect(() => {
    getAllCountries();

    if (token) {
      if (userInfo?.newUpdate) {
        getNewData();
      } else {
        getAllCities(userInfo?.subModel?.careerAdvisingLocation?.country?._id);
        setSelectedCity({
          label: userInfo?.subModel?.careerAdvisingLocation?.city?.nameEn,
          id: userInfo?.subModel?.careerAdvisingLocation?.city?._id,
        });
        setUserData({
          schoolUrl: userInfo?.newData?.schoolUrl || userInfo?.subModel?.schoolUrl,
          careerAdvisingPhone: userInfo?.newData?.careerAdvisingPhone || userInfo?.subModel?.careerAdvisingPhone,
          careerAdvisingEmail: userInfo?.newData?.careerAdvisingEmail || userInfo?.subModel?.careerAdvisingEmail,
          city: {
            label: userInfo?.newData?.careerAdvisingLocation?.city?.nameEn || userInfo?.subModel?.careerAdvisingLocation?.city?.nameEn,
            id: userInfo?.newData?.careerAdvisingLocation?.city?.nameEn || userInfo?.subModel?.careerAdvisingLocation?.city?._id,
          },
          country: {
            label: userInfo?.newData?.careerAdvisingLocation?.country?.nameEn || userInfo?.subModel?.careerAdvisingLocation?.country?.nameEn,
            id: userInfo?.newData?.careerAdvisingLocation?.country?.nameEn || userInfo?.subModel?.careerAdvisingLocation?.country?._id,
          },
          background: userInfo?.newData?.background || userInfo?.subModel?.background,
        });
      }
    }
  }, [token]);

  const handleSelectChange = (e: any) => {
    const isArray = Array.isArray(e.value);
    if (e) {
      if (isArray) {
        setUserData((_data: any) => ({ ..._data, [e.name]: e.value.map((item: any) => item?.id || null) }));
      } else {
        if (e.name == "city") {
          setSelectedCity(e.value);
        }
        setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.id || null }));
      }
    }
    setTimeout(() => {
      formik.handleChange(e.event);
    }, 100);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    dispatch(
      addAlertInfo({
        show: false,
        message: null,
      })
    );
    setSubmitClicked(true);
    setLoadingSubmit(true);

    if (getErrorMessage() == true && Object.keys(formik.errors).length === 0 && userData.background && userData?.city && userData?.country) {
      formData.append(
        "careerAdvisingLocation",
        JSON.stringify({
          country: userData.country?.id ? userData.country?.id : userData.country,
          city: userData.city?.id ? userData.city?.id : userData.city,
        })
      );

      for (const property in userData) {
        if (userData[property] != null && userData[property] !== "city" && userData[property] !== "country") {
          if (Array.isArray(userData[property])) {
            // updateData[property] = userData[property].map((item: any) => item.id ? item.id : item)
            formData.append(property, JSON.stringify(userData[property].map((item: any) => (item.id ? item.id : item))));
          } else if (typeof userData[property] == "object") {
            formData.append(property, userData[property].id ? userData[property].id : userData[property]);
          } else {
            formData.append(property, userData[property]);
          }
        }
      }
      const response = await UpdateUser(userInfo?._id, formData);
      if (response?.status === 200) {
        dispatch(
          addAlertInfo({
            show: true,
            message: response?.data?.data?.message,
          })
        );

        setLoadingSubmit(false);
        setShowHint(true);
        // window.scrollTo(0, 0);
      } else {
        setLoadingSubmit(false);
        dispatch(
          addAlertInfo({
            show: true,
            message: response.data.error,
            type: "error",
          })
        );
      }
    } else {
      setLoadingSubmit(false);
      dispatch(
        addAlertInfo({
          show: true,
          message: getErrorMessage(),
          // message: "Please double check your information and try again",
          type: "error",
        })
      );
    }
  };


  return (
    <>
      {token && Object.keys(userData).length !== 0 && (
        <Container>
          <div className="header-underline">
            <h3 className="title">{userInfo?.subModel?.name}- School Information</h3>
            <p className="main-description">Enter your school information here. It will be visible to students and employers.</p>
            <p className="required ">Fields marked with an * are required.</p>
            {(userInfo?.newUpdate || showHint) && (
              <div className="text-[14px] bg-[#f26403] font-[500] p-2 rounded  text-white mt-2 ">
                <p className=" text-white">
                  You updated some of your data and still waiting for the admin approval, this information visible only for you in your settings page,
                  it wil be visible for others and in your profile after admin approval.
                </p>
              </div>
            )}
          </div>

          <div className="my-6 w-full md:w-3/4 lg:w-3/4">
            <p className="secondary-title">School branding</p>
            <div className="flex justify-between my-6 w-full">
              <p className="main-description">
                Upload banner that will appear on your school profile page. <br></br> It is suggested to upload a picture of your campus.
              </p>
              <UploadeBanner newData={newData} />
            </div>
            <div className="flex justify-between my-6 w-full">
              <p className="main-description">*Upload logo that will appear on your school profile page</p>
              {/* <CustomButton text='Upload logo' click={UploadLogo} /> */}
              <UploadeLogo newData={newData} />
            </div>
            <form>
              <div className="form-item">
                <InputField
                  label="Add URL of school"
                  name="schoolUrl"
                  required={true}
                  placeholder="e.g: https://www.uniteduniversity.edu"
                  Change={handleChange}
                  formik={formik}
                  submitClicked={submitClicked}
                  value={userData?.schoolUrl}
                />
              </div>
              <div className="form-item">
                <InputField
                  label="Add career advising's phone#"
                  required={true}
                  name="careerAdvisingPhone"
                  value={userData?.careerAdvisingPhone}
                  Change={handleChange}
                  formik={formik}
                  submitClicked={submitClicked}
                  type="text"
                  placeholder={""}
                />
                {/* {userData?.careerAdvisingPhone}
                                <InputPhone
                                    label="Add career advising's phone#"
                                    required={true}
                                    name='careerAdvisingPhone'
                                    value={userData?.careerAdvisingPhone}
                                    Change={handleChangePhone}
                                    formik={formik}
                                    submitClicked={submitClicked}
                                /> */}
              </div>
              <div className="form-item">
                <InputField
                  label="Add career advising's email"
                  required={true}
                  placeholder="e.g: user@uniteduniversity.edu"
                  name="careerAdvisingEmail"
                  Change={handleChange}
                  formik={formik}
                  submitClicked={submitClicked}
                  value={userData?.careerAdvisingEmail}
                />
              </div>
              <div className="form-item">
                <label>Add career advising's location</label>

                <Grid container columnSpacing="5">
                  <Grid item xs={12}>
                    <div className="form-item ">
                      <SelectInput
                        name="country"
                        required={true}
                        label="Select your country"
                        options={countries}
                        Change={handleSelectContry}
                        loading={loadingCountries}
                        // formik={formik}
                        // submitClicked={submitClicked}
                        // value={userData?.country}
                        defaultValue={userData?.country}
                      />

                      {!userData.country && submitClicked && <span className="text-red-500 text-sm">This field id required</span>}
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="form-item ">
                      <SelectInput
                        name="city"
                        required={true}
                        label="Select your city (region/state/province options will appear with the city)"
                        labelHint="Select your country first"
                        options={cities}
                        Change={handleSelectChange}
                        loading={loadingCities}
                        // formik={formik}
                        // submitClicked={submitClicked}
                        value={selectedCity}
                        disabled={!userData.country}
                      />
                      {!userData?.city && submitClicked && <span className="text-red-500 text-sm">This field id required</span>}
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className="form-item">
                <TextEditor
                  label={"*Background"}
                  name="background"
                  maxLength={2400}
                  Change={handleTextEditorChange}
                  error={userData.background === undefined || userData.background === ""}
                  value={userData?.background}
                  submitClicked={submitClicked}
                />
              </div>
              <div className="custom-action-fill my-4">
                <CustomButton text="Change password" click={handleChangePassword} />
              </div>
              {open && <PasswordPopUp show={open} setOpen={setOpen} />}
              <div className="flex justify-end my-4">
                <CustomButton
                  text="Submit for review"
                  click={handleSubmit}
                  loading={loadingSubmit}
                  disabled={Object.keys(userData).length === 0 || textLength + 1 > 2400}
                />
              </div>
            </form>
          </div>
        </Container>
      )}
    </>
  );
}

export default SchoolInformation;
