import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";

import { useState } from "react";
import TabThree from "./TabThree";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
    const validateList = ['name', 'webSite', 'email', 'phoneNumber', 'companySize', 'country', 'city', 'password', 'industries']

  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state: any) => state.auth.user);
  const [value, setValue] = React.useState(parseInt(searchParams?.get('tap')||'0'));
 
  const parms = useParams()
  const { id } = parms


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(`/company/add-information?tap=${newValue}`)
  };
  const [showForm, setShowForm] = useState(false)
  const navigate = useNavigate();
  const createJobForm = () => {
    setShowForm(true)
  }
  React.useEffect(() => {
    if (window.location.search.replace('?', '') === 'jobs') {
      
      setValue(1)
    }
    else if (window.location.search.replace('?', '') === 'add-job') {
      setValue(2)
    }
    if(searchParams){
       setValue(parseInt(searchParams?.get('tap')||'0'))
    }
   
   
  }, [window.location.search])


  // React.useEffect(() => {
   
  //   if(value!==3&&!id){
      
  //     navigate('/company/add-information')
  //   }
  // }, [value])
  React.useEffect(() => {
    if(id){
      setValue(3)
    }

  }, [id])

  return (
    <>
      <Box>
        {value === 0 ?
          <div className="header-underline">
            <h3 className="title">Employer Information</h3>
            <span className='main-description'>All fields are required.</span>
          </div>
          : value === 1 ?
            <div className="header-underline">
              <h3 className="title">Search and filter job posts</h3>
            </div>
            : value === 2 &&
            <div className="header-underline">

              <p className='title'>New job post for {user?.subModel?.name}</p>
              <span className='main-description'>Fields marked with an * are required.</span>
            </div>
        }

        <Tabs
          className="tabs"
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: '#F26400 ', color: "#F26400" },
            '& .Mui-selected': { color: "#F26400" },
          }}
          value={value} onChange={handleChange}
        >
          <Tab className="tab" label="Company Information" {...a11yProps(0)} />
          <Tab className="tab" label="Job Posts" {...a11yProps(1)} />
          <Tab className="tab" label="Create New Job Post" {...a11yProps(2)} />
          {id && <Tab className="tab" label="Edit Job Post" {...a11yProps(3)} />}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TabOne />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabTwo createJobForm={createJobForm} showForm={showForm} />
      </TabPanel>
      <TabPanel value={value} index={2} >
        <TabThree setTap={setValue} />
      </TabPanel>
      <TabPanel value={value} index={3} >
        <TabThree setTap={setValue} id={id}/>
      </TabPanel>
    </>
  );
}
