import { formatDistanceToNow, subHours } from 'date-fns';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { InstitutionLastMentorshipsCompleated, latestInteractionsWithEmployers, StudentRecentlyHired } from '../../../../services/analyticsService';
import NoDataMessage from '../../../../Components/NoDataMessage';


export default function LastMentorshipsCompleated(props: any) {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const getData = async () => {
    const response = await InstitutionLastMentorshipsCompleated(user._id, 0, 4)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token]);
  const getApplicationStatus = (status: string) => {

    if (status == '0') return 'Pending'
    if (status == '1') return 'Hired'
    if (status == '2') return 'Declined'
  }
  return (
    <Card {...props} className='post h-full'>
      <CardHeader
        subtitle={`${data.length} in total`}
        title={<p className='main-description'>Latest Mentorships Completed</p>}
      />
      <Divider />
      {data.length>0?
      <List>
        {data.map((item: any, i) => (
          <ListItem
            divider={i < data.length - 1}
            key={item?.id}
            onClick={() => navigate(`/professional/profile/${item?.user1?.user?._id}`)}
            className='cursor-pointer'
          >
            <Avatar className='mr-4'
              onClick={() => navigate(`/professional/profile/${item?.user1?.user?._id}`)}
              src={item?.user1?.user?.profileImage || item?.user1?.user?.subModel?.logo} sx={{ width: 50, height: 50, bgcolor: "#F26400" }} aria-label="avatar">
              <a className='capitalize text-white' >
                {item?.user1?.user?.subModel?.firstName} {item?.user1?.user?.subModel?.lastName}
              </a>
            </Avatar>


            <ListItemText
              primary={item?.user1?.user?.subModel?.firstName + ' ' + item?.user1?.user?.subModel?.lastName}
              secondary={item?.mentorShipSession?.mentorShipType?.nameEn}
            />

          </ListItem>
        ))}
      </List>:
      <NoDataMessage/>}

      {/* <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      p: 2
    }}
  >
    <a
      className='colored'
    >
      View all<ArrowRightIcon />
    </a>
  </Box> */}
    </Card>
  )
}
