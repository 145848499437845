import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const listAllMajor = () => {
  return axios
    .get(`${BASEURL}major`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};


