import React, { useEffect, useState } from "react";
import CarouselList from "../../../../Components/CardsList";
import { Container, Pagination } from "@mui/material";
import { userInfo } from "os";
import { listCompanyJobPosts } from "../../../../services/company/jobPostsService";
import { useSelector } from "react-redux";
import NoDataMessage from "../../../../Components/NoDataMessage";
import { CardLoadingSkeleton } from "../../../Student/Access/Tabs";
import Post from "../../../../Components/CardsList/Post";
import { useParams } from "react-router-dom";

interface JobsPostedProps {
  nested?: boolean;
}
export default function JobsPosted(props: JobsPostedProps) {
  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [jobslist, setJobslist] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [totlaPages, setTotlaPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };
  const parms = useParams();
  const { id, name } = parms;
  const getCompanyJobs = async () => {
    setLoadingJobs(true);
    const response = await listCompanyJobPosts(id ? id : userInfo._id, currentPage, 9);
    if (response.status === 200) {
      setJobslist(response.data.data.queryResult);
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9));

    }
    setLoadingJobs(false);
  };
  useEffect(() => {
    if (token) {
      getCompanyJobs();
    }
  }, [token, currentPage]);
  return (
    <Container>
      <div className="m-b-50">
        <div className="header-underline">
          <h3 className="title">Jobs posted at {name ? name : userInfo?.subModel?.name}</h3>
        </div>
        <div className="grid gap-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
          {!loadingJobs
            ? jobslist && jobslist.length > 0
              ? jobslist.map((item: any, i: number) => {
                  return <Post data={item} index={i} listAllData={getCompanyJobs} />;
                })
              : ""
            : [1, 2, 3, 4, 5, 6].map((item: any) => {
                return <CardLoadingSkeleton />;
              })}
        </div>
        {!loadingJobs && jobslist.length === 0 && <NoDataMessage />}
        {totlaPages > 1 && (
          <div className="flex justify-end my-6">
            <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
          </div>
        )}
      </div>
    </Container>
  );
}
