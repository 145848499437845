import { Box } from "@mui/material";
import React from "react";

import JobDetails from "../../JobDetails";
import NoDataMessage from "../../../../Components/NoDataMessage";
interface Props {
  selectedJob: any
  loading: boolean
}
export default function RightContent(props: Props) {
  const { selectedJob, loading } = props

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <div className="pb-4">
        </div>

        {Object.keys(selectedJob).length === 0 && !loading ?
          <NoDataMessage /> :
          <JobDetails nested={true} selectedJob={selectedJob} loading={loading} />
        }


      </Box>

    </div>
  );
}
