import React from "react";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import MenteeCard from "./MenteeCard";
import NoDataMessage from "../../../Components/NoDataMessage";
export default function MentessRequests(props: any) {
    const { list ,listAll} = props
    return (
        <>
            
            <div className="mt-10">
                {list.length>0?
                <Grid container spacing={3}  >
                    {list.map((item: any) => {
                        return (
                            <Grid item lg={6} sm={12}>
                                <MenteeCard data={item}  listAll={listAll}/>
                            </Grid>
                        )
                    })
                    }
                </Grid>
                :
                <NoDataMessage/>
}
            </div>

        </>
    );
}
