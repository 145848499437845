import axios from '../axios';
import { BASEURL } from "../environments/environment";

// const token = localStorage.getItem("userToken");

export const listAllMentors = (id: string) => {
  return axios
    .get(`${BASEURL}mentor/list?mentorShipType=${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const changeMentorShipSessionStatus = (id: string, data: any) => {
  return axios
    .patch(`${BASEURL}meeting/status/${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const listAllMentorsToconnect = (
  page: number,
  pageSize: number,
  majorFilterParm: any,
  industryFilterParm: any,
  almaFilterParm: any,
  areaInterestFilterParm:any
) => {
  return axios
    .get(
      `${BASEURL}mentor/list/in-connect?page=${page}&pageSize=${pageSize}${majorFilterParm}${industryFilterParm}${almaFilterParm}${areaInterestFilterParm}`
    )
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const getLatestActiveSessionsMentor = (
  id: string,
  currentPage=0,
  pageSize?: number
) => {
  const paging =
     currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}mentor/${id}/active-sessions${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const LastMentorshipsCompleatedMentor= (
  id: string,
  currentPage=0,
  pageSize?: number
) => {
  const paging =
     currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
  .get(`${BASEURL}mentor/${id}/sessions${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const AddAvailableTimeSlot = (id: string, data: any) => {
  return axios
    .patch(`${BASEURL}mentor/availabilityTime/${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const removeAvailableTimeSlot = (id: string, index: any) => {
  return axios
    .get(`${BASEURL}mentor/delete/availabilityTime/${id}?dateIndex=${index}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const mentorRatting = (id: any, data: any) => {
  return axios
    .patch(`${BASEURL}meeting/mentee-rating/${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const menteeRatting = (id: any, data: any) => {
  return axios
    .patch(`${BASEURL}meeting/mentor-rating/${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};