import { Container } from "@mui/system";
import { Grid } from "@mui/material";
import LeftContent from "./LefttContent";
import RightContent from "./RightContent";
import { SingleGobs, listAlljobs } from "../../../services/jobsService";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface JobDetailsProps { }

const Jobs = (props: JobDetailsProps) => {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);

  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingSingle, setLoadingSingle] = useState(true)
  const [selectedJob, setSelectedJob] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)
  const [listCount, setListCount] = useState(0)
  const [searchTerm, setSearchTerm] = useState(null)
  const [filter, setFilter] = useState(undefined)
  const [filterMajor, setFilterMajor] = useState(undefined)
  const [filterIndustry, setFilterIndustry] = useState(undefined)
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }

  const getSingleGob = async (jobId: string) => {
    setLoadingSingle(true)
    const response = await SingleGobs(jobId, user?._id, user?.model != "Student")
    if (response.status === 200) {
      setLoadingSingle(false)
      setSelectedJob(response.data.data)

    }
    else {
      setLoadingSingle(false)

    }
  }
  const getGobs = async () => {
    setLoading(true)
    setLoadingSingle(true)
    const response = await listAlljobs({ currentPage: currentPage, pageSize: 10, searchTerm: searchTerm, filter: filter, filterMajor: filterMajor, filterIndustry: filterIndustry })
    if (response && response.status === 200) {
      setList(response.data.data.queryResult)
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      setListCount(response.data.data.wholeModelDataCount)
      if (response.data.data.queryResult.length > 0) {
        getSingleGob(response.data.data.queryResult[0]._id)
      }
      else {
        setSelectedJob({})
        setLoadingSingle(false)
      }

      setLoading(false)
    }
    else {
      setLoading(false)
      setSelectedJob({})
      setLoadingSingle(false)
    }

  }

  useEffect(() => {
    if (token) {
      getGobs()

    }
  }, [token, currentPage]);

  useEffect(() => {
    if (searchTerm !== null) {
      const getData = setTimeout(() => {
        getGobs();
      }, 500);
      return () => clearTimeout(getData);
    }
  }, [searchTerm]);
  useEffect(() => {
    if (filter !== undefined) {
      const getData = setTimeout(() => {
        getGobs();
      }, 500);
      return () => clearTimeout(getData);
    }

  }, [filter]);
  useEffect(() => {
    if (filterMajor !== undefined) {
      const getData = setTimeout(() => {
        getGobs();
      }, 500);
      return () => clearTimeout(getData);
    }

  }, [filterMajor]);
  useEffect(() => {
    if (filterIndustry !== undefined) {
      const getData = setTimeout(() => {
        getGobs();
      }, 500);
      return () => clearTimeout(getData);
    }

  }, [filterIndustry]);
  const [isMobileShow, setIsMobileShow] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    if (/iPhone|Android|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent)) {
      setIsMobileShow(false)
      setIsMobile(true)
    }

  }, [])
  return (
    <Container>
      <div className="page">
        <Grid container spacing={3} className='mb-6' >
          {!isMobileShow && <Grid item lg={3} md={5} xs={12} className={`pr-0 md:pr-4 ${isMobile ? "" : "main-r-border"}`}>
            <LeftContent jobsList={list}
              loading={loading}
              totlaPages={totlaPages}
              handlePaginationChange={handlePaginationChange}
              listCount={listCount}
              selectedJob={selectedJob}
              getSingleGob={getSingleGob}
              setSearchTerm={setSearchTerm}
              setFilter={setFilter}
              setFilterMajor={setFilterMajor}
              setFilterIndustry={setFilterIndustry}
              setIsMobileShow={setIsMobileShow}
              isMobile={isMobile}
            />
          </Grid>}
          {(isMobileShow||!isMobile) &&
            <Grid item lg={9} md={7} xs={12}>
              <div className='flex md:hidden justify-between items-center bg-[#eaeaea] px-1 py-2 rounded'>
                <a className="colored block cursor-pointer" onClick={() => (setIsMobileShow(false))}><ArrowBackIcon /> Back to all jobs</a>

              </div>
              <RightContent selectedJob={selectedJob} loading={loadingSingle} />
            </Grid>}
        </Grid>

      </div>

    </Container>
  );
};

export default Jobs;
