
import { ChangeEvent, useEffect, useState } from "react";
import TextEditor from "../../../../../Components/Editor";
import InputField from "../../../../../Components/Input";
import SelectInput from "../../../../../Components/SelectInput";
import CustomizedButtons from "../../../../../Components/CustomButton";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { ListAllIndustry } from "../../../../../services/industry";
import { Language } from "../../../../../models/language";
import { listAllCities, listAllCountries } from "../../../../../services/locationService";
import Grid from "@mui/material/Grid";
import { getAllSocialMedia } from "../../../../../services/scoialMediaService";
import UploadeBanner from "./Upload/banner";
import { UploadeLogo } from "./Upload/logo";

import CloseIcon from '@mui/icons-material/Close';
import { addAlertInfo } from "../../../../../store/alertSlice";
import { UpdateUserProfile } from "../../../../../services/authService";
import IndustriesUI from "./Industries";
import SocialMediaUI from "./SocialMedia";
import MediaPlacement from "../../../../../assets/svg/media.svg";
import { UploadeMedia } from "./Upload/media";
import { getUserNewInfo } from "../../../../../services/userService";
import InputPhone from "../../../../../Components/Input/phoneInput";
import CustomButton from "../../../../../Components/CustomButton";
import PasswordPopUp from "../../../../Student/AccountSetting/components/passwordPopup";
import { useNavigate } from 'react-router';

export default function TabOne() {
  const validateList = ['name', 'email', 'phoneNumber', 'webSite', 'companySize', 'country', 'city']
  const validateListNames = ['Name of company', 'Email', 'Phone', 'WebSite', 'Company size', 'Country', 'City']

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [loadingPage, setLoadingPage] = useState(true);
  const [file, setImagefile] = useState<File>();
  const [userData, setUserData] = useState<any>({})
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [type, setType] = useState('')
  const [allindustry, setAllIndustry] = useState<any>([]);
  const [loadingIndustry, setloadingIndustry] = useState(true);
  const [headquarterCountry, SetheadquarterCountry] = useState<any>(null)
  const [headquarterCity, SetheadquarterCity] = useState<any>()
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [employerCities, setEmployerCities] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([])
  const [addressList, setAddressList] = useState([])
  const [showHint, setShowHint] = useState(false);
  const [socialMediaLinksList, setSocialMediaLinksList] = useState<any>([])
  const [allSubIndustry, setAllSubIndustry] = useState<any>();
  const [newData, setUserNewData] = useState<any>();
  const [selectimage, setSelectImage] = useState<any>([]);
  const [selectFiles, setSelectFiles] = useState<any>([]);
  const [toBeDeletedImages, setToBeDeletedImages] = useState<any>([]);
  const [hover, setHover] = useState<any>(null);
  const [selected, setSelected] = useState<any>(null);
  const [selectedCity, setSelectedCity] = useState<any>()
  const [open, setOpen] = useState(false)
  const formData = new FormData()
  const [textLength, setTextLength] = useState<any>(0);
  const maximumUploadMedia = userInfo?.newUpdate ?newData?.subModel?.companyMedia?.length: userInfo?.subModel?.companyMedia?.length  || 0
  const handleChangePassword = () => {
    setOpen(true)
  }
  const UploadVideo = () => {
    setShowVideo(true)
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));


  };

  const handleTextEditorChange = (text: any, textLength = 0) => {
    setTextLength(textLength)
    setUserData((_data: any) => ({ ..._data, 'background': text }));
    const event = new Event("change");

  }
  const getAllIndustry = async () => {
    setloadingIndustry(true)
    const response = await ListAllIndustry()
    if (response) {
      const data = response.data.data.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))

      setAllIndustry(data)
      setloadingIndustry(false)
    }
  }
  const getAllCities = async (countryId: string) => {
    setLoadingCities(true)
    const response = await listAllCities(countryId)
    if (response) {
      const citiesOfCountry = response.data.data
      const cities = citiesOfCountry.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
      setCities(cities)
      setLoadingCities(false)
    }
  }

  const handleSelectChange = (e: any) => {

    if (e.name === "headquarterCountry") {
      SetheadquarterCountry(e.value)
      if (e?.value) {
        SetheadquarterCity(null)
        getAllCities(e?.value?.id)
      }

    }
    else if (e.name === "headquarterCity") {
      SetheadquarterCity(e.value)

    } else {
      const isArray = Array.isArray(e.value);
      if (isArray) {
        setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.length > 0 ? e.value.map((item: any) => item.id) : null }));
      }
      else {
        setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.id || null }));
      }

    }


  }
  const getAllCountries = async () => {
    const response = await listAllCountries()
    if (response) {
      const countries = response.data.data.queryResult.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
      setCountries(countries)
      setLoadingCountries(false)
    }
  }
  const getAllsetEmployerCities = async (countryId: string) => {
    setLoadingCities(true)
    const response = await listAllCities(countryId)
    if (response) {
      const citiesOfCountry = response.data.data
      const cities = citiesOfCountry.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
      setEmployerCities(cities)
      setLoadingCities(false)
    }
  }

  const getSocialMediaList = async () => {
    const response = await getAllSocialMedia()
    setSocialMediaList(response.data.data)
  }

  const handleSelectContry = (e: any) => {
    setSelectedCity(null)
    handleSelectChange(e)

    if (e.value) {
      getAllsetEmployerCities(e.value.id)
    }
    else {
      setCities([])
    }
  }

  useEffect(() => {
    getAllCountries()
    getAllIndustry()
    getSocialMediaList()

  }, []);

  const validateForm = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return false
    }
    if ((textLength - 1) > 2200) return false
    if (!headquarterCountry) return false
    if (!headquarterCity) return false
    if (userData?.phoneNumber == "+") return false
    return true
  }
  const getErrorMessage = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return validateListNames[i] + ' is required'
    }
    if ((textLength - 1) > 2200) return 'You have exceeded the maximum number of characters allowed for background field. Please remove the extra characters to continue'
    if (!headquarterCountry) return 'Headquarter country id required'
    if (!headquarterCity) return 'Headquarter city id required'
    if (userData?.phoneNumber == "+") return 'Phone number id required'
    return true
  }
  function handleSubmit() {
    setSubmitClicked(true)
    if (validateForm() && (file || userInfo?.subModel?.logo) && userData?.industries?.length !== 0) {
      for (const property in userData) {
        if (userData[property]) {

          if (typeof userData[property] === 'string' || typeof userData[property] === 'boolean' || userData[property].id) {

            formData.append(property, userData[property].id ? userData[property].id : userData[property]);
          }
          else {
            if (property === 'industries') {
              formData.append(property, JSON.stringify(userData[property].map((item: any) => ({ 'industry': item.industry._id ? item.industry._id : item.industry, 'subIndustries': item.subIndustries.map(((item: any) => item._id ? item._id : item)) }))))
            }

            else if (property === 'socialMediaLinks') {
              formData.append(property, JSON.stringify(userData[property].map((item: any) => ({ 'socialMedia': item.socialMedia._id ? item.socialMedia._id : item.socialMedia, 'url': item.url }))))
            }
            else {
              formData.append(property, JSON.stringify(userData[property]));
            }

          }
        }

      }
      const media = Object.keys(socialMediaLinksList)?.map((item: any) => ({ 'socialMedia': item, 'url': socialMediaLinksList[item] }))
      Object.keys(socialMediaLinksList).length > 0 && formData.append("socialMediaLinks", JSON.stringify(media));


      if (selectFiles.length > 0) {
        // formData.append("companyMedia", selectFiles);
        for (const image of selectFiles) {
          formData.append(`companyMedia`, image);
        }
      }
      // formData.append("logo", file || userInfo?.subModel?.logo)
      formData.append("model", 'Company');

      setLoadingSubmit(true)
      setTimeout(async () => {
        const response = await UpdateUserProfile(userInfo?._id, formData)

        setSubmitClicked(false)
        if (response.status === 200) {

          dispatch(addAlertInfo(
            {
              show: true,
              // message: 'Data updated successfully',
              message: response?.data?.data?.message,
            }
          ))

          setLoadingSubmit(false)
          navigate('/company/information')
        }
        else {
          dispatch(addAlertInfo(
            {
              show: true,
              message: response.data.error,
              type: 'error'
            }
          ))
          setLoadingSubmit(false)
        }


      }, 1000);
    }
    else {
      // setSubmitClicked(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: getErrorMessage(),
          type: 'error'
        }
      ))
    }
  }

  const removeMedia = (index: number) => {

    const images = [...selectimage]
    const files = [...selectFiles]
    images.splice(index, 1)
    files.splice(index, 1)
    setSelectFiles(files)
    setSelectImage(images)

  }
  const removeMediaIndexs = (index: number) => {
    const deletedImages = [...toBeDeletedImages]
    deletedImages.push(index)
    setToBeDeletedImages(deletedImages)
    setUserData((_data: any) => ({ ..._data, 'toBeDeletedImages': deletedImages }));

  }
  const getNewData = async () => {
    const response = await getUserNewInfo(userInfo?._id)
    if (response.status === 200) {
      setUserNewData(response?.data?.data)
      getAllCities(response?.data?.data?.subModel?.headQuarters?.country?._id)
      getAllsetEmployerCities(response?.data?.data?.country?._id)
      SetheadquarterCountry(response?.data?.data?.subModel?.headQuarters ? { 'label': response?.data?.data?.subModel?.headQuarters?.country?.nameEn, 'id': response?.data?.data?.subModel?.headQuarters?.country?._id } : null)
      SetheadquarterCity(response?.data?.data?.subModel?.headQuarters ? { 'label': response?.data?.data?.subModel?.headQuarters?.city?.nameEn, 'id': response?.data?.data?.subModel?.headQuarters?.city?._id } : null)
      setSelectedCity(response?.data?.data?.city ? { 'label': response?.data?.data?.city?.nameEn, 'id': response?.data?.data?.city?._id } : null)
      setUserData({
        name: response?.data?.data?.subModel?.name,
        webSite: response?.data?.data?.subModel?.webSite,
        email: response?.data?.data?.email,
        phoneNumber: response?.data?.data?.subModel?.phoneNumber,
        companySize: response?.data?.data?.subModel?.companySize,
        city: response?.data?.data?.city && { 'label': response?.data?.data?.city?.nameEn, 'id': response?.data?.data?.city?._id },
        country: response?.data?.data?.country && { 'label': response?.data?.data?.country?.nameEn, 'id': response?.data?.data?.country?._id },
        industries: response?.data?.data?.subModel?.industries,
        background: response?.data?.data?.subModel?.background
      })
      setLoadingPage(false)

    }

  }
  useEffect(() => {
    getAllCountries()

    if (token) {

      if (userInfo?.newUpdate) {
        getNewData()
      }
      else {
        getAllCities(userInfo?.subModel?.headQuarters?.country?._id)
        getAllsetEmployerCities(userInfo?.country?._id)
        SetheadquarterCountry(userInfo?.subModel?.headQuarters?.country ? { 'label': userInfo?.subModel?.headQuarters?.country?.nameEn, 'id': userInfo?.subModel?.headQuarters?.country?._id } : null)
        SetheadquarterCity(userInfo?.subModel?.headQuarters ? { 'label': userInfo?.subModel?.headQuarters?.city?.nameEn, 'id': userInfo?.subModel?.headQuarters?.city?._id } : null)
        setSelectedCity(userInfo?.city ? { 'label': userInfo?.city?.nameEn, 'id': userInfo?.city?._id } : null)
        setUserData({
          name: userInfo?.subModel?.name,
          webSite: userInfo?.subModel?.webSite,
          email: userInfo?.email,
          phoneNumber: userInfo?.subModel?.phoneNumber,
          companySize: userInfo?.subModel?.companySize,
          city: userInfo?.city && { 'label': userInfo?.city?.nameEn, 'id': userInfo?.city?._id },
          country: userInfo?.country && { 'label': userInfo?.country?.nameEn, 'id': userInfo?.country?._id },
          industries: userInfo?.subModel?.industries,
          background: userInfo?.subModel?.background
        })
        setLoadingPage(false)
      }
    }

  }, [token]);

  // useEffect(() => {
  //   if (cities.length > 0 && newData) {
  //     SetheadquarterCity({ 'label': newData?.subModel?.headQuarters?.city?.nameEn || userInfo?.subModel?.headQuarters?.city?.nameEn, 'id': newData?.subModel?.headQuarters?.city?._id || newData?.subModel?.headQuarters?.city?.nameEn })
  //   }
  // }, [cities, newData]);
  // useEffect(() => {
  //   if (cities.length > 0 && !newData) {
  //     SetheadquarterCity({ 'label': userInfo?.subModel?.headQuarters?.city?.nameEn, 'id': userInfo?.subModel?.headQuarters?.city?._id })
  //   }
  // }, [cities, newData]);

  useEffect(() => {
    if (headquarterCountry && headquarterCity) {
      setUserData((_data: any) => ({
        ..._data, 'headQuarters': {
          country: headquarterCountry?.id,
          city: headquarterCity?.id
        }
      }));
    }
  }, [headquarterCountry, headquarterCity])

  const handleChangePhone = (phone: any) => {
    setUserData((_data: any) => ({ ..._data, 'phoneNumber': `+${phone}` }));
  }
  return (
    <div>
      {token && !loadingPage &&
        <form className="my-6">
          {(userInfo?.newUpdate || showHint) &&
            <div className="text-[14px] bg-[#f26403] font-[500] p-2 rounded  text-white mb-4 ">
              <p className=" text-white">You updated some of your data and still waiting for the admin approval,  this information visible only for you in your settings page, it wil be visible for others and in your profile after admin approval.
              </p>
            </div>}
          <div className='form-item md:w-3/4'>
            <InputField
              label='Name of company'
              name='name'
              required={true}
              placeholder='Name of company'
              Change={handleChange}

              submitClicked={submitClicked}
              value={userData?.name}
              error={!userData?.name && submitClicked}

            />
          </div>


          <div className='form-item md:w-3/4'>
            <label>* Contact information</label>

            <InputField
              placeholder='email'
              name='email'
              required={true}
              Change={handleChange}

              submitClicked={submitClicked}
              value={userData?.email}
              error={!userData?.email && submitClicked}
            />
            <div className='form-item mt-2'>
              {/* <InputField
                label=""
                required={true}
                name='phoneNumber'
                Change={handleChange}
                submitClicked={submitClicked}
                type="number"
                placeholder={"Phone number"}
                value={userData?.phoneNumber}
                error={!userData?.phoneNumber && submitClicked}
              /> */}

              <InputPhone
                label='Phone'
                required={true}
                name='phoneNumber'
                Change={handleChangePhone}
                submitClicked={submitClicked}
                value={userData?.phoneNumber}
              />
              {(!userData?.phoneNumber || userData?.phoneNumber == "+") && submitClicked &&
                <span className='text-red-500 text-sm'>{'This field is required'}</span>}
            </div>

          </div>

          <div className='form-item md:w-3/4'>
            <InputField
              label='Website URL'
              name='webSite'
              required={true}
              placeholder='Website URL'
              Change={handleChange}

              submitClicked={submitClicked}
              value={userData?.webSite}
              error={!userData?.webSite && submitClicked}
            />
          </div>

          <div>
            <div className="form-item md:w-3/4">
              <IndustriesUI required={true} setUserData={setUserData} Userindustries={userData?.industries} handleSelectChange={handleSelectChange} submitClicked={submitClicked} />
              {(!userData?.industries || userData?.industries.length == 0) && submitClicked &&
                <span className='text-red-500 text-sm'>{'This field is required'}</span>}
            </div>

            <div className='form-item  md:w-3/4'>
              <label>* Headquarter location</label>
              <Grid container columnSpacing='5'>
                <Grid item xs={12}>
                  <div className='form-item '>

                    <SelectInput
                      name="headquarterCountry"
                      required={true}
                      placeholder='Select Headquarter country'
                      options={countries}
                      Change={handleSelectChange}
                      loading={loadingCountries}

                      submitClicked={submitClicked}
                      value={headquarterCountry}
                    />

                    {!headquarterCountry && submitClicked &&
                      <span className='text-red-500 text-sm'>{'This field is required'}</span>}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className='form-item '>
                    <SelectInput
                      name="headquarterCity"
                      required={true}
                      placeholder='Select Headquarter city'
                      labelHint='Select your country first'
                      label="Select city (region/state/province options will appear with the city)"
                      options={cities}
                      Change={handleSelectChange}
                      loading={loadingCities}
                      submitClicked={submitClicked}
                      value={headquarterCity}
                      disabled={!headquarterCountry}
                    />
                    {!headquarterCity && submitClicked &&
                      <span className='text-red-500 text-sm'>{'This field is required'}</span>}
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className='form-item  md:w-3/4'>
              <InputField
                name="companySize"
                required={true}
                label='Number of employees'
                placeholder='ex:500'
                Change={handleChange}
                value={userData.companySize}
                type="number"
                submitClicked={submitClicked}
                error={!userData?.companySize && submitClicked}
              />
            </div>


            <div className="form-item md:w-3/4">
              <label>Company Branding</label>
              <div className='md:flex justify-between'>
                <p className='main-description md:w-1/2'>*Upload logo that will appear on your employer page.</p>

                <UploadeLogo newData={newData} />

              </div>
              <div className='md:flex justify-between mt-4'>
                <p className='main-description md:w-1/2'>Upload banner that will appear on your employer page. It can be a picture of your offices or background image to present your company.</p>
                <UploadeBanner newData={newData} />

              </div>
            </div>


            <div className='form-item md:w-3/4'>
              {/* <SelectInput placeholder='Country' required={true} multiple={false} options={[]} /> */}
              <SelectInput
                name="country"
                required={true}
                placeholder="Select your country"
                options={countries}
                Change={handleSelectContry}
                loading={loadingCountries}
                error={!userData?.country && submitClicked}
                submitClicked={submitClicked}
                defaultValue={userData.country}
                label="Select your country's office" />

            </div>


            <div className='form-item md:w-3/4' >
              <SelectInput
                name="city"
                required={true}
                placeholder="Select your city"
                labelHint=' Select country first'
                options={employerCities}
                Change={handleSelectChange}
                loading={loadingCities}
                error={!userData?.city && submitClicked}
                submitClicked={submitClicked}
                value={selectedCity}
                label="Select your city's office (region/state/province options will appear with the city)"
                disabled={!userData.country}
              />

            </div>

            <div className="form-item">


              <TextEditor
                label="*Background on company"
                maxLength={2200}
                name="background"
                Change={handleTextEditorChange}
                error={(userData.background === undefined || userData.background === '') && submitClicked}
                value={userData?.background}
                submitClicked={submitClicked} />
            </div>
            <div className='custom-action-fill my-4'>
              <CustomButton text="Change password" click={handleChangePassword} />
            </div>
            {open && <PasswordPopUp show={open} setOpen={setOpen} />}
            <div className='form-item '>
              <label>Company Media</label>
              {/* <SelectInput placeholder='Browse' required={false} multiple={false} options={[]} /> */}


              <UploadeMedia setSelectImage={setSelectImage} maximumUploadMedia={maximumUploadMedia} selectimage={selectimage} setSelectFiles={setSelectFiles} selectFiles={selectFiles} />

              {(newData?.subModel?.companyMedia?.length > 0 ||
                userInfo?.subModel?.companyMedia?.length > 0 || selectimage.length > 0) &&
                <Grid container spacing={3} mb={4}  >

                  {(newData?.subModel?.companyMedia?.length > 0 ||
                    userInfo?.subModel?.companyMedia?.length > 0) &&
                    newData?.subModel?.companyMedia?.length > 0 ?

                    newData?.subModel?.companyMedia.map((item: any, index: number) => {
                      return (
                        <>
                          {!toBeDeletedImages.includes(index) && <Grid item md={4} sm={12} className="w-full" key={index}>
                            <div className="relative border rounded-xl" onMouseEnter={() => { setHover(index); setSelected(2) }} onMouseLeave={() => { setHover(null); setSelected(null) }}>
                              {item.includes('video') ?

                                <video controls src={item} className="h-[250px]  w-full" />

                                :
                                <img src={item} className="h-[250px]  w-full" />}
                              <a className="absolute right-0 top-0 h-[fit-content] ml-auto bg-[#303030] text-white cursor-pointer" onClick={() => { removeMediaIndexs(index) }}>
                                <CloseIcon />
                              </a>
                            </div>
                          </Grid>
                          }
                        </>

                      )
                    })
                    : !userInfo?.newUpdate &&
                    userInfo?.subModel?.companyMedia?.map((item: any, index: number) => {
                      return (
                        <>
                          {!toBeDeletedImages.includes(index) && <Grid item md={4} sm={12} className="w-full" key={index}>
                            <div className="relative border rounded-xl" onMouseEnter={() => { setHover(index); setSelected(2) }} onMouseLeave={() => { setHover(null); setSelected(null) }}>
                              {item.includes('.jpeg') || item.includes('.gif') || item.includes('.png') || item.includes('.jpg') ?
                                <img src={item} className="h-[250px]  w-full" /> :
                                <video src={item} className="h-[250px]  w-full" controls />
                              }
                              <a className="absolute right-0 top-0 h-[fit-content] ml-auto bg-[#303030] text-white cursor-pointer" onClick={() => { removeMediaIndexs(index) }}>
                                <CloseIcon />
                              </a>
                            </div>
                          </Grid>
                          }
                        </>

                      )
                    })

                  }
                  {selectimage.length > 0 &&
                    selectimage.map((item: any, index: number) => {
                      return (
                        <Grid item md={4} sm={12} className="w-full" key={index}>
                          <div className="relative border rounded-xl" onMouseEnter={() => { setHover(index); setSelected(1) }} onMouseLeave={() => { setHover(null); setSelected(null) }}>
                            {item.includes('video') ?

                              <video controls src={item} className="h-[250px]  w-full" />

                              :
                              <img src={item} className="h-[250px]  w-full" />}
                            {/* <div className={`inset-0 absolute bg-[#0000006e] ${hover === index && selected === 1 ? 'flex' : "hidden"}  z-50`}>
                             
                            </div> */}
                            <a className="absolute right-0 top-0 h-[fit-content] ml-auto bg-[#303030] text-white cursor-pointer" onClick={() => { removeMedia(index) }}>
                              <CloseIcon />
                            </a>
                          </div>
                        </Grid>
                      )
                    })}
                </Grid>
              }


              {/* {selectimage.length === 0 &&
                (newData?._id ?
                  (newData?.subModel?.companyMedia === 'undefined' || newData?.subModel?.companyMedia?.length === 0) ?
                    <div className="bg-light-green pb-4 border rounded-xl">
                      <img src={MediaPlacement} className="w-full h-[350px]" alt="media" />
                      <p className="text-center text-xl text-[#F26400]">No media added by {userInfo?.subModel?.name} yet</p>
                    </div> : ''
                  :
                  (userInfo?.subModel?.companyMedia === 'undefined' || userInfo?.subModel?.companyMedia?.length === 0) &&
                  <div className="bg-light-green pb-4 border rounded-xl">
                    <img src={MediaPlacement} className="w-full h-[350px]" alt="media" />
                    <p className="text-center text-xl text-[#F26400]">No media added by {userInfo?.subModel?.name} yet</p>
                  </div>
                )

              } */}

            </div>

            <SocialMediaUI userInfo={newData?._id ? newData : userInfo} setUserData={setUserData} socialMediaLinksList={socialMediaLinksList} setSocialMediaLinksList={setSocialMediaLinksList} />
          </div>
          <div className='actions-end'>
            <CustomizedButtons text='Submit for review' click={handleSubmit} loading={loadingSubmit} disabled={textLength + 1 > 2200} />
          </div>

        </form>
      }
    </div>
  );
}
