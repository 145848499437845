

import { Box, Container, Grid, TextField, Typography, Button } from '@mui/material';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNumberOfInstitutionUsersViews, getNumberOfUsersFollowedInstitution, getNumberOfUsersHiredInstitution } from '../../../services/analyticsService';

// icons 
import VisibilityIcon from '@mui/icons-material/Visibility';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import { DatePicker } from '@mui/x-date-pickers';
import { NumberBox } from '../../../Components/AnalysicBox/numberBox';
import moment from 'moment';
import TaskIcon from '@mui/icons-material/Task';
import LatestInteractionsWithEmployers from './components/latest-orders';
import  StudentsRecentlyHired  from './components/studentsRecentlyHired';
import LastMentorshipsCompleated from './components/LastMentorshipsCompleated';
import LatestActiveSessions from './components/latest-active-sessins';
import DialogModalInstite from '../PopUp';

export default function Dashboard() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 30)

  const [startDate, setStartDate] = useState<any>(new Date(yesterday));
  const [endDate, setEndDate] = useState<any>(new Date());
  const [data, setData] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(true)
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const numberOfUsersViews = async () => {
    const response = await getNumberOfInstitutionUsersViews(user._id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersViews': response?.data?.data }))
    }
  }

  const handleOpenDialog = () => {
    setOpenPopup(true)
  }

  const numberOfUsersFollowedInstitution = async () => {
    const response = await getNumberOfUsersFollowedInstitution(user._id)
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersFollowedInstitution': response?.data?.data }))
    }
  }
  const numberOfUsersHiredInstitution = async () => {
    const response = await getNumberOfUsersHiredInstitution(user._id)
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount }))
    }
  }
  useEffect(() => {
    if (token) {
      numberOfUsersViews()
    }
  }, [token, endDate, startDate]);

  useEffect(() => {
    if (token) {
      numberOfUsersFollowedInstitution()
      numberOfUsersHiredInstitution()
    }
  }, [token]);
  return (
    <div className="m-t-50 m-b-50">
      <Container>
        <div className='flex'>
          <h3 className='title'>Dashboard</h3>
          <div className='w-1/2'>
            <Grid item md={12} className='flex gap-2'>
              <div className='form-item w-1/2'>

                <DatePicker
                  label="From"
                  value={startDate}
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}

                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                />

              </div>
              <div className='form-item w-1/2'>

                <DatePicker
                  label="To"
                  value={endDate}
                  minDate={startDate}
                  onChange={(newValue: any) => {
                    setEndDate(newValue);
                  }}

                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                />
              </div>

            </Grid>
          </div>
        </div>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={4}
                sm={4}
                xl={4}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersViews || 0} text="Users views" icon={<VisibilityIcon />} color="green" />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                sm={4}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersFollowedInstitution || 0}
                  text="Users followed Institution"
                  icon={<FollowTheSignsIcon />}
                  color="blue" />

              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                sm={4}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersHiredInstitution || 0}
                  text="Users hired"
                  icon={<TaskIcon />}
                  color="orange" />

              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                sm={12}
                xs={12}
              >
                {/* <LatestOrders/> */}
                <StudentsRecentlyHired />
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                sm={12}
                xs={12}
              >
                <LatestInteractionsWithEmployers />

              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                sm={12}
                xs={12}
              >
                {/* <LatestOrders/> */}
                <LastMentorshipsCompleated />
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                sm={12}
                xs={12}
              >
                <LatestActiveSessions />

              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
      {
        openPopup && (
            <DialogModalInstite open={openPopup} setOpenPop={setOpenPopup} />
        )
      }
      {
        openModal && (
            <Container id="ToolTip">
            <div className="helpContainer">
              <Typography className="guidance">Do you need guidance on using our platform?</Typography>
              <Button className="button" onClick={handleOpenDialog}>
                Yes
              </Button>
              <Button className="button" onClick={() => setOpenModal(false)}>No, thanks</Button>
              <Button className="closeButton" onClick={() => setOpenModal(false)}>&times;</Button>
            </div>
            </Container>
        )
      }
    </div>
  )
}
