import { Container } from "@mui/system";
import React from "react";
import BasicTabs from "./Tabs";
import style from "./access.module.scss";

export default function MentorshipProgram() {
  return (
    <Container>
      <div className={style["page"]}>
        
        <BasicTabs />
      </div>
    </Container>
  );
}
