
import styles from "./list.module.scss";
import Carousel from "react-multi-carousel";
import Post, { CardLoadingSkeleton } from "./Post";
import NoDataMessage from "../NoDataMessage";

interface CarouselListProps {
    defaultCount?: number | null,
    list?: any
    loading?: boolean
    listAllData?:any
}
export default function CarouselList(props: CarouselListProps) {
   
    const { defaultCount, list, loading,listAllData } = props

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: defaultCount ? defaultCount : 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: defaultCount ? defaultCount : 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
    return (
        <div className={[styles.Carousel_list, defaultCount === 1 && styles.Carousel_list_small].join(" ")
        }>
            <Carousel
                autoPlaySpeed={3000}
                centerMode={false}
                focusOnSelect={false}
                infinite
                keyBoardControl
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<CustomButtonGroup />}
                responsive={responsive}
                itemClass={styles.itemCarousal}
                containerClass="container py-2"
                arrows={false}

            >
                
                {loading ?
                    [1, 2, 3].map((item: any) => {
                        return (
                            
                                <CardLoadingSkeleton />
                        
                        )
                    })
                    :
                    list && list.length > 0 ?
                        list.map((item: any,i:number) => {
                            return (
                                <Post data={item} index={i} listAllData={listAllData}/>
                            )
                        })

                        :
                    <></>
                }
            </Carousel>
            { !loading&&list?.length ==0&& <NoDataMessage/>}
        </div >

    );
}
const CustomButtonGroup = ({ next, previous }: any) => {
    return (
        <>
            <div className={styles.arrow}>
                <button className={styles.left} type="button" onClick={() => previous()}>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.32">
                            <path d="M13 25.6379C19.6274 25.6379 25 20.2654 25 13.6379C25 7.01052 19.6274 1.63794 13 1.63794C6.37258 1.63794 1 7.01052 1 13.6379C1 20.2654 6.37258 25.6379 13 25.6379Z" stroke="#6A6A6A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13 8.83789L8.20001 13.6379L13 18.4379" stroke="#6A6A6A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.8 13.6379L8.20001 13.6379" stroke="#6A6A6A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                    </svg>

                </button>
                <button className={styles.right} type="button" onClick={() => next()}>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.32">
                            <path d="M13 25.6379C19.6274 25.6379 25 20.2654 25 13.6379C25 7.01052 19.6274 1.63794 13 1.63794C6.37258 1.63794 1 7.01052 1 13.6379C1 20.2654 6.37258 25.6379 13 25.6379Z" stroke="#6A6A6A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13 8.83789L8.20001 13.6379L13 18.4379" stroke="#6A6A6A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.8 13.6379L8.20001 13.6379" stroke="#6A6A6A" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                    </svg>

                </button>

            </div>
        </>
    );
};
