import React, { useState } from "react";

import { Avatar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../../../../Components/CustomButton";
import ImageCrop from "../../../../../../Components/ImageUpload";
import { UpdateInstitutionProfile } from "../../../../../../services/institution/updateInstitution";
import { addAlertInfo } from "../../../../../../store/alertSlice";
import { UpdateUserProfile } from "../../../../../../services/authService";

export const UploadeLogo = (parms:any) => {
    const {newData}=parms
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [image, setSelectimage] = useState('')
    const [loading, setLoading] = useState(false)
    const user = useSelector((state: any) => state.auth.user);
    const formData = new FormData()
    const handleSubmit = (selectimage: any, imagefile: any) => {
        setSelectimage(selectimage)
        setLoading(true)
        formData.append("logo", imagefile);
        updateUserProfile()
    }
    const updateUserProfile = async () => {
        dispatch(addAlertInfo(
            {
                show: false,
            }
        ))
        const response = await UpdateUserProfile(user?._id, formData)
        if (response.status === 200) {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response?.data?.data?.message,
                }
            ))
        }
        else {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: 'error'
                }
            ))
            setSelectimage('')
        }
    }
    return (
        <div className="flex items-center justify-end space-x-4">
            {show &&

                <ImageCrop show={show} setOpen={setShow} type='image' handleSubmit={handleSubmit} />
            }
            
            {(newData?.subModel?.logo|| user?.subModel?.logo ||image)&&
                <div className="relative">
                    <Avatar className="object-contain"  src={image ? image : newData?.subModel?.logo|| user?.subModel?.logo} sx={{ width: '50px', height: "50px" }}  >
                    </Avatar>
                </div>
            }
            <CustomButton text='Upload logo' click={() => setShow(true)} loading={loading} />
        </div>
    );
}

export default UploadeLogo;


