
import React, { useEffect, useState } from "react";
import CustomizedButtons from "../../../../../Components/CustomButton";
import InputField from "../../../../../Components/Input";
import SelectInput from "../../../../../Components/SelectInput";
import { useDispatch, useSelector } from "react-redux";
import { GetEducationLevelById, listAllEducationLevel } from "../../../../../services/educationLevelService";
import { CircularProgress, Grid, TextField } from "@mui/material";
import MajorsSelection from "../../../../../Components/ItemSelect/Majors";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { UpdateUserProfile } from "../../../../../services/authService";
import { addAlertInfo } from "../../../../../store/alertSlice";

interface ProjectFormProps {
  isShown: boolean;
  onCancel: any;
  getUserData: any
  selectedEducation: number
}
export default function SchoolForm(props: ProjectFormProps) {
  const dispatch = useDispatch()
  const { isShown, onCancel, getUserData, selectedEducation } = props;
  const user = useSelector((state: any) => state.auth.user);

  const token = useSelector((state: any) => state.auth.token);
  const [educationLevels, setEducationLevels] = useState([]);
  const [loadingEducationLevels, setLoadingEducationLevels] = useState(true);
  const [fieldsToValidate, setFieldsToValidate] = useState<Array<string>>([]);
  const [loadingEducationLevelsFailds, setLoadingEducationLevelsFailds] = useState(false);
  const [school, setSchool] = useState<any>({});
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const getAllEducationLevel = async () => {
    const response = await listAllEducationLevel()
    if (response) {
      const educationLevels = response?.data?.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id, 'educationLevelGroup': item?.educationLevelGroup }))
      setEducationLevels(educationLevels)
      setLoadingEducationLevels(false)
    }
  }
  const getEducationLevelById = async (id: string) => {
    setFieldsToValidate([])
    setLoadingEducationLevelsFailds(true)
    const response = await GetEducationLevelById(id)
    if (response) {
      setFieldsToValidate(response.data.data.fieldsToValidate)
      setLoadingEducationLevelsFailds(false)

    }
  }
  const handleSelectChange = (e: any) => {

    if (e) {
      setSchool((_data: any) => ({ ..._data, [e.name]: e.value }));
      if (e.name === 'educationLevel') {
        getEducationLevelById(e.value.educationLevelGroup)
      }
    }

  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'gpa') {
      setSchool((_data: any) => ({ ..._data, [e.target.name]: parseFloat(e.target.value) }));
    }
    else {
      setSchool((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
    }

  };
  useEffect(() => {
    if (token) {
      getAllEducationLevel()
    }
  }, [token]);
  useEffect(() => {
    if (user) {
      if (selectedEducation !== null) {
        getEducationLevelById(user.subModel.education[selectedEducation].educationLevel.educationLevelGroup)
        setSchool(
          {
            'university': user.subModel.education[selectedEducation].university,
            'educationLevel': { 'label': user.subModel.education[selectedEducation].educationLevel.nameEn, 'id': user.subModel.education[selectedEducation].educationLevel._id },
            'gpa': user.subModel.education[selectedEducation].gpa,
            'minor': user.subModel.education[selectedEducation].minor,
            'startingDate': moment(user.subModel.education[selectedEducation]?.startingDate),
            'graduationDate': user.subModel.education[selectedEducation]?.graduationDate ? moment(user.subModel.education[selectedEducation]?.graduationDate) : null,
            'endingDate': moment(user.subModel.education[selectedEducation]?.endingDate),
            'major': user?.subModel.education[selectedEducation]?.major?{ 'label': user?.subModel.education[selectedEducation]?.major?.nameEn, 'id': user.subModel.education[selectedEducation]?.major?._id }:null
          }
        )
        setLoadingData(false)

      }
      else {
        // setSchool((_data: any) => ({ ..._data, 'startingDate': moment(new Date()).format('yyyy-MM-DD') }));
        // setSchool((_data: any) => ({ ..._data, 'endingDate': moment(new Date()).format('yyyy-MM-DD') }));
        setLoadingData(false)
      }
    }


  }, [user]);

  const handleClick = () => {

    setErrors(false)
    if (school.university && school.educationLevel) {
      // for (let i = 0; i < fieldsToValidate.length; i++) {
      //   if (!school[fieldsToValidate[i]]) {
      //     setErrors(true)
      //   }
      //   else {
      //     setErrors(false)
      //     if (i === fieldsToValidate.length - 1) {
      //       UpdateUserInfo()
      //     }
      //   }
      // }
      UpdateUserInfo()
    }
    else {
      setErrors(true)
    }
  }
  const handleCancel = () => {
    onCancel()
  }

  const UpdateUserInfo = async () => {
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))
    setLoading(true)
    const Updateddata: any = {}
    const data = [...user.subModel['education']]
    if (selectedEducation !== null) {
      data.splice(selectedEducation, 1)
    }

    Updateddata['education'] = data.map((item: any) => (
      {
        'university': item.university,
        'educationLevel': item.educationLevel._id,
        'gpa': item.gpa,
        'startingDate': moment(item?.startingDate).format('yyyy-MM-DD'),
        'endingDate': moment(item?.endingDate).format('yyyy-MM-DD'),
        'graduationDate': item?.graduationDate ? moment(item?.graduationDate).format('yyyy-MM-DD') : null,
        'major': item?.major?._id
      }

    ))
    const updatedSchool = {
      'university': school.university,
      'educationLevel': school.educationLevel.id ? school.educationLevel.id : school.educationLevel,
      'gpa': school.gpa,
      'startingDate': school.startingDate,
      'minor': school.minor,
      'endingDate': school.endingDate,
      'graduationDate': school?.graduationDate,
      'major': school?.major?.id ? school?.major?.id : school?.major
    }
    Updateddata['education'].push(updatedSchool)
    const formData = new FormData()
    for (const property in Updateddata) {
      if (typeof Updateddata[property] === 'string' || Updateddata[property] === 'boolean') {
        formData.append(property, Updateddata[property]);
      }
      else {
        formData.append(property, JSON.stringify(Updateddata[property]));
      }
    }
    const response = await UpdateUserProfile(user._id, formData)
    if (response.status === 200) {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
      handleCancel()
      getUserData()
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  return (
    <div>
      {isShown && (
        !loadingData ?
          <form>
            <p className=" required my-1">Fields marked with an * are required</p>
            <div className="form-item">
              <InputField
                label='School Name'
                name='university'
                required={true}
                placeholder='School name'
                Change={handleChange}
                defaultValue={school.university}
                error={errors && !school.university}
              />
            </div>
            <div className="form-item">
              <SelectInput
                label={'Level of education'}
                required={true}
                multiple={false}
                placeholder='Select'
                options={educationLevels}
                loading={loadingEducationLevels}
                name="educationLevel"
                Change={handleSelectChange}
                error={errors && !school.educationLevel}
                defaultValue={school.educationLevel}
              />

            </div>
            {loadingEducationLevelsFailds ?
              <div className="flex justify-center">
                <CircularProgress size="30px" sx={{ color: '#F26400' }} />
              </div>
              :
              <>
                <div className="form-item">
                  <MajorsSelection
                    handleChange={handleSelectChange}
                    label={"Area of study"}
                    multiple={false}
                    name={"major"}
                    required={fieldsToValidate.includes('major')}
                    error={errors && !school.major}
                    defaultValue={school.major}
                  />
                </div>

                {fieldsToValidate.includes('minor') && <div className="form-item">

                  <InputField
                    name="minor"
                    label={'Minor/Concentration'}
                    required={false}
                    Change={handleChange}
                    error={errors && !school.minor}
                    defaultValue={school.minor}
                    placeholder={"Minor"} />
                </div>

                }
                {fieldsToValidate.includes('startingDate')
                  && <div className="mt-6 form-item">
                    <Grid container columnSpacing='5'>
                      <Grid item xs={6} >
                        <DatePicker

                          label="From"
                          value={school.startingDate}
                          onChange={(newValue) => {
                            setSchool((_data: any) => ({ ..._data, 'startingDate': moment(newValue).format('yyyy-MM-DD') }));
                          }}
                          renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                        />

                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker

                          label="To"
                          value={school.endingDate}
                          onChange={(newValue) => {
                            setSchool((_data: any) => ({ ..._data, 'endingDate': moment(newValue).format('yyyy-MM-DD') }));
                          }}
                          renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                        />
                      </Grid>
                    </Grid>
                  </div>
                }
                {fieldsToValidate.includes('graduationDate')
                  && <div className="mt-6 form-item">
                    <label>Graduation date</label>
                    <DatePicker
                      // label="Graduation day"
                      value={school?.graduationDate || null}
                      onChange={(newValue) => {
                        setSchool((_data: any) => ({ ..._data, 'graduationDate': moment(newValue).format('yyyy-MM-DD') }));
                      }}
                      renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                    />


                  </div>
                }
                {fieldsToValidate.includes('gpa') &&
                  <div >
                    <InputField
                      label="GPA"
                      required={false}
                      placeholder='GPA'
                      multiline={false}
                      name='gpa'
                      type="number"
                      Change={handleChange}
                      error={errors && !school.gpa}
                      max={4}
                      value={school.gpa}
                    />

                  </div>
                }
              </>

            }
            <div className="flex small-btn justify-end my-6 gap-x-4">
              <CustomizedButtons text="Cancel" click={handleCancel} />
              <CustomizedButtons text="Save" click={handleClick} loading={loading} />
            </div>
          </form>
          :
          <div className="flex justify-center">
            <CircularProgress size="30px" sx={{ color: '#F26400' }} />
          </div>
      )}
    </div>
  );
}
