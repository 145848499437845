

import React, { useEffect, useState } from "react";
import SelectInput from "../../../../../Components/SelectInput";
import InputField from "../../../../../Components/Input";
import CustomizedButtons from "../../../../../Components/CustomButton";
import { Chip, Stack } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
interface QuestionProps {
    setJob: any
    job: any
}
export default function Questions(props: QuestionProps) {
    const { setJob, job } = props
    const [questionsType, setQuestionsType] = useState('text')
    const [question, setQuestion] = useState('')
    const [questionAnswers, setQuestionAnswers] = useState<any>([])
    const [questions, setQuestions] = useState<any>([])
    const [selectedAnswer, setSelectedAnswer] = useState('')

    const handleSelectChangeQuestionType = (e: any) => {
        setQuestionsType(e.value) 
    }
    const handleDeleteQuestion = (i: any) => {
        const arr = [...questions]
        arr.splice(i, 1)
        setQuestions(arr)
    }
    const RemoveAnswer = (i: any, answerIndex: number) => {
        const arr = [...questions]
        const question = arr[i]
        if (question.questionChoices.length == 1) {
            arr.splice(i, 1)
        }
        else {
            question.questionChoices.splice(answerIndex, 1)
            arr[i] = question
        }


        setQuestions(arr)
    }

    const handleSelectAnswer = () => {
        const arr = [...questionAnswers]
        arr.push(selectedAnswer)
        setQuestionAnswers(arr)
        setSelectedAnswer('')
    }

    const handleAddClick = () => {
        const arr = [...questions]
        let q: any = {}
        if (questionsType === 'choice') {
            q = {
                question: question,
                questionType: questionsType,
                questionChoices: questionAnswers
            }
        }
        else {
            q = {
                question: question,
                questionType: questionsType,

            }
        }
        setQuestion('')
        setSelectedAnswer('')
        setQuestionAnswers([])
        arr.push(q)
        setQuestions(arr)

    }
    useEffect(() => {

        if (questions.length > 0) {
            setJob((_data: any) => ({ ..._data, 'questions': questions }));
        }

    }, [questions])
    useEffect(() => {
        if (job?.questions?.length > 0) {
            setQuestions(job?.questions)

        }


    }, [job?.questions?.length])

    return (
        <div>
            <label>Questions</label>
            <div className="block md:flex md:space-x-4">
                <div className="w-full md:w-1/4">
                    <SelectInput
                    disableClearable={true}
                        required={true}
                        label='Question type'
                        multiple={false}
                        options={["choice", "text"]}
                        placeholder="Select"
                        name="questionType"
                        defaultValue={questionsType}
                        Change={handleSelectChangeQuestionType}
                    />
                </div>
                <div className="w-full md:w-1/4">
                    <InputField
                        value={question}
                        label="Question"
                        required={true}
                        placeholder="Add Question"
                        Change={(e: any) => setQuestion(e.target.value)}
                    />
                </div>
                {questionsType === 'choice' && <div className="w-full md:w-1/2 ">
                    <InputField
                        value={selectedAnswer}
                        label="Answer"
                        required={false}
                        placeholder="Add Answer"
                        Change={(e: any) => setSelectedAnswer(e.target.value)}
                    />
                    <Stack direction="row" gap={1} className="flex-wrap mt-4">

                        {questionAnswers?.map((answer: any, index: number) => {
                            return (
                                <Chip label={answer} />
                            )
                        })}

                    </Stack>
                    <div className="small-btn flex justify-end mt-2">
                        <CustomizedButtons disabled={!selectedAnswer} text={"Add answer"} click={handleSelectAnswer} />
                    </div>
                </div>}
            </div>
            <div className="small-btn flex justify-end mt-2">

                <CustomizedButtons text={"Add this Question"} click={handleAddClick}
                    disabled={questionsType === 'choice' ? (!question || questionAnswers.length == 0) : !question} />
            </div>
            {/* {questions?.length > 0 &&
                <ul className="mt-4">
                    {questions?.map((question: any) => {
                        return (
                            <li className="block border p-2 mb-2 rounded-xl">
                                <span className="block">Q: {question.question} </span>
                                {question?.questionChoices?.length > 0 &&
                                    <ol className="block mt-4 list-decimal px-6">
                                        {question?.questionChoices?.map((answer: any, index: number) => {
                                            return (
                                                <li>{answer} </li>
                                            )
                                        })}
                                    </ol>
                                }
                            </li>
                        )
                    })}

                </ul>
            } */}
            {job?.questions?.length > 0 &&
                <ul className="mt-4">
                    {job?.questions?.map((question: any, index: number) => {
                        return (
                            <li className="block border p-2 mb-2 rounded-xl">

                                <div className="flex justify-between">

                                    <span className="block">Q: {question.question}
                                    </span>
                                    <div className='icon flex justify-end cursor-pointer' ><DeleteIcon onClick={() => handleDeleteQuestion(index)} sx={{ width: 18, height: 18 }} /></div>

                                </div>
                                {question?.questionChoices?.length > 0 &&
                                    <div className="block mt-4 list-decimal px-6 space-x-2">
                                        {question?.questionChoices?.map((answer: any, answerIndex: number) => {
                                            return (
                                                <Chip label={answer}
                                                    onDelete={() => RemoveAnswer(index, answerIndex)}
                                                    deleteIcon={<RemoveIcon />} />
                                            )
                                        })}
                                    </div>
                                }
                            </li>
                        )
                    })}

                </ul>
            }
        </div>
    );
}
