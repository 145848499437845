import { useState } from "react";
import CustomizedButtons from "../../../../Components/CustomButton";
import JobApply from "../../JobApply";
import DropDown from "../components/DropDown";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import styles from "./roleInfo.module.scss";
import Moment from "react-moment";
interface JobInfoProps {
  nested?: boolean
  loading: boolean
  selectedJob: any
}
const RoleInfo = (props: JobInfoProps) => {
  const { loading, nested, selectedJob } = props
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  return (
    <>
      {loading ?
        <RoleInfoLoading nested={nested} /> :

        <div className={[styles["header"]].join(' ')}>
          <h4 className="colored secondary-title">About the role</h4>
          <div className={`grid ${nested ? 'grid-rows-2' : 'grid-rows-2'} grid-cols-2 lg:grid-cols-3 md:grid-cols-3 pb-4  gap-y-4  `}>
            <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1">Application Deadline</h5>
              <p className="text-base text-sm"><Moment format="MMMM DD, YYYY">{selectedJob.deadLine}</Moment> </p>
            </div>
            <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1 ">Date Posted</h5>
              <p className=" text-base text-sm"><Moment format="MMMM DD, YYYY">{selectedJob.createdAt}</Moment> </p>
            </div>
            <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1 ">Start and End Date</h5>
              <p className=" text-sm"><Moment format="MMMM DD, YYYY">{selectedJob.startDate}</Moment> to <Moment format="MMMM DD, YYYY">{selectedJob.endDate}</Moment></p>
            </div>
            <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1 ">Type</h5>
              <p className="text-base text-sm ">
               <span className="capitalize">{selectedJob.applicationType}
                </span>  application
              </p>
            </div>
            <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1 "> Work Authorization</h5>
              <p className="text-base text-sm">{
              selectedJob?.workAuthorization == 0 ?
                'Will be provided for country' :
                selectedJob?.workAuthorization == 1 ? 'Yes required for country'
                  : 'No required for country'}</p>
            </div>
            <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
              <h5 className="font-semibold mb-1 ">Estimated Salary</h5>
              <p className="text-base text-sm">{selectedJob?.salary!=0?selectedJob?.salary?.toLocaleString() : 'Unpaid'} {selectedJob?.currency?.nameEn}</p>
            </div>
      
          </div>


        </div>
      }
    </>

  );
};
const RoleInfoLoading = (props: any) => {
  const { nested } = props

  return (
    <div className={[styles["header"], 'pb-4 animate-pulse'].join(' ')}>
      <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 mb-2"></div>
      <div className={`grid ${nested ? 'grid-rows-2' : 'grid-rows-2'} grid-cols-2 lg:grid-cols-3 md:grid-cols-3 pb-4  gap-y-4  `}>
        <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
          <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
          <div className="h-2 bg-gray-200 rounded col-span-1  mb-2"></div>
        </div>
        <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
          <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
          <div className="h-2 bg-gray-200 rounded col-span-1  mb-2"></div>
        </div>
        <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
          <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
          <div className="h-2 bg-gray-200 rounded col-span-1  mb-2"></div>
        </div>
        <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
          <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
          <div className="h-2 bg-gray-200 rounded col-span-1  mb-2"></div>
        </div>
        <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
          <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
          <div className="h-2 bg-gray-200 rounded col-span-1  mb-2"></div>
        </div>
        <div className={[styles["info"], nested ? 'm-all-10' : 'm-x-10'].join(' ')} >
          <div className="h-4 bg-gray-200 rounded col-span-1  mb-2"></div>
          <div className="h-2 bg-gray-200 rounded col-span-1  mb-2"></div>
        </div>
      </div>


    </div>
  );
};
export default RoleInfo;
