import axios from '../axios';
import { BASEURL } from "../environments/environment";

export const listAlljobs = (props: any) => {
  const { currentPage, pageSize, searchTerm, filter,filterMajor,filterIndustry} = props;
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  const search = searchTerm ? `&searchTerm=${searchTerm}` : "";
  const Filteration = filter ? `&${filter.type}=${filter.id}` : "";
  const FilterationMajor = filterMajor ? `&major=${filterMajor.id}` : "";
  const FilterationIndustry = filterIndustry ? `&industry=${filterIndustry.id}` : "";
  return axios
    .get(`${BASEURL}job${paging}${search}${Filteration}${FilterationMajor}${FilterationIndustry}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const listRecentJobs = (props: any) => {
  const { currentPage, pageSize, searchTerm, filter } = props;
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  const search = searchTerm ? `&searchTerm=${searchTerm}` : "";
  const Filteration = filter ? `&${filter.type}=${filter.id}` : "";
  return axios
    .get(`${BASEURL}job/recent${paging}${search}${Filteration}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const listJobsMajor = (
  id: string,
  currentPage=0,
  pageSize?: number
) => {
  const paging =
     currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}job/major${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const listjobsByMajor = (
  id: any,
  selectedJobId?:any,
  currentPage=0,
  pageSize?: number
) => {
  const paging =
     currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}job/major/${id}${paging}${`&${selectedJobId?`currentJob=${selectedJobId}`:''}`}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const SingleGobs = (id: any,userId:string,isCompany?:any) => {
  return axios
    .get(`${BASEURL}job/${id}${!isCompany?`?userId=${userId}&save=true`:''}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const deleteSingleGobs = (id: any) => {
  return axios
    .delete(`${BASEURL}job/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const ListAllWorkType = () => {
  return axios
    .get(`${BASEURL}workType`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const ListAllJobsType = () => {
  return axios
    .get(`${BASEURL}jobType`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const ApplayToJob = (id:string,data: any) => {
  return axios
    .post(`${BASEURL}application?userId=${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const ListSavedJobs = (id: string,populate=false) => {
  return axios
    .get(`${BASEURL}student/saved_jobs/${id}${populate?`?populate=true`:""}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const SaveJob = (id: string,userId:string) => {
  return axios
    .patch(`${BASEURL}student/save_job?userId=${userId}`, { 'savedJobs': id })
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const ListAllTypeOfWork = () => {
  return axios
    .get(`${BASEURL}typeOfWork`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const ListAllJobRole = () => {
  return axios
    .get(`${BASEURL}jobRole`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

