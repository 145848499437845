import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CustomizedButtons from "../../../../Components/CustomButton";

interface HeaderProps {
  institution?:any
}
export default function InistitutionHeader(props:HeaderProps) {
  const userInfo = useSelector((state: any) => state.auth.user);
  const parms = useParams()
  const { id } = parms
 const {institution}=props
  const [isFollow,setIsFollow]=useState(false)
  const handleIsFollowAction=()=>{
    setIsFollow(!isFollow)
  }
  return (
    <div className="">
      <div
        style={{ backgroundImage: `url(${institution?.subModel?.banner})` }}
        className="bg-no-repeat bg-cover h-80 bg-center"
      ></div>
      <div className="md:flex items-center px-4 w-full -my-6 header-underline">
        <Avatar
          sx={{ width: 120, height: 120 }}
          src={`${institution?.subModel?.logo?institution?.subModel?.logo:'/broken-image.jpg'}`}
        
        />

        <h4 className="title mx-4 min-w-[200px] ">
       {institution?.subModel?.name}
        </h4>
        {userInfo?._id!=id&&<div className={`actions-center rounded-action custom-action ${isFollow&&'custom-action-fill'} w-full`}>
         
         <CustomizedButtons 
          text={!isFollow?'Follow':"Following"} 
          click={handleIsFollowAction}/> 
       </div>}
        {/* <div className="actions-end rounded-action custom-action w-full">
          <CustomizedButtons text="Follow this company"  />
        </div> */}
      </div>
    </div>
  );
}
