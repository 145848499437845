import { Container, Pagination } from "@mui/material";
import styles from "./posts.module.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { listjobsByMajor } from "../../../../services/jobsService";
import { Job } from "../../../../models/job";
import Post, { CardLoadingSkeleton } from "../../../../Components/CardsList/Post";
interface PostsProps { }

const Posts = (props: PostsProps) => {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const majorId = user?.subModel?.major?._id
  const [jobs, setJobs] = useState<Array<Job>>([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)
  const getGobs = async () => {
    setLoading(true)
    const response = await listjobsByMajor(majorId,'', currentPage)
    if (response) {
      setJobs(response.data.data.queryResult)
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9))
      setLoading(false)
    }
  }
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  useEffect(() => {
    if (token) {
      getGobs()
    }
  }, [token, currentPage]);
  return (
    <Container>
      <div className="m-b-50 main-b-border pb-6 header-underline">
        <div className='title'>Recommended jobs for you</div>
        <p className='main-description'>These are jobs you might be interested in</p>
      </div>

      <div className={styles.jobs_list}>
        <div className="gap-4 space-y-4 columns-3">
          {!loading ?
            jobs && jobs.length > 0 &&
            jobs.map((item: any, i: number) => {
              return (
              <div key={i}>

                <Post data={item} index={i} />
                </div>
              )
            })
            :
            [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any) => {
              return (
                <div key={item}>
                <CardLoadingSkeleton />
                </div>
              )
            })}
        </div>

      </div>
      {totlaPages > 1 &&
        <div className="flex justify-end my-6">
          <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      }
    </Container>
  );
};

export default Posts;
