import React, { ChangeEvent, useEffect, useState } from 'react'
import { Container } from '@mui/system'
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import styles from './account-setting.module.scss'
import CustomButton from '../../../Components/CustomButton';
import PasswordPopUp from './components/passwordPopup';
import InputField from '../../../Components/Input';
import SelectInput from '../../../Components/SelectInput';
import { useDispatch, useSelector } from 'react-redux';
import { listAllLanguages } from '../../../services/languageService';
import { Language } from '../../../models/language';
import { listAllEducationLevel } from '../../../services/educationLevelService';
import { listAllAreaInterest } from '../../../services/areaInterestservice';
import { listAllMajor } from '../../../services/majorsServise';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UpdateUser, UpdateUserProfile } from '../../../services/authService';
import moment from 'moment';

import { addAlertInfo } from '../../../store/alertSlice';
import UploadeProfileImage from './components/uploadeProfileImage';
import { listAllCountries, listAllCities } from '../../../services/locationService';
import { validatePasswordTrue } from '../../../helpers/validation';


export default function AccountSetting() {
    const validateList = ['firstName', 'lastName', 'email', 'fluentLanguage', 'major', 'country', 'city']
   const validateListNames = ['First name', 'Last name', 'Email',  'Fluent language','Major', 'Country', 'City']
 
    const validateForm = () => {
        for (let i = 0; i < validateList.length; i++) {
            if (!userData[validateList[i]]) return false
        }
        if (userData?.secondaryEmail && (userData?.email == userData?.secondaryEmail)) return false
        return true
    }
    const getErrorMessage = () => {
        for (let i = 0; i < validateList.length; i++) {
            if (!userData[validateList[i]]) return validateListNames[i] + ' is required'
        }
        if (userData?.secondaryEmail && (userData?.email == userData?.secondaryEmail)) {

            return "You can't use your primary email as a secondary email"
        }
        return true
    }
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.auth.user);
    const token = useSelector((state: any) => state.auth.token);

    const [graduationMonth, setGraduationMonth] = useState<any>();
    const [graduationYear, setGraduationYear] = useState<any>();

    const handleChangePassword = () => {
        setOpen(true)
    }

    const [userData, setUserData] = useState<any>()

    const [userMajors, setUserMajors] = useState<any>()
    const [selectedCity, setSelectedCity] = useState<any>()
    const [privacy, setPrivacy] = useState('employer')
    const [radioValue, setRadioValue] = React.useState('');
    const [open, setOpen] = React.useState(false)
    const [loadingPage, setLoadingPage] = React.useState(true)
    const [Languages, setLanguages] = useState([]);
    const [majors, setMajors] = useState([]);
    const [educationLevels, setEducationLevels] = useState([]);
    const [allAreaInterest, setAllAreaInterest] = useState([]);
    const [loadingAreaInterest, setloadingAreaInterest] = useState(true);
    const [loadingLanguages, setLoadingLanguages] = useState(true);
    const [loadingMajors, setLoadingMajors] = useState(true);
    const [loadingEducationLevels, setLoadingEducationLevels] = useState(true);


    const [submitClicked, setSubmitClicked] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [loadingCountries, setLoadingCountries] = useState(true);
    const [loadingCities, setLoadingCities] = useState(false);

    // list Education Levels
    const getAllEducationLevel = async () => {
        const response = await listAllEducationLevel()
        if (response) {
            const educationLevels = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
            setEducationLevels(educationLevels)
            setLoadingEducationLevels(false)
        }
    }
    const getAllCountries = async () => {
        const response = await listAllCountries()
        if (response) {
            const countries = response.data.data.queryResult.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
            setCountries(countries)
            setLoadingCountries(false)
        }
    }

    const getAllCities = async (countryId: string) => {
        setLoadingCities(true)
        const response = await listAllCities(countryId)
        if (response) {
            const citiesOfCountry = response.data.data
            const cities = citiesOfCountry.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
            setCities(cities)
            setLoadingCities(false)
        }
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {

        if (e.target.name === "enrolledInHighSchool") {
            setRadioValue(e.target.value)
        }
        else {

            setUserData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));

        }
    };

    const handleSelectChange = (e: any) => {
        const isArray = Array.isArray(e.value);
        if (e.name == 'major') {
            setUserMajors(e.value)
        }
        // if(e.name=='country'){
        //     setSelectedCity(null)
        //     setUserData((_data: any) => ({ ..._data, 'city': null }));
        // }

        if (isArray) {
            setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.length > 0 ? e.value.map((item: any) => item.id) : null }));
        }
        else {
            setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.id || null }));
        }


    }

    // list All Languages
    const getAllLanguages = async () => {
        const response = await listAllLanguages()
        if (response) {
            const langs = response.data.data.queryResult.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
            setLanguages(langs)
            setLoadingLanguages(false)
        }
    }
    // list All Area Interest
    const getAllAreaInterest = async () => {
        const response = await listAllAreaInterest()
        if (response) {
            const allAreaInterest = response.data.data.queryResult.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
            setAllAreaInterest(allAreaInterest)
            setloadingAreaInterest(false)
        }
    }
    // list All Majors
    const getAllMajors = async () => {
        const response = await listAllMajor()
        if (response) {
            const majors = response.data.data.queryResult.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
            setMajors(majors)
            setLoadingMajors(false)
        }
    }
    const handleSubmit = async () => {
        const formData = new FormData()
        dispatch(addAlertInfo(
            {
                show: false,
                message: null,
            }
        ))
        setSubmitClicked(true)
        setLoadingSubmit(true)
        if (validateForm()) {
            formData.append("enrolledInHighSchool", radioValue === 'yes' ? 'true' : 'false')
            formData.append("accountVisibility", privacy)
            formData.append("graduationDate", graduationYear ? moment(graduationYear).format('yyyy') + '-' + moment(graduationMonth).format('MM-DD') : 'null')

            for (const property in userData) {
                if (userData[property] != null && property != "enrolledInHighSchool" && property != "accountVisibility" && property != "graduationDate") {

                    if (Array.isArray(userData[property])) {
                        formData.append(property, JSON.stringify(userData[property].map((item: any) => item.id ? item.id : item)));

                    }
                    else if (typeof (userData[property]) == 'object') {
                        formData.append(property, userData[property].id ? userData[property].id : userData[property]);
                    }
                    else {
                        formData.append(property, userData[property]);
                    }
                }
            }

            const res = await UpdateUserProfile(user._id, formData)
            if (res.status === 200) {
                dispatch(addAlertInfo(
                    {
                        show: true,
                        message: `Your information has been updated.`,
                    }
                ))

                setLoadingSubmit(false)
                // window.scrollTo(0, 0);
            }
            else {
                dispatch(addAlertInfo(
                    {
                        show: true,
                        message: res.data.error,
                        type: 'error'
                    }
                ))
                // setErrorMessage(res.data.error)
                setLoadingSubmit(false)
                // window.scrollTo(0, 0);
            }



        }
        else {
            setLoadingSubmit(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: getErrorMessage(),
                    type: 'error'
                }
            ))
        }

    }
    const handleSelectContry = (e: any) => {
        handleSelectChange(e)
        setSelectedCity(null)
        setUserData((_data: any) => ({ ..._data, 'city': null }));
        if (e.value) {
            getAllCities(e.value.id)
        }
        else {
            setCities([])
        }
    }
    useEffect(() => {
        if (token) {
            setLoadingPage(false)
            getAllLanguages()
            getAllMajors()
            getAllEducationLevel()
            getAllAreaInterest()
            getAllCountries()
            setRadioValue(user?.subModel?.enrolledInHighSchool ? 'yes' : 'no')
            setGraduationMonth(user?.subModel?.graduationDate)
            setGraduationYear(user?.subModel?.graduationDate)
            setPrivacy(user?.subModel?.accountVisibility)
            setUserMajors(user?.subModel?.major.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id })))
            setSelectedCity(user?.city ? { 'label': user?.city?.nameEn, 'id': user?.city?._id } : null)
            setUserData({
                gpa:user?.subModel?.gpa,
                firstName: user?.subModel?.firstName,
                lastName: user?.subModel?.lastName,
                middleName: user?.subModel?.middleName,
                email: user?.email,
                secondaryEmail: user?.subModel?.secondaryEmail,
                proficientLanguage: user?.subModel?.proficientLanguage.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id })),
                areaOfInterest: user?.subModel?.areaOfInterest ? { 'label': user?.subModel?.areaOfInterest?.nameEn, 'id': user?.subModel?.areaOfInterest?._id } : null,
                fluentLanguage: user?.subModel?.fluentLanguage.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id })),
                major: user?.subModel?.major.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id })),
                educationLevel: user?.subModel?.educationLevel ? { 'label': user?.subModel?.educationLevel?.nameEn, 'id': user?.subModel?.educationLevel?._id } : null,
                country: user?.country?.nameEn ? { 'label': user?.country?.nameEn, 'id': user?.country?._id } : null,
                city: user?.city ? { 'label': user?.city?.nameEn, 'id': user?.city?._id } : null,
            })
        }
    }, [token]);

    return (
        <Container>
            {!loadingPage &&
                <div className={styles['form']} id="form">
                    <div className="m-b-50 main-b-border pb-6">
                        <h3 className='title '>Settings</h3>
                        <p className='secondary-title '>Tell us a little about you so we can customize your experience</p>
                        <span className='required-hint'>Fields marked with an * are required.</span>
                    </div>
                    <UploadeProfileImage />
                    {/* {errorMessage && (
                        <FormErrorMessage errorMessage={errorMessage} />
                    )} */}
                    <form className='w-full  '>
                        <div className='form-item'>
                            <InputField
                                label='First name'
                                name='firstName'
                                required={true}
                                placeholder='First name'
                                Change={handleChange}
                                error={!userData?.firstName && submitClicked}
                                submitClicked={submitClicked}
                                value={userData?.firstName}

                            />
                        </div>
                        <div className='form-item'>
                            <InputField label='Middle name'
                                name='middleName'
                                required={true}
                                placeholder='Middle name'
                                Change={handleChange}
                                value={userData?.middleName}
                                submitClicked={submitClicked} />


                        </div>
                        <div className='form-item'>
                            <InputField label='Last name' name='lastName'
                                required={true} placeholder='Last name'
                                Change={handleChange}
                                error={!userData?.lastName && submitClicked}
                                submitClicked={submitClicked}
                                value={userData?.lastName} />
                        </div>
                        <div className='form-item'>

                            <InputField
                                label='Primary email'
                                name='email'
                                required={true}
                                placeholder='Primary email'
                                Change={handleChange}
                                error={!userData?.email && submitClicked}
                                submitClicked={submitClicked}
                                value={userData?.email} />

                        </div>
                        <div className='form-item'>
                            <InputField
                                value={userData?.secondaryEmail}
                                label='Secondary email'
                                name='secondaryEmail'
                                Change={handleChange}
                                required={false}
                                placeholder='Secondary email'
                                labelHint='This could be your personal email. Your primary email will be your school email which you used to register with.'
                                error={(userData?.secondaryEmail == userData?.email) && submitClicked}
                                errorMessage="You can't use your primary email as a secondary email"
                                submitClicked={submitClicked}
                            />

                        </div>
                        <div className='form-item'>
                            <Grid container >
                                <Grid item xs={12}>
                                    <div className='form-item '>
                                        <SelectInput
                                            name="country"
                                            required={true}
                                            label='Select your country'
                                            options={countries}
                                            Change={handleSelectContry}
                                            loading={loadingCountries}
                                            error={!userData?.country && submitClicked}
                                            submitClicked={submitClicked}
                                            defaultValue={userData?.country} />

                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className='form-item '>

                                        <SelectInput
                                            name="city"
                                            required={true}
                                            labelHint='Select your country first'
                                            label='Select your city (region/state/province options will appear with the city)'
                                            options={cities}
                                            Change={handleSelectChange}
                                            loading={loadingCities}
                                            error={!userData?.city && submitClicked}
                                            submitClicked={submitClicked}
                                            value={selectedCity}
                                            disabled={!userData.country} />

                                    </div>
                                </Grid>
                            </Grid>
                            {/* <SelectInput disablePortal={true} label='Your location' required={false} options={options} /> */}
                        </div>
                        <div className='form-item'>
                            <SelectInput
                                labelHint='You can select the language you are fluent in.'
                                name='fluentLanguage'
                                required={true}
                                label='Select Language Fluency'
                                options={Languages}
                                multiple={true}
                                Change={handleSelectChange}
                                loading={loadingLanguages}
                                defaultValue={userData.fluentLanguage}
                                error={!userData?.fluentLanguage && submitClicked}

                            />
                        </div>
                        <div className='form-item'>
                            <SelectInput labelHint='You can select the language you are proficient in.'
                                name='proficientLanguage'
                                required={false}
                                label='Select Language Proficiency'
                                multiple={true}
                                options={Languages}
                                Change={handleSelectChange}
                                loading={loadingLanguages}
                                defaultValue={userData.proficientLanguage}
                            />
                        </div>
                        <div className='form-item'>
                            <InputField
                                value={userData?.gpa}
                                label='GPA'
                                name='gpa'
                                Change={handleChange}
                                required={false}
                                placeholder=''
                                defaultValue={userData.gpa}
                                // labelHint='This could be your personal email. Your primary email will be your school email which you used to register with.'
                                // error={(userData?.secondaryEmail == userData?.email) && submitClicked}
                                // errorMessage="You can't use your primary email as a secondary email"
                                submitClicked={submitClicked}
                            />

                        </div>
                        <div className='form-item'>
                            <FormControl className={styles.radioGroup}>
                                <label  >
                                    <strong>Are you currently enrolled in higher education (eg. degree level)?
                                    </strong></label>
                                <RadioGroup
                                    value={radioValue}
                                    onChange={handleChange}
                                    row
                                    name="enrolledInHighSchool"
                                >
                                    <FormControlLabel value="yes" control={<Radio size='small' />}
                                        label="Yes" />
                                    <FormControlLabel value="no" control={<Radio size='small' />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </div>
                        {radioValue === 'no' &&
                            <div className='form-item'>
                                <SelectInput
                                    required={false}
                                    label='Area of interest'
                                    options={allAreaInterest}
                                    loading={loadingAreaInterest}
                                    name="areaOfInterest"
                                    defaultValue={userData?.areaOfInterest}
                                    Change={handleSelectChange}

                                />
                            </div>
                        }
                        {
                            radioValue === 'yes' &&
                            <div className='form-item'>
                                <SelectInput required={false}
                                    label='Major/Area of study'
                                    loading={loadingMajors}
                                    options={majors}
                                    name='major'
                                    defaultValue={userMajors}
                                    Change={handleSelectChange}
                                    multiple={true}
                                />
                            </div>
                        }
                        <div className='form-item'>
                            <SelectInput required={false}
                                label='Level of education'
                                options={educationLevels}
                                loading={loadingEducationLevels}
                                name='educationLevel'
                                defaultValue={userData.educationLevel}
                                Change={handleSelectChange}
                            />
                        </div>

                        <>
                            <label>Graduation date (estimation accepted)</label>
                            <Grid container columnSpacing='5'>
                                <Grid item xs={12} md={6} >
                                    <DatePicker

                                        label={`${radioValue === 'yes' ? '*' : ''}Month`}
                                        openTo="month"
                                        views={['month']}
                                        value={graduationMonth}
                                        onChange={(newValue) => {
                                            setGraduationMonth(newValue);
                                        }}
                                        inputFormat="MMMM"
                                        renderInput={(params) => <TextField  {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder: ""
                                            }}
                                            fullWidth margin="normal" helperText={null} />}

                                    />
                                    {radioValue === 'yes' && (!graduationMonth) && submitClicked &&
                                        <span className='text-red-500 text-sm'>{'This field is required'}</span>}
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <DatePicker
                                        views={['year']}
                                        label={`${radioValue === 'yes' ? '*' : ''}Year`}
                                        openTo="year"
                                        value={graduationYear}
                                        onChange={(newValue) => {
                                            setGraduationYear(newValue);
                                        }}

                                        renderInput={(params) => <TextField  {...params}
                                            inputProps={{
                                                 ...params.inputProps,
                                                placeholder: ""
                                            }} fullWidth margin="normal" helperText={null} />}
                                    />
                                    {radioValue === 'yes' && (!graduationYear) && submitClicked &&
                                        <span className='text-red-500 text-sm'>{'This field is required'}</span>}
                                </Grid>
                            </Grid>
                        </>


                        {/* <p className={[styles.notificationsHint, 'mb-5'].join(" ")}>ou will automatically receive edqan email notifications, you can unsubscribe at any time from your Notification Preferences page.</p> */}
                    </form>
                    <div className='custom-action-fill'>
                        <CustomButton text="Change password" click={handleChangePassword} />
                    </div>
                    {open && <PasswordPopUp show={open} setOpen={setOpen} />}
                    <div className='my-10'>
                        <h3 className='secondary-title'>Privacy Settings</h3>
                        <div className='main-border p-4'>
                            <p className='main-description mb-4'>Update Privacy</p>
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="female"
                                    name="accountVisibility"
                                    onChange={handleChange}
                                >
                                    {/* <div className='flex items-start mb-4 cursor-pointer gap-2' onClick={() => setPrivacy('community')}>
                                        <FormControlLabel value="community" control={<Radio size='small' checked={privacy === 'community'} />}
                                            label="" />
                                        <div>
                                            <p className='list-title mb-2 '>Community <span className='mx-2 bg-[#FFFAF7] p-1 text-[#f26400] '>Recommended</span></p>
                                            <p className="main-description ">Your profile will be visible to employers/ students, mentors and early career professionals that are part of the edqan network in addition to other educational institutions. You will be able to communicate with many of them across our network. If you want to change this setting, please change it from the below</p>
                                        </div>
                                    </div> */}

                                    <div className='flex items-start mb-4 cursor-pointer gap-2' onClick={() => setPrivacy('employer')}>
                                        <FormControlLabel value="employer" control={<Radio size='small' />} label="" checked={privacy === 'employer'} />
                                        <div>
                                            <p className='list-title mb-2 '>Employers and Educational Institutions <span className='mx-2 bg-[#FFFAF7] p-1 text-[#f26400] '>Recommended</span></p>
                                            <p className="main-description ">Your profile will be visible to employers and your educational institution.</p>
                                        </div>
                                    </div>
                                    <div className='flex items-start mb-4 cursor-pointer gap-2' onClick={() => setPrivacy('hidden')}>
                                        <FormControlLabel value="hidden" control={<Radio size='small' />} label="" checked={privacy === 'hidden'} />
                                        <div>
                                            <p className='list-title mb-2 '>Private</p>
                                            <p className="main-description ">Your profile will not be visible to the public.</p>
                                        </div>
                                    </div>
                                </RadioGroup>
                            </FormControl>
                        </div>


                    </div>
                    {/* <div className='my-10'>
                        <p className='secondary-title'>Calendar export</p>
                        <p className={'main-description'}>Add your edqan events, meetings, interviews to your calendar by using the following URL so your calendar can be automatically updated with edqan updates
                            .
                            <a className='main-link'>You can also download the static iCalendar file.</a> Your calendar will only show available time slots.</p>
                    </div> */}


                    <p className={'main-description mb-6'}>
                    You will automatically receive edqan email notifications, you can unsubscribe at any time from your Notification Preferences page. </p>
                    <div className='actions-end'>
                        <CustomButton text="Save updates" type='submit' click={handleSubmit} loading={loadingSubmit} />
                    </div>


                </div>
            }
        </Container>
    )
}
