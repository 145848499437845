import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    show:false,
    message:'',
    type:''
  },
  reducers: {
    addAlertInfo: (state, action) => {
      return action.payload;
    },
  },
});

export const { addAlertInfo } = alertSlice.actions;

export default alertSlice.reducer;
