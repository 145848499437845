import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomizedButtons from '../../../../Components/CustomButton';
import Moment from 'react-moment';

interface DynamicTableProps {
  columns: Array<string>;
  alignment: Array<any>
  checked?: any;
  mouseAction?: Array<any>;
  coloredHeader?: boolean
  list?: any
}


export default function StudentsTable(props: DynamicTableProps) {
  const { columns, alignment, checked, mouseAction, coloredHeader, list } = props;
  function formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  }
  return (
    <div className='main-table my-8 w-full'>

      <Table size="small" aria-label="simple table">
        <TableHead >
          <TableRow className={coloredHeader ? "colored-header" : ''}>
            {columns.map((el, index) => {
              return (
                <TableCell align={alignment[index]}>
                  <p className='list-title py-2'>{el}</p>
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item: any, index: any) => {
            return (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
              >
                <TableCell>{`${item?.subModel?.firstName} ${item?.subModel?.middleName||''} ${item?.subModel?.lastName}`}</TableCell>
                <TableCell> {item?.email}</TableCell>
                <TableCell> {item?.subModel?.educationLevel?.nameEn}</TableCell>
                
                <TableCell> 
                  {item?.subModel?.major.map((item:any)=> {
                  return <span className='bg-gray-200 rounded p-1 text-[12px] mr-1 mb-1 block text-center'>{item?.nameEn}</span>
                  
                })}
               </TableCell >
                {/* <TableCell> {item?.subModel?.minor}</TableCell> */}
                <TableCell align='center'>   
                {item?.subModel?.graduationDate?  <Moment format="MMM DD, YYYY">{item?.subModel?.graduationDate}</Moment>:''}
           
               </TableCell>
                
              </TableRow>
            )
          }
          )
          }
        </TableBody>
      </Table>

    </div>
  );
}