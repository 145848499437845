import React, { useState, useCallback, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";

import { Box } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CustomButton from "../../../Components/CustomButton";
interface ImageCropProps {
    show: boolean;
    setOpen?: any;
    type?: any;
    handleSubmit?: any
    loadingUpload:any
}
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: '16px',
    boxShadow: 0,
    p: 4,
};

export default function FileUpload(props: ImageCropProps) {
    const { show, setOpen, type, handleSubmit,loadingUpload } = props;
    const [file, setFile] = useState<File>();
    const handleClose = () => setOpen(false);
    const inputUploadFile: any = useRef(null);
    const changeFile = () => {
        inputUploadFile.current.click();
    };
    const onFileChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0]);


        }
    };


    const handleSave = () => {
        if (file) {
            handleSubmit(file)
            handleClose()
        }

    };



    return (
        <div>
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            ><Box sx={style}>

                    <p>{file?.name}</p>

                    {type === "banner" && <p className="mb-2 text-sm">Zoom in & out to get the perfect selection</p>}
                    <div className="custom-action" style={{ justifyContent: "center" }}>
                        <CustomButton text={"Click here to select file"} icon={<FileUploadIcon />} click={changeFile}></CustomButton>
                        <input
                            type="file"
                            accept=".xlsx, .xls, .csv"
                            onChange={onFileChange}
                            hidden
                            ref={inputUploadFile}
                        />
                    </div>

                    <div className="modal-footer">
                        <div className="custom-action custom-action-fill mt-4 main-border-top">
                            <div className="cancel mr-2">
                                <CustomButton type="button" text={"Cancel"} click={handleClose}></CustomButton>

                            </div>
                            <CustomButton type="button" text={"Upload"} click={handleSave} loading={loadingUpload}></CustomButton>

                        </div>

                    </div>
                </Box>


            </Modal>
        </div>
    )
}