import React, { useEffect, useState } from "react";
import DynamicTable from "../../../Components/Table";
import { Avatar, Card, Container, Grid, Pagination } from "@mui/material";
import { listAllInstitutionStudent, studentSheetFormat, uploadStudentsListSheet } from "../../../services/institution/studentsList";
import { useSelector } from "react-redux";
import StudentsTable from "./Table";
import { TableLoadingSkeleton } from "../../../Components/Loaders/TableLoader";
import NoDataMessage from "../../../Components/NoDataMessage";
import CustomizedButtons from "../../../Components/CustomButton";
import FileUpload from "./upload";
import { getInitials } from "../../helpers";
import MenteeCard from "../../Employer/EmployerInformation/Students/MenteeCard";
import { useNavigate } from 'react-router';
import SelectInput from "../../../Components/SelectInput";
import { listAllMajor } from "../../../services/majorsServise";

export default function StudentsProfiles() {
    const [majorFilter, setMajorFilter] = useState<any>(null)
    const [majors, setMajors] = useState<Array<any>>([])
    const userInfo = useSelector((state: any) => state.auth.user);
    const token = useSelector((state: any) => state.auth.token);
    const [loading, setloading] = useState(true)
    const [loadingMajors, setLoadingMajors] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [list, setList] = useState([])
    const [totlaPages, setTotlaPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [open, setOpen] = useState(false);
    const handleUpload = () => {
        setOpen(true)
    }
    const navigateTo = useNavigate()
    const getAllMajors = async () => {
        const response = await listAllMajor()
        if (response) {
            const majors = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
            setMajors(majors)
            setLoadingMajors(false)
        }
    }
    const getInstitutionStudentList = async () => {
        setloading(true)
        const response: any = await listAllInstitutionStudent(userInfo?._id, currentPage,majorFilter?.id)
        if (response.status === 200) {
            setloading(false)
            setList(response.data.data.queryResult)
            setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 10))

        }
    }
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page - 1)
    }
    useEffect(() => {
        if (token) {
            getInstitutionStudentList()

        }

    }, [token, currentPage,majorFilter])
    useEffect(() => {
        if (token) {
       
            getAllMajors()
        }

    }, [token])
    const getStudentListSheet = async () => {
        setLoadingDownload(true)
        const response: any = await studentSheetFormat()
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Students-list-format.xlsx"
            );
            document.body.appendChild(link);
            link.click();
            setLoadingDownload(false)

        }
    }
    const uploadStudentsSheet = async (file: any) => {
        const formData = new FormData()
        formData.append('excel', file)
        setLoadingUpload(true)
        const response: any = await uploadStudentsListSheet(formData)
        if (response.status === 200) {
            setLoadingUpload(false)
            getInstitutionStudentList()
        }
    }
    return (
        <div className="page">
            <Container>
            <div className="header-underline">
                        <h3 className='title'>Student Profiles - {userInfo?.subModel?.name}</h3>
                        <p className='main-description'>All students with public profiles</p>
                    </div>
               
                <div className='mr-5 w-full md:w-1/4'>
                    <SelectInput
                        label='Filter by major'
                        value={majorFilter}
                        options={majors}
                        loading={loadingMajors}
                        Change={(e: any) => setMajorFilter(e.value)}
                        required={false}

                    />
                </div>
                {loading ?
                    <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                        {[...Array(21)].map((item: any) => {
                            return (
                                <div className="border  border-[#efeeee] rounded-md p-4 w-full mx-auto h-full">
                                    <div className="animate-pulse block space-x-4">
                                        <div className="flex space-y-6 py-1">

                                            <div className="rounded-full bg-gray-200 h-10 w-10 "></div>
                                            <div className="space-y-3 w-[90%] ml-4">
                                                <div className="grid grid-cols-3 gap-4 ">

                                                    <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                                                </div>
                                                <div className="h-2 bg-gray-200 rounded"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div> :
                    <div className="overflow-x-auto w-full">

                        <div >
                            {list.length > 0 ?
                                <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">

                                    {
                                        list.map((user: any) => {
                                            return (
                                                <Card className="student-card" onClick={() => navigateTo(`/professional/profile/${user?._id}`)}>
                                                    <div className="flex">
                                                        <Avatar className='mr-4'
                                                            src={user?.profileImage} sx={{ width: 50, height: 50, bgcolor: "#F26400" }} aria-label="avatar">
                                                            <a className='capitalize text-white'>
                                                                {user?.subModel?.firstName?.charAt(0).toUpperCase()}{user?.subModel?.lastName?.charAt(0).toUpperCase()}</a>
                                                        </Avatar>

                                                        <div>
                                                            <h3 className="text-[18px] font-[500]"> {user?.subModel?.firstName} {user?.subModel?.lastName}</h3>
                                                            <div className="flex items-baseline">
                                                                {user?.subModel?.major?.length > 0 && <span className="font-[500] text-[14px] mr-2">Major(s):</span>}
                                                                <ul>
                                                                    {user?.subModel?.major?.map((major: any) => {
                                                                        return (
                                                                            <li>
                                                                                <span className="text-[14px]">
                                                                                    {major?.nameEn}
                                                                                </span>
                                                                            </li>

                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </Card>
                                            )
                                        }
                                        )
                                    }



                                </div> : <NoDataMessage />}

                        </div>
                        {totlaPages > 1 &&
                            <div className="flex justify-end my-6">
                                <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                            </div>
                        }
                    </div>
                }
            </Container>

        </div>

    );
}
