import React from 'react'
import CheckBoxList from '../../../../Components/CheckBoxList/CheckBoxList';

interface InterestsSectionProps{
    title:string;
    list:Array<string>;
    setListReselt:any
    selectedList:any
}
export default function InterestsSection(props:InterestsSectionProps) {
    const {title,list,setListReselt,selectedList} = props;
  return (
    
    <div>
        <p className='list-title my-3'>{title}</p>
        <div className='CheckBoxList'>
        <CheckBoxList list={list} styling='row' setListReselt={setListReselt} name='subIndustry' selectedList={selectedList}/>
        </div>
    </div>
  )
}
