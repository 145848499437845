import * as React from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Badge, CircularProgress, IconButton } from '@mui/material';
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router";
import UnderDevelopment from '../../../../pages/UnderDevelopment';
import { listNotifications } from '../../../../services/notificationService';
import NoDataMessage from '../../../../Components/NoDataMessage';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
export default function NotificationMenu() {
    const user = useSelector((state: any) => state.auth.user);
    const token = useSelector((state: any) => state.auth.token);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        navigate('/notifications');

    };
    const [loading, setLoading] = React.useState(true)
    const [notificationsList, setNotificationsList] = React.useState([])

    const listAllNotifications = async () => {
        const response = await listNotifications(user?._id)

        if (response.status === 200) {
            setLoading(false)
            setNotificationsList(response.data.data.queryResult)
        }
        else {
            setLoading(false)
        }
    }
    useEffect(() => {
        if (token) {
            listAllNotifications()
        }

    }, [token])
    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                size="large"
                color="inherit"
            >
                <Badge color="warning" badgeContent={notificationsList.filter((item: any) => item.status === 0).length}>
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}

            >
                <div className='notification-list'>
                    {!loading ?

                        notificationsList.length > 0 ?
                            <>
                                {notificationsList.map((item: any, index: number) => {
                                    return (
                                        <>
                                            {index < 6 &&
                                                <MenuItem >
                                                    <div className='flex  items-center notification-item'>
                                                    {item?.status===0&& <div className='h-2 w-2 bg-[#f26400] rounded mr-1'></div>}
                                                    <NotificationAddIcon className={`mr-2`}/>
                                                            <div className='text-sm flex text-wrap max-w-[311px]' >{item.text}</div>
                                          
                                                    </div>
                                                </MenuItem>
                                            }
                                        </>
                                    )
                                })

                                }
                                <MenuItem onClick={handleClose}>
                                    <div className='see-all text-center'>
                                        <p>See all</p>
                                    </div>
                                </MenuItem>

                            </>

                            :
                            <p className='text-center'>No notification for you</p>
                        :
                        <CircularProgress size="30px" sx={{ color: '#F26400' }} />
                    }


                </div>

            </Menu>
        </div>
    );
}