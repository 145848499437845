import { Box, Container } from "@mui/material";
import styles from "./posts.module.scss";
import CustomButton from "../../../../Components/CustomButton";
import SectionHeader from "../components/SectionHeader";
import CarouselList from "../../../../Components/CardsList";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { listRecentJobs } from "../../../../services/jobsService";
import { useNavigate } from "react-router";
import NoDataMessageImage from "../../../../Components/NoDataMessageWithImage";
interface PostsProps { }
const Posts = (props: PostsProps) => {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const [jobs, setJobs] = useState<Array<any>>([])
  const [loading, setLoading] = useState(true)
  const handleViewMore = () => {
    navigate('/professionals/more-jobs')
  }
  const getGobs = async () => {
    const response = await listRecentJobs({ currentPage: 0 })
    if (response.status === 200) {
      setJobs(response.data.data.queryResult)
      setLoading(false)
    }
    else {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (token) {
      getGobs()
    }
  }, [token]);
  return (
    <Container>
      <div className={styles["posts"]}>
        {jobs.length !== 0 ?
          <SectionHeader
            title="Discover job recommendations based on your interests"
            description="These are more personalized opportunities based on your career interests"
          // description="Discover the latest job posts tailored to your major or area of interest. For more personalized opportunities, fill out your career interests page"
          /> :
          <div className={styles.section_header}>
            <h5 className={styles.title}>For more personalized job opportunities, let us know your career
              interests by filling out your preferences <a className="main-link" onClick={() => navigate('/professionals/career-interests')}>here</a></h5>
            {/* <p className={styles.description}>{description}</p> */}
          </div>
        }
        {jobs && jobs.length !== 0 &&
          <>
            <div className={styles.jobs_list}>
              <Box sx={{ width: '100%' }}>
                <CarouselList list={jobs} loading={loading} />
              </Box>
            </div>
            <div className={styles.actions}>
              <CustomButton text="View more jobs" click={handleViewMore} />
            </div>
          </>
        }
        {!loading && jobs && jobs.length === 0 &&
          <NoDataMessageImage />
        }
      </div>
    </Container>
  );
};
export default Posts;
