import React, { useState, useCallback, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import CustomButton from "../CustomButton";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getOrientation } from "get-orientation";
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
interface ImageCropProps {
    show: boolean;
    setOpen?: any;
    type?: any;
}
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: '16px',
    boxShadow: 0,
    p: 4,
};
const ORIENTATION_TO_ANGLE: any = {
    3: 180,
    6: 90,
    8: -90,
};
export default function VideoUpload(props: ImageCropProps) {
    const { show, setOpen, type } = props;
    const [imagePreview, setImagePreview] = useState(null);
    const [videoPreview, setVideoPreview] = useState(null);
    const handleClose = () => setOpen(false);
    const [selectimage, setSelectImage] = useState();
    const [videoSrc, seVideoSrc] = useState("");
    const inputUploadFile: any = useRef(null);
    const changeFile = () => {
        inputUploadFile.current.click();
    };
    // const onFileChange = async (e: any) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         const file = e.target.files[0];
    //         let url: any = await readFile(file);
    //         var urdddl = URL.createObjectURL(url.originFileObj);
    //         seVideoSrc(url);
    //         console.log(url.originFileObj)
    //         // setSelectImage(imageDataUrl);
    //     }
    // };
    // function readFile(file: any) {
    //     return new Promise((resolve) => {
    //         const reader = new FileReader();
    //         reader.addEventListener("load", () => resolve(reader.result), false);
    //         reader.readAsDataURL(file);

    //     });
    // }
    function previewFile(e: any) {
        // Reading New File (open file Picker Box)
        const reader = new FileReader();
        // Gettting Selected File (user can select multiple but we are choosing only one)
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        }
        // As the File loaded then set the stage as per the file type
        reader.onload = (readerEvent: any) => {
            if (selectedFile.type.includes("image")) {
                setImagePreview(readerEvent.target.result);
            } else if (selectedFile.type.includes("video")) {
                setVideoPreview(readerEvent.target.result);
            }
        };
    }



    return (
        <div>
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            ><Box sx={style}>
                    <div className="crop-container">
                        {!videoPreview && <VideoCameraBackIcon />}
                        {videoPreview != null && <video controls src={videoPreview}></video>}
                    </div>
                    <div className="custom-action" style={{ justifyContent: "center" }}>
                        <CustomButton text={"Click here to upload"} icon={<FileUploadIcon />} click={changeFile}></CustomButton>
                        <input
                            type="file"
                            accept="video/*"
                            onChange={previewFile}
                            hidden
                            ref={inputUploadFile}
                        />
                    </div>

                    <div className="modal-footer">
                        <div className="custom-action custom-action-fill mt-4 main-border-top">
                            <div className="cancel mr-2">
                                <CustomButton text={"Cancel"} click={handleClose}></CustomButton>

                            </div>
                            <CustomButton text={"Save"} click={handleClose}></CustomButton>

                        </div>

                    </div>
                </Box>


            </Modal>
        </div>
    )
}