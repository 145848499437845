import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const saveCareerInterest = (userId:string,data:any) => {
    return axios
      .patch(`${BASEURL}student/${userId}/career_interest`,data)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  
  