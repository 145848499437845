import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import styles from "./form.module.scss";
import CustomButton from "../../../../Components/CustomButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import InputField from "../../../../Components/Input";
import SelectInput from "../../../../Components/SelectInput";
import LogoImage from "../../../../assets/images/Logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { useDispatch } from "react-redux/es/exports";
import { Language } from "../../../../models/language";
import { signUp } from "../../../../services/authService";
import { listAllCountries, listAllCities } from "../../../../services/locationService";
import { listAllMajor } from "../../../../services/majorsServise";
import { addAlertInfo } from "../../../../store/alertSlice";
import { listCompanies } from "../../../../services/students/companies";
import { ListAlltitutions } from "../../../../services/institutionService";
import { ListAllIndustry } from "../../../../services/industry";
import UploadeProfileImage from "./uploadeProfileImage";
import { listAllAreaInterest } from "../../../../services/areaInterestservice";
import { validatePassword, validatePasswordTrue } from "../../../../helpers/validation";
import { CAPTCHACLINTSECRET } from "../../../../environments/environment";
interface SignUpProps {}
const SignUp = (props: SignUpProps) => {
  const validateList = [
    "firstName",
    "lastName",
    "email",
    "currentTitle",
    "password",
    "linkedInUrl",
    "country",
    "city",
    // "mentorShipTypes",
    "areaOfInterest",
    "about",
    "industries",
  ];
  const validateListNames = [
    "First name",
    "Last name",
    "Email",
    "Current title",
    "Password",
    "Professional Website or LinkedIn Profile",
    "Country",
    "City",
    // "How you want to mentor",
    "Tell us how you want to mentor",
    "Description that will appear on your profile for mentees",
    "Industries",
  ];
  const formData = new FormData();
  const [file, setImagefile] = useState<File>();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const captchaRef = useRef(null);
  const [userData, setUserData] = useState<any>();
  const [radioValue, setRadioValue] = useState("true");
  const [radioGender, setRadioGender] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [majors, setMajors] = useState([]);
  const [industry, setAllIndustry] = useState([]);
  const [loadingIndustry, setloadingIndustry] = useState(true);
  const [loadingMajors, setLoadingMajors] = useState(true);
  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [companiesList, setCompaniesList] = React.useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [loadingnstitutions, setILoadingnstitutions] = React.useState(true);
  const [institutionsList, setInstitutionsList] = useState([]);
  const [loadingnMentorShipType, setLoadingnMentorShipType] = React.useState(true);
  const [mentorShipTypeList, setMentorShipTypeList] = useState([]);
  const [selectedCity, setSelectedCity] = useState<any>();
  const [allAreaInterest, setAllAreaInterest] = useState([]);
  const [loadingAreaInterest, setloadingAreaInterest] = useState(true);
  const validateForm = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return false;
    }
    if (!agreeToTerms) return false;
    if (!validatePasswordTrue(userData?.password)) return false;
    if (userData?.password != userData?.newPasswordConfirmation) return false;
    return true;
  };
  const getErrorMessage = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!userData[validateList[i]]) return validateListNames[i] + " is required";
    }
    if (!agreeToTerms) return "Agreement to the Terms of Use and the Privacy Policy is required";
    if (!validatePasswordTrue(userData?.password)) {
      return validatePassword(userData?.password);
    }
    if (userData?.password != userData?.newPasswordConfirmation) {
      return "Your passwords does not match";
    }
    return true;
  };
  function handleSignUp(e: any) {
    e.preventDefault();
    setSubmitClicked(true);
    if (validateForm()) {
      for (const property in userData) {
        if (typeof userData[property] === "string" || userData[property] === "boolean") {
          formData.append(property, userData[property]);
        } else {
          formData.append(property, JSON.stringify(userData[property]));
        }
      }
      file && formData.append("profileImage", file);
      formData.append("gender", radioGender);
      agreeToTerms && formData.append("agreeToTerms", "true");
      formData.append("featuredAsPublic", radioValue);
      setLoadingSubmit(true);
      setTimeout(async () => {
        const res = await signUp(formData);
        if (res.status === 201) {
          navigate(`/mailVerification?userId=${res.data.data.user._id}`);
          setLoadingSubmit(false);
        } else {
          setUserData((_data: any) => ({ ..._data, captchaValue: null }));
          const captcha: any = document.getElementById("captcha") || "";
          captcha?.remove();
          dispatch(
            addAlertInfo({
              show: true,
              message: res.data.error,
              type: "error",
            })
          );
          setLoadingSubmit(false);
          const captchaView: any = document.getElementById("captcha-view") || "";
          captchaView.appendChild(captcha);
        }
      }, 1000);
    } else {
      dispatch(
        addAlertInfo({
          show: true,
          message: getErrorMessage(),
          type: "error",
        })
      );
    }
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "featuredAsPublic") {
      setRadioValue(e.target.value);
    }
    if (e.target.name === "agreeToTerms") {
      setAgreeToTerms(!agreeToTerms);
    }
    if (e.target.name === "gender") {
      setRadioGender(e.target.value);
    } else {
      setUserData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
    }
  };
  const handleSelectChange = (e: any) => {
    if (e.name == "city") {
      setSelectedCity(e.value);
    }
    const isArray = Array.isArray(e.value);
    if (isArray) {
      if (e.value.length === 0) e.value = null;
      setUserData((_data: any) => ({ ..._data, [e.name]: e?.value ? e.value.map((item: any) => item.id) : null }));
    } else {
      setUserData((_data: any) => ({ ..._data, [e.name]: e?.value?.id || null }));
    }
  };
  const handleSelectContry = (e: any) => {
    handleSelectChange(e);
    setSelectedCity(null);
    setUserData((_data: any) => ({ ..._data, city: null }));
    if (e.value) {
      getAllCities(e.value.id);
    } else {
      setCities([]);
    }
  };
  const getAllMentorShipTypes = async () => {
    const response = await listAllAreaInterest();
    if (response) {
      const data = response.data.data.queryResult?.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setMentorShipTypeList(data);
      setLoadingnMentorShipType(false);
    }
  };
  const getAllCountries = async () => {
    const response = await listAllCountries();
    if (response) {
      const countries = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCountries(countries);
      setLoadingCountries(false);
    }
  };
  const getAllCities = async (countryId: string) => {
    setLoadingCities(true);
    const response = await listAllCities(countryId);
    if (response) {
      const citiesOfCountry = response.data.data;
      const cities = citiesOfCountry.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCities(cities);
      setLoadingCities(false);
    }
  };
  // list All Majors
  const getAllMajors = async () => {
    const response = await listAllMajor();
    if (response) {
      const majors = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setMajors(majors);
      setLoadingMajors(false);
    }
  };
  const getAllIndustry = async () => {
    setloadingIndustry(true);
    const response = await ListAllIndustry();
    if (response) {
      const data = response.data.data.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setAllIndustry(data);
      setloadingIndustry(false);
    }
  };
  const getCompaniesList = async () => {
    const response = await listCompanies({ currentPage: null });
    if (response) {
      const companies = response.data.data.queryResult.map((item: any) => ({ label: item.subModel.name, id: item._id }));
      setCompaniesList(companies);
      setLoadingCompanies(false);
    }
  };
  const getAlltitutions = async () => {
    const response = await ListAlltitutions();
    if (response) {
      const institutions = response.data.data.queryResult.map((item: any) => ({ label: item?.subModel?.name, id: item._id }));
      setInstitutionsList(institutions);
      setILoadingnstitutions(false);
    }
  };
  function handleClick() {
    navigate("/mentor/home");
  }
  function handleClickLogin() {
    navigate("/");
  }
  const getAllAreaInterest = async () => {
    const response = await listAllAreaInterest();
    if (response) {
      const allAreaInterest = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setAllAreaInterest(allAreaInterest);
      setloadingAreaInterest(false);
    }
  };
  useEffect(() => {
    getAllAreaInterest();
    getCompaniesList();
    getAllIndustry();
    getAllMajors();
    getAllCountries();
    getAlltitutions();
    getAllMentorShipTypes();
    setUserData((_data: any) => ({ ..._data, model: "Mentor" }));
  }, []);

  const handleChangeCaptcha = (e: string) => {
    setUserData((_data: any) => ({ ..._data, captchaValue: e }));
  };

  return (
    <div className={styles["signUp-form"]} id="form">
      <div className={styles["form"]}>
        <div className="md:hidden" onClick={handleClick}>
          <img src={LogoImage} />
        </div>
        <h3 className="title header-underline">Mentor Sign up</h3>
        {/* <p className='main-description'>Tell us a little about you so we can customize your experience</p> */}
        <UploadeProfileImage setImagefile={setImagefile} />
        <span className={styles.requarid}>Fields marked with an * are required.</span>
        <form onSubmit={handleSignUp}>
          <div className="form-item">
            <InputField
              label="First name"
              name="firstName"
              required={true}
              placeholder="First name"
              Change={handleChange}
              submitClicked={submitClicked}
              error={!userData?.firstName && submitClicked}
            />
          </div>
          <div className="form-item">
            <InputField
              label="Middle name"
              name="middleName"
              required={false}
              placeholder="Middle name"
              Change={handleChange}
              submitClicked={submitClicked}
            />
          </div>
          <div className="form-item">
            <InputField
              label="Last name"
              name="lastName"
              required={true}
              placeholder="Last name"
              Change={handleChange}
              error={!userData?.lastName && submitClicked}
              submitClicked={submitClicked}
            />
          </div>
          <div className="form-item">
            <InputField
              label="Email address"
              required={true}
              placeholder="Primary address"
              name="email"
              Change={handleChange}
              error={!userData?.email && submitClicked}
              submitClicked={submitClicked}
            />
          </div>
          <div className="form-item">
            <InputField
              label="Current title"
              required={true}
              placeholder="Your current title"
              name="currentTitle"
              Change={handleChange}
              error={!userData?.currentTitle && submitClicked}
              submitClicked={submitClicked}
            />
          </div>
          {/*  */}
          <div className="form-item">
            <InputField
              label="Password"
              labelHint="Choose a strong password - at least 12 characters long, has a combination of upper and lowercase letters, numbers, and special symbols."
              name="password"
              required={true}
              type="password"
              placeholder="Password"
              Change={handleChange}
              submitClicked={submitClicked}
              error={validatePassword(userData?.password) && submitClicked}
              errorMessage={validatePassword(userData?.password)}
            />
          </div>
          <div className="form-item">
            <InputField
              label="Confirm your password"
              required={true}
              name="newPasswordConfirmation"
              Change={handleChange}
              placeholder="Confirm your password"
              type="password"
              submitClicked={submitClicked}
              error={userData?.password != userData?.newPasswordConfirmation && submitClicked}
              errorMessage="Your passwords does not match"
            />
          </div>
          <div className="form-item">
            <InputField
              label="Professional Website or LinkedIn Profile"
              required={true}
              placeholder="linkedin profile"
              Change={handleChange}
              submitClicked={submitClicked}
              name="linkedInUrl"
              error={!userData?.linkedInUrl && submitClicked}
            />
          </div>
          <div className="form-item">
            <div className="form-item">
              <SelectInput
                label="Company"
                name="company"
                Change={handleSelectChange}
                required={false}
                options={companiesList}
                loading={loadingCompanies}
                disablePortal={true}
              />
              <InputField
                labelHint="If your company name is not in the list, enter it in the field below."
                required={false}
                placeholder="Company name"
                Change={handleChange}
                submitClicked={submitClicked}
                name="company"
                // error={!userData?.company && submitClicked}
              />
              {/* {!userData?.company && submitClicked &&
                            <span className='text-red-500 text-sm'>{'This field is required'}</span>} */}
            </div>
          </div>
          <div className="form-item">
            <FormControl className={styles.radioGroup}>
              <div className="input-label">
                {" "}
                <label className="text-[#000000]">* Gender</label>
              </div>
              <RadioGroup name="gender" value={radioGender} onChange={handleChange} row>
                <FormControlLabel value="male" control={<Radio size="small" />} label="Male" />
                <FormControlLabel value="female" control={<Radio size="small" />} label="Female" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="form-item">
            <Grid container columnSpacing="5">
              <Grid item xs={12}>
                <div className="form-item ">
                  <SelectInput
                    name="country"
                    required={true}
                    label="Select your country"
                    options={countries}
                    Change={handleSelectContry}
                    loading={loadingCountries}
                    submitClicked={submitClicked}
                    error={!userData?.country && submitClicked}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="form-item ">
                  <SelectInput
                    name="city"
                    required={true}
                    label="Select your city (region/state/province options will appear with the city)"
                    labelHint="Select your country first"
                    options={cities}
                    Change={handleSelectChange}
                    loading={loadingCities}
                    value={selectedCity?.id ? selectedCity : null}
                    submitClicked={submitClicked}
                    disabled={!userData?.country}
                    error={!userData?.city && submitClicked}
                  />
                </div>
              </Grid>
            </Grid>
            {/* <SelectInput disablePortal={true} label='Your location' required={false} options={options} /> */}
          </div>
          {/* <div className="form-item">
            <SelectInput
              multiple={true}
              label="Tell us how you want to mentor"
              required={true}
              name="mentorShipTypes"
              labelHint="You can select multiple options."
              options={mentorShipTypeList}
              loading={loadingnMentorShipType}
              submitClicked={submitClicked}
              Change={handleSelectChange}
              error={!userData?.mentorShipTypes && submitClicked}
            />
          </div> */}
          {/* <div className="form-item">
            <SelectInput
              required={false}
              label="Alma Mater (this can connect you to students/early career professionals from your school)"
              placeholder="Enter latest education here..."
              disablePortal={true}
              options={institutionsList}
              loading={loadingnstitutions}
              Change={handleSelectChange}
              name="almaMater"
            />
          </div> */}
          <div className="form-item">
            <SelectInput
              required={true}
              error={!userData?.areaOfInterest && submitClicked}
              name="areaOfInterest"
              Change={handleSelectChange}
              label="Tell us how you want to mentor"
              options={allAreaInterest}
              loading={loadingAreaInterest}
              submitClicked={submitClicked}
              defaultValue={userData?.areaOfInterest}
              multiple={true}
            />
          </div>
          <div className="form-item">
            <SelectInput
              required={false}
              label="Enter majors you can mentor in"
              placeholder="Enter major"
              multiple={true}
              loading={loadingMajors}
              options={majors}
              name="majors"
              Change={handleSelectChange}
              submitClicked={submitClicked}
            />
          </div>
          <div className="form-item">
            <SelectInput
              required={true}
              label="Enter industries you can mentor in"
              placeholder="Enter industry..."
              multiple={true}
              Change={handleSelectChange}
              options={industry}
              loading={loadingIndustry}
              name="industries"
              error={!userData?.industries && submitClicked}
              submitClicked={submitClicked}
            />
          </div>
          <div className="form-item">
            <label className="relative flex justify-between">
              <span>* Enter a description that will appear on your profile for mentees</span>
              <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{userData?.about?.length || 0}/1500</p>
            </label>
            <textarea
              maxLength={1500}
              className="w-full border hover:border p-2 rounded-lg"
              rows={4}
              placeholder="Enter text here..."
              name="about"
              onChange={(e: any) => handleChange(e)}
            />
            {!userData?.about && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
          </div>
          {/* <div className='form-item'>
                    <InputField label='Enter a description that will appear on your profile for mentees'
                        required={true}
                        multiline={true}
                        rows={4}
                        placeholder='Enter text here...'
                        Change={handleChange}
                        submitClicked={submitClicked}
                        name="about"
                        error={!userData?.about && submitClicked}
                    />
                </div> */}
          <div className="form-item">
            <FormControl className={styles.radioGroup}>
              <div className="input-label">
                <label>
                  <span className="star">*</span>
                  Do you agree to have your profile featured on the public page of our website as a mentor?
                </label>
              </div>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="featuredAsPublic"
                value={radioValue}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="true" control={<Radio size="small" />} label="Yes" />
                <FormControlLabel value="false" control={<Radio size="small" />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div id="captcha-view">
            <div className="form-item m-t-20 text-underline mt-4 md:mt-0" id="captcha">
              <ReCAPTCHA sitekey={CAPTCHACLINTSECRET} ref={captchaRef} onChange={(e: any) => handleChangeCaptcha(e)} />
            </div>
          </div>
          <div className="mb-4">
            <Checkbox
              {...label}
              sx={{ ".MuiCheckbox-root": { paddingLeft: 0 } }}
              name="agreeToTerms"
              checked={agreeToTerms}
              onChange={handleChange}
            />
            *I agree to the{" "}
            <a href="https://edqan.com/terms-of-use/" target="_blank" className="main-link">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="https://edqan.com/privacy-policy/" target="_blank" className="main-link">
              Privacy Policy
            </a>
            .
          </div>
          {!userData?.agreeToTerms && submitClicked && (
            <span className="text-[#ff000e] text-md bg-[#ffdbdb] p-2 rounded my-4 block">
              Agreement to the Terms of Use and the Privacy Policy is required.
            </span>
          )}
          <p className={styles.notificationsHint}>Note: You will be able to update your profile once you are registered.</p>
          <p className={styles.hint}>
            Already have an account? <a onClick={handleClickLogin}>Login here</a>.{" "}
          </p>
          <div className={styles.actions}>
            <CustomButton text="Continue" type="submit" loading={loadingSubmit} />
          </div>
        </form>
      </div>
    </div>
  );
};
export default SignUp;
