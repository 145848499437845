import React, { useEffect, useState } from "react";
import LogoImage1 from "../../../../assets/images/temp/4.png";
import LogoImage2 from "../../../../assets/images/temp/2.png";
import LogoImage3 from "../../../../assets/images/temp/3.png";
import styles from '../inistitution.module.scss'
import { useNavigate } from "react-router";
import { Institution } from "../../../../models/institution";
import { useSelector } from "react-redux";
import { listCompanies, listCompaniesRemote, listCompaniesThatHiresFromSchool } from "../../../../services/students/companies";
import PopUpSendMesage from "../../../../Components/PopUp";



interface HeaderProps {
  institution?: any
}

export default function InistitutionInfo(props: HeaderProps) {
  const { institution } = props
  const navigate = useNavigate();
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [companiesThatHiresFromSchool, setCompaniesThatHiresFromSchool] = useState([])
  const [companies, setCompanies] = useState([])
  const [companiesRemote, setCompaniesRemote] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)
  const [open, setOpen] = useState(false);

  const getCompaniesList = async () => {
    const response = await listCompaniesThatHiresFromSchool({ currentPage: currentPage, id: user?._id })
    if (response) {
      setLoading(false)
      setCompaniesThatHiresFromSchool(response.data.data.queryResult)

    }

  }

  const getCountryCompaniesList = async () => {

    const response = await listCompanies({ currentPage: currentPage, filterName: "country", filterValue: user?.country?._id })
    if (response) {
      setCompanies(response.data.data.queryResult)
      // setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9))
      setLoading(false)
    }
  }
  const getRemoteCompaniesList = async () => {

    const response = await listCompaniesRemote({ currentPage: currentPage, filterName: "workType", filterValue: 'remote' })
    if (response?.data?.data) {
      setCompaniesRemote(response.data.data.queryResult)
      setLoading(false)
    }
  }
  useEffect(() => {
    if (token) {
      getCompaniesList()
      getCountryCompaniesList()
      getRemoteCompaniesList()
    }
  }, [token]);


  function handleClick(href: string) {
    navigate(href);
  }
  return (
    <div className="flex flex-col md:flex-row gap-x-9 header-underline m-b-20">
      {open && <PopUpSendMesage
        user={institution}
        show={open}
        setOpen={setOpen} resiverName={`${institution?.subModel?.name}`}
        resiverEmail={institution?.email}
        id={institution?._id} />}
      <div className="text-grey  mt-6 flex flex-col justify-between h-fit ">
        <h2 className="list-title">
          Companies that hire from our school
        </h2>
        <ul className={styles['Companies-list']}>
          {companiesThatHiresFromSchool?.map((item: any, index: number) => {
            return (
              index < 3 &&
              <li className="">
                <span className="w-2 h-2 bg-[#f26400] rounded-full"></span>
                {item?.subModel?.name}
              </li>

            )
          })}

        </ul>

        {companiesThatHiresFromSchool?.length > 0 && <a className="main-link mb-4 block" onClick={() => handleClick("/employers?active=0")} >View all</a>}

      </div>

      <div className="text-grey  mt-6 flex flex-col justify-between h-fit">
        <h2 className="list-title">Employers in your country</h2>
        <ul className={styles['Companies-list']}>
          {companies && companies.length > 0 && companies.map((item: any, index: number) => {
            return (
              index < 3 &&
              <li className="">
                <span className="w-2 h-2 bg-[#f26400] rounded-full"></span>
                {item?.subModel?.name}
              </li>

            )
          })}
        </ul>
        <a className="main-link mb-4 block" onClick={() => handleClick("/employers?active=1")}>View all</a>
      </div>

      <div className="text-grey  mt-6 flex flex-col justify-between h-fit">
        <h2 className="list-title">Employers that hire remote</h2>
           {(companiesRemote && companiesRemote.length > 0 )&&
           <ul className={styles['Companies-list']}>
       
         {  companiesRemote.map((item: any, index: number) => {
            return (
              index < 3 &&
              <li className="">
                <span className="w-2 h-2 bg-[#f26400] rounded-full"></span>
                {item?.subModel?.name}
              </li>

            )
          })}
        </ul>}
        <a className="main-link mb-4 block" onClick={() => handleClick(`/employers?active=2`)}>View all</a>
      </div>
      <div className="text-grey  mt-6">
        <h2 className="list-title">
          Contact Information for your Career Advising
        </h2>
        <ul className="py-4 list-disc list-inside text-[15px]">
          <li className=" my-2 text-sm flex gap-x-2">
            <p className="font-semibold">Website:</p>
            <a className="main-link " href={institution?.subModel?.schoolUrl} target="_blank">{institution?.subModel?.schoolUrl}</a>
          </li>
          {user?._id!=institution?._id?
            <li className=" my-2 text-sm flex gap-x-2">
            <p className="font-semibold">Career Advising email:</p>
            < a className="main-link " onClick={() => setOpen(true)} > Send Message</a>
           
          </li>:
           <li className=" my-2 text-sm flex gap-x-2">
            <p className="font-semibold">Career Advising email:</p>
            < a className="main-link " href={`mailto:${institution?.subModel?.careerAdvisingEmail}`}>{institution?.subModel?.careerAdvisingEmail}</a>
            {!institution?.subModel?.careerAdvisingEmail && 'Not available'}
          </li>
          }
         
          <li className=" my-2 text-sm flex gap-x-2">
            <p className="font-semibold">Career Advising Phone:</p>{institution?.subModel?.careerAdvisingPhone}
          </li>
          <li className=" my-2 text-sm flex gap-x-2">
            <p className="font-semibold">Location:</p>
            {institution?.city?.nameEn}, {institution?.country.nameEn}
          </li>
         {user?._id!=institution?._id&& <li className=" my-2 text-sm flex gap-x-2">
            <p className="font-semibold">Message:</p>
            <a onClick={() => setOpen(true)} className="main-link">Career Advising</a>
          </li>}
        </ul>
      </div>
    </div>
  );
}
