import JobDetails from './index'
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { SingleGobs } from '../../../services/jobsService';
import { useSelector } from 'react-redux';
const SingleJobDetails = () => {
  const { id } = useParams()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const isCompany= window.location.href.includes('company')
  const [loadingSingle, setLoadingSingle] = useState(true)
  const [selectedJob, setSelectedJob] = useState({})
  const { pathname } = useLocation()
  const getSingleGob = async () => {
    setLoadingSingle(true)
    const response = await SingleGobs(id,user._id,user?.model!="Student")
    if (response.status === 200) {
      setLoadingSingle(false)
      setSelectedJob(response.data.data)
    }
  }


  useEffect(() => {
      window.scrollTo(0, 0);

  }, [pathname]);
  useEffect(() => {
    if (token) {
      getSingleGob()
    }

  }, [token,pathname]);
  return (

    <JobDetails selectedJob={selectedJob} loading={loadingSingle} />
  )
}
export default SingleJobDetails;

