import * as React from "react";
import { useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from './editor.module.scss'
interface TextEditorProps {
  Change?: any;
  value?: any;
  name?: string
  defaultValue?: string;
  formik?: any
  submitClicked?: boolean;
  error?: any
  maxLength?: number
  label?: any

}
export default function TextEditor(props: TextEditorProps) {
  const { Change, name, submitClicked, formik, error, value, maxLength, label } = props
  const reactQuillRef: any = useRef(null);
  const editor = reactQuillRef?.current?.getEditor();
  const unprivilegedEditor = reactQuillRef?.current?.makeUnprivilegedEditor(editor);
  // You may now use the unprivilegedEditor proxy methods
  // unprivilegedEditor.getText();
  const textLength = (unprivilegedEditor?.getText()?.length) - 1
  return (
    <>
      {label && maxLength && <label className="relative flex justify-between">
        <span >{label}</span>
        <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">
          <span className={textLength > maxLength ? 'text-red-500' : ''}>{textLength || 0}</span>
          /{maxLength}</p>

      </label>}
      <ReactQuill placeholder="Enter text here..."
        ref={reactQuillRef}
        value={value}
        className={styles['ql-container']}
        style={{
          height: "200px",
          border: error || (maxLength && textLength > maxLength) ? "1.25px solid red" : "1.25px solid rgba(165, 164, 164, 0.786)",
          borderRadius: '8px'
        }}
        onChange={e => Change(e, textLength)}
      />
      {error && submitClicked && (
        <span className='text-[#ff000e] text-sm'>This field is required</span>
      )}
      {maxLength && textLength > maxLength && (
        <span className='text-[#ff000e] text-sm'>You have exceeded the maximum number of characters allowed for this field. Please remove the extra characters to continue</span>
      )}


    </>


  )
}
