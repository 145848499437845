import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckIcon from '@mui/icons-material/Check';
import styles from "./job.module.scss";
import CloseIcon from '@mui/icons-material/Close';
interface JobMatcherProps {
  loading: boolean
  selectedJob: any
}
export default function JobMatcher(props: JobMatcherProps) {
  const { loading, selectedJob } = props
  const jobPreference = selectedJob.jobPreference
  return (
    <>
      {loading ?
        <JobMatcherLoading /> :
        <div className={[styles["job-match"], "p-4"].join(" ")}>
          <h3 className="secondary-title text-center my-4">
            You match the main job criteria for this role
          </h3>
          <p className="text-base mb-4">
            You are more likely to be interviewed by the employer you if you match
            these preferences
          </p>
          <div>
            <div className="grid grid-rows-3 grid-flow-col gap-1 my-4 lg:grid-rows-2">
              {Object.keys(jobPreference).map(key => {
                return (
                  <div className="flex">

                    {jobPreference[key].term && <div className={[styles["check-box"], 'flex'].join(' ')}>
                      <FormControlLabel
                        control={<Checkbox icon={jobPreference[key].matches ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />} />}
                        label=""
                        disabled
                      />
                      <label className="capitalize">{jobPreference[key].term}</label>
                    </div>
                    }
                  </div>

                )
              })}
            </div>
            {selectedJob?.additionalComments && <h3 className="text-base font-semibold mb-4">Additional comments:</h3>}
            {selectedJob?.additionalComments}

          </div>
        </div>
      }
    </>

  );
}
const JobMatcherLoading = () => {
  return (
    <div className={[styles["container"], 'animate-pulse'].join(' ')}>
      <div>
        <div className="h-48 bg-gray-200 rounded col-span-1  mb-2"></div>
      </div>
    </div>
  )
}