import { Checkbox, Container, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import CustomButton from "../../../Components/CustomButton";
import { useState } from "react";


interface HomeProps {

}

const Guidelines = (props: HomeProps) => {
    const [value,setValue]=useState("yes")
    return (
        <div className="page">
            <Container>
                <h2 className="title header-underline">Edqan Employer Guidelines</h2>
                <div className="form-item md:flex items-baseline gap-4">
                    <label>Are you a 3rd party company working on behalf of a company?</label>
                    <RadioGroup row>
                        <FormControlLabel
                            value="yes"
                            control={<Radio size="small" />}
                            label="Yes"
                            onChange={()=>setValue("yes")}
                            checked={value==="yes"}
                        />
                        <FormControlLabel
                            value="no"
                            control={<Radio size="small" />}
                            label="No"
                            onChange={()=>setValue("no")}
                            checked={value==="no"}
                        />
                    </RadioGroup>
                </div>
                {value==="yes"&&
                <div>
                       <p className="main-description my-4">Jobs posted by recruiters and 3rd parties must be for a single company. The job posting should clearly show the company name you are posting for and that you representing them. You cannot disclose any student information to another company or party without their written consent.</p>
                <div className='mb-4'>
                    <Checkbox sx={{ '.MuiCheckbox-root': { paddingLeft: 0 } }} />
                    I agree and comply to the guidelines mentioned above as a third party recruiter.
                </div>
               
                </div>
             
    }
     <div className="form-item block">
                    <label>By continuing, it means you agree to the <a className='main-link'>terms of Use</a> and <a className='main-link'>privacy Policy</a> and the Edqan employer guidelines.
                    </label>
                    <div className="custom-action mt-8">
                        <CustomButton text={"Continue"} />
                    </div>
                </div>
            </Container>


        </div>
    );
};

export default Guidelines;