import { useEffect, useState } from "react";
import CustomizedButtons from "../../../../Components/CustomButton";
import PopUpSendMesage from "../../../../Components/PopUp";
import styles from "./applysection.module.scss";
import ShowMoreText from "react-show-more-text";
import LogoImage from "../../../../assets/images/temp/1.png";
import JobApply from "../../JobApply";
interface ApplySectionProps {
  nested?: boolean
  loading: boolean
  selectedJob: any
}
export default function ApplySection(props: ApplySectionProps) {
  const { nested, loading, selectedJob } = props;
  const [open, setOpen] = useState(false);
  const [applied, setApplied] = useState(false);
  const [applyPopup, setApplyPopup] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const applyClickHandler = () => {
    setApplyPopup(true);
  };

  useEffect(() => {
    if (!loading) {
      // let benefitsID = document.getElementById('benefits') as HTMLElement
      // benefitsID.innerHTML = selectedJob?.benefits||''
       setApplied(false)

    }
  }, [loading])

  return (
    <>
      {loading ?
        <ApplySectionLoading /> :
        <div className={styles["container"]}>

          <div className={[styles["job-description"]].join(" ")}>
            {/* <h3 className="secondary-title">Role Description</h3>
            <p className="main-description" id="description"></p> */}
            {selectedJob.applicationUrl && selectedJob?.applicationType === 'external'&&
              <h3 className="text-base font-semibold my-4  ">
                Apply Here:
                <a href="www.com" className="break-words" >
                  {selectedJob.applicationUrl}
                </a>
              </h3>
            }
          </div>


        
          <div>
            {selectedJob?.applicationType !== 'external' && <div className="action-section pt-4 flex justify-center mb-8">
              {selectedJob.applied || applied ?

                <p className="p-2 border-green-all Applied_label rounded-xl bg-light-green">Looks like you already applied for this job</p> :
                <CustomizedButtons text="Apply internally on edqan" click={applyClickHandler} />
              }
              {applyPopup && <JobApply show={applyPopup} setApplied={setApplied} setOpen={setApplyPopup} loading={loading} selectedJob={selectedJob} />}
            </div>
            }
          </div>
          <div>
            {selectedJob?.receiveMessage &&
              <div className={[styles["recrutier-card"], "flex", 'w-full', nested ? 'w-full' : 'lg:w-3/5', ''].join(" ")}>
                <h3 className="secondary-title">You have the option to see if this job is right for you before applying. The employer will allow a direct message.</h3>
                <div className="flex">
                  <img src={LogoImage} className={styles['logo']} alt="logo" />
                  <div className="flex justify-between">
                    <div className="pt-4 text-left">
                      <p className="text-base font-semibold mb-1">Company Name</p>
                      <p className="text-sm">Before sending a message, please read the job description below.</p>
                    </div>
                  </div>
                </div>
                <CustomizedButtons text="Send message" click={handleClick} />
                <PopUpSendMesage user={null} show={open} setOpen={setOpen} />
              </div>
            }
          </div>
        </div>
      }
    </>

  );
}
const ApplySectionLoading = () => {
  return (
    <div className={[styles["container"], 'animate-pulse'].join(' ')}>

      <div className={[styles["job-description"]].join(" ")}>
        <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 mb-2"></div>
        <p className="main-description">
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
        </p>
        <h3 className="text-base font-semibold my-4  ">
          <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
        </h3>
      </div>


      <div className={[styles["job-description"]].join(" ")}>
        <div className="h-2 bg-gray-200 rounded col-span-1 w-1/4 mb-2"></div>
        <p className="main-description">
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
          <div className="h-1 bg-gray-200 rounded col-span-1 mb-2"></div>
        </p>
      </div>
      <div>
        <div className="action-section pt-4 flex justify-center mb-8">
          <div className="h-12 bg-gray-200 rounded col-span-1 w-1/4 mb-2"></div>
        </div>

      </div>
      <div>
        <div className="h-48 bg-gray-200 rounded col-span-1  mb-2"></div>
      </div>
    </div>
  )
}