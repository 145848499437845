export const InistitutionCardLoadingSkeleton = () => {
    return (
      <div className="border border-[#efeeee]  rounded-md p-4 max-w-lg w-full mx-auto">
        <div className="animate-pulse flex space-x-8">
          <div className="rounded bg-gray-200 h-[155px] w-[206px]"></div>
          <div className="flex-1 space-y-6 py-1 block my-auto">
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-gray-200 rounded col-span-2"></div>
                <div className="h-2 bg-gray-200 rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }