

import { Grid } from '@mui/material';
import LogoImage from '../../../../assets/images/Logo.png'
import styles from './banner.module.scss'
interface LoginProps {
    title:string
}

const Login = (props: LoginProps) => {
    const {title}=props
    return (
        <div className={styles['banner']}>
              <div className={styles.logo}>
                <img src={LogoImage}/>
            </div>
            <div  className={styles.content}>
                {/* <h2>{title}</h2> */}
                {/* <ul >
                    <li>
                        <h3>Students/Alumni/Early Career Professional
                        </h3>
                        <p>Find the right opportunity for in person or remote work</p>
                    </li>
                    <li>
                        <h3>Employers</h3>
                        <p>
                            Hire the right talent for in-person or remote work
                        </p>
                    </li>
                    <li>
                        <h3>Educational Institutions</h3>
                        <p>
                            Support your students in finding their ideal job
                        </p>
                    </li>
                    <li>
                        <h3>Mentors</h3>
                        <p>
                            Leverage your experience and professional skills wherever you are to help students bridge the gap to their ideal job</p>
                    </li>
                
                </ul> */}
        
            </div>
        </div>
      
    );
};

export default Login;