import React, { useEffect, useState } from 'react'
import SelectInput from '../../SelectInput';
import { useSelector } from 'react-redux';
import { Language } from '../../../models/language';
import { listAllMajor } from '../../../services/majorsServise';

interface SelecttionProps {
    handleChange: any
    defaultValue?: any
    label: string
    multiple: boolean
    labelHint?: string
    name: string,
    required: boolean,
    submitClicked?: any
    formik?: any
    error?:boolean
    
}

export default function MajorsSelection(props: SelecttionProps) {
    const { handleChange, defaultValue, label, multiple, labelHint, name, required, submitClicked, formik,error } = props
    const token = useSelector((state: any) => state.auth.token);
    const [optionsList, setOptionsList] = useState([]);
    const [loading, setLoading] = useState(true);

    const getOptionsList = async () => {
        const response = await listAllMajor()
        if (response) {
            const langs = response.data.data.queryResult.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
            setOptionsList(langs)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (token) { getOptionsList() }
    }, [token]);

    return (
        <SelectInput
            labelHint={labelHint}
            name={name}
            required={required}
            label={label}
            options={optionsList}
            multiple={multiple}
            Change={handleChange}
            loading={loading}
            defaultValue={defaultValue ? defaultValue : null}
            submitClicked={submitClicked}
            formik={formik}
            error={error}
        />
    )
}
