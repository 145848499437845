import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Avatar, TextareaAutosize } from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CustomizedButtons from "../CustomButton";
import CloseIcon from '@mui/icons-material/Close';
import styles from './model.module.scss'
import { sendMesage } from "../../services/mesages";
import { addAlertInfo } from "../../store/alertSlice";
import { useDispatch } from "react-redux";
interface PopUpProps {
  show: boolean;
  setOpen?: any;
  resiverName?: any
  resiverEmail?: any
  id?: string
  user: any
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: '16px',
  boxShadow: 0,
  p: 4,
  border: '0',
  outline: 0,
};

function PopUpSendMesage(props: PopUpProps) {
  const { show, setOpen, resiverName, resiverEmail, id, user } = props;
  const dispatch = useDispatch()
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const handleClose = () => setOpen(false);
  const formData = new FormData()
  const handleSendMesage = async () => {
    setLoading(true)
    id && formData.append("receiver", id)
    formData.append("message", text)
    const response = await sendMesage(formData)
    if (response.status === 201) {
      setText('')
      handleClose()
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Message sent successfully',
        }
      ))
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }


  }
  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style}>
          <div className="flex justify-end " >
            <CloseIcon className="cursor-pointer" onClick={handleClose} />

          </div>
          <p className="font-semibold text-lg text-orange">Send your message</p>
          <p className={styles.model_text}>
            For future reference this message will appear in your edqan inbox.
          </p>
          <div className="flex my-6">
            <Avatar className='mr-4 object-contain' 
              src={user?.profileImage||user?.subModel?.logo||user?.user?.profileImage} sx={{ width: 70, height: 70, bgcolor: "#F26400" }} aria-label="avatar">
              <a className='capitalize text-white'>
                {user?.subModel?.firstName?.charAt(0)?.toUpperCase()||user?.firstName?.charAt(0)?.toUpperCase()}{user?.subModel?.lastName?.charAt(0)?.toUpperCase()||user?.lastName?.charAt(0)?.toUpperCase()} {user?.subModel?.name?.charAt(0)}</a>
            </Avatar>
            <div className="w-full">
              <p>{resiverName}</p>
              {/* <p className="mb-8">{resiverEmail}</p> */}
              <TextField
                id="filled-multiline-static"
                label=""
                multiline
                fullWidth
                rows={5}
                placeholder="Enter your message"
                InputProps={{ disableUnderline: true }}
                maxRows={4}
                onChange={(e: any) => setText(e.target.value)}
              />

            </div>
          </div>
          <div className="flex flex-row-reverse">
            <CustomizedButtons text="Send Message" disabled={!text} click={handleSendMesage} loading={loading} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default PopUpSendMesage;
