import { Container } from '@mui/system'
import { Rating } from '@mui/material';
import React, { useState } from 'react'
import InputField from '../../../Components/Input';
import CustomizedButtons from '../../../Components/CustomButton';
import { useNavigate, useParams } from 'react-router';
import { menteeRatting, mentorRatting } from '../../../services/mentors';
import { addAlertInfo } from '../../../store/alertSlice';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

interface RatingProps {
    type: string
}
export default function RatingPage(props: RatingProps) {
    const { type } = props;
    const parms = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const username = searchParams.get("username")

    const dispatch = useDispatch()
    const { id } = parms
    const [rate, setRateValue] = useState(0)
    const [whatYouLiked, setWhatYouLiked] = useState('')
    const [improvementSuggestion, setImprovementSuggestion] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const handleNavigation = () => {
        if (type === 'Mentor') {
            navigate(`/mentor/mentorship-program`)
        }
        else {
            navigate(`/professionals/mentorship-sessions`)
        }


    }
    const handleOnClick = async () => {
        setLoading(true)
        const data = type == 'Mentor' ?
            {
                "mentorShipSession": {
                    "mentorRating": {
                        rate,
                        whatYouLiked,
                        // improvementSuggestion
                    }
                }
            } :
            {
                "mentorShipSession": {
                    "menteeRating": {
                        rate,
                        whatYouLiked,
                        // improvementSuggestion
                    }
                }

            }
        const response = type === 'Mentor' ? await menteeRatting(id, data) : await mentorRatting(id, data)
        if (response?.status === 200) {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'Data updated successfully',
                }
            ))
            handleNavigation()
        }
        else {
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response?.data?.error,
                    type: 'error'
                }
            ))
        }
    }

    return (
        <Container>
            <div>
                <h3 className='title header-underline'>Mentorship Program - {type} Experience</h3>
                <p className='secondary-title'>Describe your mentorship experience</p>
                <p className='main-description '>How would you rate your experience with <span className='colored'>{username}</span>? Five stars are the highest. Click on the stars to rate.</p>

                <div className='my-8'>
                    <Rating name="read-only"
                        value={rate}
                        size="large"
                        onChange={(event, newValue: any) => {
                            setRateValue(newValue);
                        }}
                        precision={0.5}
                    />
                </div>

                <div className="form-item">
               
                    <label className="relative flex justify-between">
                        <span >What you liked and learned?</span>
                        <p className="absolute right-0 bottom-0 text-[11px] text-gray-500 text-end mb-1">{whatYouLiked?.length || 0}/700</p>

                    </label>
                    <textarea
                        maxLength={700}
                        className="w-full border hover:border p-2 rounded-lg"
                        rows={8}
                        required={true}
                        placeholder='Tell what you learned'
                        onChange={(e: any) => setWhatYouLiked(e.target.value)} />
                </div>
                {/* <div className='my-6'>
                    <InputField
                        label='What areas of improvement do you suggest?'
                        required={false}
                        multiline={true}
                        rows={6}
                        textArea={true}
                        placeholder='Tell us your Suggestions'
                        Change={(e: any) => setImprovementSuggestion(e.target.value)}
                    />
                </div> */}
                <div className='flex justify-end'>
                    <CustomizedButtons text='Submit' click={handleOnClick} loading={loading} />
                </div>

            </div>
        </Container>
    )
}
