import React, { useState, useCallback, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import { Box } from "@mui/material";
import CustomButton from "../CustomButton";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { getOrientation } from "get-orientation";
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
interface ImageCropProps {
    show: boolean;
    setOpen?: any;
    type?: any;
    handleSubmit?:any
}
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    borderRadius: '16px',
    boxShadow: 0,
    p: 4,
};
const ORIENTATION_TO_ANGLE: any = {
    3: 180,
    6: 90,
    8: -90,
};
export default function ImageCrop(props: ImageCropProps) {
    const { show, setOpen, type,handleSubmit } = props;
    const [imagefile, setImagefile] = useState<File>();
    const handleClose = () => setOpen(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [cropSize, setCropSize] = useState({ width: 300, height: 300 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState()
    const [selectimage, setSelectImage] = useState();
    const inputUploadFile: any = useRef(null);
    const changeFile = () => {
        inputUploadFile.current.click();
    };
    const onFileChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            
            const file = e.target.files[0];
            setImagefile(e.target.files[0]);
            let imageDataUrl: any = await readFile(file);

            // apply rotation if needed
            const orientation = await getOrientation(file);
            const rotation = ORIENTATION_TO_ANGLE[orientation];
            if (rotation) {
                //imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
            }
            setSelectImage(imageDataUrl);
        }
    };
    function readFile(file: any) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }
    const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: any) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])
    const handleSave = () => {
        handleSubmit(selectimage,imagefile)
        handleClose()
    };

    useEffect(() => {
        if (type === "banner") {
            setCropSize({ width: 600, height: 280 })
        }
        else{
            setCropSize({ width: 300, height: 300 })
        }
    }, []);


    return (
        <div>
            <Modal
                open={show}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            ><Box sx={style}>
                    <div className="crop-container">
                        {!selectimage && <PhotoSizeSelectLargeIcon />}
                        <Cropper
                            image={selectimage}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={1}
                            cropShape={type === "image" ? "round" : "rect"}
                            showGrid={false}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            cropSize={cropSize}
                        />
                    </div>
                    {type === "banner"&&<p className="mb-2 text-sm">Zoom in & out to get the perfect selection</p>}
                    <div className="custom-action" style={{ justifyContent: "center" }}>
                        <CustomButton text={"Click here to upload"} icon={<FileUploadIcon />} click={changeFile}></CustomButton>
                        <input
                            type="file"
                            accept="image/jpeg,image/gif,image/png,image/jpg"
                            onChange={onFileChange}
                            hidden
                            ref={inputUploadFile}
                        />
                    </div>

                    <div className="modal-footer">
                        <div className="custom-action custom-action-fill mt-4 main-border-top">
                            <div className="cancel mr-2">
                                <CustomButton type="button" text={"Cancel"} click={handleClose}></CustomButton>

                            </div>
                            <CustomButton type="button" text={"Save"} click={handleSave}></CustomButton>

                        </div>

                    </div>
                </Box>


            </Modal>
        </div>
    )
}