import { Grid } from "@mui/material";
import LogoImage from "../../../../assets/images/Logo.png";
import styles from "./banner.module.scss";
interface LoginProps {

}

const Login = (props: LoginProps) => {
  return (
    <div className={styles["banner"]}>
      <div className={styles.logo}>
        <img src={LogoImage} />
      </div>
      <Grid container className={styles.content}>
        <ul>
          <li>
            <h3>Students & Early Career Professionals</h3>
            <p>Find the right opportunity for in person or remote work and the right mentor to guide you in your career path</p>
          </li>
          <li>
            <h3>Employers</h3>
            <p>Hire the right talent for in-person or remote work</p>
          </li>
          <li>
            <h3>Educational Institutions</h3>
            <p>Support your students in finding their ideal job</p>
          </li>
          <li>
            <h3>Mentors</h3>
            <p>
              Leverage your experience and professional skills wherever you are
              to help students bridge the gap to their ideal job
            </p>
          </li>
        </ul>
      </Grid>
    </div>
  );
};

export default Login;
