

import React, { useEffect, useState } from "react";
import CustomizedButtons from "../../../Components/CustomButton";
import { followedCompany, listFollowedCompanies } from "../../../services/followingService";
import { useDispatch, useSelector } from "react-redux";
import { addDataInfo } from "../../../store/followSlice";
import { addAlertInfo } from "../../../store/alertSlice";

interface CompanyProps {
  followText: any
  unFollowText: any
  id: string
  index?: number
}
export default function CompanyInfo(props: CompanyProps) {
  const dispatch = useDispatch()
  const { followText, unFollowText, id, index } = props
  const [loading, setLoading] = useState(false)
  const followedCompanies = useSelector((state: any) => state.companies.FollowedCompanies);
  const user = useSelector((state: any) => state.auth.user);
  const handleIsFollowAction = async () => {
    setLoading(true)
    const data = {
      "followedCompanies": id
    }
    const response = await followedCompany(data,user._id)
    if (response) {
      getAllFollwedCompany()
      setLoading(false)
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  

  const getAllFollwedCompany = async () => {
    const response = await listFollowedCompanies(user._id)
    if (response) {
      dispatch(addDataInfo(
        { FollowedCompanies: response?.data?.data?.followedCompanies?.map((item: any) => item._id) }
      ))
      setLoading(false)
    }
  }
  const isFollowedCompany = () => {
    if (followedCompanies) {
      if (followedCompanies.includes(id)) {
        return true
      }
      else {
        return false
      }
    }
    return false
  }
  useEffect(() => {
    if (!followedCompanies) {
      if (index) {
        if (index === 0) {
          getAllFollwedCompany()
        }
      }
      else {
        getAllFollwedCompany()
      }

    }
  }, [followedCompanies]);
  return (
    <div className={`actions-center rounded-action custom-action ${isFollowedCompany() && 'custom-action-fill'}`}>

      <CustomizedButtons
        text={!isFollowedCompany() ? followText : unFollowText}
        click={handleIsFollowAction} loading={loading} />
    </div>
  )
}