import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import BackgroundInfo from "./BackgroundInfo";
import Header from "./InistitutionHeader";
import CompanyMedia from "./CompanyMedia";
import Students from "./Students";
import { Avatar, Box, Checkbox, FormControlLabel, Grid, List, ListItem, ListItemText, Tab, Tabs, Typography } from "@mui/material";
import Reviews from "./Reviews";
import MailIcon from "@mui/icons-material/Mail";
import CarouselList from "../../../Components/CardsList";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getUserInfo } from "../../../services/userService";
import { listCompanyStudents } from "../../../services/company/companyStudents";
import NoDataMessage from "../../../Components/NoDataMessage";
import { listCompanyJobPosts } from "../../../services/company/jobPostsService";
import CloseIcon from "@mui/icons-material/Close";
import PopUpSendMesage from "../../../Components/PopUp";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function EmployerInformation() {
  const [value, setValue] = React.useState(0);
  const [Studentslist, setStudentslist] = useState([]);
  const [totlaPages, setTotlaPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //pass
  };

  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [user, setUserInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [loadingStudents, setloadingStudents] = useState(true);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleSendMesage = () => {
    setOpen(true);
  };

  function handleClick(href: string) {
    navigate(href);
  }
  const parms = useParams();
  const { id } = parms;

  const getUserData = async () => {
    const response = await getUserInfo(id);
    if (response.status === 200) {
      setLoading(false);
      setUserInfo(response.data.data);
    } else {
    }
  };
  const getCompanyStudents = async () => {
    const response: any = await listCompanyStudents(id ? id : userInfo._id, currentPage, 4);
    if (response.status === 200) {
      setloadingStudents(true);
      setStudentslist(response.data.data.queryResult);
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 4));
    }
  };

  const [jobslist, setJobslist] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);

  const getCompanyJobs = async () => {
    const response = await listCompanyJobPosts(id ? id : userInfo._id, currentPage, 9);
    if (response.status === 200) {
      setLoadingJobs(false);
      setJobslist(response.data.data.queryResult);
    } else {
      setLoadingJobs(false);
    }
  };

  useEffect(() => {
    if (token) {
      getCompanyJobs();
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getCompanyStudents();
    }
  }, [token]);

  useEffect(() => {
    if (token && !id) {
      setUserInfo(userInfo);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, []);

  return (
    <Container>
      {open && <PopUpSendMesage user={user} show={open} setOpen={setOpen} resiverName={`${user?.subModel?.name}`} resiverEmail="" id={user?._id} />}
      {token ? (
        <div className="page">
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Header user={user} />
              <Box>
                <Tabs
                  className="tabs"
                  variant="scrollable"
                  allowScrollButtonsMobile
                  sx={{
                    "& .MuiTabs-indicator": { backgroundColor: "#F26400 ", color: "#F26400" },
                    "& .Mui-selected": { color: "#F26400" },
                  }}
                  value={value}
                  onChange={handleChange}
                >
                  <Tab className="tab" label="Background" {...a11yProps(0)} onClick={() => setValue(0)} />
                  <Tab className="tab" label="Reviews" {...a11yProps(1)} onClick={() => setValue(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <BackgroundInfo user={user} />
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Reviews />
                </TabPanel>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="gray-box">
                <h3 className="box-title  mb-2">Contact Information</h3>
                <div className="form-item mb-2">
                  <label className="m-b-0">Website</label>
                  <a href={user?.subModel?.webSite} className="colored main-description block link break-words ">
                    {user?.subModel?.webSite}
                  </a>
                </div>
                <div className="form-item mb-2 ">
                  <label className="m-b-0">Phone #</label>
                  <p className="main-description link">{user?.subModel?.phoneNumber}</p>
                </div>
                {/* <div className="form-item mb-2">
                  <label className="m-b-0">Email</label>
                  <a href={`mailto:${user?.email}`} className="colored main-description block link break-words">{user?.email}</a>
                </div> */}
                <div className="form-item mb-2">
                  {
                    id && (
                      // <p className="flex items-baseline">to schedule an appointment with {user?.subModel.firstName}
                      <a className="main-link  block small-text" onClick={() => handleSendMesage()}>
                        Send a message
                      </a>
                    )
                    // </p>
                  }
                </div>
                {/* <ul className="social-media-list">
                  <li>
                    <MailIcon />
                  </li>
                  <li>
                    <svg
                      width="8"
                      height="17"
                      viewBox="0 0 8 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.184 16.0293V8.76263H7.552L7.936 5.89596H5.248V4.09596C5.184 3.2293 5.376 2.69596 6.528 2.69596H8V0.16263C7.68 0.0959635 6.848 0.0292969 5.824 0.0292969C3.712 0.0292969 2.304 1.36263 2.304 3.76263V5.8293H0V8.69596H2.368V16.0293H5.184Z"
                        fill="white"
                      />
                    </svg>
                  </li>

                  <li>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 1.66566C0 1.18081 0.1875 0.817176 0.5 0.514145C0.8125 0.211115 1.25 0.0292969 1.8125 0.0292969C2.3125 0.0292969 2.75 0.150509 3.125 0.453539C3.4375 0.75657 3.625 1.18081 3.625 1.66566C3.625 2.15051 3.4375 2.51415 3.125 2.81718C2.8125 3.12021 2.375 3.30202 1.8125 3.30202C1.25 3.30202 0.8125 3.12021 0.5 2.81718C0.1875 2.51415 0 2.15051 0 1.66566ZM0.1875 13.9687V4.57475H3.375V13.9687H0.1875ZM5.1875 13.9687H8.375V8.75657C8.375 8.45354 8.4375 8.15051 8.5 7.96869C8.625 7.66566 8.8125 7.36263 9.125 7.18081C9.375 6.93839 9.75 6.87778 10.1875 6.87778C11.25 6.87778 11.8125 7.60505 11.8125 8.99899V14.0293H15V8.63536C15 7.24142 14.6875 6.21112 14 5.48384C13.3125 4.75657 12.4375 4.39293 11.3125 4.39293C10.0625 4.39293 9.125 4.93839 8.375 5.96869V4.57475H5.1875C5.1875 4.87778 5.1875 5.78687 5.1875 7.36263C5.1875 8.93839 5.1875 11.1202 5.1875 13.9687Z"
                        fill="white"
                      />
                    </svg>
                  </li>

                </ul> */}
              </div>
              {user?.socialMediaLinks
                ?.map((item: any) => item?.url?.length)
                .reduce((accumulator: any, currentValue: any) => {
                  return accumulator + currentValue;
                }, 0) > 0 && (
                <div className="gray-box mt-2">
                  <h3 className="box-title  mb-2">Company social media</h3>
                  {user?.socialMediaLinks?.map((item: any) => {
                    return (
                      <>
                        {item?.url?.length > 0 && (
                          <div className="form-item mb-2">
                            <label className="m-b-0">{item?.socialMedia?.nameEn}</label>
                            <ul className="space-y-2">
                              {item?.url?.map((url: any) => {
                                return (
                                  <li className="">
                                    <a href={url} className="colored main-description block link break-words" target="_blank">
                                      {" "}
                                      {url}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
              <div className="mt-4 CarouselListSingle">
                <h3 className="box-title mb-2 text-center mb-4">Jobs posted at {user?.subModel?.name}</h3>
                <CarouselList defaultCount={1} list={jobslist} loading={loadingJobs} listAllData={getCompanyJobs} />
                {jobslist.length > 0 && (
                  <a
                    className="main-link flex w-full justify-end mt-2"
                    onClick={() => handleClick(id ? `/company-jobs/${id}/${user?.subModel?.name}` : "/company/jobs")}
                  >
                    See all
                  </a>
                )}
                {/* {id && <div className="flex align-center mt-4 receive-notifications-checkbox">
                  <FormControlLabel
                    control={<Checkbox />}
                    label=""
                  />
                  <label><p className="description">Click here to receive new notifications when jobs are posted.</p></label>
                </div>
                } */}
              </div>
            </Grid>
          </Grid>
          {value === 0 && (
            <Grid item xs={12}>
              <CompanyMedia user={user} />
              <Grid container spacing={4}>
                <Grid item md={12} xs={12}>
                  <Students Studentslist={Studentslist} Cname={user?.subModel?.name} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      ) : (
        <NoDataMessage />
      )}
    </Container>
  );
}

export default EmployerInformation;
