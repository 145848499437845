import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedButtons from "../../../../../Components/CustomButton";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextEditor from "../../../../../Components/Editor";
import InputField from "../../../../../Components/Input";
import SelectInput from "../../../../../Components/SelectInput";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Language } from "../../../../../models/language";
import { listAllLanguages } from "../../../../../services/languageService";
import { listAllCountries, listAllCities } from "../../../../../services/locationService";
import { listAllMajor } from "../../../../../services/majorsServise";
import { addAlertInfo } from "../../../../../store/alertSlice";
import { ListAllJobsType, ListAllWorkType } from "../../../../../services/jobsService";
import { listAllCurrency } from "../../../../../services/currencyService";
import { CreateNewJob, EditJob, getSingleJobPosts } from "../../../../../services/company/jobPostsService";
import Questions from "../AddQuistions";
import IndustriesUI from "../../Tabs/TabOne/Industries";

export default function CreateJobForm(props: any) {
  const validateList = ["name", "jobType", "workType", "country", "city", "workAuthorization", "roleDescription", "hoursPerWeek"];
  const validateListNames = [
    "Role title",
    "Job type",
    "work type",
    "Country",
    "City",
    "Work authorization",
    "Role description",
    "Number of hours per week this role requires",
  ];

  const userInfo = useSelector((state: any) => state.auth.user);

  const [job, setJob] = useState<any>({
    salary: null,
  });
  const [upDatedjob, setUpDatedjob] = useState<any>({});
  const [upDatedjobPreference, setUpDatedjobPreference] = useState<any>({});

  const [jobPreference, setJobPreference] = useState<any>({});

  const [deadLine, setDeadLine] = useState<any>(new Date());
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setendDate] = useState<any>(new Date());
  const [graduationDate, setGraduationDate] = useState<any>(null);
  const [Languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [majors, setMajors] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [loadingCurrencies, setLoadingCurrencies] = useState(false);

  const [loadingLanguages, setLoadingLanguages] = useState(true);
  const [loadingMajors, setLoadingMajors] = useState(true);

  const [loadingCountries, setLoadingCountries] = useState(true);
  const [loadingCities, setLoadingCities] = useState(false);

  const [submitClicked, setSubmitClicked] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [WorkTypeList, setWorkTypeList] = useState([]);
  const [loadingWorkType, setLoadingWorkType] = useState(true);
  const [jobsTypeList, setJobsTypeList] = useState([]);

  const [loadingJobsType, setLoadingJobsType] = useState(true);
  const [disabledSalary, setDisabledSalary] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const WorkAuthorizationList = [
    {
      label: "Will be provided for country",
      id: 0,
    },
    {
      label: "Yes required for country",
      id: 1,
    },
    {
      label: "Not required for country",
      id: 2,
    },
  ];

  const getAllJobsType = async () => {
    const response = await ListAllJobsType();
    if (response) {
      const data = response.data.data.queryResult.map((item: any) => ({ label: item.nameEn, id: item._id, type: "jobType" }));
      setJobsTypeList(data);
      setLoadingJobsType(false);
    }
  };
  const getAllWorkType = async () => {
    const response = await ListAllWorkType();
    if (response) {
      const data = response.data.data.queryResult.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setWorkTypeList(data);
      setLoadingWorkType(false);
    }
  };
  function handleClick() {
    navigate("/professionals-home");
  }

  function handleClickLogin() {
    navigate("/");
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJob((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };
  const handleChangeJobPreference = (e: React.ChangeEvent<HTMLInputElement>) => {
    setJobPreference((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };
  const handleChangeUnPaid = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setDisabledSalary(true);
      setJob((_data: any) => ({ ..._data, [e.target.name]: 0 }));
    } else {
      setDisabledSalary(false);
    }
  };
  const handleSelectChange = (e: any) => {
    if (e.name == "city") setSelectedCity(e?.value);
    const isArray = Array.isArray(e?.value);

    if (isArray) {
      setJob((_data: any) => ({ ..._data, [e.name]: e?.value?.map((item: any) => item.id) }));
    } else {
      setJob((_data: any) => ({ ..._data, [e.name]: e?.value?.id }));
    }
  };
  const handleSelectChangeJobPreference = (e: any) => {
    let ChangedName: any = null;
    const isArray = Array.isArray(e.value);
    if (e.value) {
      if (isArray) {
        setJobPreference((_data: any) => ({ ..._data, [ChangedName ? ChangedName : e.name]: e.value.map((item: any) => item.id) }));
      } else {
        setJobPreference((_data: any) => ({ ..._data, [ChangedName ? ChangedName : e.name]: e.value.id }));
      }
    }
  };
  const handleSelectContry = (e: any) => {
    setSelectedCity(null);
    setJob((_data: any) => ({ ..._data, city: null }));
    handleSelectChange(e);
    if (e.value) {
      getAllCities(e.value.id);
    } else {
      setCities([]);
    }
  };
  // list All Languages

  const getAllLanguages = async () => {
    const response = await listAllLanguages();
    if (response) {
      const languages = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setLanguages(languages);
      setLoadingLanguages(false);
    }
  };
  const getAllCountries = async () => {
    const response = await listAllCountries();
    if (response) {
      const countries = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCountries(countries);
      setLoadingCountries(false);
    }
  };
  const getAllCities = async (countryId: string) => {
    setLoadingCities(true);
    const response = await listAllCities(countryId);
    if (response) {
      const citiesOfCountry = response.data.data;
      const cities = citiesOfCountry.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCities(cities);
      setLoadingCities(false);
    }
  };

  const getAllMajors = async () => {
    const response = await listAllMajor();
    if (response) {
      const majors = response.data.data.queryResult.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setMajors(majors);
      setLoadingMajors(false);
    }
  };
  const getAllCurrency = async () => {
    const response = await listAllCurrency();
    if (response) {
      const Currency = response.data.data.map((item: Language) => ({ label: item.nameEn, id: item._id }));
      setCurrencies(Currency);
      setLoadingCurrencies(false);
    }
  };
  const handleTextEditorChange = (text: any, name: string) => {
    setJob((_data: any) => ({ ..._data, [name]: text }));
    const event = new Event("change");
  };
  useEffect(() => {
    getAllLanguages();
    getAllMajors();
    getAllCountries();
    getAllJobsType();
    getAllWorkType();
    getAllCurrency();
  }, []);
  const getJobById = async (id: any) => {
    setloading(true);
    const response = await getSingleJobPosts(userInfo._id, id);
    if (response.status === 200) {
      setloading(false);
      const selectedJob = response.data.data;
      setendDate(selectedJob?.endDate);
      setDeadLine(selectedJob?.deadLine);
      setStartDate(selectedJob.startDate);
      setSelectedCity({ label: selectedJob?.city?.nameEn, id: selectedJob?.city?._id });
      setJob({
        applicationUrl: selectedJob?.applicationUrl,
        name: selectedJob?.name,
        description: selectedJob?.description,
        jobType: { label: selectedJob?.jobType?.nameEn, id: selectedJob?.jobType?._id },
        workType: { label: selectedJob?.workType?.nameEn, id: selectedJob?.workType?._id },
        country: { label: selectedJob?.country?.nameEn, id: selectedJob?.country?._id },
        city: { label: selectedJob?.city?.nameEn, id: selectedJob?.city?._id },
        currency: selectedJob?.currency?.nameEn ? { label: selectedJob?.currency?.nameEn, id: selectedJob?.currency?._id } : null,
        major: selectedJob?.major && { label: selectedJob?.major?.nameEn, id: selectedJob?.major?._id },
        jobRole: selectedJob?.jobRole,
        questions: selectedJob?.questions,
        workAuthorization: selectedJob?.workAuthorization,
        salary: selectedJob?.salary || null,
        applicationType: selectedJob?.applicationType,
        additionalComments: selectedJob?.additionalComments,
        hoursPerWeek: selectedJob?.hoursPerWeek,
        roleDescription: selectedJob?.roleDescription,
        benefits: selectedJob?.benefits,
        moreDetails: selectedJob?.moreDetails,
        endDate: moment(selectedJob.endDate).format("yyy-MM-DD"),
        startDate: moment(selectedJob.startDate).format("yyy-MM-DD"),
        deadLine: moment(selectedJob.deadLine).format("yyy-MM-DD"),
        industry: selectedJob?.industry,
        subIndustry: selectedJob?.subIndustry,
      });

      setJobPreference({
        major: selectedJob?.jobPreference?.major,
        location: selectedJob?.jobPreference?.location,
        fluentLanguage: selectedJob?.jobPreference?.fluentLanguage && {
          label: selectedJob?.jobPreference?.fluentLanguage?.nameEn,
          id: selectedJob?.jobPreference?.fluentLanguage?._id,
        },
        gpa: selectedJob?.jobPreference?.gpa,
        graduationDate: selectedJob?.jobPreference?.graduationDate ? moment(selectedJob?.jobPreference?.graduationDate).format("yyy-MM-DD") : null,
      });
    }
  };

  useEffect(() => {
    if (props.id) {
      getJobById(props.id);
    }
  }, []);

  useEffect(() => {
    setJob((_data: any) => ({ ..._data, jobPreference: jobPreference }));
  }, [jobPreference]);

  useEffect(() => {
    for (let key in Object.keys(jobPreference)) {
      setUpDatedjobPreference((_data: any) => ({
        ..._data,
        [Object.keys(jobPreference)[key]]: jobPreference[Object.keys(jobPreference)[key]]?.id
          ? jobPreference[Object.keys(jobPreference)[key]].id
          : jobPreference[Object.keys(jobPreference)[key]],
      }));
    }

    setUpDatedjob((_data: any) => ({ ..._data, jobPreference: upDatedjobPreference }));

    for (let key in Object.keys(job)) {
      if (Object.keys(job)[key] !== "jobPreference") {
        setUpDatedjob((_data: any) => ({
          ..._data,
          [Object.keys(job)[key]]: job[Object.keys(job)[key]]?.id
            ? job[Object.keys(job)[key]].id
            : job[Object.keys(job)[key]]?._id || job[Object.keys(job)[key]],
        }));
      }
    }
  }, [job, jobPreference]);

  useEffect(() => {
    setJob((_data: any) => ({ ..._data, deadLine: moment(deadLine).format("yyy-MM-DD") }));
  }, [deadLine]);

  useEffect(() => {
    setJob((_data: any) => ({ ..._data, startDate: moment(startDate).format("yyy-MM-DD") }));
  }, [startDate]);

  useEffect(() => {
    setJob((_data: any) => ({ ..._data, endDate: moment(endDate).format("yyy-MM-DD") }));
  }, [endDate]);

  useEffect(() => {
    if (graduationDate) setJobPreference((_data: any) => ({ ..._data, graduationDate: moment(graduationDate).format("yyy-MM-DD") }));
  }, [graduationDate]);

  useEffect(() => {
    setJob((_data: any) => ({ ..._data, applicationType: "internal" }));
    setJobPreference((_data: any) => ({ ..._data, major: true }));
    setJobPreference((_data: any) => ({ ..._data, location: true }));
    // setJobPreference((_data: any) => ({ ..._data, 'workAuthorization': true }))
  }, []);

  const validateForm = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (job[validateList[i]] == null || job[validateList[i]] == undefined || job[validateList[i]] == "undefined") return false;
    }
    if (!startDate) return false;
    if (!endDate) return false;
    if (!deadLine) return false;
    if (!jobPreference?.fluentLanguage) return false;
    if (!job?.currency && job?.salary) return false;
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) return false;
    return true;
  };
  const getErrorMessage = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (job[validateList[i]] == null || job[validateList[i]] == undefined || job[validateList[i]] == "undefined")
        return validateListNames[i] + " is required";
    }
    if (!startDate) return "Start date is required";
    if (!endDate) return "End date is required";
    if (!deadLine) return "DeadLine is required";
    if (!jobPreference?.fluentLanguage) return "Fluent language is required";
    if (!job?.currency && job?.salary) return "Currency is required";
    if (new Date(startDate).getTime() > new Date(endDate).getTime()) return "End date must be greater than start date";
    return true;
  };
  function handleSubmit() {
    setSubmitClicked(true);

    if (validateForm()) {
      setLoadingSubmit(true);
      setTimeout(async () => {
        const res = await CreateNewJob(userInfo?._id, job);
        if (res.status === 201) {
          dispatch(
            addAlertInfo({
              show: true,
              message: `Job Added successfully and waiting for admin review to be published`,
            })
          );

          //   window.location.href = "/company/add-information?tap=1";
          props.setTap(1);
          setLoadingSubmit(false);
          window.scrollTo(0, 0);
        } else {
          dispatch(
            addAlertInfo({
              show: true,
              message: res.data.error,
              type: "error",
            })
          );
          setLoadingSubmit(false);
          // element.scrollTo(0, 0);
        }
      }, 1000);
    } else {
      dispatch(
        addAlertInfo({
          show: true,
          message: getErrorMessage(),
          type: "error",
        })
      );
    }
  }
  function handleSubmitEdit() {
    setSubmitClicked(true);
    if (validateForm()) {
      setLoadingSubmit(true);

      setTimeout(async () => {
        const res = await EditJob(userInfo?._id, upDatedjob, props.id);
        if (res.status === 200) {
          dispatch(
            addAlertInfo({
              show: true,
              message: `Job edited successfully`,
            })
          );
          window.location.href = "/company/add-information?tap=1";
          props.setTap(1);

          setLoadingSubmit(false);
          window.scrollTo(0, 0);
        } else {
          dispatch(
            addAlertInfo({
              show: true,
              message: res.data.error,
              type: "error",
            })
          );
          setLoadingSubmit(false);
        }
      }, 3000);
    } else {
      dispatch(
        addAlertInfo({
          show: true,
          message: getErrorMessage(),
          type: "error",
        })
      );
    }
  }
  const handleSubmitClicked = () => {
    if (props.id) {
      handleSubmitEdit();
    } else {
      handleSubmit();
    }
  };

  return (
    <div>
      {!loading && (
        <form className="md-w-3/4">
          <div className="form-item">
            <InputField
              label="Role title"
              name="name"
              required={true}
              placeholder="Enter role title"
              Change={handleChange}
              value={job?.name}
              error={!job?.name && submitClicked}
              submitClicked={submitClicked}
            />
          </div>

          <div className="flex space-x-4">
            <div className="form-item w-1/2">
              <SelectInput
                options={jobsTypeList}
                placeholder="Select"
                required={true}
                multiple={false}
                name="jobType"
                label="Job type"
                Change={handleSelectChange}
                loading={loadingJobsType}
                submitClicked={submitClicked}
                error={!job?.jobType && submitClicked}
                defaultValue={job?.jobType}
              />
            </div>
            <div className="form-item w-1/2">
              <SelectInput
                options={WorkTypeList}
                placeholder="Select"
                required={true}
                multiple={false}
                name="workType"
                label="Work type"
                Change={handleSelectChange}
                loading={loadingJobsType}
                submitClicked={submitClicked}
                defaultValue={job?.workType}
                error={!job?.workType && submitClicked}
                // defaultValue={job?.workType}
              />
            </div>
          </div>
          {/*  */}
          <div className="form-item ">
            <IndustriesUI
              required={false}
              single={true}
              setUserData={setJob}
              Userindustries={job?.subIndustry}
              handleSelectChange={handleSelectChange}
              submitClicked={submitClicked}
            />
          </div>
          <div className="block md:flex md:space-x-4">
            <div className="form-item w-full md:w-1/3">
              <label>*Application deadline</label>
              <DatePicker
                minDate={new Date()}
                value={deadLine}
                onChange={(newValue) => {
                  setDeadLine(newValue);
                }}
                renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
              />
              {!deadLine && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
            </div>
            <div className="form-item md:w-1/3">
              <label>*Start date for job</label>
              <DatePicker
                minDate={new Date()}
                value={startDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
              />
              {!startDate && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
            </div>
            <div className="form-item md:w-1/3">
              <label>*End date for job</label>
              <DatePicker
                value={endDate}
                onChange={(newValue) => {
                  setendDate(newValue);
                }}
                minDate={startDate}
                renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
              />
              {!endDate && submitClicked && <span className="text-red-500 text-sm">{"This field is required"}</span>}
              {new Date(startDate).getTime() > new Date(endDate).getTime() && (
                <span className="text-red-500 text-sm">{"End date must be greater than start date"}</span>
              )}
            </div>
          </div>

          <div className="form-item ">
            <Grid container columnSpacing="10">
              <Grid item xs={12} md={12}>
                <div className="form-item ">
                  <SelectInput
                    name="country"
                    required={true}
                    label="Select country"
                    options={countries}
                    Change={handleSelectContry}
                    loading={loadingCountries}
                    submitClicked={submitClicked}
                    defaultValue={job?.country}
                    error={!job?.country && submitClicked}
                    // defaultValue={job?.country}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <div className="form-item ">
                  <SelectInput
                    name="city"
                    required={true}
                    label="Select your city (region/state/province options will appear with the city)"
                    labelHint='Select your country first'
                    options={cities}
                    Change={handleSelectChange}
                    loading={loadingCities}
                    error={!job?.city && submitClicked}
                    submitClicked={submitClicked}
                    // defaultValue={job?.city}
                    value={selectedCity?.id ? selectedCity : null}
                    disabled={!job?.country}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="form-item ">
            <SelectInput
              required={true}
              label="Work Authorization"
              multiple={false}
              options={WorkAuthorizationList}
              placeholder="Select"
              name="workAuthorization"
              Change={handleSelectChange}
              loading={loadingJobsType}
              error={job?.workAuthorization == null && submitClicked}
              // value={job?.workAuthorization}
              submitClicked={submitClicked}
              defaultValue={WorkAuthorizationList[job?.workAuthorization]}
            />
          </div>
          <div className="form-item flex flex-col">
            <div className="md:flex items-center">
              <div className="md:mr-2 md:w-1/2">
                <InputField
                  label="Estimated salary"
                  required={false}
                  placeholder="Amount"
                  name="salary"
                  Change={handleChange}
                  value={job?.salary}
                  submitClicked={submitClicked}
                  type="number"
                  disabled={disabledSalary}
                />
              </div>
              <div className="md:mr-2 md:w-1/4">
                <SelectInput
                  label="Currency"
                  required={job?.salary ? true : false}
                  multiple={false}
                  name="currency"
                  options={currencies}
                  loading={loadingCurrencies}
                  disabled={disabledSalary}
                  Change={handleSelectChange}
                  error={job?.salary != null && job?.salary != 0 && !job?.currency && submitClicked}
                  value={job?.currency}
                  submitClicked={submitClicked}
                  defaultValue={job?.currency}
                />
              </div>
              <div className="flex mt-8 ml-6">
                <p className="mr-2">Or</p>
                <FormControlLabel
                  defaultChecked={!job?.salary || job?.salary === 0}
                  control={<Checkbox size="small" />}
                  label="Unpaid"
                  name="salary"
                  onChange={(e: any) => handleChangeUnPaid(e)}
                  className="m-0"
                />
              </div>
            </div>
          </div>
          <div className="form-item">
            <FormControl>
              <label>*Apply</label>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="applicationType"
                value={job.applicationType}
                defaultValue={"internal"}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="internal" control={<Radio size="small" />} label="Internally on edqan" />
                <FormControlLabel value="external" control={<Radio size="small" />} label="External website" />
              </RadioGroup>
            </FormControl>
          </div>
          {job.applicationType === "external" && (
            <div className="form-item flex">
              <label>Enter url to the job posting</label>
              <InputField
                required={job.applicationType === "external"}
                placeholder="URL"
                name="applicationUrl"
                Change={handleChange}
                error={!job?.applicationUrl && submitClicked}
                value={job?.applicationUrl}
                // defaultValue={job?.applicationUrl}
                submitClicked={submitClicked}
              />
            </div>
          )}

          <label className="input-label ">
            General Requirements for Role
            <br />
            <p className="mb-8 block">
              {" "}
              If these are not required, please leave the default option. Some of these fields are required and some are not.
            </p>
          </label>
          <div className="form-item ">
            <SelectInput
              loading={loadingMajors}
              options={majors}
              required={false}
              label="Major"
              name="major"
              Change={handleSelectChange}
              submitClicked={submitClicked}
              defaultValue={job?.major}
            />
          </div>
          <div className="form-item flex">
            <FormControl>
              <label>Student should match this major</label>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="major"
                value={jobPreference.major}
                defaultValue={false}
                onChange={handleChangeJobPreference}
                row
              >
                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="form-item">
            <FormControl>
              <label>Student should be located in this country</label>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="location"
                value={jobPreference.location}
                defaultValue={false}
                onChange={handleChangeJobPreference}
                row
              >
                <FormControlLabel value={true} control={<Radio size="small" />} label="Yes" />
                <FormControlLabel value={false} control={<Radio size="small" />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>

          <div className="form-item">
            <SelectInput
              // labelHint='You can select the language you are fluent in.'
              name="fluentLanguage"
              required={true}
              label="Select Language Fluency"
              multiple={false}
              options={Languages}
              Change={handleSelectChangeJobPreference}
              loading={loadingLanguages}
              submitClicked={submitClicked}
              defaultValue={jobPreference.fluentLanguage}
              error={!job?.jobPreference?.fluentLanguage && submitClicked}
              value={job?.jobPreference?.fluentLanguage}
            />
          </div>

          <div className="form-item">
            <InputField
              label="Student should have a GPA of at least "
              required={false}
              placeholder="GPA"
              name="gpa"
              Change={handleChangeJobPreference}
              value={jobPreference?.gpa}
              submitClicked={submitClicked}
              type="number"
            />
          </div>

          <div className="form-item">
            <div className="input-label">
              <label>Student should be at graduating in this school year</label>
              <p className="label-hint">Note: Students will only have estimates.</p>
            </div>
            <DatePicker
              views={["year"]}
              openTo="year"
              value={graduationDate}
              onChange={(newValue) => {
                setGraduationDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "",
                  }}
                  required
                  fullWidth
                  margin="normal"
                  helperText={null}
                />
              )}
            />
            {/* <SelectInput required={false} label=' ' labelHint='' multiple={true} options={['option1 ', 'option 2', 'option 3']} /> */}
          </div>

          <div className="form-item">
            <InputField
              required={false}
              label="Additional comments"
              placeholder=""
              name="additionalComments"
              Change={handleChange}
              value={job?.additionalComments}
              submitClicked={submitClicked}
              multiline={true}
              rows={4}
            />
          </div>

          <div className="form-item ">
            <InputField
              label="Number of hours per week this role requires "
              required={true}
              placeholder=""
              name="hoursPerWeek"
              Change={handleChange}
              value={job?.hoursPerWeek}
              error={!job?.hoursPerWeek && submitClicked}
              submitClicked={submitClicked}
              type="number"
            />
            {/* <SelectInput required={true}
                        multiple={false} options={['option1 ', 'option 2', 'option 3']} /> */}
          </div>

          <div className="form-item">
            <TextEditor
              label="*Role description"
              maxLength={2400}
              name="roleDescription"
              Change={(e: any) => handleTextEditorChange(e, "roleDescription")}
              error={(job.roleDescription === undefined || job.roleDescription === "") && submitClicked}
              value={job?.roleDescription}
              submitClicked={submitClicked}
            />
          </div>
          <div className="form-item">
            <TextEditor
              label="Benefits"
              maxLength={1500}
              name="benefits"
              Change={(e: any) => handleTextEditorChange(e, "benefits")}
              // error={job.benefits === undefined || job.benefits === ''}
              value={job?.benefits}
              submitClicked={submitClicked}
            />
          </div>
          <div className="form-item">
            <TextEditor
              label="More Details"
              maxLength={700}
              name="moreDetails"
              Change={(e: any) => handleTextEditorChange(e, "moreDetails")}
              // error={job.benefits === undefined || job.benefits === ''}
              value={job?.moreDetails}
              submitClicked={submitClicked}
            />
          </div>
          {job.applicationType === "internal" && <Questions setJob={setJob} job={job} />}
          <div className="actions-end">
            <CustomizedButtons text="Submit" disabled={!job} click={handleSubmitClicked} loading={loadingSubmit} />
          </div>
        </form>
      )}
    </div>
  );
}
