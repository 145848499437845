

import LogoImage from '../../../../assets/images/Logo.png'
import styles from './banner.module.scss'
interface LoginProps {
    title:string
}

const Login = (props: LoginProps) => {
    return (
        <div className={styles['banner']}>
              <div className={styles.logo}>
                <img src={LogoImage}/>
            </div>
            <div  className={styles.content}>
            </div>
        </div>
      
    );
};

export default Login;