import React from "react";
import { useNavigate } from "react-router";
import MentessRequests from "../../../MentessRequests";

export default function TabThree(props: any) {
  const { list } = props
  const navigate = useNavigate()

  const handleCLick = () => {
    navigate('/calendar')
  }

  return (
    <>
      <MentessRequests list={list} listAll={props.listAll}/>
      <div className="mt-4">
      <p className="list-title mt-6 ">For all scheduled appointments, please see your <span className="main-link" onClick={handleCLick}>calendar</span>.</p>
  </div>
    </>

  );
}
