import React, { useEffect, useState } from "react";
import CreateJobForm from '../../components/CreateJobPost'
import Post, { CardLoadingSkeleton } from '../../../../../Components/CardsList/Post'
import CustomizedButtons from "../../../../../Components/CustomButton";
import SelectInput from "../../../../../Components/SelectInput";
import { listCompanyJobPosts } from "../../../../../services/company/jobPostsService";
import { useSelector } from "react-redux";
import NoDataMessage from "../../../../../Components/NoDataMessage";
import { Pagination } from "@mui/material";
import { ListAllJobsType, ListAllWorkType } from "../../../../../services/jobsService";
import { listAllCities } from "../../../../../services/locationService";
import { listCompaniesNoFilter } from "../../../../../services/students/companies";
import InputField from "../../../../../Components/Input";
export default function TabTwo(props: any) {
  const userInfo = useSelector((state: any) => state.auth.user);
  const [list, setList] = useState([]);
  const [totlaPages, setTotlaPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setloading] = useState(true)

  const [jobTypeList, setjobTypeList] = useState([])
  const [loadingjobType, setLoadingjobType] = useState(true)

  const [jobTypeFilter, setjobTypeFilter] = useState<any>(null)
  const [workTypeFilter, setworkTypeFilter] = useState<any>(null)
  const [citiesFilter, setCitiesFilter] = useState<any>(null)
  const [companyFilter, setCompanyFilter] = useState<any>(null)
  const [WorkTypeList, setWorkTypeList] = useState([])

  const [loadingWorkType, setLoadingWorkType] = useState(true)
  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null)
  const getCompanyJobs = async () => {
    setloading(true)
    let jobTypeFilterarm = ''
    if (jobTypeFilter?.id) {
      jobTypeFilterarm = `&jobType=${jobTypeFilter.id}`
    }
    let workTypeFilterParm = ''
    if (workTypeFilter) {
      workTypeFilterParm = `&workType=${workTypeFilter.id}`
    }
    let cityFilterParm = ''
    if (citiesFilter) {
      cityFilterParm = `&city=${citiesFilter.id}`
    }
    let companyFilterParm = ''
    if (companyFilter) {
      companyFilterParm = `&company=${companyFilter.id}`
    }
    let searchTermFilterParm = ''
    if (searchTerm) {
      searchTermFilterParm = `&searchTerm=${searchTerm}`
    }

    const response = await listCompanyJobPosts(companyFilter?.value||userInfo._id, currentPage, 21, jobTypeFilterarm, workTypeFilterParm, cityFilterParm, companyFilterParm, searchTermFilterParm)
    if (response.status === 200) {
      setloading(false)
      setList(response.data.data.queryResult)
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 21))
    }

  }
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  const getAlljobType = async () => {
    const response = await ListAllJobsType()
    if (response) {
      const data = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setjobTypeList(data)
      setLoadingjobType(false)
    }
  };
  const getAllWorkType = async () => {
    const response = await ListAllWorkType()
    if (response) {
      const data = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setWorkTypeList(data)
      setLoadingWorkType(false)
    }
  };
  // const getAllCities = async () => {
  //   setLoadingCities(true)
  //   const response = await listAllCities(countryId)
  //   if (response) {
  //     const citiesOfCountry = response.data.data
  //     const cities = citiesOfCountry.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
  //     setCities(cities)
  //     setLoadingCities(false)
  //   }
  // }
  const getCompaniesList = async () => {
    const response = await listCompaniesNoFilter({ currentPage: currentPage })
    if (response) {
      setCompanies(response.data.data.queryResult.map((item: any) => ({ 'label': item?.subModel?.name, 'value': item._id })))
      // setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9))
      setLoadingCompanies(false)
    }
  }
  useEffect(() => {
    if (userInfo) {
      getCompanyJobs()
    }

  }, [userInfo,currentPage,userInfo, jobTypeFilter, citiesFilter, workTypeFilter, companyFilter, searchTerm])
  useEffect(() => {
    if (userInfo) {
      getAlljobType()
      getAllWorkType()
      getCompaniesList()
    }

  }, [userInfo])

  return (
    <>

      <div>
        <div className='block md:flex mb-4'>
          <div className='mr-2 w-full md:w-1/5 mb-3'>
            <InputField
              label="Search jobs"
              required={false}
              placeholder="Search jobs"
              Change={(e: any) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='mr-2 w-full md:w-1/5 mb-3'>
            <SelectInput
              label='Filter by job type'
              value={jobTypeFilter}
              options={jobTypeList}
              loading={loadingjobType}
              Change={(e: any) => setjobTypeFilter(e.value)}
              required={false}

            />
          </div>
          <div className='mr-2 w-full md:w-1/5 mb-3'>
            <SelectInput
              label='Filter by work type'
              value={workTypeFilter}
              options={WorkTypeList}
              loading={loadingWorkType}
              Change={(e: any) => setworkTypeFilter(e.value)}
              required={false}

            />
          </div>
          {/* <div className='mr-2 w-1/5'>
          <SelectInput
              label='Filter by city'
              value={citiesFilter}
              options={cities}
              loading={loadingCities}
              Change={(e: any) => setCitiesFilter(e.value)}
              required={false}
            />
          </div> */}
          <div className='mr-2 w-full md:w-1/5 mb-3'>
            <SelectInput
              label='Filter by company'
              value={companyFilter}
              options={companies}
              loading={loadingCompanies}
              Change={(e: any) => setCompanyFilter(e.value)}
              required={false}
            />
          </div>

        </div>

        <p className="list-title mb-4">Current job posts</p>
        <div className="grid gap-5 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1">
          {!loading ?
            list && list.length > 0 ?
              list.map((item: any, i: number) => {
                return (
                  <Post data={item} index={i} listAllData={getCompanyJobs}/>
                )
              })
              :
              ''
            :
            [1, 2, 3, 4, 5, 6].map((item: any) => {
              return (
                <CardLoadingSkeleton />
              )
            })}
        </div>
        {!loading && list.length === 0 && <NoDataMessage />}
        {totlaPages > 1 &&
          <div className="flex justify-end my-6">
            <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
          </div>
        }
      </div>

    </>
  );
}
