import { Grid, Pagination, TextField } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import CarouselList from "../../../Components/CardsList";
import CustomizedButtons from "../../../Components/CustomButton";
import DateRangePickerComponent from "../../../Components/DatePicker";
import InputField from "../../../Components/Input";
import SelectInput from "../../../Components/SelectInput";
import styles from "./job-application.module.scss";
import { listAllApplication } from "../../../services/jobApplicationsService";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import Post from "../../../Components/CardsList/Post";
import { CardLoadingSkeleton } from "../Access/Tabs";
import NoDataMessage from "../../../Components/NoDataMessage";
import { addAlertInfo } from "../../../store/alertSlice";

export default function JobApplication() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(true)
  const [searchWord, setSearchWord] = useState('')
  const [stutesFilter, setStutesFilter] = useState<any>(null)
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [totlaPages, setTotlaPages] = useState(0)
  const dispatch = useDispatch()
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  // PENDING: 0,
  // HIRED: 1,
  // DECLINED: 2,
  const getAllApplication = async () => {
    setLoading(true)
    let date = ''
    if (startDate && endDate) {
      date = `&dateFrom=${moment(startDate).format('YYYY-MM-DD')}&dateTo=${moment(endDate).format('YYYY-MM-DD')}`
    }
    const response = await listAllApplication(user._id, currentPage, searchWord, stutesFilter, date)
    if (response.status === 200) {
      setList(response.data.data.queryResult)
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 4))
      setLoading(false)
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }

  }
  const clearFilters = () => {
    setSearchWord('')
    setEndDate(null)
    setStartDate(null)
    setStutesFilter('')
  }
  useEffect(() => {
    if (token) {
      getAllApplication()
      getAllApplication()
    }
  }, [token, stutesFilter, currentPage]);

  useEffect(() => {
    if (startDate && endDate) {
      getAllApplication()
    }
  }, [startDate, endDate, currentPage]);

  useEffect(() => {
    if (searchWord) {
      const getData = setTimeout(() => {
        getAllApplication();
      }, 800);
      return () => clearTimeout(getData);
    }

  }, [searchWord, currentPage]);

  const optionsList = [{ label: 'Pending', id: '0' }, { label: 'Selected', id: '1' }, { label: 'Declined', id: '2' }]
  return (
    <Container>

      <h2 className="title header-underline">Job Applications</h2>
      <Grid container className="my-8">
        <Grid item lg={4} md={5} xs={12} className={styles["leftgrid"]} >
          <div className={styles["leftgrid-content"]}>
            <p className="text-sm font-semibold m-4">Filters</p>
            <div className={[styles["row"], "my-4"].join(" ")}>
              <InputField label='Search' required={false} placeholder=""
                value={searchWord} Change={(e: any) => setSearchWord(e.target.value)} />
            </div>
            <div className={[styles["row"], "my-4", 'w-full'].join(" ")}>
              <p className="text-sm  mb-2">Status of Application</p>
              <SelectInput
                options={optionsList}
                Change={(e: any) => setStutesFilter(e?.value ? e?.value?.id : null)}
                required={false} />
            </div>
            <div className={[styles["row"], "my-4"].join(" ")}>
              <p className="text-sm mb-2">Date Applied</p>
              <Grid container rowSpacing='3'>
                <Grid item xs={12} >
                  <DatePicker
                    label="From"
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                  />

                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="To"
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                  />
                </Grid>
              </Grid>
              <div className="custom-action my-4 ">

                <CustomizedButtons text="Clear search results" click={clearFilters} />
              </div>
            </div>

          </div>
        </Grid>
        <Grid item xs={12} md={7} lg={8} className={styles["rightgrid"]}>
          <p className="text-sm font-semibold ">Results</p>
          <p className="text-sm mb-4">
            These are the results based on your search criteria:
          </p>

          {/* <div className={styles["text"]}>
            <CarouselList defaultCount={2} list={list.map((item: any) => item.job)} loading={loading} />

          </div> */}
          <Grid
            className={styles.jobs_list}
            container
            spacing={3}
            sx={{ justifyContent: 'space-between' }}
          >
            {!loading ?
              list && list.length > 0 ?
                list.map((item: any, i: number) => {
                  return (
                    <Grid item sm={12} md={6}>
                      {<Post data={item.job} index={i} />}
                    </Grid>



                  )
                })
                :
                <Grid item sm={12} md={12}>
                <NoDataMessage />
                </Grid>
              :
              [1, 2, 3, 4].map((item: any) => {
                return (
                  <Grid item sm={12} md={6}>
                    <CardLoadingSkeleton />
                  </Grid>
                )
              })}


          </Grid>
          {/* <div className={styles.jobs_list}>
            <div className="gap-4 space-y-4 columns-1 md:columns-2">

            </div>

          </div> */}

          <div className="flex justify-end my-6">

            <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
          </div>

        </Grid>
      </Grid>
    </Container>
  );
}
