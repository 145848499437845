import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { latestInteractionsWithEmployers } from '../../../../services/analyticsService';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {


  Box,
  Button,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import Moment from 'react-moment';
import NoDataMessage from '../../../../Components/NoDataMessage';


export default function LatestInteractionsWithEmployers(props: any) {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const getData = async () => {
    const response = await latestInteractionsWithEmployers(user._id,0,4)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token]);
  const getApplicationStatus = (status: string) => {

    if (status == '0') return 'Pending'
    if (status == '1') return 'Hired'
    if (status == '2') return 'Declined'
  }
  return (
    <Card {...props} className='post'>
      <CardHeader title={<p className='main-title'>Latest interactions with employers</p>} />

      <div className='main-table'>
        {data?.length > 0 ? <Table>
          <TableHead>
            <TableRow>

              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                
                  </TableSortLabel>
                </Tooltip>  */}
                Student/Professional
              </TableCell>
              {/* <TableCell>
              
            </TableCell> */}
              {/* <TableCell>
              Employer Name
            </TableCell> */}
              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                  
                  </TableSortLabel>
                </Tooltip>   */}
                Employer Name
              </TableCell>
              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                  
                  </TableSortLabel>
                </Tooltip>   */}
                Job Posting
              </TableCell>
              {/* <TableCell>
              Job Posting
            </TableCell> */}
              <TableCell>

                Application Status
              </TableCell>

              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                 
                  </TableSortLabel>
                </Tooltip>   */}
                Latest update
              </TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any) => (
              <TableRow
                hover
                key={item._id}
              >

                <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/professional/profile/${item?.user?._id}`)}>
                    {item?.user?.subModel?.firstName} {item?.user?.subModel?.lastName}
                  </a>

                </TableCell>
                <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/company/profile/${item?.company?._id}`)}>
                    {item?.company?.subModel?.name}
                  </a>

                </TableCell>
                <TableCell>
                  <a className='main-link' onClick={() => navigate(`/job-details/${item.job?._id}`)}>{item?.job?.name}</a>
                </TableCell>
                <TableCell>
                  <div className={`${getApplicationStatus(item?.status)}_label text-center`}>
                    {getApplicationStatus(item?.status)}
                  </div>

                </TableCell>
                <TableCell>
                  <Moment format="MMMM DD, yyyy">{item?.updatedAt}</Moment>

                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table> :
          <NoDataMessage />}
      </div>

      {data?.length > 0 && <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <a
          className='colored cursor-pointer'
          onClick={() => navigate(`/interactions-with-employers`)}
        >
          View all<ArrowRightIcon />
        </a>
      </Box>}
    </Card>
  )
}
