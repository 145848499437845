import React from "react";
import { useNavigate } from "react-router";
import DynamicTable from "../../Table";
import NoDataMessage from "../../../../../Components/NoDataMessage";
import { data } from "jquery";
import { changeMentorShipSessionStatus } from "../../../../../services/mentors";
import { addAlertInfo } from "../../../../../store/alertSlice";
import { useDispatch } from "react-redux";

export default function TabOne(props: any) {
  const { list,type,listAll,setValue } = props
  const navigate = useNavigate()
const dispatch=useDispatch()
  const handleCLick = () => {
    navigate('/calendar')
  }


  return (
    <div className="py-8 " >
     {list.length>0?
        <DynamicTable
        listAll={listAll}
        setValue={setValue}
        type={type}
          list={list}
          columns={['Name Mentee', 'Scheduled Date','Mentorship Type']}
          alignment={['left', 'left']}
        />
      :
      <NoDataMessage/>
      }
        <div className="mt-4">

     
      <p className="list-title ">For all scheduled appointments, please see your <span className="main-link" onClick={handleCLick}>calendar</span>.</p>
    </div>   </div>
  );
}
