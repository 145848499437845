import React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
interface SectionTitleProps {
  title: string;
  editable?:boolean;
  onEdit?:any
}
export default function SectionTitle(props: SectionTitleProps) {
  const { title, editable,onEdit } = props;
  const handleClick=()=>{
    onEdit(true)
  }
  return (
    <div className="flex items-center justify-between">
      <h4 className="title capitalize">{title}</h4>
      {editable &&(
        <IconButton color="inherit" aria-label="upload picture" component="label" onClick={handleClick}>
        <input hidden />
        <EditIcon fontSize="small" />
      </IconButton>
      )}
    </div>
  );
}
