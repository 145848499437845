
import axios from "../../axios";
import { BASEURL } from "../../environments/environment";


export const listCompanies = (props: any) => {
  const { currentPage, pageSize, filterName, filterValue } = props;

  const paging = currentPage !== null
    ? `&page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
    : "";
  const filter = filterName
    ? `&${filterName}=${filterValue}`
    : "";

  return axios
    .get(`${BASEURL}user?model=Company${paging}${filter}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const listCompaniesRemote = (props: any) => {
  const { currentPage, pageSize } = props;

  const paging = currentPage !== null
    ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
    : "";


  return axios
    .get(`${BASEURL}company/remote-hiring${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const listAllStudents = (props: any) => {
  const { currentPage, pageSize } = props;

  const paging = currentPage !== null
    ? `&page=${currentPage}&pageSize=${pageSize ? pageSize : 21}`
    : "";

  return axios
    .get(`${BASEURL}user?model=Student${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const listAllStudentsByMajor = (props: any) => {
  const { id, currentPage, pageSize } = props;

  const paging = currentPage !== null
    ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 21}`
    : "";

  return axios
    .get(`${BASEURL}student/major/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const listCompaniesThatHiresFromSchool = (props:any) => {

  const { currentPage, pageSize ,id} = props;

  const paging = currentPage!==null
    ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
    : "";

  return axios
    .get(`${BASEURL}student/companiesThatHiresFromStudentsInstitution${paging}&userId=${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const listCompaniesNoFilter = (props: any) => {
  return axios
    .get(`${BASEURL}user?model=Company`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
