
import React, { useEffect, useState } from "react";
import styles from './section.module.scss'
import { Pagination } from "@mui/material";
import Post, { CardLoadingSkeleton } from "../../../../Components/CardsList/Post";
import InputField from "../../../../Components/Input";
import SelectInput from "../../../../Components/SelectInput";
import { ListAllWorkType, ListAllJobsType } from "../../../../services/jobsService";
import { useSelector } from "react-redux";
import NoDataMessage from "../../../../Components/NoDataMessage";
import { listAllMajor } from "../../../../services/majorsServise";
import { ListAllIndustry } from "../../../../services/industry";

export default function LeftContent(props: any) {
  const { jobsList, loading, totlaPages, handlePaginationChange, listCount, getSingleGob, setSearchTerm, setFilter,setFilterMajor,setFilterIndustry ,setIsMobileShow,isMobile} = props
  const token = useSelector((state: any) => state.auth.token);
  const [WorkTypeList, setWorkTypeList] = useState([])
  const [jobsTypeList, setJobsTypeList] = useState([])
  const [selectedJobId, setSelectedJobId] = useState('')
  const [loadingWorkType, setLoadingWorkType] = useState(true)
  const [loadingJobsType, setLoadingJobsType] = useState(true)
  const [majors, setMajors] = useState([]);
    const [industry, setAllIndustry] = useState([]);
    const [loadingIndustry, setloadingIndustry] = useState(true);
    const [loadingMajors, setLoadingMajors] = useState(true);

  const getAllWorkType = async () => {
    const response = await ListAllWorkType()
    if (response) {
      const data = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id, type: 'workType' }))
      setWorkTypeList(data)
      setLoadingWorkType(false)
    }
  };
   // list All Majors
   const getAllMajors = async () => {
    const response = await listAllMajor()
    if (response) {
        const majors = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
        setMajors(majors)
        setLoadingMajors(false)
    }
}
 const getAllIndustry = async () => {
    setloadingIndustry(true)
    const response = await ListAllIndustry()
    if (response) {
        const data = response.data.data.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))

        setAllIndustry(data)
        setloadingIndustry(false)
    }
}
  // const getAllJobsType = async () => {
  //   const response = await ListAllJobsType()
  //   if (response) {
  //     const data = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id, type: 'jobType' }))
  //     setJobsTypeList(data)
  //     setLoadingJobsType(false)
  //   }
  // };
  const ShowJob = (id: string) => {
   
    setSelectedJobId(id)
    getSingleGob(id)
    if(isMobile){
      setIsMobileShow(true)
    }
  }
  const handleSelectChange = (e: any) => {
    setFilter(e.value);
  }
  const handleSelectChangeMajor = (e: any) => {
    setFilterMajor(e.value);
  }
  const handleSelectChangeIndustry = (e: any) => {
    setFilterIndustry(e.value);
  }
  useEffect(() => {
    if (token) {
      getAllWorkType()
      getAllMajors()
       getAllIndustry()
    }

  }, [token]);
  useEffect(() => {
    if (!loading && jobsList.length > 0) {
      setSelectedJobId(jobsList[0]._id)
    }

  }, [loading]);

  return (
    <div>
      <div className={styles['filter-section']}>
        <div className="mb-2 pb-2 main-b-border ">
          <div className="form-item">
            <SelectInput
              options={WorkTypeList}
              placeholder="Filter by work type"
              required={false}
              multiple={false}
              name="filter"
              Change={handleSelectChange}
              loading={loadingWorkType }
            />
          </div>
          <div className="form-item">
            <SelectInput
              options={majors}
              placeholder="Filter by major"
              required={false}
              multiple={false}
              name="filterMajor"
              Change={handleSelectChangeMajor}
              loading={loadingMajors}
            />
          </div>
          <div className="form-item">
            <SelectInput
              options={industry}
              placeholder="Filter by industry"
              required={false}
              multiple={false}
              name="filterIndustry"
              Change={handleSelectChangeIndustry}
              loading={loadingIndustry}
            />
          </div>
          <div className="form-item">
            <InputField
              label="Search jobs"
              required={false}
              placeholder="Search jobs"
              Change={(e: any) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className={[styles['filter-reselt'], 'flex'].join(' ')}>
          {loading ?
            <div className="h-2 bg-gray-200 rounded"></div>
            :
            <span className="block mb-4">{listCount} jobs found</span>
          }
          {/* <div className={styles['select']}>
          </div> */}
        </div>
        <div className={styles['jobs-list']}>
          {loading ?
            [1, 2, 3].map((item: any) => {
              return (
                <div className="mb-2">
                  <CardLoadingSkeleton />
                </div>
              )
            })

            :
            jobsList.length > 0 ?
              jobsList.map((job: any,i:number) => (
                <div className={`mb-2  ${selectedJobId === job._id ? 'main-box-shadow rounded-xl ' : ''}`}
                  onClick={() => ShowJob(job._id)}>
                  <Post data={job} action="nested"  index={i}/>

                </div>

              ))
              :
              <NoDataMessage />}
          {totlaPages > 1 &&
            <div className="flex justify-end my-6">

              <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
            </div>
          }
        </div>
      </div>

    </div>
  );
}
