
import axios from "../axios";
import { BASEURL } from "../environments/environment";
// const token = localStorage.getItem("userToken");

export const listFollowedCompanies = (id:string,page?:any,pageSize?:any) => {
  return axios
    .get(`${BASEURL}student/followed_company/${id}${page?`?page=${page}&pageSize=${pageSize}`:''}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const followedCompany = (data:any,userID:string) => {
    return axios
      .patch(`${BASEURL}student/follow_company?userId=${userID}`,data)
      .then((res: any) => res)
      .catch((err: any) => err?.response);
  };
  