import Moment from "react-moment";
import CheckIcon from '@mui/icons-material/Check';
import Post from "../../../../../Components/CardsList/Post";
// import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import { useEffect, useState } from "react";
import axios from "axios";

interface SenderMesageProps {
    message: any
    checks: number
}


const SenderMesage = (props: SenderMesageProps) => {
    const { message, checks } = props
    const [linkPreview, setLinkPreview] = useState<any>([])
    const isValidUrl = (urlString: any) => {
        try {
            return Boolean(new URL(urlString))
        }
        catch {

        }

    }

    // useEffect(() => {
    //     if(message?.message){
    //          for (let i = 0; i < message?.message?.split(" ").length; i++) {
    //             console.log(message?.message.split(" ")[i],i)
    //         if (isValidUrl(message?.message.split(" ")[i]) && !message?.message.split(" ")[i].includes('job-details')) {
    //             getLinkPreview(message?.message.split(" ")[i])
    //             console.log(message?.message.split(" ")[i])
    //         }
    //     }
    //     }
       
    // }, []);

    //     linkPreview()
    // const getLinkPreview = (link: string) => {
    //     let temp = [...linkPreview]
    //     axios.post(
    //         'https://api.linkpreview.net',
    //         {
    //             q: 'https://www.youtube.com/watch?v=NCUR6dalW-g&ab_channel=SAMEHSANAD%D8%B3%D8%A7%D9%85%D8%AD%D8%B3%D9%86%D8%AF',
    //             key: '19acea77eed047965e55fd981fc4a50c'
    //         }).then(resp => {
    //             console.log(resp.data)
    //             temp.push(resp.data)
    //             setLinkPreview(temp)
    //         }).catch(err => {
    //             // something went wrong
    //             console.log(err.response.status)
    //             return false
    //         })

    // }

    return (
        <div className={`sender-message ${message?.job && message?.job?.length > 0 ? 'w-full' : 'w-fit'}`}>
            <p className="flex-wrap flex gap-[4px]">
                {message?.message ?
                    message?.message.split(" ").map((word: string) => {
                        return (
                            <>
                                {isValidUrl(word) ?
                                    <div>

                                        <a className="main-link" href={word} target="_blank">{word}</a>

                                    </div>
                                     : `${word} `}</>
                        )
                    }) : message}
            </p>

            {/* {linkPreview && linkPreview?.length > 0 ?
                <div className="block">
                    {linkPreview.map((item: any) => {
                        return (
                            <div className="border border-[#cac9c9] rounded flex gap-2 p-2 m-2">
                                <img src={item.image} className="h-[150px] w-[200px] "/>
                                <div>
                                    <a href={item?.url} target="_blank" className="main-link mb-4">{item?.title}</a>
                                    <p className="mentor-card-text">{item?.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                :
                ''
            } */}
            {message?.job && message?.job?.length > 0 ?
                <div className="grid gap-5 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mt-4">
                    {message?.job.map((item: any) => {
                        return (
                            <Post data={item} />
                        )
                    })}
                </div>
                :
                ''
            }
            <div className="flex mt-1">
                <span className="time"><Moment format="hh:mm A">{message.createdAt}</Moment></span>
                <div className="flex items-center ml-1">
                    {checks == 2 && <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2 12L7.25 17C7.25 17 8.66939 15.3778 9.875 14" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /> <path d="M8 12L13.25 17L22 7" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /> <path d="M16 7L12.5 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /> </svg>}
                    {checks == 1 && <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 24 24">
                        <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"></path>
                    </svg>}


                </div>

            </div>
        </div>
    );
};

export default SenderMesage;
