import { Container, Pagination } from "@mui/material";
import styles from "./posts.module.scss";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { listAlljobs } from "../../../../services/jobsService";

import Post, { CardLoadingSkeleton } from "../../../../Components/CardsList/Post";
interface PostsProps {}

const Posts = (props: PostsProps) => {
  const token = useSelector((state: any) => state.auth.token);
  const [jobs, setJobs] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totlaPages, setTotlaPages] = useState(0);
  const getGobs = async () => {
    setLoading(true);
    const response = await listAlljobs({ currentPage: 0, pageSize: 10 });
    if (response) {
      setJobs(response.data.data.queryResult);
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 20));
      setLoading(false);
    }
  };
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };
  useEffect(() => {
    if (token) {
      getGobs();
    }
  }, [token, currentPage]);
  return (
    <Container>
      <div className="m-b-50 main-b-border pb-6 header-underline">
        <div className="title">Explore the latest job posts </div>
        <p className="main-description">
          Discover the latest job posts tailored to your major or area of interest. For more personalized opportunities, fill out your career
          interests page
        </p>
      </div>

      <div className={styles.jobs_list}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
          {!loading
            ? jobs &&
              jobs.length > 0 &&
              jobs.map((item: any, i: number) => {
                return <Post data={item} index={i} />;
              })
            : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any) => {
                return <CardLoadingSkeleton />;
              })}
        </div>
      </div>
      {totlaPages > 1 && (
        <div className="flex justify-end my-6">
          <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      )}
    </Container>
  );
};

export default Posts;
