import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from "./form.module.scss";
import CustomButton from "../../../../Components/CustomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputField from "../../../../Components/Input";
import ReCAPTCHA from "react-google-recaptcha"
import LogoImage from '../../../../assets/images/Logo.png'
import { UserResetPassword, resetPassword, signIn } from "../../../../services/authService";
import { Box, CircularProgress } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addAuthInfo } from "../../../../store/authSlice";

import { useDispatch, useSelector } from 'react-redux';
import FormErrorMessage from "../../../../Components/FormErrorMessage";
import { addAlertInfo } from "../../../../store/alertSlice";
import AlertMessage from "../../../../Components/Alert";
import ForgotPassword from "../ForgetPasswordForm";
import CustomizedButtons from "../../../../Components/CustomButton";
interface LoginProps { }
interface LoginData {
  email: ""
  password: ""
}
const ResetPasswordForm = (props: LoginProps) => {
  const alert = useSelector((state: any) => state.alert);
  const captchaRef = useRef(null)
  const [loginData, SetLoginData] = useState<LoginData>({ email: '', password: '' })

  const [showForgotPassword, setShowForgotPassword] = useState(false)



  function handleClickSingUP(url: string) {
    navigate(url);
  }



  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [passwordData, setPasswordData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangePassword = async () => {
    // e.preventDefault()
    setLoading(true)
    const data={
      newPassword: passwordData.newPassword,
      passwordResetToken: searchParams.get("passwordResetToken")
    }
    const res = await UserResetPassword(searchParams.get("userId"),data)
    if (res.status === 200) {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: `password changed successfully please login again with your new password`,
        }
      ))
      navigate('/')
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          type:'error',
          show: true,
          message: `something went wrong please try again`,
        }
      ))

      // navigate('/')
    }
  }

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      newPasswordConfirmation: '',

    },
    onSubmit: function (values) {
      handleChangePassword()
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),

      newPasswordConfirmation: Yup.string()
        .required("Confirm your Password")
        .oneOf([Yup.ref('newPassword')], 'Your passwords does not match.')

    })
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    setPasswordData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };

  return (
    <div className={styles["login-form"]}>
      <div className={styles["form"]}>
        <div className={styles.logo}>
          <img src={LogoImage} alt="logo" />
        </div>
        <form onSubmit={formik.handleSubmit}>
         
     
            <h1 className="title">Reset Password</h1>
      
          <Box >
          <div className="flex justify-between">
          </div>
          <p className='required-hint w-3/4 mb-4'>Your password must be 12 characters long with a combination of uppercase letters and lowercase letters, symbols and numbers. </p>
          <form className="form">
            <div className="form-item">
              <InputField
                label="New password"
                required={true}
                value={formik.values.newPassword}
                Change={handleChange}
                name="newPassword"
                placeholder="New password"
                type="password"
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <span className='text-[#ff000e] text-sm'>{formik.errors.newPassword}</span>
              )}
            </div>
            <div className="form-item">
              <InputField
                label="Confirm new password"
                required={true}
                name='newPasswordConfirmation'
                Change={handleChange}
                value={formik.values.newPasswordConfirmation}
                placeholder="Confirm new password"
                type="password"
              />
              {formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation && (
                <span className='text-[#ff000e] text-sm'>{formik.errors.newPasswordConfirmation}</span>
              )}
            </div>

          </form>
        </Box>
            <a className="main-link flex " onClick={() => handleClickSingUP("/")}> Back to login</a>
            <div className={styles.actions}>
            <CustomButton type="submit" loading={loading} text={`Continue`} />
          </div>
        

          
          <div className={styles.hint}>
            <h2 className=" font-bold my-4">
              If you are new to edqan, select from the below:
            </h2>
            <p className="mb-4">
              Are you a student and early career professional that needs support
              in your career path?
              <a className="block" onClick={() => handleClickSingUP("/professionals/registration")}>
                Register here
              </a>
            </p>
            <p className="mb-4">
              Are you an employer?
              <a className="block" onClick={() => handleClickSingUP("/company/registration")}>Register here</a>
            </p>
            <p className="mb-4">
              Do you want to become a mentor?
              <a className="block" onClick={() => handleClickSingUP("/mentor/registration")}>
                Register here</a>
            </p>
            <p className="mb-4">
              Are you an educational institution (high school, college,
              university, vocational center, etc.)?
              <a className="block" >Learn more</a>
            </p>
          </div>
          <div className={styles.contact_us}>
            <p className=" mt-8 text-right">
            <a href="https://edqan.com/contact-us/" target="_blank">Contact us</a> for support
            </p>
          </div>
        </form>

      </div>
    </div>
  );
};

export default ResetPasswordForm;
