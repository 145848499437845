import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {


  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip
} from '@mui/material';
import Moment from 'react-moment';
import NoDataMessage from '../../Components/NoDataMessage';
import { getLatestActiveSessionsMentor } from '../../services/mentors';

export default function AllLatestActiveSessions(props: any) {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [data, setData] = useState([])
  const getData = async () => {
    const response = await getLatestActiveSessionsMentor(user._id, currentPage, 20)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 20))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token, currentPage]);

  return (
    <Container>

      <h3 className='title header-underline'>Latest Active Sessions</h3>


      <div className='main-table'>
        {data?.length > 0 ?
          <Table>
            <TableHead>
              <TableRow>

                <TableCell sortDirection="desc">
                  Mentorship Type
                </TableCell>
                <TableCell sortDirection="desc">
                  Mentee
                </TableCell>
      
                <TableCell>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {data.map((item: any) => (
                <TableRow
                  hover
                  key={item._id}
                >

                  <TableCell>
                    {item?.mentorShipSession?.mentorShipType?.nameEn}
                  </TableCell>
                  <TableCell>
                    <a className='cursor-pointer' onClick={() => navigate(`/professional/profile/${item?.user1?.user?._id}`)}>
                      {item?.user1?.user?.subModel?.firstName} {item?.user1?.user?.subModel?.lastName}
                    </a>

                  </TableCell>
                  {/* <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/mentor/profile/${item?.user2?.user?._id}`)}>
                    {item?.user2?.user?.subModel?.firstName} {item?.user2?.user?.subModel?.lastName}
                  </a>

                </TableCell> */}
                  <TableCell>
                    <Moment format="MMMM DD, yyyy">{item?.date}</Moment>
                  </TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table> :
          <NoDataMessage />}
      </div>

      {data?.length > 0 &&
        <div className="flex justify-end my-6">

          <Pagination count={totalPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      }
    </Container>
  )
}
