import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Avatar, CardMedia } from "@mui/material";
import styles from './card.module.scss'
import FollowingButton from "../../pages/Components/FollowingButton";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
interface CardProps {
  data?: any
  index: number
}
export default function CompanyCard(props: CardProps) {
  const userInfo = useSelector((state: any) => state.auth.user);
  const { data, index } = props
  const navigate = useNavigate()
  const navigateTo = (url: string) => {
    navigate(url)
  }
  return (
    <Card className="student-card" >
      <div>
        <div className="block  w-full ">
        <div className={[styles['card-actions'], "rounded-action"].join(" ")}>
          {userInfo?.model==='Student' && <FollowingButton followText='Follow' unFollowText='Unfollow' id={data?._id} index={index} />}
          </div>
          <div className="flex mt-2" onClick={()=>navigate(`/company/profile/${data?._id}`)}>
            <Avatar className='mr-4 object-contain'
              src={data?.subModel?.logo} sx={{ width: 50, height: 50, bgcolor: "#F26400" }} aria-label="avatar">
              <a className='capitalize text-white'>
                {data?.subModel?.name?.charAt(0).toUpperCase()}{data?.subModel?.name?.charAt(1).toUpperCase()}</a>
            </Avatar>
            <div>
              <h3 className="text-[18px] font-[500]"> {data?.subModel?.name}</h3>
              {(data?.country||data.city?.country)&& <h4 className="text-sm">{data?.country?.nameEn||data.city?.country?.nameEn}, {data.city?.nameEn}</h4>}
            </div>
          </div>
        


        </div>
        {/* <Typography sx={{ mb: 1.5 }} color="text.secondary" onClick={() => navigateTo(`/professionals/company-information/${data?._id}`)} className="cursor-pointer">
          {data?.subModel?.name}
        </Typography> */}
        {/* <Typography variant="body2">
          {data?.subModel?.industries?.map((industry: any, index: number) => {
            return (
              <>
                {industry.nameEn} {index !== data?.subIndustry?.length - 1 && <span>,</span>}
              </>

            )
          })}

{data?.subModel?.name}
        </Typography> */}
      </div>
    </Card>
  );
}
