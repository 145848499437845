import axios from '../axios';
import {BASEURL} from '../environments/environment'

// const token = localStorage.getItem("userToken");

export const liskeyWays = () => {
    return axios
      .get(`${BASEURL}keyWays`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };