import React from 'react'
import { TextField } from '@mui/material'

interface InputFieldProps {
  label?: string;
  labelHint?: string;
  required: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  placeholder: string;
  textArea?: boolean;
  Change?: any;
  value?: any;
  KeyDown?: any;
  name?: string
  defaultValue?: string;
  formik?: any
  submitClicked?: boolean;
  error?: boolean
  max?: number
  disabled?:boolean
  errorMessage?:any
  
}

export default function InputField(props: InputFieldProps) {
  const { label,
    required,
    name,
    type,
    multiline,
    rows,
    placeholder,
    labelHint,
    textArea,
    Change,
    KeyDown,
    value,
    defaultValue,
    formik,
    submitClicked,
    error,
    disabled,
    max,
    errorMessage

  } = props;

  const handleOnChange = (value: any) => {
    Change(value)
  }
  return (
    <div className='block w-full'>
      {(labelHint || label) && 
      <div className='input-label'>
        {label && <label >
          {required &&
            <span className='star'>*</span>
          }
          {label}</label>
        }
        {labelHint &&
          <p >{labelHint}</p>
        }

      </div>
      }
      {/* <div className='rounded-[10px]' style={{border:name && formik &&submitClicked&&  formik.errors[name] && "1px solid #ef4444"}}> */}
        <TextField
        inputProps={
          textArea ?
            {
              style: {
                height: 'auto',
                //  border: name && formik && formik.touched[name] && formik.errors[name] && "1px solid #f87171",
                borderRadius: '8px'
              }
            }
            :
            {
              style: {
                height: '46px',
                padding: '0px',
                //  border: name && formik &&  formik.errors[name] && "1px solid #f87171",
                borderRadius: '8px'

              }
            }
        }
        
        disabled={disabled}
        name={name}
        type={type}
        placeholder={placeholder}
        fullWidth
        multiline={multiline}
        rows={rows}
        onChange={e => handleOnChange(e)}
        onKeyDown={KeyDown}
        value={value}
        defaultValue={defaultValue}
        error={error}
        // helperText="This field id required"
     
      />
      {/* </div> */}
      
      {/* {name && formik && submitClicked && formik.errors[name] && (
        <span className='text-red-500 text-sm'>{formik.errors[name]}</span>
      )}*/}
      {error  &&
        <span className='text-red-500 text-sm'>{errorMessage||'This field is required'}</span>} 
    </div>
  )
}
