import React, { useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Modal from "@mui/material/Modal";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './popup.module.scss'
import CustomizedButtons from "../../../../../Components/CustomButton";
import InputField from "../../../../../Components/Input";
import { resetPassword } from "../../../../../services/authService";
import { useDispatch } from "react-redux";
import { addAlertInfo } from "../../../../../store/alertSlice";
import { useNavigate } from "react-router";
import FormErrorMessage from "../../../../../Components/FormErrorMessage";


interface PasswordPopUpProps {
  show: boolean;
  setOpen?: any;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: '16px',
  boxShadow: 0,
  p: 4,
};

function PasswordPopUp(props: PasswordPopUpProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { show, setOpen } = props;

  const [passwordData, setPasswordData] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const handleClose = () => setOpen(false);

  const handleChangePassword = async () => {
    setLoading(true)
    const res = await resetPassword(passwordData)
    if (res.status === 200) {
      setLoading(false)
      setOpen(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: `password changed successfully please login again with your new password`,
        }
      ))
      localStorage.clear();
      window.location.href = '/?password-change=true'
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
            show: true,
            message: res.data.error||'Your old password is invalid',
            type: 'error'
        }
    ))
      // setErrorMessage("Your old password is invalid")
    }
  }
  const handleCancelPassword = async () => {
    setPasswordData({})
    setErrorMessage('')
    handleClose()
  }
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',

    },
    onSubmit: function (values) {
      handleChangePassword()
    },
    validationSchema: Yup.object({

      oldPassword: Yup.string()
        .required('Old password is required'),

      newPassword: Yup.string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol'),

      newPasswordConfirmation: Yup.string()
        .required("Confirm your Password")
        .oneOf([Yup.ref('newPassword')], 'Your passwords does not match.')

    })
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e)
    setPasswordData((_data: any) => ({ ..._data, [e.target.name]: e.target.value }));
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-between">
            <p className="title">Change password</p>

            <div onClick={handleClose}>
              <CloseIcon />
            </div>
          </div>
          <p className='required-hint w-3/4 mb-4'>Your password must be 12 characters long with a combination of uppercase letters and lowercase letters, symbols and numbers. </p>
          <form className="form">
            {/* {errorMessage && (
              <FormErrorMessage errorMessage={errorMessage} />
            )} */}
            <div className="form-item">
              <InputField
                label="Old password"
                required={true}
                name='oldPassword'
                placeholder="Old password"
                Change={handleChange}
                value={formik.values.oldPassword}
                type="password"
              />
              {formik.touched.oldPassword && formik.errors.oldPassword && (
                <span className='text-[#ff000e] text-sm'>{formik.errors.oldPassword}</span>
              )}
            </div>
            <div className="form-item">
              <InputField
                label="New password"
                required={true}
                value={formik.values.newPassword}
                Change={handleChange}
                name="newPassword"
                placeholder="New password"
                type="password"
              />
              {formik.touched.newPassword && formik.errors.newPassword && (
                <span className='text-[#ff000e] text-sm'>{formik.errors.newPassword}</span>
              )}
            </div>

            <div className="form-item">
              <InputField
                label="Confirm new password"
                required={true}
                name='newPasswordConfirmation'
                Change={handleChange}
                value={formik.values.newPasswordConfirmation}
                placeholder="Confirm new password"
                type="password"
              />
              {formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation && (
                <span className='text-[#ff000e] text-sm'>{formik.errors.newPasswordConfirmation}</span>
              )}
            </div>

          </form>

          <div className="flex gap-4 justify-end mt-2">
            <CustomizedButtons text="Change password" click={formik.handleSubmit} loading={loading} />
            <CustomizedButtons text="Cancel" click={handleCancelPassword} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default PasswordPopUp;


