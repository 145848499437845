import axios from '../axios';
import { BASEURL } from '../environments/environment'

// Company

export const getNumberOfUsersViews = (id: string, startDate: string, endDate: string) => {
  return axios
    .get(`${BASEURL}company/dashboard/users-viewed-profile/${id}?startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const getNumberOfApplications = (id: string, startDate: string, endDate: string) => {
  return axios
    .get(`${BASEURL}company/dashboard/students-applications/${id}?startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const getNumberOfUsersFollowedCompany = (id: string, startDate: string, endDate: string) => {
  return axios
    .get(`${BASEURL}company/dashboard/users-followed-company/${id}?startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const getNumberOfUsersHired = (id: string, startDate: string, endDate: string) => {
  return axios
    .get(`${BASEURL}company/dashboard/users-hired/${id}?startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const getNumberOfJobViews = (id: string, jobId: any) => {
  return axios
    .get(`${BASEURL}company/dashboard/job-views/${id}?jobId=${jobId}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
//   institution


export const getNumberOfInstitutionUsersViews = (id: string, startDate: string, endDate: string) => {
  return axios
    .get(`${BASEURL}institution/dashboard/users-viewed-profile/${id}?startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const getNumberOfUsersFollowedInstitution = (id: string) => {
  return axios
    .get(`${BASEURL}institution/dashboard/users-followed-institution/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const getNumberOfUsersHiredInstitution = (id: string) => {
  return axios
    .get(`${BASEURL}institution/dashboard/users-hired/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const latestInteractionsWithEmployers = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}institution/applications/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const latestInteractionsWithStudent = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}company/student-applications/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const latestInteractionsWithEmployersForStudent = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}student/dashboard/interaction-employer/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const latestStudentActiveSessions = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}student/dashboard/active-session/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const studentRecentlyHiredInCompany = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}company/student-applications-hired/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const getLatestActiveSessions = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}institution/mentorship-sessions-ongoing/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const InstitutionLastMentorshipsCompleated = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}institution/mentorship-sessions/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const StudentRecentlyHired = (
  id: string,
  currentPage = 0,
  pageSize?: number
) => {
  const paging =
    currentPage !== null
      ? `?page=${currentPage}&pageSize=${pageSize ? pageSize : 9}`
      : "";
  return axios
    .get(`${BASEURL}institution/recently-hired/${id}${paging}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};


export const getNumberOfMentorUsersViews = (id: string, startDate: string, endDate: string) => {
  return axios
    .get(`${BASEURL}mentor/dashboard/users-viewed-profile/${id}?startDate=${startDate}&endDate=${endDate}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const getNumberOfUsersMentorshipSession = (id: string, startDate: string, endDate: string, status = null) => {
  return axios
    .get(`${BASEURL}mentor/dashboard/users-mentorshipSession/${id}?startDate=${startDate}&endDate=${endDate}${status ? `&status=${status}` : ''}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

