import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const listAllEducationLevel = () => {
  return axios
    .get(`${BASEURL}educationLevel`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const ListEducationLevelGroup = () => {
  return axios
    .get(`${BASEURL}educationLevelGroup`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};
export const GetEducationLevelById = (id:string) => {
  return axios
    .get(`${BASEURL}educationLevelGroup/fields/${id}`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};


