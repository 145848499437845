import { Avatar, Chip, Rating, Stack } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import styles from "./staff-profile.module.scss";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../../services/userService";
import PopUpSendMesage from "../../../Components/PopUp";

export default function StaffProfile(props: any) {
  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [user, setUserInfo] = useState<any>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleSendMesage = () => {
    setOpen(true);
  };

  function handleClick(href: string) {
    navigate(href);
  }
  const parms = useParams();
  const { id } = parms;

  const getUserData = async () => {
    const response = await getUserInfo(id || props?.id);
    if (response.status === 200) {
      setLoading(false);
      setUserInfo(response.data.data);
    } else {
    }
  };

  useEffect(() => {
    if (token && !id && !props?.id) {
      setUserInfo(userInfo);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    if (id || props?.id) {
      getUserData();
    }
  }, []);
  return (
    <>
      {!loading && (
        <Container>
          {open && (
            <PopUpSendMesage
              user={user}
              show={open}
              setOpen={setOpen}
              resiverName={`${user?.subModel?.firstName} 
              ${user?.subModel?.middleName || ""} 
              ${user?.subModel?.lastName}`}
              resiverEmail={user?.subModel?.currentTitle}
              id={user?._id}
            />
          )}

          <div className="page">
            {!props?.id && (
              <div className="m-b-50 main-b-border pb-6">
                <h2 className="title">Profile</h2>
              </div>
            )}
            <div className="flex justify-center gap-20 mt-8  responsive-profile-col">
              <div className="block text-center info">
                <Avatar
                  className="mr-4"
                  src={user?.profileImage}
                  sx={{ width: 120, height: 120, bgcolor: "#F26400", margin: "auto", marginBottom: "20px" }}
                  aria-label="avatar"
                >
                  <span className="uppercase text-white">{`${user?.subModel?.firstName.charAt(0)} ${user?.subModel?.lastName.charAt(0)}`}</span>
                </Avatar>
                <h3 className="secondary-title ">
                  {user?.subModel?.firstName} {user?.subModel?.middleName || ""} {user?.subModel?.lastName}
                </h3>
                <span className="colored m-b-10 block">Mentor</span>
                <Rating name="read-only" value={user?.subModel?.rating} size="large" readOnly precision={0.5} />
              </div>
              <div className={styles["profile-info"]}>
                {/* <h3 className="secondary-title"> Ahmed Amin</h3> */}
                <form>
                  <div className="form-item">
                    <label>About</label>
                    <p className="main-description">{user?.subModel?.about}</p>
                  </div>
                  {/* <div className="form-item">
                    <label >Primary email</label>
                    <a href={`mailto:${user?.email}`} className="colored main-description block">{user?.email}</a>
                  </div> */}

                  {user?.subModel?.phoneNumber ? (
                    <div className="form-item ">
                      <label className="mr-4">Phone #</label>
                      <p className="main-description">{user?.subModel?.phoneNumber}</p>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-item">
                    <label>Current title</label>
                    <p className="main-description">{user?.subModel?.currentTitle}</p>
                  </div>
                  <div className="form-item">
                    <label>Website/Profile Link</label>
                    <a href={user?.subModel?.linkedInUrl} rel="noreferrer" target="_blank" className="main-description underline">
                      {user?.subModel?.linkedInUrl}
                    </a>
                  </div>
                  {user?.subModel?.company && (
                    <div className="form-item">
                      <label>Company</label>
                      <p className="main-description">{user?.subModel?.company || "N/A"}</p>
                    </div>
                  )}
                  {user?.subModel?.educationLevel?.nameEn && (
                    <div className="form-item">
                      <label className="m-b-10 block">Level of Education </label>

                      <p className="main-description">{user?.subModel?.educationLevel?.nameEn || "N/A"}</p>
                    </div>
                  )}
                  {user?.subModel?.mentoringLanguages.length ? (
                    <div className="form-item">
                      <label className="m-b-10 block">Preferred languages for mentoring</label>
                      <Stack direction="row" gap={1} className="flex-wrap">
                        {user?.subModel?.mentoringLanguages.map((lang: any) => {
                          return <Chip label={lang.nameEn} />;
                        })}
                      </Stack>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="form-item">
                    <label className="m-b-10 block">Areas of mentoring</label>

                    <Stack direction="row" gap={1} className="flex-wrap">
                      {user?.subModel?.areaOfInterest.map((lang: any) => {
                        return <Chip label={lang.nameEn} />;
                      })}
                    </Stack>
                  </div>
                  {user?.subModel?.majors && user?.subModel?.majors?.length > 0 && (
                    <div className="form-item ">
                      <label className="m-b-10 block">Major(s)</label>

                      <p className="main-description">
                        <Stack direction="row" gap={1} className="flex-wrap">
                          {user?.subModel?.majors.map((lang: any) => {
                            return <Chip label={lang.nameEn} />;
                          })}
                        </Stack>
                        {/* {user?.subModel?.majors.map((item: any, index: number) => {
                        return (
                          <>
                            <span key={item._id}>{item.nameEn}</span>
                            {index !== user?.subModel?.majors.length - 1 && <span>, </span>}
                          </>
                        )
                      })} */}
                      </p>
                    </div>
                  )}
                  {user?.subModel?.countries.length ? (
                    <div className="form-item">
                      <label className="m-b-10 block">Experience living in these countries</label>
                      <Stack direction="row" gap={1} className="flex-wrap">
                        {user?.subModel?.countries.map((lang: any) => {
                          return <Chip label={lang.nameEn} />;
                        })}
                      </Stack>
                    </div>
                  ) : (
                    ""
                  )}
                  {user?.subModel?.almaMater?.subModel?.name && (
                    <div className="form-item flex">
                      <label className="mr-4">Alma Mater</label>
                      <p className="main-description">{user?.subModel?.almaMater?.subModel?.name}</p>
                    </div>
                  )}

                  <div className="form-item flex">
                    <label className="mr-4">Industry</label>

                    <p className="main-description">
                      {user?.subModel?.industries.map((item: any, index: number) => {
                        return (
                          <>
                            <span key={item._id}>{item.nameEn}</span>
                            {index !== user?.subModel?.industries.length - 1 && <span>, </span>}
                          </>
                        );
                      })}
                    </p>
                  </div>
                  {user?.subModel?.video ? (
                    <div className="form-item flex">
                      <label className="mr-4">Video from Mentor</label>

                      <a className="main-link" href={user?.subModel?.video} target="_blank">
                        {user?.subModel?.video}
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                  {
                    user?._id != userInfo?._id && (
                      // <p className="flex items-baseline">to schedule an appointment with {user?.subModel.firstName}
                      <a className="main-link  block small-text" onClick={() => handleSendMesage()}>
                        Send a message
                      </a>
                    )
                    // </p>
                  }
                </form>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}
