import React, { useEffect, useState } from "react";
import InputField from "../../../../Components/Input";
import EducationElement from "../components/EducationElement";
import ProjectElement from "../components/ProjectElement";
import ProjectForm from "../components/ProjectsForm";
import SchoolForm from "../components/SchoolForm";
import SectionTitle from "../components/SectionTitle";
import WorkElement from "../components/WorkElement";
import WorkForm from "../components/WorkForm";
import CustomizedButtons from "../../../../Components/CustomButton";
import RemoveIcon from '@mui/icons-material/Remove';
import { Chip, CircularProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from "../../../../services/authService";
import { addAlertInfo } from "../../../../store/alertSlice";
import { getUserInfo } from "../../../../services/userService";
import { addAuthInfo } from "../../../../store/authSlice";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import NoDataMessage from "../../../../Components/NoDataMessage";
import moment from "moment";

export default function RightContent() {
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.auth.user);
  const token = localStorage.getItem('userToken')
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showSchoolForm, setShowSchoolForm] = useState(false);
  const [showWorkForm, setShowWorkForm] = useState(false);
  const [userInfo, setUserInfo] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState<any>(null);
  const [selectedvolunteers, setSelectedvolunteers] = useState<any>(null);
  const [selectedProject, setSelectedProject] = useState<any>(null);
  const [loadingbtns, setLoadingbtns] = useState<any>({});
  const [editBrief, setEditBrief] = useState<any>(false);

  const showProjectFormHandler = () => {
    setShowProjectForm(!showProjectForm);
  };

  const showSchoolFormHandler = () => {
    setShowSchoolForm(!showSchoolForm);
    setSelectedEducation(null)
  };

  const showWorkFormHandler = () => {
    setShowWorkForm(!showWorkForm);
    setSelectedvolunteers(null)
  };
  const [keyword, setKeyword] = useState('')
  const [newCourse, setNewCourse] = useState<any>({})

  const [list, setList] = useState<Array<any>>([])
  const [listCourses, setListCourses] = useState<Array<any>>([])

  useEffect(() => {
    if (user?.subModel) {
      setListCourses([...user?.subModel?.courses])
      setList([...user?.subModel?.organizations])
    }

  }, [user])
  const addToList = (item: string) => {
    if (keyword) {
      let temp = list
      temp.push(keyword)
      setList(temp)
      setKeyword('')
      updateUserProfile('organizations', { 'organizations': list })
    }
  }

  const RemoveItem = (index: number) => {
    let temp = [...list]
    temp.splice(index, 1)
    setList(temp)
    updateUserProfile('organizations', { 'organizations': temp })
  }

  const addToListCourse = (item: string) => {
    let temp = listCourses.map((item: any) => ({ 'name': item.name, 'url': item.url }))
    temp.push(newCourse)
    // setListCourses(temp)

    updateUserProfile('courses', { 'courses': temp })
  }

  const RemoveItemCourse = (index: number) => {
    let temp = [...listCourses]
    temp.splice(index, 1)
    setListCourses(temp)
    updateUserProfile('deleteCourse', { 'courses': temp })
  }

  const updateUserProfile = async (key: string, data: any) => {
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))

    key && setLoadingbtns((_data: any) => ({ ..._data, [key]: true }))
    !key && setLoading(true)
    const formData = new FormData()
    for (const property in data) {
      if (typeof data[property] === 'string' || data[property] === 'boolean') {
        formData.append(property, data[property]);
      }
      else {
        formData.append(property, JSON.stringify(data[property]));
      }
    }
    const response = await UpdateUserProfile(user._id, formData)
    if (response.status === 200) {
      setNewCourse({
        name: '',
        url: ''
      })
      getUserData()
      !key && setLoading(false)
      key && setLoadingbtns((_data: any) => ({ ..._data, [key]: false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
    }
    else {
      setLoadingbtns((_data: any) => ({ ..._data, [key]: false }))
      setLoading(false)

      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }

  const getUserData = async () => {
    const response = await getUserInfo(user._id)
    if (response) {
      dispatch(addAuthInfo(
        {
          token: token,
          user: response.data.data
        }
      ))
    }
  }

  const UpdateUserInfo = async () => {
    setLoading(true)
    const formData = new FormData()
    for (const property in userInfo) {
      if (typeof userInfo[property] === 'string' || userInfo[property] === 'boolean') {
        formData.append(property, userInfo[property]);
      }
      else {
        formData.append(property, JSON.stringify(userInfo[property]));
      }
    }
    const response = await UpdateUserProfile(user._id, formData)
    if (response.status === 200) {
      getUserData()
      setLoading(false)
      setEditBrief(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  const handleEditEducation = (index: number) => {
    setSelectedEducation(index)
    setShowSchoolForm(true)
  }
  const handleDeleteEducation = async (index: number) => {
    setSelectedEducation(index)
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))
    setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': true }))
    const Updateddata: any = {}
    const data = [...user.subModel['education']]

    if (selectedEducation !== null) {
      data.splice(selectedEducation, 1)
    }

    data.splice(index, 1);
    Updateddata['education'] = data.map((item: any) => (
      {
        'university': item.university,
        'educationLevel': item.educationLevel._id,
        'gpa': item.gpa,
        'startingDate': moment(item?.startingDate).format('yyyy-MM-DD'),
        'endingDate': moment(item?.endingDate).format('yyyy-MM-DD'),
        'major': item?.major?._id,
        'minor': item?.minor
      }
    ))
    const formData = new FormData()
    for (const property in Updateddata) {
      if (typeof Updateddata[property] === 'string' || Updateddata[property] === 'boolean') {
        formData.append(property, Updateddata[property]);
      }
      else {
        formData.append(property, JSON.stringify(Updateddata[property]));
      }
    }
    const response = await UpdateUserProfile(user._id, formData)

    if (response.status === 200) {
      setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
      getUserData()
    }
    else {
      setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }

  }

  const handleEditWork = (index: number) => {
    setSelectedvolunteers(index)
    setShowWorkForm(true)
  }
  const handleDeleteWork = async (index: number) => {
    setSelectedvolunteers(index)
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))
    setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': true }))
    const Updateddata: any = {}
    const data = [...user.subModel['volunteers']]
    // if (selectedvolunteers !== null) {
    //   data.splice(selectedvolunteers, 1)
    // }
    data.splice(index, 1);
    Updateddata['volunteers'] = data.map((item: any) => (
      {

        'company': item.company,
        'description': item.description,
        'role': item.role,
        'startingDate': moment(item?.startingDate).format('yyyy-MM-DD'),
        'endingDate': moment(item?.endingDate).format('yyyy-MM-DD'),
        'city': item?.city?._id,
        'country': item?.country?._id,
      }

    ))

    const formData = new FormData()
    for (const property in Updateddata) {
      if (typeof Updateddata[property] === 'string' || Updateddata[property] === 'boolean') {
        formData.append(property, Updateddata[property]);
      }
      else {
        formData.append(property, JSON.stringify(Updateddata[property]));
      }
    }
    const response = await UpdateUserProfile(user._id, formData)

    if (response.status === 200) {
      setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
      getUserData()
    }
    else {
      setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }

  const handleEditProject = (index: number) => {
    setSelectedProject(index)
    setShowProjectForm(true)
  }
  const handleDeleteProject = async (index: number) => {
    setSelectedProject(index)
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))
    setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': true }))
    const Updateddata: any = {}
    const data = [...user.subModel['projects']]
    // if (selectedProject !== null) {
    //   data.splice(selectedProject, 1)
    // }
    data.splice(index, 1);
    Updateddata['projects'] = data.map((item: any) => (
      {

        'name': item.name,
        'url': item.url,
        'owner': item.owner,
        'description': item.description,
        'role': item.role,
        'startingDate': moment(item?.startingDate).format('yyyy-MM-DD'),
        'endingDate': moment(item?.endingDate).format('yyyy-MM-DD'),
        'city': item?.city?._id,
        'country': item?.country?._id,

      }

    ))

    const formData = new FormData()
    for (const property in Updateddata) {
      if (typeof Updateddata[property] === 'string' || Updateddata[property] === 'boolean') {
        formData.append(property, Updateddata[property]);
      }
      else {
        formData.append(property, JSON.stringify(Updateddata[property]));
      }
    }
    const response = await UpdateUserProfile(user._id, formData)

    if (response.status === 200) {
      setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
      getUserData()
    }
    else {
      setLoadingbtns((_data: any) => ({ ..._data, 'deleteEducation': false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }
  return (
    <>
      {Object.keys(user).length === 0 ?
        '' :
        <div>
          <div className="m-b-50">
            <div className="flex justify-between ">
              <SectionTitle title="Tell us about yourself" editable={false} />
              <div className="flex items-baseline space-x-2">
                <button onClick={() => setEditBrief(!editBrief)}>
                  <EditIcon fontSize="small" />
                </button>

              </div>
            </div>

            <p className="font-extralight text-sm text-[#222222] mb-3 ">
              A very short introduction mentioning your skill sets and experiences
              and what is it you would like to do.
            </p>
            <div>

              {!editBrief && <p>  {user?.subModel?.brief}</p>}
            </div>
            {editBrief && <>
            <p className="text-[11px] text-gray-500 text-end mb-1">{userInfo?.brief?.length||0}/750</p>
              <textarea
                maxLength={750}
                className="w-full border hover:border p-2 rounded-lg"
                rows={4}
                required={false}
                placeholder="Enter text here...."
                defaultValue={user?.subModel?.brief}
                onChange={(e: any) => setUserInfo((_data: any) => ({ ..._data, 'brief': e.target.value }))}
              />
              <div className='custom-action small-btn  my-2 flex gap-x-4'>
              <CustomizedButtons text="Cancel" click={() => {
                      setEditBrief(false)

                }} loading={loading} />
                <CustomizedButtons text="Save" click={() => {
                  UpdateUserInfo()

                }} loading={loading} />
              </div>
            </>
            }
          </div>

          <div className="m-b-50 main-b-border pb-6">
            <SectionTitle title="Education" editable={false} />
            {user?.subModel?.education.length ?
              user?.subModel?.education.map((education: any, index: any) => {
                return (
                  <>
                    <div className="flex justify-between">
                      <EducationElement item={education} />

                      <div className="flex items-baseline space-x-2">
                        <button onClick={() => handleEditEducation(index)}>
                          <EditIcon fontSize="small" />
                        </button>
                        <button onClick={() => handleDeleteEducation(index)}>
                          {loadingbtns.deleteEducation && selectedEducation === index ?
                            <CircularProgress size="20px" /> :
                            <DeleteIcon fontSize="small" />
                          }
                        </button>
                      </div>
                    </div>
                    {showSchoolForm && selectedEducation === index && <SchoolForm isShown={showSchoolForm} onCancel={() => { setShowSchoolForm(false) }} getUserData={getUserData} selectedEducation={selectedEducation} />
                    }
                  </>


                )
              }) :
              <NoDataMessage text="No education added yet" />}


            <div className="flex justify-end mt-2">
              <span
                className="main-link"
                onClick={showSchoolFormHandler}
              >
                Add School
              </span>
            </div>
            {showSchoolForm && !selectedEducation && <SchoolForm isShown={showSchoolForm} onCancel={() => { setShowSchoolForm(false) }} getUserData={getUserData} selectedEducation={selectedEducation} />}
          </div>
          <div className="m-b-50 main-b-border pb-6">
            <SectionTitle title="Work & Volunteer Experience" editable={false} />
            {user?.subModel?.volunteers.length ?
              user?.subModel?.volunteers.map((education: any, index: number) => {
                return (
                  <>
                    <div className="flex justify-between">
                      <WorkElement item={education} />

                      <div className="flex items-baseline space-x-2">
                        <button onClick={() => handleEditWork(index)}>
                          <EditIcon fontSize="small" />
                        </button>
                        <button onClick={() => handleDeleteWork(index)}>
                          {loadingbtns.deleteEducation && selectedvolunteers === index ?
                            <CircularProgress size="20px" /> :
                            <DeleteIcon fontSize="small" />
                          }
                        </button>
                      </div>
                    </div>

                    {showWorkForm && selectedvolunteers === index && <WorkForm isShown={showWorkForm}
                      onCancel={() => { setShowWorkForm(false) }}
                      getUserData={getUserData}
                      selectedvolunteers={selectedvolunteers} />}
                  </>

                )
              })
              : <NoDataMessage text="No work or volunteer experience added yet" />}
            <div className="flex justify-end mt-2">
              <span
                className="main-link"
                onClick={showWorkFormHandler}
              >
                Add work/volunteer experience
              </span>
            </div>
            {showWorkForm && !selectedvolunteers && <WorkForm isShown={showWorkForm}
              onCancel={() => { setShowWorkForm(false) }}
              getUserData={getUserData}
              selectedvolunteers={selectedvolunteers} />}
          </div>
          <div className="m-b-50 main-b-border pb-6">
            <SectionTitle title="Committees/Organizations" editable={false} />

            <p className="font-light text-[14px] my-1">
              Committees and organizations you have been involved in in your school
            </p>
            <InputField
              label=""
              placeholder="Add text"
              required={false}
              Change={(e: any) => setKeyword(e.target.value)}
              value={keyword}
            />
            {list.length > 0 &&
              <div className={['mt-2'].join(' ')}>
                <Stack direction="row" gap={1} className="flex-wrap">
                  {
                    list.map((item: string, index: number) => {
                      return (
                        <Chip label={item}
                          onClick={() => RemoveItem(index)}
                          onDelete={() => RemoveItem(index)}
                          deleteIcon={<RemoveIcon />} />
                      )
                    })}
                </Stack>
              </div>
            }
            <div className='custom-action small-btn  my-2 flex gap-x-4'>

              <CustomizedButtons text="Add" click={addToList} disabled={!keyword} />
            </div>
          </div>
          <div className="m-b-50">
            <SectionTitle title="Courses completed so far" editable={false} />
            <div className='form-item '>
              <InputField
                label="Course name"
                placeholder="Add text"
                required={false}
                Change={(e: any) => setNewCourse((_data: any) => ({ ..._data, 'name': e.target.value }))}
                value={newCourse.name}
              />
            </div>
            <div className='form-item '>
              <InputField
                label="Course URL"
                placeholder="Add text"
                required={false}
                Change={(e: any) => setNewCourse((_data: any) => ({ ..._data, 'url': e.target.value }))}
                value={newCourse.url}
              />
            </div>
            <div className='custom-action small-btn  my-2 flex gap-x-4'>
              <CustomizedButtons text="Add Course" click={addToListCourse}
                disabled={!(newCourse?.name)}
                loading={loadingbtns.courses} />
            </div>
            <p className="font-light text-[15px] my-1">
              {listCourses.length ?
                listCourses.map((item: any, index: number) => {
                  return (
                    <div className="flex justify-between" key={index}>
                      <div className="mb-4">
                        <p>
                          {item?.name}
                        </p>
                        <a href={item.url} target="_blank" rel="noreferrer" className="main-link text-small">{item.url}</a>
                      </div>
                      <button onClick={() => RemoveItemCourse(index)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  )
                })
                :
                'No courses added yet'
              }
            </p>
          </div>
          <div className="m-b-50">
            <SectionTitle title="Notable Projects/Products you worked on" />
            {user?.subModel?.projects.length ?
              user?.subModel?.projects.map((education: any, index: number) => {
                return (
                  <>
                    <div className="flex justify-between">
                      <ProjectElement item={education} />

                      <div className="flex items-baseline space-x-2">
                        <button onClick={() => handleEditProject(index)}>
                          <EditIcon fontSize="small" />
                        </button>
                        <button onClick={() => handleDeleteProject(index)}>
                          {loadingbtns.deleteEducation && selectedProject === index ?
                            <CircularProgress size="20px" /> :
                            <DeleteIcon fontSize="small" />
                          }
                        </button>
                      </div>
                    </div>
                    {showProjectForm && selectedProject === index && <ProjectForm
                      isShown={showProjectForm}
                      onCancel={() => { setShowProjectForm(false) }}
                      getUserData={getUserData}
                      selectedProject={selectedProject}
                    />}
                  </>
                )
              })
              :
              <NoDataMessage text="No projects experience added yet" />}
            <div className="flex justify-end mt-2">
              <a className="main-link" onClick={showProjectFormHandler} >Add Project</a>
            </div>
            {showProjectForm && selectedProject == null
              &&
              <ProjectForm
                isShown={showProjectForm}
                onCancel={() => { setShowProjectForm(false) }}
                getUserData={getUserData}
                selectedProject={selectedProject}
              />}

          </div>
        </div>
      }
    </>
  );
}
