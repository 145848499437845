import React from "react";
import './popup.scss';
import { Dialog, DialogTitle, DialogContent, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router";
interface PopupProp {
    open: boolean
    setOpenPop: React.Dispatch<React.SetStateAction<boolean>>;
}
// const closePopup = (DialogModal (false));
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogModal = ({ open, setOpenPop }: PopupProp) => {
    const handleClose = () => {
        setOpenPop(false)
    }
    const navigate = useNavigate();
    return (

        <Dialog className="dialog" open={open} >
            <DialogTitle >
                <div className="title">
                    <div><b>Ensure a smooth mentoring experience as a mentor:</b></div>
                    <div><label onClick={handleClose} id="closePopup"> &times;</label></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    1. Set your availability time for mentees.<br />
                    2. Respond to requests promptly. <br />
                    3. Connect with other mentors, and much more.<br />
                    4. Gain valuable insights.<br />
                </Typography>
                <br />
                <Typography><b>Quick actions:</b></Typography>
                <div className="buttons">
                    <Button id="button1" size="small" className="btn" onClick={() => navigate("/mentor/add-availability")}>
                        Set my available time now
                    </Button>
                    <Button id="button2" size="small" className="btn" onClick={() => navigate("/mentor/settings")}>
                        Update my public profile
                    </Button>
                    <Button id="button3" size="small" className="btn" onClick={() => navigate("/mentor/connect-mentors")}>
                        Connect with mentors
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default DialogModal;
