import React from 'react'
import styles from './error-message.module.scss'

interface FormErrorMessageProps {
  errorMessage:string;
}

export default function FormErrorMessage(props:FormErrorMessageProps) {
    const {errorMessage} = props
  return (
    <div className={styles['container']}>
        <p>{errorMessage}</p>
    </div>
  )
}
