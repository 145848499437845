

import axios from "../axios";
import { BASEURL } from "../environments/environment";

export const getUserInfo = (id: any) => {
  return axios
    .get(`${BASEURL}user/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const getUserNewInfo = (id: any) => {
  return axios
    .get(`${BASEURL}user/new-version/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const UpdateUserProfile = (id:string,payload: any) => {
  return axios
    .patch(`${BASEURL}user/${id}`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const UpdateUserNotifications = (id:string,payload: any) => {
  return axios
    .patch(`${BASEURL}user/notifications/${id}`, payload)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const UserRestPassword = (email: any) => {
  return axios
    .get(`${BASEURL}user/reset-password/${email}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const SentMailVerification = (id:any) => {
  return axios
    .get(`${BASEURL}user/verify-request/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
