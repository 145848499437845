import React, { useEffect, useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Checkbox } from "@mui/material";
import CustomizedButtons from "../../../../../Components/CustomButton";
import InputField from "../../../../../Components/Input";
import { ListAllJobsType, ListAllTypeOfWork, ListAllWorkType } from "../../../../../services/jobsService";
import { useSelector } from "react-redux";

interface TabOneProps {
  setValue: any;
  setDataValues: any
  reviewData: any
}

export default function TabOne(props: TabOneProps) {
  const token = useSelector((state: any) => state.auth.token);

  const { setValue, setDataValues, reviewData } = props;
  const [agreement, setAgreement] = useState<boolean>(true)
  const [WorkTypeList, setWorkTypeList] = useState([])
  const [jobsTypeList, setJobsTypeList] = useState([])
  const [defaultValue, setDefaultValue] = useState('')
  const [otherText, setOtherText] = useState('')


  const submitHandler = () => {
    setValue(1)
  }

  const handleChange = (e: any) => {
    if (e.target.name === 'otherOfWorkType') {
      setOtherText(e.target.value)
    }

    if (e.target.value === 'Other') {
      setDataValues((_data: any) => ({ ..._data, 'typeOfWork': otherText }));
      // setDataValues(otherText, 'resourcesFindingJob');
    }
    else {
      // setDataValues(e);
      setDataValues((_data: any) => ({ ..._data, 'typeOfWork': e.target.value }));
    }
  };

  const handleagreementchange = (e: any) => {
    setAgreement(!agreement)

    setDataValues((_data: any) => ({ ..._data, 'agreeToTerms': agreement }));
  }

  const getAllWorkType = async () => {
    setOtherText('')
    const response = await ListAllJobsType()
    if (response) {
      setWorkTypeList(response?.data?.data?.queryResult)
      if (response?.data?.data?.queryResult.map((item: any) => item.nameEn).includes(reviewData?.typeOfWork)) {
        setOtherText('')
      } else {
        setOtherText(reviewData?.typeOfWork)
      }
      // setLoading(false)
    }
  };

  useEffect(() => {
    if (token) {
      if (WorkTypeList.length > 0) {

      } else {
        getAllWorkType()
      }

      // getAllJobsType()
      setDataValues((_data: any) => ({ ..._data, 'agreeToTerms': true }));

    }
  }, [token]);


  return (
    <div>
      <p className="secondary-title">Type of work experience</p>
      <p className="main-description">Choose your experience.</p>
      <div className="py-4 ">
        <FormControl className="w-1/2">
          <RadioGroup
            name="typeOfWork"
            onChange={handleChange}
          >
            {WorkTypeList?.map((item: any) => {
              return (
                <FormControlLabel
                  defaultValue={reviewData?.typeOfWork}

                  value={item.nameEn}
                  control={<Radio size='small' checked={reviewData?.typeOfWork == item.nameEn} />}
                  label={<span className="text-[15px]">{item.nameEn}</span>}
                />
              )
            })}
            <div className="flex justify-between gap-4">
              <FormControlLabel value="Other"
                control={<Radio size='small' checked={!WorkTypeList.map((item: any) => item.nameEn).includes(reviewData?.typeOfWork)} />} label={<span className="text-[15px]">Other</span>} />
              <InputField
                required
                placeholder="Other"
                name="otherOfWorkType"
                Change={handleChange}
                value={otherText}
              />
            </div>

          </RadioGroup>
          <div className="mt-2">
            <FormControlLabel
              name="agreeToTerms"
              onClick={handleagreementchange}
              value={agreement}
              control={<Checkbox defaultChecked size="small" />} label={<span className="main-description">I have read and agreed to the <a className="main-link" href="https://edqan.com/terms-of-use/" target="_blank">Terms</a> of Service and <a className="main-link" href="https://edqan.com/privacy-policy/" target="_blank">Privacy Policy</a>.</span>} />
          </div>
        </FormControl>
        <div className="mt-6 custom-action">
          <CustomizedButtons text="Next" click={submitHandler}
            disabled={!(reviewData.typeOfWork && agreement)} />
        </div>
      </div>


    </div>
  );
}
