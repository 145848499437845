import React, { ChangeEvent, useEffect, useState } from "react";
import MentorCard from "../../MentorCard";
import RequestMentor from "../../RequestMentor";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { listAllMentors } from "../../../../../services/mentors";

import { useFormik } from "formik";
import moment from "moment";
import CustomButton from "../../../../../Components/CustomButton";
import { MentorShipSessionRequest } from "../../../../../services/mentorshipService";
import { addAlertInfo } from "../../../../../store/alertSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import FormErrorMessage from "../../../../../Components/FormErrorMessage";
import NoDataMessage from "../../../../../Components/NoDataMessage";
import StaffProfile from "../../../../Mentor/StaffProfile";
interface TabOneProps {
  setValue: any;
}

export default function TabOne(props: TabOneProps) {
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState<any>({});
  const [mentors, setMentors] = useState([]);
  const [loadingMentors, setLoadingMentors] = useState(false);

  const [selectedDate, setSelectedDate] = useState<any>();
  const [loadingBtn, setLoadingMentorsBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [mentorId, setMentorId] = useState("");

  const handleClick = async () => {
    setLoadingMentorsBtn(true);
    const data = {
      user2: {
        user: requestData.mentor,
      },
      meetingType: "session",
      mentorShipSession: {
        mentorShipType: requestData.mentorShipType?.id,
        preferredLanguage: requestData.preferredLanguage?.id,
        whatIsOutcomeYouAreLookingFor: requestData.whatIsOutcomeYouAreLookingFor,
        WhyYouWouldLikeMentorShip: requestData.WhyYouWouldLikeMentorShip,
        enterYourLinkedinOrSocialMediaSite: requestData.enterYourLinkedinOrSocialMediaSite,
        domain: requestData.requestData,
        startUpEmail: requestData.startUpEmail,
        additionalInformation: requestData.additionalInformation,
      },
      date: selectedDate,
      startTime: selectedDate,
    };
    const response = await MentorShipSessionRequest(user?._id, data);
    if (response.status === 201) {
      dispatch(
        addAlertInfo({
          show: true,
          message: `Your request sent successfully`,
        })
      );
      navigate("/professionals/mentorship-sessions");
      setLoadingMentorsBtn(false);
    } else {
      setLoadingMentorsBtn(false);
      // setErrorMessage(response.data.error)
      dispatch(
        addAlertInfo({
          show: true,
          message: response.data.error,
          type: "error",
        })
      );
    }
  };
  const handleSelectTime = (data: any) => {
    // const date = moment(data.date).format('yyyy-MM-DD')

    // setRequestData((_data: any) => ({ ..._data, 'date': moment(data.date).format('yyyy-MM-DD') }));
    // setRequestData((_data: any) => ({ ..._data, 'time': time }));
    setSelectedDate(data);
  };
  const getAllMentors = async (id: string) => {
    setLoadingMentors(true);
    const response = await listAllMentors(id);
    if (response) {
      setMentors(response.data.data.queryResult);
      setLoadingMentors(false);
    }
  };
  useEffect(() => {
    if (requestData.mentor) {
      setSelectedDate(null);
    }
  }, [requestData.mentor]);
  const handleShowMentor = (__id: string) => {
    setStep(3);
    setMentorId(__id);
  };
  return (
    <div>
      <h3 className="title header-underline ">{step == 3 ? "Mentor Profile" : "Request a Mentor"}</h3>
      {/* {errorMessage && (<FormErrorMessage errorMessage={errorMessage} />)} */}

      {step === 1 && <RequestMentor setRequestData={setRequestData} getAllMentors={getAllMentors} requestData={requestData} setStep={setStep} />}
      {step === 2 && (
        <>
          <p className="mb-4">Please select one of the mentors and a session time. Click on '<b>book now</b>' after you have selected the session time.</p>
          <a className="colored mb-4 block cursor-pointer" onClick={() => setStep(1)}>
            <ArrowBackIcon /> Back
          </a>

          {!loadingMentors && mentors.length > 0 ? (
            <Grid container spacing={3}>
              {mentors.map((mentor: any, index: number) => {

                return (
                  <Grid item lg={4} key={mentor._id}>
                    <div
                      onClick={() => setRequestData((_data: any) => ({ ..._data, mentor: mentor.user }))}
                      className={`h-full ${requestData.mentor === mentor.user ? "main-box-shadow rounded-xl" : ""}`}
                    >
                      <MentorCard
                        isCard={requestData.mentor === mentor.user}
                        id={mentor.user}
                        profileImage={mentor?.profileImage}
                        rating={mentor?.rating}
                        name={`${mentor?.firstName} ${mentor?.lastName}`}
                        subName={`${mentor?.firstName.charAt(0).toUpperCase()}${mentor?.lastName.charAt(0).toUpperCase()}`}
                        title={mentor?.currentTitle}
                        description={mentor?.about}
                        availabilityTime={mentor?.availabilityTime}
                        handleSelectTime={handleSelectTime}
                        selectedDate={selectedDate}
                        handleBookClick={handleClick}
                        loadingBtn={loadingBtn}
                        handleShowMentor={handleShowMentor}
                      />
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <NoDataMessage />
          )}
          {/* <div className="actions-end ">
            {requestData.date && requestData.time &&
              <CustomButton text="Request this mentor" type="button" click={handleClick} loading={loadingBtn} />
            }
          </div> */}
        </>
      )}
      {step === 3 && (
        <>
          <a className="colored mb-4 block cursor-pointer" onClick={() => setStep(2)}>
            <ArrowBackIcon /> Back to mentors list
          </a>

          <StaffProfile id={mentorId} />
        </>
      )}
    </div>
  );
}
