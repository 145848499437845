import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { ListAllIndustry } from "../../../../../../services/industry";
import SelectInput from "../../../../../../Components/SelectInput";
import CustomButton from "../../../../../../Components/CustomButton";
import { useSelector } from "react-redux";
import InputField from "../../../../../../Components/Input";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CloseIcon from '@mui/icons-material/Close';
import { getAllSocialMedia } from "../../../../../../services/scoialMediaService";
interface SocialMediaUIProps {
  userInfo: any,
  setUserData: any
  socialMediaLinksList: any,
  setSocialMediaLinksList: any

}
export default function SocialMediaUI(props: SocialMediaUIProps) {
  const { userInfo, setUserData, socialMediaLinksList, setSocialMediaLinksList } = props
  const [socialMediaKeys, setSocialMediaKeys] = useState<any>()
  const [socialMediaList, setSocialMediaList] = useState([])
  const token = useSelector((state: any) => state.auth.token);
  // const userInfo = useSelector((state: any) => state.auth.user);
  const [count, setCount] = useState<any>({});
  const [mediaError, setMediaError] = useState(false);
  // const [socialMediaLinksList, setSocialMediaLinksList] = useState<any>([])
  const handleInputChange = (e: any, index: number, id: string) => {
    const allList = [...userInfo?.socialMediaLinks]
    allList.splice(index, 1)
    const temp = [...userInfo?.socialMediaLinks[index].url]
    temp.push(e.target.value)

    const item = {
      socialMedia: id,
      url: temp
    }
    allList.push(item)
    setUserData((_data: any) => ({ ..._data, 'socialMediaLinks': allList }));
  }
  const getSocialMediaList = async () => {
    const response = await getAllSocialMedia()
    setSocialMediaList(response.data.data)
    for (let i = 0; i < response.data.data.length; i++) {
      setSocialMediaKeys((_data: any) => ({ ..._data, [response.data.data[i]._id]: response.data.data[i].nameEn }))
    }
    for (let i = 0; i < userInfo?.socialMediaLinks?.length; i++) {
      setSocialMediaLinksList((_data: any) => ({ ..._data, [userInfo?.socialMediaLinks[i].socialMedia._id]: userInfo?.socialMediaLinks[i].url }))
    }
  }
  useEffect(() => {
    if (token) {
      getSocialMediaList()

    }

  }, []);
  const removeSocialMedia = (e: any, item: string, url: string) => {
    e.preventDefault()
    const temp: any = socialMediaLinksList[item] ? [...socialMediaLinksList[item]] : []
    temp.splice(temp.indexOf(url), 1)

    setSocialMediaLinksList((_data: any) => ({ ..._data, [item]: temp }))

  }
  const checkUrl = (url: string) => {
    let givenURL;
    try {
      givenURL = new URL(url);
    } catch (error) {

      return false;
    }
    return true;
  }
  const handleKeyDown = (socialMediaType: any, e: any) => {
    
    if (e.key === 'Enter') {
      setMediaError(false)
      e.preventDefault()
      if (checkUrl(e.target.value)) {
        const temp: any = Object.keys(socialMediaLinksList).length>0 ? socialMediaLinksList[socialMediaType?._id]?[...socialMediaLinksList[socialMediaType?._id]]:[]  : []
  
        temp.push(e.target.value)
        setSocialMediaLinksList((_data: any) => ({ ..._data, [socialMediaType._id]: temp }))
        e.target.value = ''
      }
      else {
        setMediaError(true)

      }
    }

  }
return (
    <>

      <div className="form-item">
        <div className='input-label'>
          <label>Social media sites</label>
          <p className=''>Press Enter To add link</p>
        </div>


        <div className="md:flex  items-center gap-4">
          {socialMediaList.map((item: any) => {
            return (
              <div className="w-full md:w-1/2">
                <div className='form-item '>
                  <InputField
                    required={false}
                    label={item.nameEn}
                    placeholder='URL'
                    KeyDown={(e: any) => handleKeyDown(item, e)}
                  />
                </div>
              </div>
            )
          })}


        </div>
      </div>
      {mediaError &&
        <span className='text-red-500 text-sm'>{'Please enter a valid url'}</span>}


      {Object.keys(socialMediaLinksList).length > 0 &&


        Object.keys(socialMediaLinksList).map((item: any) => {
          return (
            <>
              {socialMediaLinksList[item].length > 0 &&

                <div className='p-4 bg-light-orange main-border border-orangr mb-2'>
                  <h4 className='mt-4 mb-2'>{socialMediaKeys[item]} </h4>
                  <ul className='space-y-2'>
                    {socialMediaLinksList[item]?.map((url: any) => {
                      return (
                        <li className='flex space-x-2 w-1/2 location-item'>

                          <div className='flex'>
                            {/* <LocationOnIcon sx={{ color: "#f26400" }} /> */}
                            <div>
                              {url}
                            </div>
                          </div>
                          <button type='button' onClick={e => removeSocialMedia(e, item, url)}>
                            <CloseIcon sx={{ color: "#e2e2e2", cursor: 'pointer' }} />
                          </button>

                        </li>
                      )
                    })}

                  </ul>
                </div>
              }
            </>

          )
        })}



    </>

  );
}
