import * as React from "react";
import Box from "@mui/material/Box";
import TabOne from "./TabOne";


export default function StepsTabs() {
  const [value, setValue] = React.useState(0);
  return (

     
      <TabOne setValue={setValue} />

  );
}
