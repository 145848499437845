import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedButtons from "../../../../Components/CustomButton";
import Moment from "react-moment";
import { useNavigate } from "react-router";
import { changeMentorShipSessionStatus } from "../../../../services/mentors";
import { addAlertInfo } from "../../../../store/alertSlice";
import { useDispatch } from "react-redux";
import { Box, Grid, Modal, Rating } from "@mui/material";
import moment from "moment";

interface DynamicTableProps {
  columns: Array<string>;
  alignment: Array<any>;
  checked?: any;
  mouseAction?: Array<any>;
  coloredHeader?: boolean;
  list?: any;
  type?: any;
  listAll?: any;
  setValue?: any;
}

export default function DynamicTable(props: DynamicTableProps) {
  const { columns, alignment, checked, mouseAction, coloredHeader, list, type, listAll, setValue } = props;
  function formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openMenteeRate, setOpenMenteeRate] = React.useState(false);

  const [selectedData, setSelectedData] = React.useState<any>({});
  const [selectedId, setSelectedId] = React.useState<any>(null);

  const handleClose = () => setOpen(false);
  const handleCloseMenteeRate = () => setOpenMenteeRate(false);
  const navigateTo = (url: string) => {
    navigate(url);
  };
  const handleNavigationToRating = (id: any) => {};
  const [loading, setLoading] = React.useState(false);
  const handleCLickComplete = async (id: any, status: string) => {
    dispatch(
      addAlertInfo({
        show: false,
      })
    );
    setLoading(true);

    const data = {
      status: status,
    };
    const response = await changeMentorShipSessionStatus(id, data);
    if (response.status === 200) {
      setLoading(false);
      dispatch(
        addAlertInfo({
          show: true,
          message: "Done",
        })
      );
      listAll();
      setValue(1);
    } else {
      setLoading(false);
      dispatch(
        addAlertInfo({
          show: true,
          message: response.data.error,
          type: "error",
        })
      );
    }
  };
  const handleComplete = (id: string) => {
    setSelectedId(id);
    handleCLickComplete(id, "completed");
  };

  return (
    <div className="main-table my-8 w-full">
      {open && <SeeMentorReview show={open} handleClose={handleClose} data={selectedData} />}
      {openMenteeRate && <SeeMenteeReview show={openMenteeRate} handleClose={handleCloseMenteeRate} data={selectedData} />}

      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow className={coloredHeader ? "colored-header" : ""}>
            {columns.map((el, index) => {
              return (
                <TableCell align={alignment[index]}>
                  <p className="list-title py-2">{el}</p>
                </TableCell>
              );
            })}
            {type === "ongoing" && <TableCell>Actions</TableCell>}
            {type === "completed" && (
              <TableCell>
                <span className="list-title text-center block">Reviews Given to Mentee</span>
              </TableCell>
            )}
            {type === "completed" && (
              <TableCell>
                <span className="list-title text-center block">Review Given to You by Mentee</span>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((item: any, index: any) => {
            return (
              <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}>
                <TableCell>
                  <a onClick={() => navigateTo(`/professional/profile/${item?.user1?.user?._id}`)}>
                    {`${item?.user1?.user?.subModel?.firstName} ${item?.user1?.user?.subModel?.middleName || ""} ${
                      item?.user1?.user?.subModel?.lastName
                    }`}
                  </a>
                </TableCell>
                <TableCell>
                  {" "}
                  <div className="justify-between flex">
                    <Moment format="dddd, MMM DD, YYYY">{item?.date}</Moment>
                    {item?.startTime && <span>{moment(item?.startTime).format("hh:mm A")}</span>}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <span className="capitalize">{item?.meetingType}</span>
                </TableCell>
                {type === "ongoing" && (
                  <TableCell>
                    <div className="small-btn">
                      <CustomizedButtons text="Mark as complete" click={() => handleComplete(item._id)} loading={loading && selectedId == item._id} />
                    </div>
                  </TableCell>
                )}

                {type === "completed" && (
                  <TableCell>
                    <div className="text-center">
                      {item?.mentorShipSession?.mentorRating?.rate ? (
                        <div className="block">
                          <div className="block">
                            <Rating name="read-only" value={item?.mentorShipSession?.mentorRating?.rate} size="large" readOnly precision={0.5} />
                          </div>
                          <a
                            className="colored cursor-pointer block"
                            onClick={() => {
                              setSelectedData(item);
                              setOpen(true);
                            }}
                          >
                            See Review
                          </a>
                        </div>
                      ) : (
                        <a
                          className="colored cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/mentor/mentor-rating/${item?._id}?username=${item?.user1?.user?.subModel?.firstName} ${
                                item?.user1?.user?.subModel?.middleName || ""
                              } ${item?.user1?.user?.subModel?.lastName}`
                            )
                          }
                        >
                          Add Review
                        </a>
                      )}
                    </div>
                  </TableCell>
                )}
                {type === "completed" && (
                  <TableCell>
                    <div className="text-center">
                      {item?.mentorShipSession?.menteeRating?.rate ? (
                        <div className="block">
                          <div className="block">
                            <Rating name="read-only" value={item?.mentorShipSession?.menteeRating?.rate} size="large" readOnly precision={0.5} />
                          </div>
                          <a
                            className="colored cursor-pointer block"
                            onClick={() => {
                              setSelectedData(item);
                              setOpenMenteeRate(true);
                            }}
                          >
                            See Review
                          </a>
                        </div>
                      ) : (
                        <a>Not Reviewed yet</a>
                      )}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function SeeMentorReview(props: any) {
  const { show, handleClose, data } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "90vh",
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 0,
    p: 4,
  };

  return (
    <div>
      <Modal open={show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex justify-end " onClick={handleClose}>
            <CloseIcon className="cursor-pointer" />
          </div>
          <p className="font-semibold text-lg text-orange">Review details</p>

          <div className="flex my-6">
            <div className="w-full">
              <Grid container>
                <Grid item md={12}>
                  <div className="form-item ">
                    <label>
                    Mentee's Name :{" "}
                      <span className="font-[300] colored">
                        {data?.user1?.user?.subModel?.firstName} {data?.user1?.user?.subModel?.middleName || ""}{" "}
                        {data?.user1?.user?.subModel?.lastName}
                      </span>
                    </label>
                  </div>
                </Grid>
               
                <Grid item md={6}>
                  <div className="form-item ">
                    <label>
                      Meeting Date :{" "}
                      <span className="font-[300]">
                        <Moment format="MMMM DD, yyyy">{data?.date}</Moment>
                      </span>
                    </label>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="form-item w-100 text-end">
                    <label>
                      Meeting Type : <span className="font-[300]">{data?.meetingType}</span>
                    </label>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className="form-item ">
                    <label>Your Rating</label>
                    <Rating name="read-only" value={data?.mentorShipSession?.mentorRating?.rate} size="large" readOnly precision={0.5} />
                  </div>
                </Grid>
                <div className="text-underline h-1 w-full"></div>
                <Grid item md={12}>
                  <div className="form-item ">
                    <label>What you liked and learned?</label>
                    <p className="font-[300]">
                      {data?.mentorShipSession?.mentorRating?.whatYouLiked || <span className="text-red-500"> Not provided</span>}
                    </p>
                  </div>
                </Grid>
                <div className="text-underline h-1 w-full"></div>

                {/* <Grid item md={12}>
                  <div className='form-item '>
                  <label>What areas of improvement do you suggest?</label>
                    
                    <p className='font-[300]'>{data?.mentorShipSession?.mentorRating?.improvementSuggestion || <span className='text-red-500'>  Not provided</span>}</p>
                  </div>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

function SeeMenteeReview(props: any) {
  const { show, handleClose, data } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "90vh",
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: "16px",
    boxShadow: 0,
    p: 4,
  };

  return (
    <div>
      <Modal open={show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex justify-end " onClick={handleClose}>
            <CloseIcon className="cursor-pointer" />
          </div>
          <p className="font-semibold text-lg text-orange">Review details</p>

          <div className="flex my-6">
            <div className="w-full">
              <Grid container>
              <Grid item md={12}>
                  <div className="form-item ">
                    <label>
                    Mentee's Name :{" "}
                      <span className="font-[300] colored">
                        {data?.user1?.user?.subModel?.firstName} {data?.user1?.user?.subModel?.middleName || ""}{" "}
                        {data?.user1?.user?.subModel?.lastName}
                      </span>
                    </label>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className="form-item ">
                    <label>
                    Mentor's Name :{" "}
                      <span className="font-[300] colored">
                        {data?.user2?.user?.subModel?.firstName} {data?.user2?.user?.subModel?.middleName || ""}{" "}
                        {data?.user2?.user?.subModel?.lastName}
                      </span>
                    </label>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="form-item ">
                    <label>
                      Meeting Date :{" "}
                      <span className="font-[300]">
                        <Moment format="MMMM DD, yyyy">{data?.date}</Moment>
                      </span>
                    </label>
                  </div>
                </Grid>

                <Grid item md={6}>
                  <div className="form-item w-100 text-end">
                    <label>
                      Meeting Type : <span className="font-[300]">{data?.meetingType}</span>
                    </label>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className="form-item ">
                    <label>Rating</label>
                    <Rating name="read-only" value={data?.mentorShipSession?.menteeRating?.rate} size="large" readOnly precision={0.5} />
                  </div>
                </Grid>
                <div className="text-underline h-1 w-full"></div>
                <Grid item md={12}>
                  <div className="form-item ">
                    <label>What {data?.user1?.user?.subModel?.firstName} liked and learned?</label>

                    <p className="font-[300]">
                      {data?.mentorShipSession?.menteeRating?.whatYouLiked || <span className="text-red-500"> Not provided</span>}
                    </p>
                  </div>
                </Grid>
                <div className="text-underline h-1 w-full"></div>

                {/* <Grid item md={12}>
                  <div className='form-item '>
                  <label>What areas of improvement did {data?.user1?.user?.subModel?.firstName} suggest?</label>
                    <p className='font-[300]'>{data?.mentorShipSession?.menteeRating?.improvementSuggestion || <span className='text-red-500'>  Not provided</span>}</p>
                  </div>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
