

import ImageSrc from '../../assets/images/No data-cuate.svg'
export default function NoDataMessageImage(props:any) {
    return (

        <div className="block text-center justify-center py-8 bg-light-orange mt-12 rounded-md p-2 mb-8">
            <img src={ImageSrc} className='w-[300px] m-auto'/>
         <p className='text-lg'>
         {props.text?props.text: 'No data available in this section yet'}
            </p>
     
        </div>
    );
}