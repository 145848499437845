import React, { useState } from "react";

import { Avatar, CircularProgress } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ImageCrop from "../../../../../Components/ImageUpload";
import { useDispatch, useSelector } from "react-redux";
import { addAlertInfo } from "../../../../../store/alertSlice";

interface Props { 
  setImagefile:any
}
export const UploadeProfileImage = (props:Props) => {
  const {setImagefile}=props
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)
  const [image, setSelectimage] = useState('')
  // const [file, setImagefile] = useState(false)
  const [loading, setLoading] = useState(false)
  // const user = useSelector((state: any) => state.auth.user);
  // const formData = new FormData()
  const handleSubmit = (selectimage: any, imagefile: any) => {
    setSelectimage(selectimage)
    // setImagefile(imagefile)
    // setLoading(true)
    // formData.append("profileImage", imagefile);
    setImagefile(imagefile)
  }

  return (
    <div className="w-full flex items-center justify-center">
      {show &&

        <ImageCrop show={show} setOpen={setShow} type='image' handleSubmit={handleSubmit} />
      }
      <div className="relative">
        <Avatar src={image} sx={{ width: '150px', height: "150px" }}  className="object-contain" >
        </Avatar>
        {loading ?
        <div className="absolute right-[8px] bottom-[2px]">
          <CircularProgress size="20px" />
          </div> :
          <button className="add-image" onClick={() => setShow(true)} >

            <AddIcon />

          </button>
        }
      </div>


    </div>
  );
}

export default UploadeProfileImage;


