import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import LeftContent from "./LefttContent";
import RightContent from "./RightContent";


export default function ShowStudentProfile() {
  const token = localStorage.getItem('userToken')
  return (
    <Container>
      {token &&
        <div className='page'>
          <Grid container spacing={3} >
            <Grid item lg={4}>
              <LeftContent />
            </Grid>
            <Grid item lg={8}>
              <RightContent />
            </Grid>
          </Grid>
        </div>}
    </Container>
      
  );
}
