import { createSlice } from "@reduxjs/toolkit";

export const mesagesSlice = createSlice({
  name: "mesages",
  initialState: {
    data:null
  },
  reducers: {
    addMesages: (state, action) => {
      return action.payload;
    },
  },
});

export const { addMesages } = mesagesSlice.actions;

export default mesagesSlice.reducer;
