import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";
import { CircularProgress } from "@mui/material";

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
  
  borderColor: "#F26400",
  Height: "52px",
  flexWrap: "wrap",
  height: "auto",
  color: "#F26400",
  border: "2px solid",
  // minWidth: '120px',
  boxShadow: 'none',
  backgroundColor: 'transparent',
  textTransform: 'none',
  fontWeight: '600',
  fontSize: "16px",
  padding: '12px 28px',
  borderRadius: '8px',
  'svg': {
    color: "#F26400",
  },
  '&:hover': {
    color: "white",
    backgroundColor: "#F26400",
    'svg': {
      color: "white",
    },
  },
  variant: "outlined",
}));

interface CustomButtonProps {
  text: string | JSX.Element;
  click?: any;
  icon?: any;
  iconAlign?: string;
  type?: any
  loading?: boolean
  disabled?:boolean
  
}
export default function CustomizedButtons(props: CustomButtonProps) {
  const { text, click, icon, iconAlign, type,loading,disabled } = props;
  return <CustomButton onClick={() => click?click():''} type={type} disabled={loading||disabled}>
    {loading ?
      <CircularProgress size="20px" /> :
      <>
        {iconAlign === "right" && icon && icon}
        {text}
        {iconAlign !== "right" && icon && icon}
      </>
    }

  </CustomButton>;
}
