import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//Auth Pages
import Login from "../pages/Auth/Login";
import SignUp from "../pages/Auth/SignUp";
import MentorSignUp from "../pages/Auth/MentorSignUp";

import Home from "../pages/Student/Home";
import Layout from "../Layout";
import { useEffect } from "react";
import JobDetails from "../pages/Student/JobDetails/Single";
// import WrongLogin from "../pages/Auth/WrongLogin";
import MoreJobs from "../pages/Student/Jobs/MoreJobsPosts";
import MoreRecommendedJobs from "../pages/Student/Jobs/MoreRecomendedJobsPosts";
import MoreInterestsJobs from "../pages/Student/Jobs/MoreInterestjobs";
import Inistitution from "../pages/Student/Inistitution";
import Inbox from "../pages/Student/Inbox";

import StudentProfile from "../pages/Student/Profile";
import StudentAccess from "../pages/Student/Access";
// import RequestMentor from "../pages/Student/Mentorships/RequestMentor";
import MentoringSection from "../pages/Student/Mentorships";
import MentorshipsCompleted from "../pages/Student/MentorshipsCompleted";

import Jobs from "../pages/Student/Jobs";
import Documents from "../pages/Student/ManageDocuments";
import JobApplication from "../pages/Student/JobApplication";
import InterviewsAndMeetings from "../pages/Student/InterviewsAndMeetings";
import CareerInterests from "../pages/Student/CareerInterest";
import Notifications from "../pages/Student/notificationspage";
import Reviews from "../pages/Student/Reviews";
import AddReview from "../pages/Student/AddReview";
import NotificationsPreferences from "../pages/Student/Notifications/notificationPreferences";
import AccountSetting from "../pages/Student/AccountSetting";

// Mentor pages
import MentorHome from "../pages/Mentor/Home";
import MentorshipProgram from "../pages/Mentor/MentorshipProgram";
import Rating from "../pages/Mentor/Rating";
import MentessRequests from "../pages/Mentor/MentessRequests";
import AddAvailabilityTime from "../pages/Mentor/AddAvailabilityTime";
import MentorAccountSetting from "../pages/Mentor/AccountSetting";
import StaffProfile from "../pages/Mentor/StaffProfile";

// Institution pages
import InstitutionsHome from "../pages/Institutions/Home";
import StudentsList from "../pages/Institutions/StudentList";
import InstitutionProfile from "../pages/Institutions/Profile";
import SchoolInformation from "../pages/Institutions/SchoolInfo";

//Employer Pages
import EmployerHome from "../pages/Employer/Home";
import EmployerProfile from "../pages/Employer/Profile";
// import EmployerInformation from "../pages/Employer/EmployerInformation";
// import AddInformation from "../pages/Employer/AddInformation";
import EmployerSignUp from "../pages/Auth/EmployerSignUp";
// import MessagingSetting from "../pages/Employer/MessagingSetting";
import EmployerGuideline from "../pages/Employer/Guidelines";
import ConnectMentors from "../pages/Mentor/ConnectMentors";
import MentorInterviewsAndMeetings from "../pages/Mentor/InterviewsAndMeetings";
import { useSelector } from "react-redux";
import NotFound from "../pages/NotFound";
import InstitutionCalendar from "../pages/Institutions/InterviewsAndMeetings";

// import CompanyStudents from "../pages/Employer/EmployerInformation/Students/allStudents";
import SavedJobs from "../pages/Student/SavedJobs";
import FollwedCompanies from "../pages/Student/FollwedCompanies";
// import JobsPosted from "../pages/Employer/EmployerInformation/JobsPosted";
import CompanyCalendar from "../pages/Employer/InterviewsAndMeetings";
import AddInformation from "../pages/Employer/AddInformation";
import EmployerInformation from "../pages/Employer/EmployerInformation";
import JobsPosted from "../pages/Employer/EmployerInformation/JobsPosted";
import CompanyStudents from "../pages/Employer/EmployerInformation/Students/allStudents";
import MessagingSetting from "../pages/Employer/MessagingSetting";
import JobApplications from "../pages/Employer/JopApplications";
import ResetPassword from "../pages/Auth/Login/ResetPasswordIndex";
import MailVerification from "../pages/Auth/Login/MailVerification";
import ShowStudentProfile from "../pages/Student/StudentProfile";
import AllStudentsList from "../pages/StudentsList";
import StudentsProfiles from "../pages/Institutions/StudentList/profiles";
import InstitutionInteractions from "../pages/Institutions/Dashboard/interactions";
import ActiveSessions from "../pages/Institutions/Dashboard/activeSessions";
import InteractionsWithStudents from "../pages/Employer/Dashboard/interactionsWithStudents";
import AllSimilarJobs from "../pages/Student/Jobs/SimilarJobs";
import AllLatestActiveSessions from "../pages/Mentor/all-latest-active-sessins";
import { Container } from "@mui/material";
import InteractionsWithEmployersForProfessionals from "../pages/Student/Home/components/interactionsWithemployers";
import ActiveSessionsForStudent from "../pages/Student/Home/components/studentActiveSessions";

function AppRouter() {
  const noneAuthRoutes = [
    "/",
    "/professionals/registration",
    "/mentor/registration",
    "/company/registration",
    "/reset-password",
    "/mailVerification",
  ];
  const token = localStorage.getItem("userToken");
  const user: any = localStorage.getItem("userInfo");
  let userInfolocalStorage: any = null;

  try {
    userInfolocalStorage = JSON?.parse(user);
  } catch (err) {
    localStorage.clear();
    window.location.href = "/";
  }

  const userInfo = useSelector((state: any) => state.auth.user);
  const User = userInfo?.model || userInfolocalStorage?.model;
  const isLogedUser = token ? true : false;

  useEffect(() => {
    const pathname = window.location.pathname;
    if (token) {
      if (isLogedUser && noneAuthRoutes.includes(pathname)) {
        if (User === "Student") {
          window.location.href = "/professionals-home";
        } else if (User === "Mentor") {
          window.location.href = "/mentor/home";
        } else if (User === "Company") {
          window.location.href = "/company/home";
        } else if (User === "Institution") {
          window.location.href = "/institution/home";
        }
      }
    }
  }, [token]);

  useEffect(() => {
    const pathname = window.location.pathname;

    setTimeout(() => {
      if (!isLogedUser && !noneAuthRoutes.includes(pathname)) {
        window.location.href = "/";
      }
    }, 1000);
  }, [token]);

  if (User === "Student") {
    return (
      <Router>
        {/* <ScrollRestoration /> */}
        <Routes>
          <Route
            path="*"
            element={
              <Layout>
                {" "}
                <NotFound />
              </Layout>
            }
          />
          <Route
            path="/"
            element={
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
          />
          <Route path="/professionals/registration" element={<SignUp />} />
          <Route
            path="/professionals-home"
            element={
              <Layout>
                {" "}
                <Home />
              </Layout>
            }
          />
          <Route
            path="/professionals/jobs"
            element={
              <Layout>
                <Jobs />
              </Layout>
            }
          />
          <Route
            path="/professionals/more-jobs"
            element={
              <Layout>
                <MoreJobs />
              </Layout>
            }
          />
          <Route
            path="/professionals/more-recommended-jobs"
            element={
              <Layout>
                <MoreRecommendedJobs />
              </Layout>
            }
          />
          <Route
            path="/professionals/more-interests-jobs"
            element={
              <Layout>
                <MoreInterestsJobs />
              </Layout>
            }
          />
          <Route
            path="/job-details/:id"
            element={
              <Layout>
                {" "}
                <Container>
                  <JobDetails />
                </Container>
              </Layout>
            }
          />
          <Route
            path="/professionals/institution"
            element={
              <Layout>
                <Inistitution />
              </Layout>
            }
          />
          <Route
            path="/professionals/institution/:id"
            element={
              <Layout>
                <Inistitution />
              </Layout>
            }
          />
          <Route
            path="/professionals/my-profile"
            element={
              <Layout>
                {" "}
                <StudentProfile />
              </Layout>
            }
          />
          <Route
            path="/employers"
            element={
              <Layout>
                {" "}
                <StudentAccess />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/request-mentor"
            element={
              <Layout>
                {" "}
                <MentoringSection />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/mentorship-sessions"
            element={
              <Layout>
                {" "}
                <MentorshipsCompleted />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/manage-documents"
            element={
              <Layout>
                {" "}
                <Documents />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/job-application"
            element={
              <Layout>
                <JobApplication />
              </Layout>
            }
          />
          <Route
            path="/calendar"
            element={
              <Layout>
                <InterviewsAndMeetings />
              </Layout>
            }
          />
          <Route
            path="/professionals/career-interests"
            element={
              <Layout>
                {" "}
                <CareerInterests />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/reviews"
            element={
              <Layout>
                <Reviews />
              </Layout>
            }
          />
          <Route
            path="/professionals/write-review"
            element={
              <Layout>
                <AddReview />
              </Layout>
            }
          />
          <Route
            path="/notification-preferences"
            element={
              <Layout>
                {" "}
                <NotificationsPreferences />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/settings"
            element={
              <Layout>
                {" "}
                <AccountSetting />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/mentor-profile/:id"
            element={
              <Layout>
                {" "}
                <StaffProfile />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/saved-jobs"
            element={
              <Layout>
                <SavedJobs />
              </Layout>
            }
          />
          <Route
            path="/professionals/followed-companies"
            element={
              <Layout>
                <FollwedCompanies />
              </Layout>
            }
          />
          <Route
            path="/company-information/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/company-jobs/:id/:name"
            element={
              <Layout>
                <JobsPosted />
              </Layout>
            }
          />
          <Route
            path="/company-professionals/:id/:name"
            element={
              <Layout>
                <CompanyStudents />
              </Layout>
            }
          />
          <Route
            path="/inbox"
            element={
              <Layout>
                <Inbox />
              </Layout>
            }
          />
          <Route
            path="/notifications"
            element={
              <Layout>
                <Notifications />{" "}
              </Layout>
            }
          />
          <Route
            path="/professional/profile/:id"
            element={
              <Layout>
                {" "}
                <ShowStudentProfile />
              </Layout>
            }
          />
          <Route
            path="/mentor/profile/:id"
            element={
              <Layout>
                {" "}
                <StaffProfile />{" "}
              </Layout>
            }
          />
          <Route
            path="/professionals/mentor-rating/:id"
            element={
              <Layout>
                <Rating type="Mentee" />
              </Layout>
            }
          />
          <Route
            path="/institution/profile/:id"
            element={
              <Layout>
                <Inistitution />
              </Layout>
            }
          />
          <Route
            path="/company/profile/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/other-similar-jobs/:selectedJobId/:selectedJobMajorId"
            element={
              <Layout>
                <AllSimilarJobs />
              </Layout>
            }
          />
          <Route
            path="/interactions-with-employers"
            element={
              <Layout>
                <InteractionsWithEmployersForProfessionals />
              </Layout>
            }
          />
          <Route
            path="/professional-active-sessions"
            element={
              <Layout>
                <ActiveSessionsForStudent />
              </Layout>
            }
          />
        </Routes>
      </Router>
    );
  }
  if (User === "Mentor") {
    return (
      <Router>
        {/* <ScrollRestoration /> */}
        <Routes>
          <Route
            path="*"
            element={
              <Layout>
                {" "}
                <NotFound />
              </Layout>
            }
          />
          <Route
            path="/"
            element={
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
          />
          <Route path="/mentor/registration" element={<MentorSignUp />} />
          <Route
            path="/calendar"
            element={
              <Layout>
                <MentorInterviewsAndMeetings />
              </Layout>
            }
          />
          <Route
            path="/mentor/home"
            element={
              <Layout>
                <MentorHome />
              </Layout>
            }
          />
          <Route
            path="/mentor/mentorship-program"
            element={
              <Layout>
                <MentorshipProgram />
              </Layout>
            }
          />
          <Route
            path="/mentor/connect-mentors"
            element={
              <Layout>
                <ConnectMentors />
              </Layout>
            }
          />
          <Route
            path="/mentor/mentor-rating/:id"
            element={
              <Layout>
                <Rating type="Mentor" />
              </Layout>
            }
          />
          {/* <Route path="/professionals/mentor-rating/:id" element={<Layout><Rating type="Mentee" /></Layout>} /> */}
          <Route
            path="/notification-preferences"
            element={
              <Layout>
                <NotificationsPreferences />{" "}
              </Layout>
            }
          />
          <Route
            path="/mentor/settings"
            element={
              <Layout>
                {" "}
                <MentorAccountSetting />{" "}
              </Layout>
            }
          />
          <Route
            path="/mentor/profile"
            element={
              <Layout>
                {" "}
                <StaffProfile />{" "}
              </Layout>
            }
          />
          <Route
            path="/mentor/profile/:id"
            element={
              <Layout>
                {" "}
                <StaffProfile />{" "}
              </Layout>
            }
          />
          <Route
            path="/mentor/mentees-requests"
            element={
              <Layout>
                {" "}
                <MentessRequests />{" "}
              </Layout>
            }
          />
          <Route
            path="/mentor/add-availability"
            element={
              <Layout>
                {" "}
                <AddAvailabilityTime />{" "}
              </Layout>
            }
          />
          <Route
            path="/mentor/active-sessions"
            element={
              <Layout>
                <AllLatestActiveSessions />
              </Layout>
            }
          />
          <Route
            path="/inbox"
            element={
              <Layout>
                <Inbox />
              </Layout>
            }
          />
          <Route
            path="/notifications"
            element={
              <Layout>
                <Notifications />{" "}
              </Layout>
            }
          />
          <Route
            path="/professional/profile/:id"
            element={
              <Layout>
                {" "}
                <ShowStudentProfile />
              </Layout>
            }
          />
          <Route
            path="/institution/profile/:id"
            element={
              <Layout>
                <Inistitution />
              </Layout>
            }
          />
          <Route
            path="/company/profile/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/company-information/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
        </Routes>
      </Router>
    );
  }
  if (User === "Institution") {
    return (
      <Router>
        <Routes>
          {/* Institutions ROUTES */}
          <Route
            path="*"
            element={
              <Layout>
                {" "}
                <NotFound />
              </Layout>
            }
          />
          <Route
            path="/"
            element={
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
          />
          <Route
            path="/institution/home"
            element={
              <Layout>
                <InstitutionsHome />
              </Layout>
            }
          />
          <Route
            path="/institution/students-list"
            element={
              <Layout>
                <StudentsList />
              </Layout>
            }
          />
          <Route
            path="/institution/student-profiles"
            element={
              <Layout>
                <StudentsProfiles />
              </Layout>
            }
          />
          <Route
            path="/company-professionals/:id/:name"
            element={
              <Layout>
                <CompanyStudents />
              </Layout>
            }
          />
          <Route
            path="/institution/profile"
            element={
              <Layout>
                <InstitutionProfile />
              </Layout>
            }
          />
          <Route
            path="/institution/:id"
            element={
              <Layout>
                <Inistitution />
              </Layout>
            }
          />
          <Route
            path="/institution/jobs"
            element={
              <Layout>
                <Jobs />
              </Layout>
            }
          />
          {/* <Route path="/institution/employers" element={<Layout> <StudentAccess /> </Layout>} /> */}
          {/* <Route path="/institution/admin-profile" element={<Layout><InstitutionStaffProfile /></Layout>} /> */}
          <Route
            path="/notification-preferences"
            element={
              <Layout>
                {" "}
                <NotificationsPreferences />{" "}
              </Layout>
            }
          />
          <Route
            path="/institution/school-information"
            element={
              <Layout>
                {" "}
                <SchoolInformation />{" "}
              </Layout>
            }
          />
          <Route
            path="/calendar"
            element={
              <Layout>
                <InstitutionCalendar />
              </Layout>
            }
          />
          <Route
            path="/inbox"
            element={
              <Layout>
                <Inbox />
              </Layout>
            }
          />
          <Route
            path="/notifications"
            element={
              <Layout>
                <Notifications />{" "}
              </Layout>
            }
          />
          <Route
            path="/professional/profile/:id"
            element={
              <Layout>
                {" "}
                <ShowStudentProfile />
              </Layout>
            }
          />
          <Route
            path="/mentor/profile/:id"
            element={
              <Layout>
                {" "}
                <StaffProfile />{" "}
              </Layout>
            }
          />
          <Route
            path="/institution/profile/:id"
            element={
              <Layout>
                <Inistitution />
              </Layout>
            }
          />
          <Route
            path="/company/profile/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/job-details/:id"
            element={
              <Layout>
                <Container>
                  <JobDetails />
                </Container>
              </Layout>
            }
          />
          <Route
            path="/interactions-with-employers"
            element={
              <Layout>
                <InstitutionInteractions />
              </Layout>
            }
          />
          <Route
            path="/employers"
            element={
              <Layout>
                {" "}
                <StudentAccess />{" "}
              </Layout>
            }
          />
          <Route
            path="/active-sessions"
            element={
              <Layout>
                <ActiveSessions />
              </Layout>
            }
          />

          <Route
            path="/company-information/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/company-jobs/:id/:name"
            element={
              <Layout>
                <JobsPosted />
              </Layout>
            }
          />
          <Route
            path="/other-similar-jobs/:selectedJobId/:selectedJobMajorId"
            element={
              <Layout>
                <AllSimilarJobs />
              </Layout>
            }
          />
        </Routes>
      </Router>
    );
  }
  if (User === "Company") {
    return (
      <Router>
        <Routes>
          {/* Institutions ROUTES */}
          <Route
            path="*"
            element={
              <Layout>
                {" "}
                <NotFound />
              </Layout>
            }
          />
          <Route
            path="/"
            element={
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
          />
          <Route
            path="/company/home"
            element={
              <Layout>
                <EmployerHome />
              </Layout>
            }
          />
          <Route
            path="/company/profile"
            element={
              <Layout>
                <EmployerProfile />
              </Layout>
            }
          />
          <Route
            path="/notification-preferences"
            element={
              <Layout>
                {" "}
                <NotificationsPreferences />{" "}
              </Layout>
            }
          />
          <Route
            path="/calendar"
            element={
              <Layout>
                <CompanyCalendar />
              </Layout>
            }
          />
          <Route
            path="/company/information"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/company/students"
            element={
              <Layout>
                <CompanyStudents />
              </Layout>
            }
          />
          <Route
            path="/professionals-list"
            element={
              <Layout>
                {" "}
                <AllStudentsList />{" "}
              </Layout>
            }
          />
          <Route
            path="/company/add-information/:id"
            element={
              <Layout>
                <AddInformation />
              </Layout>
            }
          />
          <Route
            path="/company/add-information"
            element={
              <Layout>
                <AddInformation />
              </Layout>
            }
          />
          <Route
            path="/company/messaging-setting"
            element={
              <Layout>
                <MessagingSetting />
              </Layout>
            }
          />
          <Route
            path="/company/guidelines"
            element={
              <Layout>
                <EmployerGuideline />
              </Layout>
            }
          />
          <Route
            path="/company/jobs"
            element={
              <Layout>
                <JobsPosted />
              </Layout>
            }
          />
          <Route
            path="/company-jobs/:id/:name"
            element={
              <Layout>
                <JobsPosted />
              </Layout>
            }
          />
          <Route
            path="/company/job-applications/:id"
            element={
              <Layout>
                <JobApplications />
              </Layout>
            }
          />
          <Route
            path="/job-details/:id"
            element={
              <Layout>
                {" "}
                <Container>
                  <JobDetails />
                </Container>
              </Layout>
            }
          />
          <Route
            path="/inbox"
            element={
              <Layout>
                <Inbox />
              </Layout>
            }
          />
          <Route
            path="/notifications"
            element={
              <Layout>
                <Notifications />{" "}
              </Layout>
            }
          />
          <Route
            path="/professional/profile/:id"
            element={
              <Layout>
                {" "}
                <ShowStudentProfile />
              </Layout>
            }
          />
          <Route
            path="/mentor/profile/:id"
            element={
              <Layout>
                {" "}
                <StaffProfile />{" "}
              </Layout>
            }
          />
          <Route
            path="/institution/profile/:id"
            element={
              <Layout>
                <Inistitution />
              </Layout>
            }
          />
          <Route
            path="/company/profile/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/interactions-with-professionals"
            element={
              <Layout>
                <InteractionsWithStudents />
              </Layout>
            }
          />
          <Route
            path="/company-information/:id"
            element={
              <Layout>
                <EmployerInformation />
              </Layout>
            }
          />
          <Route
            path="/other-similar-jobs/:selectedJobId/:selectedJobMajorId"
            element={
              <Layout>
                <AllSimilarJobs />
              </Layout>
            }
          />
        </Routes>
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route
            path="*"
            element={
              <div className="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            }
          />
          <Route path="/" element={<Login />} />
          <Route path="/professionals/registration" element={<SignUp />} />
          <Route path="/mentor/registration" element={<MentorSignUp />} />
          <Route path="/company/registration" element={<EmployerSignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/mailVerification" element={<MailVerification />} />
        </Routes>
      </Router>
    );
  }
}
export default AppRouter;
