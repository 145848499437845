

import { Container, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Moment from 'react-moment';
import { useNavigate } from 'react-router';
import NoDataMessage from '../../../Components/NoDataMessage';
import { getLatestActiveSessions } from '../../../services/analyticsService';

export default function ActiveSessions() {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  const getData = async () => {
    const response = await getLatestActiveSessions(user._id, currentPage)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token]);
 
  return (
    <div className="m-t-50 m-b-50">
      <Container>

        <h3 className='title header-underline'>Latest Active Sessions</h3>


        {data?.length > 0 ?
        <Table>
          <TableHead>
            <TableRow>

              <TableCell sortDirection="desc">
                Mentorship Type
              </TableCell>
              <TableCell sortDirection="desc">
                Mentee
              </TableCell>
              <TableCell sortDirection="desc">
                Mentor
              </TableCell>
              <TableCell>
                Date
              </TableCell>
            </TableRow>
          </TableHead>
      <TableBody>

            {data.map((item: any) => (
              <TableRow
                hover
                key={item._id}
              >

                <TableCell>
                  {item?.mentorShipSession?.mentorShipType?.nameEn}
                </TableCell>
                <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/professional/profile/${item?.user1?.user?._id}`)}>
                    {item?.user1?.user?.subModel?.firstName} {item?.user1?.user?.subModel?.lastName}
                  </a>

                </TableCell>
                <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/mentor/profile/${item?.user2?.user?._id}`)}>
                    {item?.user2?.user?.subModel?.firstName} {item?.user2?.user?.subModel?.lastName}
                  </a>

                </TableCell>
                <TableCell>
                  <Moment format="MMMM DD, yyyy">{item?.updatedAt}</Moment>


                </TableCell>


              </TableRow>
            ))}
          </TableBody> 
        </Table>:
            <NoDataMessage />}
        <div className="flex justify-end my-6">

          <Pagination count={totalPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      </Container>
    </div>
  )
}
