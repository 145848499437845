import { ChangeEvent, useRef, useState } from "react";
import styles from "./form.module.scss";
import CustomButton from "../../../../Components/CustomButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputField from "../../../../Components/Input";
import ReCAPTCHA from "react-google-recaptcha";
import LogoImage from "../../../../assets/images/Logo.png";
import { signIn } from "../../../../services/authService";
import { addAuthInfo } from "../../../../store/authSlice";

import { useDispatch, useSelector } from "react-redux";
import FormErrorMessage from "../../../../Components/FormErrorMessage";
import { addAlertInfo } from "../../../../store/alertSlice";
import ForgotPassword from "../ForgetPasswordForm";
import { CAPTCHACLINTSECRET } from "../../../../environments/environment";
interface LoginProps { }
interface LoginData {
  email: "";
  password: "";
}
const Login = (props: LoginProps) => {
  const alert = useSelector((state: any) => state.alert);
  const [searchParams, setSearchParams] = useSearchParams();
  const captchaRef: any = useRef(null);
  const [loginData, SetLoginData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();
  function handleClick() {
    navigate("/professionals-home");
  }

  function handleClickSingUP(url: string) {
    navigate(url);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    SetLoginData((_data: any) => ({ ..._data, [e.target.name]: e.target.name == "email" ? e.target.value.toLowerCase().trim() : e.target.value }));
  };
  const handleChangeCaptcha = (e: string) => {
    SetLoginData((_data: any) => ({ ..._data, captchaValue: e }));
  };
  const validateList = ["email", "password"];

  function validateEmail(email: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }
  const validateForm = () => {
    for (let i = 0; i < validateList.length; i++) {
      if (!loginData[validateList[i]]) return false;
    }
    if (!validateEmail(loginData?.email)) return false;

    return true;
  };
  const Login = async (e: any) => {
    e.preventDefault();
    setSubmitClicked(true);
    if (validateForm()) {
      setLoading(true);
      setErrorMessage("");
      const res = await signIn(loginData);
      if (res.status === 200) {
        setSubmitClicked(false);
        if (res?.data?.data?.user?.verified) {
          dispatch(
            addAuthInfo({
              token: res.data.data.token,
              user: res.data.data.user,
            })
          );

          if (res.data.data.user.model === "Student") {
            dispatch(
              addAlertInfo({
                show: true,
                message: `Welcome back ${res.data.data.user.subModel.firstName} ${res.data.data.user.subModel.middleName || ""} ${res.data.data.user.subModel.lastName
                  } 👋`,
              })
            );
            // navigate("/professionals-home")
            window.location.href = "/professionals-home";
          } else if (res.data.data.user.model === "Mentor") {
            dispatch(
              addAlertInfo({
                show: true,
                message: `Welcome back ${res.data.data.user.subModel.firstName} ${res.data.data.user.subModel.middleName || ""} ${res.data.data.user.subModel.lastName
                  } 👋`,
              })
            );
            window.location.href = "/mentor/home";
            // navigate("/mentor/home")
          } else if (res.data.data.user.model === "Institution") {
            dispatch(
              addAlertInfo({
                show: true,
                message: `Welcome back ${res.data.data.user.subModel.name} 👋`,
              })
            );
            window.location.href = "/institution/home";
          } else if (res.data.data.user.model === "Company") {
            dispatch(
              addAlertInfo({
                show: true,
                message: `Welcome back ${res.data.data.user.subModel.name} 👋`,
              })
            );
            window.location.href = "/company/home";
          }
        } else {
          navigate(`/mailVerification?userId=${res.data.data.user._id}`);
        }
      } else {
        SetLoginData((_data: any) => ({ ..._data, captchaValue: null }));
        const captcha: any = document.getElementById('captcha') || ''
        captcha?.remove()
        setErrorMessage("Incorrect E-Mail or password");
        setLoading(false);
        const captchaView: any = document.getElementById('captcha-view') || ''
        captchaView.appendChild(captcha)

      }
    }
  };

  return (
    <div className={styles["login-form"]}>
      <div className={styles["form"]}>
        <div className={styles.logo}>
          <img src={LogoImage} alt="logo" />
        </div>

        <form onSubmit={Login}>
          {!showForgotPassword ? (
            <>
              <h1 className="title">Sign in</h1>
              {searchParams.get("verified") && (
                <p className="border bg-light-orange mt-4 rounded-lg p-2 colored text-center mb-2">
                  Thank you for verifying your email address. You can now fully access our services and enjoy all the benefits we offer. Please login
                  below using your registered email address and password.
                </p>
              )}
              {searchParams.get("password-change") && (
                <p className="border bg-light-green mt-4 rounded-lg p-2 text-green-500 text-center mb-2">
                  You password changed successfully, please login again with your new password.
                </p>
              )}

              {errorMessage && <FormErrorMessage errorMessage={errorMessage} />}
              <div className="form-item">
                <InputField
                  label="Enter your email"
                  name="email"
                  required={true}
                  placeholder="Enter your email"
                  Change={handleChange}
                  submitClicked={submitClicked}
                  error={!loginData?.email && submitClicked}
                />
                {!validateEmail(loginData?.email) && submitClicked && <span className="text-red-500 text-sm">Invalid email format</span>}
              </div>
              <div className="form-item">
                <InputField
                  label="Enter your password"
                  name="password"
                  required={true}
                  type={"password"}
                  placeholder="Enter your password"
                  Change={handleChange}
                  submitClicked={submitClicked}
                  error={!loginData?.password && submitClicked}
                />
                {/* {formik.touched.password && formik.errors.password && (
                <span className='text-[#ff000e] text-sm'>{formik.errors.password}</span>
              )} */}
              </div>
              <a className="main-link flex mb-4 md:mb-0" onClick={() => setShowForgotPassword(true)}>
                Forgot your password?
              </a>
              <div className="actions-end ">
                <CustomButton disabled={!loginData?.captchaValue} type="submit" loading={loading} text={`Continue`} />
              </div>
            </>
          ) : (
            <ForgotPassword setShowForgotPassword={setShowForgotPassword} />
          )}
          <div id="captcha-view">
            {!showForgotPassword && (
              <div className="form-item m-t-20 text-underline mt-4 md:mt-0" id="captcha">
                <ReCAPTCHA sitekey={CAPTCHACLINTSECRET} ref={captchaRef} onChange={(e: any) => handleChangeCaptcha(e)} />
              </div>
            )}
          </div>

 
          <div className={styles.hint}>
            <h2 className=" font-bold my-4">If you are new to edqan, select from the below:</h2>
            <p className="mb-4">
              Are you a student and early career professional that needs support in your career path?
              <a className="block" onClick={() => handleClickSingUP("/professionals/registration")}>
                Register here
              </a>
            </p>
            <p className="mb-4">
              Are you an employer?
              <a className="block" onClick={() => handleClickSingUP("/company/registration")}>
                Register here
              </a>
            </p>
            <p className="mb-4">
              Do you want to become a mentor?
              <a className="block" onClick={() => handleClickSingUP("/mentor/registration")}>
                Register here
              </a>
            </p>
            <p className="mb-4">
              Are you an educational institution (high school, college, university, vocational center, etc.)?
              <a className="block" href="https://edqan.com/institutions-contact-us/" target="_blank">
                Learn more
              </a>
            </p>
          </div>
          <div className={styles.contact_us}>
            <p className=" mt-8 text-right">
              <a href="https://edqan.com/contact-us/" target="_blank">
                Contact us
              </a>{" "}
              for support
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
