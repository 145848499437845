import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Box, Grid, Modal, Typography } from "@mui/material";

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import styles from "./post.module.scss";
import { useNavigate } from "react-router";
import SavingJob from "../../../pages/Components/SavingJob";
import { useDispatch, useSelector } from "react-redux";
import CustomizedButtons from "../../CustomButton";
import { deleteSingleGobs } from "../../../services/jobsService";
import { useState } from "react";
import { addAlertInfo } from "../../../store/alertSlice";
import { deleteDocument } from "../../../services/documentsService";
import CloseIcon from '@mui/icons-material/Close';

export default function Post(props: any) {
  const userInfo = useSelector((state: any) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const data = props.data
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function handleClick() {
    if (props.action !== 'nested') {
      if (userInfo.model === 'Student') {
        navigate(`/job-details/${data._id}`);
      }
      else {
        navigate(`/job-details/${data._id}`);
      }

    }

  }
  const navigateTo = (url: string) => {
    navigate(url)
  }
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');


  const handleClose = () => setOpen(false);
  const handleDeleteItem = (id: string) => {
    setSelectedItem(id)
    setOpen(true)
  }
  return (
    <Card className={styles.post}>
      <div className={styles.card_link}>
        <CardContent className={styles.content}>
          <div className={styles.card_header}>
            <span>{data.workType?.nameEn}</span>
            {userInfo?.model === 'Student' && <SavingJob id={data._id} index={props.index} getGobs={props?.getGobs}/>}

            {userInfo?.model === 'Company' && (data.company?._id == userInfo?._id) &&
              <div className="flex">
                {data?.status === "pending" ?
                  <div className="text-xs mx-2 text-red-500 flex justify-center items-center">Pending Approval</div>
                  : data?.status === "approved" ?
                    <div className="text-xs mx-2 text-green-500 flex justify-center items-center">Approved</div>
                    : <div className="text-xs mx-2 text-red-500 flex justify-center items-center">Declined</div>

                }
                <a title="Show job applications" onClick={() => navigateTo(`/company/add-information/${data._id}`)}>
                  <ModeEditIcon />
                </a>
                <a title="Show job applications" onClick={() => navigateTo(`/company/job-applications/${data._id}`)}>
                  <ListAltIcon />
                </a>
              </div>
            }




          </div>

          {data.company?.subModel?.logo ?
            <CardMedia
              className="bg-[#f26403]"
              component="img"
              height="140"
              image={data.company?.subModel?.logo}
              alt=""
            /> :
            <div className="w-[50px] h-[50px] bg-[#f26403] text-white text-[24px] flex justify-center items-center">
              {data?.company?.subModel?.name?.charAt(0)}{data?.company?.subModel?.name?.charAt(1)}
            </div>
          }
          <a onClick={() => handleClick()} >
            <div>
              <div className={styles.job}>
                <h3>{data.name} </h3>
                <h4>{data.jobType?.nameEn}</h4>
              </div>
              <Grid container spacing={1} className={styles.info}>
                <Grid item xs={12} className={styles.compny}>
                  <h3>{data.company?.subModel?.name}</h3>
                  {(data?.country || data.city?.country) && <h4>{data?.country?.nameEn || data.city?.country?.nameEn}, {data.city?.nameEn}</h4>}
                 
                </Grid>
              </Grid>
            </div>
          </a>
          {userInfo?.model === 'Company' && (data.company?._id == userInfo?._id) &&
                    <div className="flex small-btn justify-end">
                      <CustomizedButtons text={"Delete Post"} click={()=>handleDeleteItem(data?._id)} />
                    </div>
                  }
        </CardContent>
        {open &&
        <DeleteModal show={open} handleClose={handleClose} id={selectedItem} listAllData={props?.listAllData} />
      }
      </div>
    </Card>
  );
}

export const CardLoadingSkeleton = () => {
  return (
    <div className="border  border-[#efeeee] rounded-md p-4 w-full mx-auto h-full">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-gray-200 rounded"></div>
          <div className="rounded-full bg-gray-200 h-10 w-10"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">

              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: '16px',
  boxShadow: 0,
  p: 4,
};

export  function DeleteModal(props: any) {
  const [loading, setLoading] = useState(false);
const dispatch=useDispatch()

  const handleDelete = async () => {
    setLoading(true)
    const res = await deleteSingleGobs(props?.id)
    if (res.status == 200) {
      setLoading(false)

      props.listAllData()
      dispatch(addAlertInfo(
        {
          show: true,
          message: `Post deleted successfully`
        }
      ))
      props.handleClose()

    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: res.data.error,
          type: 'error'
        }
      ))

    }
  }
  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-end " onClick={props.handleClose}>
            <CloseIcon className="cursor-pointer" />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to delete this post

          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action cannot be reverted
          </Typography>
         
          <div className="custom-action gap-4">

          <CustomizedButtons text="Cancel" click={props.handleClose} />
          <CustomizedButtons text="Delete Post" click={handleDelete} loading={loading} />
         
        </div>
        </Box>
      

      </Modal>
    </div>
  );
}
