import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const listAllCountries = () => {
  return axios
    .get(`${BASEURL}country`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const listAllCities = (countryId:any) => {
    return axios
      .get(`${BASEURL}city/country/${countryId}`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  
