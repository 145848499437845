import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CustomizedButtons from "../CustomButton";
import CloseIcon from '@mui/icons-material/Close';

import { addAlertInfo } from "../../store/alertSlice";
import { useDispatch, useSelector } from "react-redux";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SelectTime from "../../pages/Mentor/AddAvailabilityTime/SelectTime";
import dayjs, { Dayjs } from 'dayjs'
import { CreateMeetingWithUser } from "../../services/calenderService";
import moment from "moment";
interface PopUpProps {
  show: boolean;
  setOpen?: any;
  resiverName?: any
  resiverEmail?: any
  id?: string
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: '16px',
  boxShadow: 0,
  p: 4,
};

function CreateMeeting(props: PopUpProps) {

  const { show, setOpen, resiverName, resiverEmail, id } = props;
  const dispatch = useDispatch()
  const [date, setValueDate] = useState<any>(dayjs(new Date()));
  const [from, setValueFrom] = useState<any>(dayjs(new Date()));
  const [to, setValueTo] = useState<any>(dayjs(new Date(addMinutes(new Date(), 30))))
  const [today, setToday] = useState<any>(dayjs(new Date()));
  const [minTimeFrom, setMinTimeFrom] = useState<Dayjs>(dayjs(new Date()));
  const [minTimeTo, setMinTimeTo] = useState<Dayjs>(dayjs(new Date(addMinutes(new Date(from), 30))));

  const UserId = useSelector((state: any) => state.auth.user._id);
  const [loading, setLoading] = useState(false)
  const handleClose = () => setOpen(false);

  const handleClick = async () => {
    setLoading(true)

    const data: any = {
      "user2": {
        "user": id
      },
      "meetingType": "meeting",
 
      "date":moment(date.toString()).utc(),
      "startTime":moment(from.toString()).utc(),
      "endTime": moment(to.toString()).utc()
    }
    const response = await CreateMeetingWithUser(UserId, data)
    if (response.status === 201) {

      handleClose()
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Meeting Created successfully',
        }
      ))
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }


  }
  function addMinutes(date: any, minutes: any) {
    date.setMinutes(date.getMinutes() + minutes);

    return date;
  }
  // useEffect(()=>{
  //   const date = new Date(from);
  //   const newDate = addMinutes(date, 30);
  //   setMinTimeTo(dayjs(new Date(addMinutes(new Date(from), 30))))
  // },[from])
  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style}>
          <div className="flex justify-end " onClick={handleClose}>
            <CloseIcon className="cursor-pointer" />

          </div>
          <p className="font-semibold text-lg text-orange">Create meeting with {resiverName}</p>

          <div className="flex my-6">

            <div className="w-full">
              <Grid container>
                <Grid item md={12}>
                  <div className='form-item'>
                    <label>Select the Date</label>
                    <DatePicker
                      value={date}
                      minDate={new Date()}
                      onChange={(newValue: any) => {
                        setValueDate(dayjs(newValue));
                      }}
                      renderInput={(params) => <TextField {...params} required fullWidth margin="normal" helperText={null} />}
                    />
                  </div>
                </Grid>
                <Grid item md={12} className='flex gap-2'>
                  <div className='form-item w-1/2'>
                    <label>Select Starting Time</label>
                    <SelectTime value={from} 
                    setValue={setValueFrom}

                      minTime={date.format('MMM DD, YYYY').toString() === today.format('MMM DD, YYYY').toString() ? minTimeFrom : null} />

                  </div>
                  <div className='form-item w-1/2'>
                    <label>Select End Time</label>
                    <SelectTime value={to} setValue={setValueTo}

                      minTime={minTimeTo} />

                  </div>

                </Grid>

              </Grid>


            </div>
          </div>
          <div className="flex flex-row-reverse">
            <CustomizedButtons text="Create Meeting" click={handleClick} loading={loading} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CreateMeeting;
