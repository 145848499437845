import Moment from "react-moment";
import CheckIcon from '@mui/icons-material/Check';
import Post from "../../../../../Components/CardsList/Post";
interface ReciverMesageProps {
    message: any
    checks: number
}

const ReciverMesage = (props: ReciverMesageProps) => {
    const { message, checks } = props
    const isValidUrl = (urlString: any) => {
        try {
            return Boolean(new URL(urlString))
        }
        catch {

        }

    }
    return (

        <div className={`reciver-message ${message?.job && message?.job?.length > 0 ? 'w-full' : 'w-fit'}`}>
            <p className="flex-wrap flex gap-[4px]">
                {message?.message ?
                    message?.message.split(" ").map((word: string) => {
                        return (
                            <>

                                {isValidUrl(word) ?
                                    <a className="main-link" href={word} target="_blank">{word}</a>
                                    : `${word} `}</>
                        )
                    }) : message}
            </p>
            {message?.job && message?.job?.length > 0 ?
                <div className="grid gap-5 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 mt-4">
                    {message?.job.map((item: any) => {
                        return (
                            <Post data={item} />
                        )
                    })}
                </div>
                :
                ''
            }

            <span className="time"><Moment format="hh:mm A">{message.createdAt}</Moment></span>

        </div>
    );
};

export default ReciverMesage;
