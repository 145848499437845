

import { Box, Container, Grid, Rating, TextField, Typography, Button } from '@mui/material';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNumberOfMentorUsersViews, getNumberOfUsersFollowedInstitution, getNumberOfUsersMentorshipSession } from '../../../services/analyticsService';
import dayjs, { Dayjs } from 'dayjs';
import "../Dashboard/dashboard.scss";
// icons 
import VisibilityIcon from '@mui/icons-material/Visibility';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import { DatePicker } from '@mui/x-date-pickers';
import { NumberBox } from '../../../Components/AnalysicBox/numberBox';
import moment from 'moment';
import TaskIcon from '@mui/icons-material/Task';
import { VerticalChart } from './components/verticalChart';
import { listAllMentorSessionsForMentor } from '../../../services/mentorshipService';
import LastMentorshipsCompleated from './LastMentorshipsCompleated';
import LatestActiveSessions from './latest-active-sessins';
import DialogModal from "../PopUp";
export default function Dashboard() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 30)

  const [startDate, setStartDate] = useState<any>(new Date(yesterday));
  const [endDate, setEndDate] = useState<any>(new Date());
  const [data, setData] = useState<any>({});
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(true)
  const handleOpenDialog = () => {
    setOpenPopup(true)
  }
  const numberOfUsersViews = async () => {
    const response = await getNumberOfMentorUsersViews(user._id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersViews': response?.data?.data }))
    }

  }

  // const numberOfUsersMentorshipSession = async (status = null) => {
  //   const response = await getNumberOfUsersMentorshipSession(user._id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), status)
  //   if (response) {
  //     if (status === 'completed') {
  //       setData((_data: any) => ({ ..._data, 'mentorshipSessionCompleted': response?.data?.data }))
  //     }
  //     else if (status === 'ongoing') {
  //       setData((_data: any) => ({ ..._data, 'mentorshipSessionOngoing': response?.data?.data }))
  //     }
  //     else {
  //       setData((_data: any) => ({ ..._data, 'numberOfUsersMentorshipSession': response?.data?.data }))
  //     }

  //   }

  // }
 
  const numberOfUsersMentorshipSession = async (status: any = null) => {
    const response = await getNumberOfUsersMentorshipSession(
      user?._id,
      moment(startDate).format('YYYY-MM-DD'),
      moment(endDate).format('YYYY-MM-DD'),
      status
    )
    if (response) {
      if (status === 'completed') {
        setData((_data: any) => ({ ..._data, mentorshipSessionCompleted: response?.data?.data }))
      } else if (status === 'ongoing') {
        setData((_data: any) => ({ ..._data, mentorshipSessionOngoing: response?.data?.data }))
      } else {
        setData((_data: any) => ({ ..._data, numberOfUsersMentorshipSession: response?.data?.data }))
      }
    }
  }

  useEffect(() => {
    if (token) {
      numberOfUsersViews()
      numberOfUsersMentorshipSession()
      numberOfUsersMentorshipSession('completed')
      numberOfUsersMentorshipSession('ongoing')
    }
  }, [token,endDate, startDate])
  // useEffect(() => {
  //   if (token) {
  //     numberOfUsersViews()
  //     numberOfUsersMentorshipSession()

  //   }
  // }, [token, endDate, startDate]);

  return (
    <div className="m-t-50 m-b-20">
      <Container>
        <div className='flex'>
          <h3 className='title'>Dashboard</h3>
          <div className='w-1/2'>
            <Grid item md={12} className='flex gap-2'>
              <div className='form-item w-1/2'>

                <DatePicker
                  label="From"
                  value={startDate}
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}

                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                />

              </div>
              <div className='form-item w-1/2'>
                <DatePicker
                  label="To"
                  value={endDate}
                  minDate={startDate}
                  onChange={(newValue: any) => {
                    setEndDate(newValue);
                  }}

                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                />
              </div>
            </Grid>
          </div>
        </div>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 4
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={6}
                sm={6}
                xl={6}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersViews || 0} text="Users views" icon={<VisibilityIcon />} color="green" />
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <NumberBox value={user?.subModel?.rating || 0}
                  text="Overall rating"
                  icon={null} color="orange" />
               
              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                sm={6}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersMentorshipSession || 0}
                  text="Total Mentorship Sessions"
                  icon={<TaskIcon />} color="orange" />

              </Grid>

              <Grid
                item
                xl={4}
                lg={4}
                sm={6}
                xs={12}
              >
                <NumberBox value={data?.mentorshipSessionOngoing || 0}
                  text="Mentorship Sessions Ongoing"
                  icon={<TaskIcon />} color="orange" />

              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                sm={6}
                xs={12}
              >
                <NumberBox value={data?.mentorshipSessionCompleted || 0}
                  text="Mentorship Sessions Completed"
                  icon={<TaskIcon />} color="orange" />

              </Grid>

              <Grid
                item
                xl={3}
                lg={3}
                sm={12}
                xs={12}
              >
                <LastMentorshipsCompleated />
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                sm={12}
                xs={12}
              >
                <LatestActiveSessions />

              </Grid>
            </Grid>
          </Container>
        </Box>
      </Container>
      {
        openPopup && (
            <DialogModal open={openPopup} setOpenPop={setOpenPopup}  />
        )
      }
      {
        openModal  && (
            <Container id='ToolTip'>
              <div  className="helpContainer">
                <Typography className="guidance">Do you need guidance on using our platform?</Typography>
                <Button  className="button"   onClick={handleOpenDialog}>
                  Yes
                </Button>
                <Button className="button" onClick={()=> setOpenModal(false)}>No, thanks</Button>
                <Button className="closeButton" onClick={()=> setOpenModal(false)}>&times;</Button>
              </div>
            </Container>
        )
      }
    </div>
  )
}
