import React, { ChangeEvent, useState } from "react";
import Box from "@mui/material/Box";
import { Avatar, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import CustomizedButtons from "../CustomButton";
import styles from "./model.module.scss";
import SelectInput from "../SelectInput";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../Input";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { AddUserAttachment } from "../../services/documentsService";
import { useDispatch, useSelector } from "react-redux";
import { addAlertInfo } from "../../store/alertSlice";
import FormErrorMessage from "../FormErrorMessage";
interface PopUpProps {
  show: boolean;
  setOpen?: any;
  listAllData: any;
  attachmentType: any;
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 0,
  p: 4,
};
const btnstyle = {
  borderColor: "#F26400",
  height: "52px",
  color: "#F26400",
  border: "2px solid",
  boxShadow: "none",
  backgroundColor: "transparent",
  fontWeight: "600",
  fontSize: "16px",
  padding: "12px 28px",
  borderRadius: "8px",
  texttransform: "none",
  "&:hover": {
    color: "white",
    backgroundColor: "#F26400",
  },
};
function UploadeDocumentPopUp(props: PopUpProps) {
  const user = useSelector((state: any) => state.auth.user);
  const dispatch = useDispatch();
  const formData = new FormData();
  const { show, setOpen, listAllData } = props;
  const [errors, setError] = useState<any>([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [data, setData] = useState({
    attachmentType: props.attachmentType.id ? props.attachmentType.id : props.attachmentType,
    name: null,
  });

  const [cvfile, setcvFile] = useState<File>();
  const handleCvFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setcvFile(e.target.files[0]);
    }
  };
  const handleClose = () => setOpen(false);
  const handleChange = (e: any) => {
    setData((_data: any) => ({ ..._data, attachmentType: e?.value?.id || null }));
    formData.append("attachmentType", e.value.id);
  };

  const handleApply = async () => {
    if (!data.name) {
      setError((_data: any) => ({ ..._data, name: "Document name field is required" }));
    }
    if (!cvfile) {
      setError((_data: any) => ({ ..._data, cv: "Please Select or upload your Attachment" }));
    }

    if (data.name && cvfile) {
      setLoadingBtn(true);

      formData.append("attachment", cvfile);
      formData.append("attachmentType", data.attachmentType);
      formData.append("name", data.name);

      const response = await AddUserAttachment(user?._id, formData);
      if (response.status === 201) {
        setLoadingBtn(false);
        setOpen(false);
        dispatch(
          addAlertInfo({
            show: true,
            message: `Your attachment added successfully`,
          })
        );
        listAllData();
      } else {
        dispatch(
          addAlertInfo({
            show: true,
            message: response.data.error,
            type: "error",
          })
        );

        setLoadingBtn(false);
      }
    }
  };
  return (
    <div>
      <Modal open={show} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <div className="flex justify-between">
            <h2 className="title colored">Add Document</h2>
            <div className="flex justify-end " onClick={handleClose}>
              <CloseIcon className="cursor-pointer" />
            </div>
          </div>

          <div className="flex items-center gap-10">
            <div className="form-item w-full">
              <InputField
                label="Document Name"
                placeholder=""
                required={true}
                Change={(e: any) => setData((_data: any) => ({ ..._data, name: e.target.value }))}
              />
              {errors?.name && <span className="text-[#ff000e] text-sm">{errors?.name}</span>}
            </div>

            <div className="form-item w-full">
              <SelectInput
                disableClearable={true}
                options={[
                  { label: "CV", id: "cv" },
                  { label: "Cover Letter", id: "coverLetter" },
                  { label: "Other", id: "other" },
                ]}
                required={true}
                label="Type of Document"
                defaultValue={data.attachmentType}
                Change={handleChange}
              />
            </div>
          </div>
          <div className="my-8 p-4 fill text-center popup-box">
            {/* <p className="sub-title mb-2">
              Drag and drop here or choose from your computer
            </p> */}
            <Button variant="outlined" component="label" style={btnstyle} sx={{ textTransform: "none" }}>
              Browse and Select from Computer
              <input hidden type="file" max-size="5000000" accept="application/pdf" onChange={handleCvFileChange} />
            </Button>

            <div>
              <span className="text-sm text-gray-600">Maximum allowed upload size is 5 MB. PDF documents only</span>
            </div>
          </div>
          {errors?.cv && <span className="text-[#ff000e] text-sm">{errors?.cv}</span>}

          {cvfile ? (
            cvfile?.size > 5000000 ? (
              <div className="border rounded border-red-500 p-2 text-sm mb-4 text-red-500 bg-red-100">
                File "{cvfile.name}" exceeded the maximum allowed upload size of
                <strong className="text-sm text-red-500"> (5 MB)</strong> Please retry your upload!
              </div>
            ) : (
              <div className="mb-2 p-1 bg-light-orange rounded-xl w-[fit-content] main-orange-border">
                <FilePresentIcon className="mr-2" />
                {cvfile?.name}

                <button onClick={() => setcvFile(undefined)}>
                  <CloseIcon className="ml-4" sx={{ color: "#f27342", fontSize: 12 }} />
                </button>
              </div>
            )
          ) : (
            ""
          )}
          <div className="custom-action gap-4">
            <CustomizedButtons text="Cancel" click={handleClose} />
            <CustomizedButtons text="Add Document" click={handleApply}
              loading={loadingBtn} disabled={!cvfile || cvfile?.size > 5000000} />
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default UploadeDocumentPopUp;
