import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Box, Grid, Modal, Rating, TextField } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import styles from "./card.module.scss";
import { useNavigate, useParams } from "react-router";
import NoDataMessage from "../../../../Components/NoDataMessage";
import Moment from "react-moment";
import CustomButton from "../../../../Components/CustomButton";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { date } from "yup";
import CustomizedButtons from "../../../../Components/CustomButton";
import SelectTime from "../../../Mentor/AddAvailabilityTime/SelectTime";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
interface MentorCardProps {
  rating: number;
  name: string;
  subName: string;
  title: string;
  description: string;
  availabilityTime: any;
  handleSelectTime: any;
  selectedDate: any;
  isCard: boolean;
  handleBookClick: any;
  loadingBtn: boolean;
  id: string;
  handleShowMentor: any;
  profileImage: string;
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 0,
  p: 0,
};

export default function MentorCard(props: MentorCardProps) {
  const [showTimeSelection, setShowTimeSelection] = useState(false);
  const [availableDays, setAvailableDays] = useState<any>([]);
  const {
    rating,
    name,
    subName,
    title,
    availabilityTime,
    handleSelectTime,
    selectedDate,
    isCard,
    handleBookClick,
    loadingBtn,
    id,
    handleShowMentor,
    profileImage,
  } = props;
  const navigate = useNavigate();
  const handleClick = () => {
    handleShowMentor(id);
    //   navigate(`/professionals/mentor-profile/${id}`)
  };

  const handleShowTimeSelectionOpen = () => {
    setShowTimeSelection(true);
  };
  const handleShowTimeSelectionClose = () => {
    setShowTimeSelection(false);
  };
  const handleSelect = (time: any) => {
    setTimeout(() => {
      handleSelectTime(time);
    }, 100);
  };
  function formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  }

  useEffect(() => {
    const days = availabilityTime.map((item: any) => moment(item).format("L"));
    setAvailableDays(days.filter((item: any, index: any) => days.indexOf(item) === index));
  }, []);
  return (
    <Card className="post h-full">
      <div className={styles["expanded-card"]}>
        <CardHeader
          onClick={handleClick}
          sx={{ padding: "10px 0", cursor: "pointer" }}
          avatar={
            <div>
              <Avatar sx={{ bgcolor: "#F26400" }} aria-label="recipe">
                {profileImage ? <img src={profileImage} /> : subName}
              </Avatar>{" "}
            </div>
          }
          title={name}
          subheader={
            <div className="flex justify-between">
              <span>{title}</span>
              <Rating name="read-only" value={rating} readOnly size="small" precision={0.5} />
            </div>
          }
        />
        <div>
          {availabilityTime.length > 0 ? (
            <>
              <div className="h-[25px]">
                {selectedDate && !showTimeSelection && (
                  <p className=" mb-2 ">
                    <Moment format="DD, MMMM YYYY hh:mm A">{selectedDate}</Moment>
                  </p>
                )}
              </div>

              <div>
                {showTimeSelection && (
                  <Modal
                    open={showTimeSelection}
                    onClose={handleShowTimeSelectionClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <div className="h-[80vh] p-8" style={{ overflowY: "auto" }}>
                        <div className="flex justify-end " onClick={handleShowTimeSelectionClose}>
                          <CloseIcon className="cursor-pointer" />
                        </div>
                        <Typography paragraph sx={{ overflow: "auto" }}>
                          {availableDays.map((day: any) => {
                            return (
                              <div className="border-b py-2">
                                <Moment format="MMMM DD, YYYY">{day}</Moment>
                                {/* <h3 className='text-small mb-2'>{<Moment format='MMMM DD, YYYY'>{time.date}</Moment>}</h3> */}
                                <ul className={styles["appointments-list"]}>
                                  {availabilityTime.map((item: any) => {
                                    return (
                                      <>
                                        {day == moment(item).format("L") && (
                                          <li
                                            key={item}
                                            onClick={() => handleSelect(item)}
                                            className={[
                                              `${selectedDate === item && isCard ? "main-box-shadow rounded-xl" : "border-green-all"}`,
                                              "mx-1",
                                            ].join(" ")}
                                          >
                                            <div className={styles["time"]}>
                                              <p>
                                                <Moment format="hh:mm A">{item}</Moment>
                                              </p>
                                            </div>
                                          </li>
                                        )}
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                            );
                          })}
                        </Typography>
                        <div className="flex flex-row-reverse">
                          <CustomizedButtons text="Select" click={handleShowTimeSelectionClose} />
                        </div>
                      </div>
                    </Box>
                  </Modal>
                )}
              </div>

              <div className="actions-end w-full small-btn gap-2">
                <CustomButton text="Select Session time" type="button" click={handleShowTimeSelectionOpen} />
                <CustomButton
                  text="Book now"
                  type="button"
                  click={handleBookClick}
                  loading={loadingBtn && isCard}
                  disabled={!(selectedDate && isCard)}
                />
              </div>
            </>
          ) : (
            <NoDataMessage text="No time slots available for this mentor" />
          )}
        </div>
      </div>
    </Card>
  );
}
