import React, { useEffect, useState } from 'react'
import SelectInput from '../../SelectInput';
import { useSelector } from 'react-redux';
import { listAllLanguages } from '../../../services/languageService';
import { Language } from '../../../models/language';


export default function LanguagesSelection(props:any) {
    const{handleChange ,defaultValue,label,multiple,labelHint,name,required,submitClicked,formik}=props
    const token = useSelector((state: any) => state.auth.token);
    const [Languages, setLanguages] = useState([]);
    const [loadingLanguages, setLoadingLanguages] = useState(true);
    // list All Languages
    const getAllLanguages = async () => {
        const response = await listAllLanguages()
        if (response) {
            const langs = response.data.data.queryResult.map((item: Language) => ({ 'label': item.nameEn, 'id': item._id }))
            setLanguages(langs)
            setLoadingLanguages(false)
        }
    }

    useEffect(() => {
        if (token) { getAllLanguages() }
    }, [token]);

    return (
        <SelectInput
            labelHint={labelHint}
            name={name}
            required={required}
            label={label}
            options={Languages}
            multiple={multiple}
            Change={handleChange}
            loading={loadingLanguages}
            defaultValue={defaultValue}
            submitClicked={submitClicked}
            formik={formik}
        />
    )
}
