import { Avatar, Chip, Pagination, Stack } from '@mui/material';
import React, { useState } from 'react'
import CustomizedButtons from '../../../../../Components/CustomButton';
import ScienceIcon from '@mui/icons-material/Science';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import styles from './reviews.module.scss'
import companyLogo from '../../../../../assets/images/temp/4.png'
import { useNavigate } from 'react-router';
import Moment from 'react-moment';
import PopUpSendMesage from '../../../../../Components/PopUp';
interface ReviewsElementProps {
    list: any;
    type?: any
}
export default function ReviewsElement(props: ReviewsElementProps) {
    const { list, type } = props
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>({});
    const handleCLick = (id: any) => {
        navigate(`/company-information/${id}`)

    }
    const handleSendMesage = (user: any) => {
        setSelectedUser(user)
        setOpen(true)
    }


    return (
        <div>
            {open && <PopUpSendMesage
                user={selectedUser}
                show={open}
                setOpen={setOpen} resiverName={`${selectedUser?.subModel?.firstName} 
              ${selectedUser?.subModel?.middleName || ''} 
              ${selectedUser?.subModel?.lastName}`}
                resiverEmail={selectedUser?.email}
                id={selectedUser?._id} />}
            {list.map((el: any) => {
                return (
                    <div className='post mb-6'>
                        <div className='flex mb-4 cursor-pointer' onClick={() => handleCLick(el?.company?._id)}>
                            <img src={el.company?.subModel?.logo} width={85} height={65} className='h-[85px]' />
                            <div className=' ml-2'>
                                <span className='title flex items-center'>{el.company?.subModel?.name}</span>
                                <div className='mb-4'>
                                    <p className='list-title mb-2'>Company Size</p>
                                    <p className='description '>{el?.company?.subModel?.companySize.toLocaleString()} employees</p> 
                                </div>
                            </div>

                        </div>
                        <div className='flex gap-3 my-4 items-center '>
                            <Avatar className=''
                                src={el?.user?.profileImage || el?.user?.subModel?.logo} sx={{ width: 50, height: 50, bgcolor: "#F26400" }} aria-label="avatar">
                                <a className='capitalize text-white'>
                                    {el?.user?.subModel?.firstName?.charAt(0)?.toUpperCase() || el?.user?.firstName?.charAt(0)?.toUpperCase()}{el?.user?.subModel?.lastName?.charAt(0)?.toUpperCase() || el?.lastName?.charAt(0)?.toUpperCase()} {el?.user?.subModel?.name?.charAt(0)}</a>
                            </Avatar>
                            <div className='block'>
                                <p className='list-title '>{el?.user?.subModel?.firstName} {el?.user?.subModel?.middelName} {el?.user?.subModel?.lastName}</p>
                                <p className='description mt-1'><span className='date'><Moment format="MMMM DD, YYYY">{el.startDate}</Moment> - <Moment format="MMMM DD, YYYY">{el.endDate}</Moment>, </span> {el?.company?.city?.nameEn}, {el?.company?.country?.nameEn}</p>
                            </div>

                        </div>

                        <div className='mb-4'>

                            <p className='list-title mb-2'>Key takeaways</p>
                            <Stack direction="row" gap={1} className="flex-wrap">
                                {
                                    el.keyWays.map((item: any) => {
                                        return (
                                            <Chip label={item?.nameEn}
                                            />
                                        )
                                    })}
                            </Stack>

                        </div>
                        <div className='mb-4'>

                            <p className='list-title mb-2'>What you liked and learned</p>
                            <p className='px-8 main-description'>{el.whatYouLikedAndLearned}</p>

                        </div>
                        <div>  <p className='list-title mb-2'>Advice for others</p>
                            <p className='px-8 main-description'>{el.advicesForOthers}</p>
                        </div>
                        {type === 'company' && <a className='main-link  w-full justify-end flex  user-name-text-size' onClick={() => handleSendMesage(el?.user)}>Ask {el?.user?.subModel?.firstName} for more details</a>}

                    </div>)

            })}
            {type !== 'company' && <div className=''>
                <p className='description'>
                    You can write a review for companies you have worked for, volunteered or interned with so other students can learn from your experience.
                </p>

            </div>
            }
            {/* <div className="flex justify-center mt-6">
                <Pagination count={10} />
            </div> */}
        </div>
    )
}
