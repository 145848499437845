import React, { useEffect, useState } from "react";
import { Container, Grid, Pagination } from "@mui/material";
import MenteeCard from "./MenteeCard";
import { listCompanyStudents } from "../../../../services/company/companyStudents";
import NoDataMessage from "../../../../Components/NoDataMessage";
import { useSelector } from "react-redux";
import { getInitials } from "../../../helpers";
import { useParams } from "react-router-dom";

export default function CompanyStudents() {
    const [Studentslist, setStudentslist] = useState([])
    const [totlaPages, setTotlaPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [loadingStudents, setloadingStudents] = useState(true)
    const userInfo = useSelector((state: any) => state.auth.user);
    const token = useSelector((state: any) => state.auth.token);
    const parms = useParams()
    const { id, name } = parms
    const getCompanyStudents = async () => {

        const response: any = await listCompanyStudents(id ? id : userInfo._id, currentPage, 9)
        if (response.status === 200) {
            setloadingStudents(true)
            setStudentslist(response.data.data.queryResult)
            setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 9))
        }
    }
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page - 1)
    }
    useEffect(() => {
        if (token) {
            getCompanyStudents()
        }

    }, [token, currentPage])

    return (
        <Container>

            <div className="m-m-50">
                <div className="header-underline">
                    <h2 className="title">
                    Students/Professionals who have worked in {name ? name : userInfo?.subModel?.name}
                    </h2>

                </div>

                <div >
                    {Studentslist.length > 0 ?
                        <Grid container spacing={3}  >
                            {
                                Studentslist.map((student: any) => {
                                    return (
                                        <Grid item md={4} sm={12} className="w-full">
                                            <MenteeCard
                                                student={student?.user}
                                                name={`${student?.user?.subModel?.firstName} ${student?.user?.subModel?.lastName}`}
                                                subName={getInitials(student?.user?.subModel?.firstName, student?.user?.subModel?.lastName)}
                                                title={`${student?.job?.name}`}
                                                jobType={`${student?.job?.jobType?.nameEn}`}
                                                // position="CEO"
                                                userAvatar={student?.user?.profileImage}
                                            />
                                        </Grid>
                                    )
                                }
                                )
                            }



                        </Grid> : <NoDataMessage />}

                </div>
                {totlaPages > 1 &&
                    <div className="flex justify-end my-6">
                        <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                    </div>
                }
            </div>
        </Container>

    );
}
