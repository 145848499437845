

import { Container, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { latestInteractionsWithStudent } from '../../../services/analyticsService';
import Moment from 'react-moment';
import { useNavigate } from 'react-router';

export default function InteractionsWithStudents() {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  const getData = async () => {
    const response = await latestInteractionsWithStudent(user._id, currentPage)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token]);
  const getApplicationStatus = (status: string) => {

    if (status == '0') return 'Pending'
    if (status == '1') return 'Hired'
    if (status == '2') return 'Declined'
  }
  return (
    <div className="m-t-50 m-b-50">
      <Container>

        <h3 className='title header-underline'>Latest interactions with students/professionals</h3>



        <Table>
          <TableHead>
            <TableRow>
              <TableCell sortDirection="desc">
                Student/Professional
              </TableCell>
              <TableCell sortDirection="desc">
                Job Posting
              </TableCell>
              <TableCell>
                Application Status
              </TableCell>
              <TableCell sortDirection="desc">
                Latest update
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any) => (
              <TableRow
                hover
                key={item._id}
              >
                <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/professional/profile/${item?.user?._id}`)}>
                    {item?.user?.subModel?.firstName} {item?.user?.subModel?.lastName}
                  </a>
                </TableCell>
                {/* <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/company/profile/${item?.company?._id}`)}>
                    {item?.company?.subModel?.name}
                  </a>
                </TableCell> */}
                <TableCell>
                  <a className='main-link' onClick={() => navigate(`/job-details/${item._id}`)}>{item?.job?.name}</a>
                </TableCell>
                <TableCell>
                  <div className={`${getApplicationStatus(item?.status)}_label text-center`}>
                    {getApplicationStatus(item?.status)}
                  </div>
                </TableCell>
                <TableCell>
                  <Moment format="MMMM DD, yyyy">{item?.updatedAt}</Moment>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex justify-end my-6">
          <Pagination count={totalPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      </Container>
    </div>
  )
}
