
import React from "react";
import ReactApexChart from "react-apexcharts";

export default function StatisticsCard(props: any) {
  const { data } = props
  const labels: any = []
  const series: any = []
  Object.keys(data).forEach(function (key, index) {
      labels.push(key)
      series.push(data[key].current===0?0:(data[key].current/data[key].max)*100)
  });

const options: any={
    chart: {
    
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        offsetY: 125,
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 5,
          size: '30%',
          background: 'transparent',
      
        },
        dataLabels: {
          name: {
            show: true,
            offsetY:0,
            color:'black',
          },
          value: {
            show: true,
            fontSize: '12px',
            color:'black',
            offsetY:0
          },
          total: {
            fontSize: '10px',
            offsetY:0,
            show: true,
            label: 'Total',
            formatter: function (w:any) {
            return w.config.series.reduce((a:any, b:any) => a + b, 0)/4 +'%'
            }
          }
         
        }
      }
    },
    colors: ['#8FD14F', '#FCDD70', '#B24141', '#6DCAB8',"#674fd1",'#00adff',"#8a7c02"],
    labels:labels,
    legend: {
      show: true,
      floating: true,
      fontSize: '14px',
      position: 'right',
      offsetX: 0,
      offsetY:0,
      labels: {
        useSeriesColors: false,
      },
      markers: {
        size: 0
      },
      formatter: function(seriesName:any, opts:any) {

        return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]+ "%"
      },
      itemMargin: {
        vertical: 1
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
            show: false
        }
      }
    }]
  }
  // const options: any = {
  //   chart: {
  //     width: 600,
  //     height: 600,
  //     type: 'pie',
  //     colors: ['#8FD14F', '#FCDD70', '#B24141', '#6DCAB8',"#674fd1",'#00adff',"#8a7c02"],
  //     dropShadow: {
  //       enabled: false,

  //     }
  //   },
  //   labels: labels,
  //   markers: {
  //     colors: ['#8FD14F', '#FCDD70', '#B24141', '#6DCAB8',"#674fd1",'#00adff',"#8a7c02"],
  //   },
  //   fill: {
  //     colors: ['#8FD14F', '#FCDD70', '#B24141', '#6DCAB8',"#674fd1",'#00adff',"#8a7c02"],
  //   },

  //   legend: {
  //     colors: ['#8FD14F', '#FCDD70', '#B24141', '#6DCAB8',"#674fd1",'#00adff',"#8a7c02"],
  //     position: 'bottom',
  //     horizontalAlign: 'left',
  //     show: true,
  //   },
  //   stroke: {
  //     show: true,
  //     curve: 'smooth',
  //     lineCap: 'butt',
  //     colors: '#ffffff',
  //     width: 3,
  //     dashArray: 0,

  //   }
  //   ,
  //   colors: ['#8FD14F', '#FCDD70', '#B24141', '#6DCAB8',"#674fd1",'#00adff',"#8a7c02"],
  //   responsive: [{
  //     breakpoint: 480,
  //     options: {
  //       chart: {
  //         width: 320
  //       },

  //     }
  //   }],
  //   plotOptions: {
  //     pie: {
  //       dataLabels: {
  //         position: 'top' // use 'bottom' for left and 'top' for right align
  //       },
  //     }
  //   },
  //   dataLabels: {
  //     enabled: true,
  //     textAnchor: 'end', // use 'start' if left aligned and 'end' if right aligned
  //     offsetX: 10,
  //     offsetY: 10,
  //     formatter: function (val: any, opts: any) {
  //       console.log(val,series[opts.seriesIndex])
  //       return Math.round(series[opts.seriesIndex]) + "%"
  //     },
  //   }


  // }
  return (

    <div id="chart" className="mt-4 ">
      <p className="font-semibold text-base">Percentage of profile complete</p>
      <div className=" flex justify-center w-full px-4">
        <ReactApexChart options={options} series={series} type="radialBar" width={300} height={300} />
      </div>
    </div>
  );
}
