import React, { useEffect, useState } from "react";
import DynamicTable from "../../../Components/Table";
import { Container, Pagination } from "@mui/material";
import { listAllInstitutionStudent, studentSheetFormat, uploadStudentsListSheet } from "../../../services/institution/studentsList";
import { useSelector } from "react-redux";
import StudentsTable from "./Table";
import { TableLoadingSkeleton } from "../../../Components/Loaders/TableLoader";
import NoDataMessage from "../../../Components/NoDataMessage";
import CustomizedButtons from "../../../Components/CustomButton";
import FileUpload from "./upload";
export default function StudentsList() {
    const userInfo = useSelector((state: any) => state.auth.user);
    const token = useSelector((state: any) => state.auth.token);
    const [loading, setloading] = useState(true)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [loadingUpload, setLoadingUpload] = useState(false)
    const [list, setList] = useState([])
    const [totlaPages, setTotlaPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [open, setOpen] = useState(false);
    const handleUpload = () => {
        setOpen(true)
    }
    const getInstitutionStudentList = async () => {
        const response: any = await listAllInstitutionStudent(userInfo?._id, currentPage)
        if (response.status === 200) {
            setloading(false)
            setList(response.data.data.queryResult)
            setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 50))

        }
    }
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page - 1)
    }
    useEffect(() => {
        if (token) getInstitutionStudentList()

    }, [token, currentPage])

    const getStudentListSheet = async () => {
        setLoadingDownload(true)
        const response: any = await studentSheetFormat()
        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Students-list-format.xlsx"
            );
            document.body.appendChild(link);
            link.click();
            setLoadingDownload(false)

        }
    }
    const uploadStudentsSheet = async (file: any) => {
        const formData = new FormData()
        formData.append('excel', file)
        setLoadingUpload(true)
        const response: any = await uploadStudentsListSheet(formData)
        if (response.status === 200) {
            setLoadingUpload(false)
            getInstitutionStudentList()
        }
    }
    return (
        <div className="page">
            {open && <FileUpload show={open} setOpen={setOpen} handleSubmit={uploadStudentsSheet} loadingUpload={loadingUpload} />}
            <Container>
                <div className="header-underline">
                    <h2 className="title ">{userInfo?.subModel?.name} - Student List</h2>
                    <p className="main-description">Please download the student list , complete the required fields, and send it via email to your designated point of contact on the edqan team.</p>

                </div>
                {loading ?
                    <TableLoadingSkeleton /> :
                    <div className="overflow-x-auto w-full">
                        <div className="small-btn flex justify-end w-full space-x-4">
                            <CustomizedButtons text="Download student list" click={getStudentListSheet} loading={loadingDownload} />
                            {/* <CustomizedButtons text="Upload students list" click={handleUpload} /> */}
                        </div>
                        {list.length > 0 ?
                            <StudentsTable
                                list={list}
                                columns={['Student Name', 'Email', 'Level of education', 'Major(s)', "Expected Graduation Date"]}
                                alignment={['left', 'left', 'left', 'center', 'center']}
                            />
                            :
                            <NoDataMessage />
                        }

                        {totlaPages > 1 &&
                            <div className="flex justify-end my-6">
                                <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                            </div>
                        }
                    </div>
                }
            </Container>

        </div>

    );
}
