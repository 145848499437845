import { Container } from "@mui/system";
import React from "react";
import BasicTabs from "./Tabs";
import style from "./add-info.module.scss";

export default function AddInformation() {
  return (
    <Container>
      <div className={style["page"]}>
       
     
        <BasicTabs />
      </div>
    </Container>
  );
}
