import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"

export const TableLoadingSkeleton = () => {
    return (
        <div className='main-table animate-pulse'>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow >
                        <TableCell ><div className="h-4 bg-gray-200 rounded"></div></TableCell>
                        <TableCell ><div className="h-4 bg-gray-200 rounded"></div></TableCell>
                        <TableCell ><div className="h-4 bg-gray-200 rounded"></div></TableCell>
                        <TableCell ><div className="h-4 bg-gray-200 rounded"></div></TableCell>
                        <TableCell ><div className="h-4 bg-gray-200 rounded"></div></TableCell>
                        <TableCell ><div className="h-4 bg-gray-200 rounded"></div></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[1, 2, 3].map(() => {
                        return (
                            <TableRow >
                                <TableCell ><div className="h-2 bg-gray-200 rounded w-3/4"></div></TableCell>
                                <TableCell ><div className="h-2 bg-gray-200 rounded w-3/4"></div></TableCell>
                                <TableCell ><div className="h-2 bg-gray-200 rounded w-3/4"></div></TableCell>
                                <TableCell ><div className="h-2 bg-gray-200 rounded w-3/4"></div></TableCell>
                                <TableCell ><div className="h-2 bg-gray-200 rounded w-3/4"></div></TableCell>
                                <TableCell ><div className="h-2 bg-gray-200 rounded w-3/4"></div></TableCell>
                            </TableRow>
                        )
                    })}

                </TableBody>

            </Table>
        </div>
    )

}