

import { Box, Container, Grid, TextField, Typography, Button } from '@mui/material';
import { NumberBox } from './components/numberBox';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNumberOfApplications, getNumberOfUsersFollowedCompany, getNumberOfUsersHired, getNumberOfUsersViews } from '../../../services/analyticsService';
import dayjs, { Dayjs } from 'dayjs';
import DialogModalEmployer from '../PopUp';

// icons 
import VisibilityIcon from '@mui/icons-material/Visibility';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import TaskIcon from '@mui/icons-material/Task';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DatePicker } from '@mui/x-date-pickers';
import { date } from 'yup';
import moment from 'moment';
import StudentsRecentlyHired from './studentsRecentlyHired';
import LatestInteractionsWithStudents from './latest-interaction-with-students';

export default function Dashboard() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 30)

  const [startDate, setStartDate] = useState<any>(new Date(yesterday));
  const [endDate, setEndDate] = useState<any>(new Date());
  const [data, setData] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(true)
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  
  const numberOfUsersViews = async () => {
    const response = await getNumberOfUsersViews(user._id, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersViews': response?.data?.data }))
    }
  }

  const handleOpenDialog = () => {
    setOpenPopup(true)
  }

  const numberOfApplications = async () => {
    const response = await getNumberOfApplications(user._id,  moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfApplications': response?.data?.data }))
    }

  }
  const numberOfUsersFollowedCompany = async () => {
    const response = await getNumberOfUsersFollowedCompany(user._id,  moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersFollowedCompany': response?.data?.data }))
    }

  }
  const numberOfUsersHired = async () => {
    const response = await getNumberOfUsersHired(user._id,  moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
    if (response) {
      setData((_data: any) => ({ ..._data, 'numberOfUsersHired': response?.data?.data }))
    }

  }
  useEffect(() => {
    if (token) {
      numberOfUsersViews()
      numberOfApplications()
      numberOfUsersFollowedCompany()
      numberOfUsersHired()
    }
  }, [token,endDate,startDate]);
  
  return (
    <div className="m-t-50 m-b-50">
      <Container>
        <div className='flex'>
          <h3 className='title'>Dashboard</h3>
          <div className='w-1/2'>
            <Grid item md={12} className='flex gap-2'>
              <div className='form-item w-1/2'>

                <DatePicker
                  label="From"
                  value={startDate}
                  onChange={(newValue: any) => {
                    setStartDate(newValue);
                  }}

                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                />

              </div>
              <div className='form-item w-1/2'>

                <DatePicker
                  label="To"
                  value={endDate}
                  minDate={startDate}
                  onChange={(newValue: any) => {
                    setEndDate(newValue);
                  }}

                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" helperText={null} />}
                />
              </div>

            </Grid>
          </div>
        </div>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersViews || 0} text="Users views" icon={<VisibilityIcon />} color="green" />
              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                sm={6}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersFollowedCompany || 0}
                  text="Users followed company"
                  icon={<FollowTheSignsIcon />}
                  color="blue" />

              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                sm={6}
                xs={12}
              >
                <NumberBox value={data?.numberOfUsersHired || 0} text="Users hired" icon={<TaskIcon />} color="orange" />

              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                sm={6}
                xs={12}
              >
                <NumberBox value={data?.numberOfApplications || 0} text="Job applications" icon={<AttachFileIcon />} color="purple" />

              </Grid>
              <Grid
                item
                xl={3}
                lg={3}
                sm={12}
                xs={12}
              >
                <StudentsRecentlyHired />
              </Grid>
              <Grid
                item
                xl={9}
                lg={9}
                sm={12}
                xs={12}
              ><LatestInteractionsWithStudents />
              </Grid>

            </Grid>
          </Container>
        </Box>
      </Container>
      {
        openPopup && (
            <DialogModalEmployer open={openPopup} setOpenPop={setOpenPopup} />
        )
      }
      {
        openModal && (
            <Container id="ToolTip">
            <div className="helpContainer">
              <Typography className="guidance">Do you need guidance on using our platform?</Typography>
              <Button className="button" onClick={handleOpenDialog}>
                Yes
              </Button>
              <Button className="button" onClick={() => setOpenModal(false)}>No, thanks</Button>
              <Button className="closeButton" onClick={() => setOpenModal(false)}>&times;</Button>
            </div>
            </Container>
        )
      }
    </div>
  )
}
