import React, { useEffect, useState } from "react";
import styles from "./companyinfo.module.scss";
import CarouselList from "../../../../Components/CardsList";
import { Job } from "../../../../models/job";
import { listjobsByMajor } from "../../../../services/jobsService";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { Pagination, Container } from "@mui/material";
import Post from "../../../../Components/CardsList/Post";
import { CardLoadingSkeleton } from "../../Access/Tabs";
interface SimilarJobsProps {
  nested?: boolean;
  selectedJobMajorId: string;
  loading: boolean;
  selectedJobId: string;
}
export default function AllSimilarJobs() {
  const { selectedJobId, selectedJobMajorId } = useParams();
  // const { selectedJobMajorId, nested, loading, selectedJobId } = props
  const [jobs, setJobs] = useState<Array<Job>>([]);
  const [loadingPost, setLoadingPost] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totlaPages, setTotlaPages] = useState(0);
  const token = useSelector((state: any) => state.auth.token);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const getGobs = async () => {
    setLoadingPost(true);
    const response = await listjobsByMajor(selectedJobMajorId, selectedJobId, currentPage, 20);
    if (response) {
      setJobs(response.data.data.queryResult);
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 20));
      setLoadingPost(false);
    }
  };
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };
  useEffect(() => {
    if (token) {
      getGobs();
    }
  }, [token, currentPage]);
  return (
    <Container>
      <h3 className="secondary-title header-underline">Other Similar Jobs</h3>
      <div className={styles.jobs_list}>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 space-y-4 columns-3">
          {!loadingPost
            ? jobs &&
              jobs.length > 0 &&
              jobs.map((item: any, i: number) => {
                return <Post data={item} index={i} listAllData={getGobs} />;
              })
            : [1, 2, 3, 4, 5, 6, 7, 8, 9].map((item: any) => {
                return <CardLoadingSkeleton />;
              })}
        </div>
      </div>
      {totlaPages > 1 && (
        <div className="flex justify-end my-6">
          <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      )}
    </Container>
  );
}
