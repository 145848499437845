import { Avatar, Container, Grid, Input, TextField } from "@mui/material";
import styles from "./inbox.module.scss";
import { useEffect, useState } from "react";

import ChatInbox from "./ChatInbox";
import CustomizedButtons from "../../../Components/CustomButton";
import { useSelector } from "react-redux";
import { listChatMessages } from "../../../services/mesages";
import ReciverMesage from "./components/reciverMesage";
import SenderMesage from "./components/senderMesage";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataMessage from "../../../Components/NoDataMessage";
import Moment from "react-moment";
import CreateMeeting from "../../../Components/CreateMeeting";
import moment from "moment";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface InboxProps {}

const Inbox = (props: InboxProps) => {
  const resiverId = window.location.search.replace("?contact=", "");
  const [selected, setSelected] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const chatId = searchParams.get("chatId");
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState(true);

  const [loadingChat, setLoadingChat] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [messagesList, setMesagesList] = useState<any>([]);
  const [myMessagesList, setMyMesagesList] = useState([]);
  const allChats = useSelector((state: any) => state.chats.data);
  const UserId = useSelector((state: any) => state.auth.user._id);
  const User = useSelector((state: any) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [isMobileShow, setIsMobileShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (/iPhone|Android|iPad|iPod|BlackBerry|Opera Mini|IEMobile|WPDesktop/i.test(navigator.userAgent)) {
      setIsMobileShow(false);
      setIsMobile(true);
    }
  }, []);

  const handleSendMesage = () => {
    setOpen(true);
  };
  const navigate = useNavigate();
  const words = (str: any, pattern = /[^a-zA-Z-]+/) => str?.split(pattern).filter(Boolean);
  const NavigateToProfile = (type: string, id: string) => {
    if (type === "Student") {
      window.open(`/professional/profile/${id}`);
    }
    if (type === "Mentor") {
      window.open(`/mentor/profile/${id}`);
    }
    if (type === "Institution") {
      window.open(`/institution/profile/${id}`);
    }
    if (type === "Company") {
      window.open(`/company/profile/${id}`);
    }
  };
  const getChatInfo = async (loading = true) => {
    isMobile && setLoadingText(true);
    const oldChat = currentPage === 0 ? [] : messagesList;
    if (oldChat.length === 0) {
      loading && setLoading(true);
    }
    const response = await listChatMessages(allChats[selected]?._id, currentPage);
    if (response.status === 200) {
      isMobile && setLoadingText(false);
      loading && setLoading(false);
      loading && setLoadingChat(false);
      const children = oldChat.concat(response.data.data.queryResult);
      setMesagesList(children);
      setHasNext(response.data.data.nextPage);
    }
  };
  const handleScroll = () => {
    if (hasNext) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleClick = (index: number) => {
    setSelected(index);
    if (selected !== index) {
      setMesagesList([]);
      setMyMesagesList([]);
    }
    if (isMobile) {
      setIsMobileShow(true);
    }
  };
  useEffect(() => {
    if (allChats && allChats.length > 0) {
      getChatInfo();
    }
    if (!(allChats && allChats.length > 0)) {
      setLoading(false);
    }
  }, [allChats, selected, currentPage]);
  useEffect(() => {
    if (chatId && allChats) {
      setSelected(allChats.map((item: any) => item?._id).indexOf(chatId));
    }
  }, [allChats]);
  return (
    <Container className="flex ">
      {open && <CreateMeeting show={open} setOpen={setOpen} resiverName={allChats[selected].otherUser.name} id={allChats[selected]?.otherUser._id} />}

      <h3 className="title text-underline mb-[20px]">Inbox</h3>
      {allChats && allChats.length > 0 ? (
        <Grid container className={styles["inbox-row"]}>
          {(!isMobileShow || !isMobile) && allChats && allChats.length > 0 && (
            <Grid item lg={4} md={4} sm={12} xs={12} className={[styles["left-col"]].join(" ")}>
              <ul>
                {allChats.map((item: any, index: number) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleClick(index)}
                      className={[selected === index ? styles["active"] : "", "overflow-hidden"].join(" ")}
                    >
                      <div className="flex">
                        {/* <Avatar className='mr-4' src="/broken-image.jpg" sx={{ width: 50, height: 50 }} /> */}

                        <Avatar
                          className="mr-4 cursor-pointer"
                          onClick={() => NavigateToProfile(item?.otherUser?.model, item?.otherUser?._id)}
                          src={item?.otherUser?.profileImage}
                          sx={{ width: 50, height: 50, bgcolor: "#F26400" }}
                          aria-label="avatar"
                        >
                          {item?.otherUser?.name && (
                            <a className="capitalize text-white">
                              {words(item?.otherUser?.name)[0]?.charAt(0)}
                              {words(item?.otherUser?.name)[1]?.charAt(0)}
                            </a>
                          )}
                        </Avatar>
                        <div className="w-full flex flex-col justify-between">
                          <p className="capitalize  max-w-[280px]  truncate ">
                            {" "}
                            {item?.otherUser?.firstName} {item?.otherUser?.name}
                          </p>
                          <p className="text-sm font-light"></p>
                          <p className={"text-[10px] max-w-[220px] md:max-w-[280px]  truncate"}>{item?.LastMessage}</p>
                          <span className="text-sm font-light">
                            <Moment format="MMM DD, YYYY">{item?.LastMessageDate}</Moment>
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Grid>
          )}
          {(isMobileShow || !isMobile) && (
            <Grid
              item
              lg={allChats && allChats.length > 0 ? 8 : 12}
              md={allChats && allChats.length > 0 ? 8 : 12}
              sm={12}
              xs={12}
              className={styles["right-col"]}
            >
              {!loading ? (
                <>
                  <div>
                    <div className={styles["heading"]}>
                      {!isMobile ? (
                        <a
                          className="secondary-title colored capitalize cursor-pointer"
                          onClick={() => NavigateToProfile(allChats[selected]?.otherUser?.model, allChats[selected]?.otherUser?._id)}
                        >
                          {allChats[selected]?.otherUser?.firstName} {allChats[selected]?.otherUser?.middleName || ""}{" "}
                          {allChats[selected]?.otherUser?.lastName} {allChats[selected]?.otherUser?.name}
                        </a>
                      ) : (
                        <div className="flex justify-between items-center bg-[#eaeaea] px-1 py-2 rounded">
                          <a className="colored block cursor-pointer" onClick={() => setIsMobileShow(false)}>
                            <ArrowBackIcon />
                          </a>

                          <a
                            className="text-sm colored capitalize cursor-pointer"
                            onClick={() => NavigateToProfile(allChats[selected]?.otherUser?.model, allChats[selected]?.otherUser?._id)}
                          >
                            {allChats[selected]?.otherUser?.firstName} {allChats[selected]?.otherUser?.middleName || ""}{" "}
                            {allChats[selected]?.otherUser?.lastName} {allChats[selected]?.otherUser?.name}
                          </a>
                        </div>
                      )}
                      {(User.model === "Company" || User.model === "Institution") && (
                        <div className="small-btn my-4 md:my-0">
                          <CustomizedButtons text={`Create Meeting `} click={handleSendMesage} />
                        </div>
                      )}
                    </div>
                    <div className={styles["body"]} id="messages-box">
                      {loadingChat && <p className="absolute w-full top-0 text-center">loading history...</p>}
                      {/* <span >
                  Dec 2, 2023
                </span> */}
                      <div
                        id="scrollableDiv"
                        style={{
                          height: "100%",
                          overflow: "auto",
                          display: "flex",
                          flexDirection: "column-reverse",
                        }}
                      >
                        <InfiniteScroll
                          dataLength={messagesList.length}
                          next={() => handleScroll()}
                          style={{ display: "flex", flexDirection: "column-reverse" }}
                          inverse={true} //
                          pullDownToRefresh
                          refreshFunction={() => handleScroll()}
                          pullDownToRefreshThreshold={50}
                          // pullDownToRefreshContent={
                          //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                          // }
                          // releaseToRefreshContent={
                          //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                          // }
                          hasMore={true}
                          loader={
                            <p className="w-full text-center mb-2">
                              {hasNext
                                ? !isMobile
                                  ? "Loading history..."
                                  : `${loadingText ? "Loading history..." : "Pull down to load more messages"} `
                                : "This is the beginning of the messages"}
                            </p>
                          }
                          scrollableTarget="scrollableDiv"
                        >
                          {messagesList.length > 0 || myMessagesList.length > 0 ? (
                            <>
                              {messagesList.map((message: any, index: number) => {
                                return (
                                  <div className="block px-2">
                                    <div className="relative">
                                      {moment(messagesList[index + 1]?.createdAt).format("L") != moment(message?.createdAt).format("L") && (
                                        <div className="w-full border-t border-[#ffe9e0] h-[20px]  my-3 ">
                                          <p className="absolute label-date">
                                            {moment(message?.createdAt).calendar(null, {
                                              lastDay: "[Yesterday]",
                                              sameDay: "[Today]",
                                              sameElse: "ddd, MMMM Do",
                                            })}
                                          </p>
                                        </div>
                                      )}{" "}
                                    </div>
                                    {UserId === message.sender && (
                                      <div className="flex justify-end mb-[10px] ">
                                        <SenderMesage message={message} checks={2} />

                                        <Avatar
                                          className="ml-2"
                                          src={User?.profileImage || User?.subModel?.logo}
                                          sx={{ width: 30, height: 30, bgcolor: "#F26400" }}
                                          aria-label="avatar"
                                        >
                                          <a className="capitalize text-white text-sm">
                                            {User?.subModel?.firstName?.charAt(0)}
                                            {User?.subModel?.lastName?.charAt(0)} {User?.subModel?.name?.charAt(0)}
                                          </a>
                                        </Avatar>
                                      </div>
                                    )}
                                    {UserId !== message.sender && (
                                      <div className="flex mb-[10px]">
                                        <Avatar
                                          className="mr-2"
                                          src={allChats[selected]?.otherUser?.profileImage}
                                          sx={{ width: 30, height: 30, bgcolor: "#F26400" }}
                                          aria-label="avatar"
                                        >
                                          <a className="capitalize text-white text-sm">
                                            {allChats[selected]?.otherUser ? allChats[selected]?.otherUser?.firstName?.charAt(0) : ""}
                                            {allChats[selected]?.otherUser ? allChats[selected]?.otherUser?.lastName?.charAt(0) : ""}
                                            {words ? words(allChats[selected]?.otherUser?.name)[0]?.charAt(0) : ""}
                                            {words ? words(allChats[selected]?.otherUser?.name)[1]?.charAt(0) : ""}
                                          </a>
                                        </Avatar>

                                        <ReciverMesage message={message} checks={2} />
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <p className="text-center">Send your first message to start a conversation</p>
                          )}
                        </InfiniteScroll>
                      </div>
                      {/* <p>
                Hi Sara,<br></br>

                My name is lorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsumlorem ipsum lorem ipsum
              </p> */}
                      {/* <div className={styles["card"]}>
                <Post />
              </div> */}
                      {/* <div className="flex rounded-action gap-4 my-2">
                <CustomizedButtons text="I am interested" />
                <CustomizedButtons text="No thank you" />
              </div> */}
                    </div>
                  </div>
                  <ChatInbox
                    receiverId={allChats[selected].otherUser._id}
                    setMyMesagesList={setMyMesagesList}
                    myMessagesList={myMessagesList}
                    getChatInfo={getChatInfo}
                    isMobile={isMobile}
                  />
                </>
              ) : (
                <ChatLoading />
              )}
            </Grid>
          )}
        </Grid>
      ) : (
        <NoDataMessage text="No messages" description="Your new messages will appear here" />
      )}
    </Container>
  );
};

export default Inbox;

const ChatLoading = () => {
  return (
    <div className={["animate-pulse"].join(" ")}>
      <div className="flex justify-start">
        <div className="h-40 bg-gray-200 rounded-xl col-span-1 w-3/4 mb-2" style={{ borderBottomLeftRadius: 0 }}></div>
      </div>

      <div className="flex justify-end">
        <div className="h-8 bg-gray-200 rounded-xl col-span-1 w-1/4 mb-2" style={{ borderBottomRightRadius: 0 }}></div>
      </div>
      <div className="flex justify-end">
        <div className="h-12 bg-gray-200 rounded-xl col-span-1 w-2/4 mb-2" style={{ borderBottomRightRadius: 0 }}></div>
      </div>
      <div className="flex justify-end">
        <div className="h-40 bg-gray-200 rounded-xl col-span-1 w-3/4 mb-2" style={{ borderBottomRightRadius: 0 }}></div>
      </div>
    </div>
  );
};
