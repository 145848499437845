import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RemoveIcon from '@mui/icons-material/Remove';
import Moment from 'react-moment';
import DeleteModal from '../deleteAtachments';
import { useState } from 'react';
import { Switch } from '@mui/material';


export default function BasicTable(props: any) {
  const { data, listAllData, handleUpdateUserAttachment } = props
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  const handleClose = () => setOpen(false);
  const handleDeleteItem = (id: string) => {
    setSelectedItem(id)
    setOpen(true)
  }
  return (
    <div className='main-table'>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow >
            <TableCell ><span className='table-title'>Name of Document</span></TableCell>
            <TableCell ><span className='table-title'>Uploaded Document</span></TableCell>
            <TableCell ><span className='table-title'>Profile Visibility</span></TableCell>
            <TableCell ><span className='table-title'>Date Uploaded</span></TableCell>
            <TableCell ><span className='table-title'>Status of Document</span></TableCell>
            <TableCell ><span className='table-title'>Delete</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row: any) => (
            <TableRow
              key={row?._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <a className='colored' href={row?.attachment} target="_blank">
                  {/* {row?.name} */}
                  {row?.name}
                </a>

              </TableCell>

              <TableCell >
                <a className='colored' href={row?.attachment} target="_blank">

                  {row?.attachment}
                </a></TableCell>
              <TableCell >
                <label className="switch">
                  <input type="checkbox" defaultChecked={row?.visibility} onChange={e => handleUpdateUserAttachment(row._id, e.target.checked)} />
                  <span className="slider round"></span>
                </label>

              </TableCell>
              <TableCell ><Moment format="MMMM DD, YYYY">{row?.dateUploaded}</Moment></TableCell>

              <TableCell align='center'>
                <div className={`${row?.status.charAt(0).toUpperCase() + row?.status.slice(1)}_label text-center capitalize`}>
                  {row?.status}
                </div>

              </TableCell>

              {row.visibility !== 'pending' &&
                <TableCell onClick={() => handleDeleteItem(row._id)}>
                  <div className='table-action justify-center'>

                    <div className='icon'><RemoveIcon sx={{ width: 23, height: 23 }} /></div>

                  </div>

                </TableCell>
              }

            </TableRow>
          ))}
        </TableBody>
      </Table>
      {open &&
        <DeleteModal show={open} handleClose={handleClose} selectedItem={selectedItem} listAllData={listAllData} />
      }

    </div>

  );
}