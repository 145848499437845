import { createSlice } from "@reduxjs/toolkit";

export const followSlice = createSlice({
  name: "follow",
  initialState: {
    FollowedCompanies: null,
  },
  reducers: {
    addDataInfo: (state, action) => {
      return action.payload;
    },
  },
});

export const { addDataInfo } = followSlice.actions;

export default followSlice.reducer;
