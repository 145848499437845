import React from "react";
import styles from "./companyinfo.module.scss";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { Grid } from "@mui/material";
import MediaPlacement from "../../../../assets/svg/media.svg";
export default function CompanyMedia(user: any) {

  return (
    <div className={[styles['company-media'], "m-b-50"].join(" ")}>
     

      {user.user?.subModel?.companyMedia?.length > 0 ?
     <>
       <h3 className="secondary-title">Company Media</h3>
        <Grid container spacing={3}  >
          {
            user.user?.subModel?.companyMedia.map((item: any, index: number) => {
              return (
                <Grid item md={4} sm={12} className="w-full" key={index}>
                  <div className="relative border rounded-xl bg-white" >
                  {item.includes('.jpeg')||item.includes('.gif')||item.includes('.png')||item.includes('.jpg')?
                          <img src={item} className="h-[250px]  w-full" />:
                          <video src={item} className="h-[250px]  w-full" controls/>
                          }
                  </div>
                </Grid>


              )
            })
          }

        </Grid>
        </>
      
      :
      ''
      // <div className="bg-light-green pb-4 border rounded-xl">
      //   <img src={MediaPlacement} className="w-full h-[350px]" alt="media" />
      //   <p className="text-center text-xl text-[#F26400]">No media added by {user?.user?.subModel?.name} yet</p>
      // </div>
      }
      {/* <Grid container spacing={3}  >
    <Grid item md={4} sm={12} className="w-full">
          <div className={styles['item']} style={{ backgroundImage: `url(${link1})` }}>
      <div className={styles['overlay']} title="play">
          <PlayCircleIcon fontSize="small"/>
        </div>
      </div>
      </Grid>
      <Grid item md={4} sm={12} className="w-full">
      <div className={styles['item']} style={{ backgroundImage: `url(${link2})` }}>
        <div className={styles['overlay']} title="play">

          <PlayCircleIcon />
        </div>
      </div>
      </Grid>
      <Grid item md={4} sm={12} className="w-full">
      <div className={styles['item']} style={{ backgroundImage: `url(${link3})` }}>
      <div className={styles['overlay']}  title="play">

          <PlayCircleIcon />
        </div>
      </div>
      </Grid>
    </Grid> */}
      {/* <a className="main-link" href="#">See full company profile</a> */}
    </div>
  );
}
