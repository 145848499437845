import { Avatar, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoneyIcon from '@mui/icons-material/Money';

export const NumberBox = (props:any) => (
  <Card
    // sx={{ height: '100%' }}
    {...props}
    className="analysis-card bg-light-green border-green "
  >
    <div className='p-0'>
      <Grid
        container
        spacing={3}
        sx={{ justifyContent: 'space-between' }}
      >
        <Grid item>
         
          <Typography
            color="textPrimary"
            variant="h4"
          >
            {props.value}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: props.color,
              height: 56,
              width: 56
            }}
          >
           {props.icon}
          </Avatar>
        </Grid>
      </Grid>
      <h6
            color="textSecondary"
            className='mt-4'
           
          >
            {props.text}
          </h6>
    </div>
  </Card>
);
