import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { useState } from 'react';

dayjs.extend(isBetweenPlugin);
export default function AddAvailabilityTimeDatePicker(props:any) {
  var todayt = new Date();
  const [after3Monthes, setAfter3Monthes] = useState<any>(new Date(new Date().setDate(todayt.getDate() + 60)));
 
  return (
   
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        // label="Week picker"
        value={props.value}
        onChange={(newValue) => {
          props.setValue(newValue);
        }}
        minDate={new Date()}
        maxDate={after3Monthes}
        renderInput={(params) => <TextField fullWidth {...params} />}
        inputFormat="'Week of' MMM d"
      />
    </LocalizationProvider>
   
  );
}