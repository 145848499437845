import { Container } from "@mui/system";
import ApplySection from "./ApplySection";
import CompanyInfo from "./CompanyInfo";
import Header from "./Header";
import styles from "./jobdetails.module.scss";
import JobMatcher from "./JobMatcher";
import RoleInfo from "./RoleInfo";
import CustomizedButtons from "../../../Components/CustomButton";
import SimilarJobs from "./SimilarJobs";
import { useEffect, useState } from "react";
import JobApply from "../JobApply";
import SavingJob from "../../Components/SavingJob";
import { useSelector } from "react-redux";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from "react-router";
import CustomButton from "../../../Components/CustomButton";
interface JobDetailsProps {
  nested?: boolean
  selectedJob?: any
  loading?: any
}

const JobDetails = (props: JobDetailsProps) => {
  const user = useSelector((state: any) => state.auth.user);
  const [open, setOpen] = useState(false);
  const isCompany = window.location.href.includes('company')
  const isInstitution = window.location.href.includes('institution')
  const navigateTo = useNavigate()
  const clickHandler = () => {
    setOpen(true);
  };
  const { nested, selectedJob, loading } = props
  useEffect(() => {
    if (!loading) {

      let descriptionID = document.getElementById('description') as HTMLElement
      descriptionID.innerHTML = selectedJob.roleDescription
      let benefitsID = document.getElementById('benefits') as HTMLElement
      benefitsID.innerHTML = selectedJob?.benefits || ''
      let moreDetailsID = document.getElementById('moreDetails') as HTMLElement
      moreDetailsID.innerHTML = selectedJob.moreDetails || ''

    }
  }, [loading])
  return (

    <>
      {open && <JobApply show={open} setOpen={setOpen} loading={loading} selectedJob={selectedJob} />}
      {(!loading) && user?.model === 'Company' && (selectedJob.company?._id == user?._id) &&
        <div className="w-full block md:flex justify-end items-baseline">
          <div className=' block actions-end mr-0  md:mr-2 mb-2 md:mb-0'>
            <CustomButton text={'Edit job post'} click={() => navigateTo(`/company/add-information/${selectedJob._id}`)}
            ></CustomButton>
          </div>
          <div className=' block custom-action-fill mb-4 md:mb-0'>
            <CustomButton text={'Show job post applications'} click={() => navigateTo(`/company/job-applications/${selectedJob._id}`)}
            ></CustomButton>
          </div>
          {/* <a title="Show job applications" onClick={() => navigateTo(`/company/add-information/${selectedJob._id}`)}>
                <ModeEditIcon />
              </a>
                <a title="Show jop applications" onClick={() => navigateTo(`/company/job-applications/${selectedJob._id}`)}>
                  <ListAltIcon />
                </a> */}
        </div>
      }

      <Header nested={nested} loading={loading} selectedJob={selectedJob} />
      <RoleInfo nested={nested} loading={loading} selectedJob={selectedJob} />
      {!loading && <div className={[styles["job-description"], `${user?.model != "Student" ? 'mb-8' : 'mb-8'}`].join(" ")}>
        <h3 className="secondary-title">Role Description</h3>
        <p className="main-description" id="description"></p>
      </div>}
      {!loading && <div className={[styles["job-description"], `${user?.model != "Student" ? 'mb-8' : 'mb-8'}`].join(" ")}>
        {selectedJob?.benefits && <h3 className="secondary-title">Benefits</h3>}
        <p className="main-description" id="benefits"></p>
      </div>}
      {!loading && <div className={[styles["job-description"], `${user?.model != "Student" ? 'mb-8' : 'mb-8'}`].join(" ")}>
        {selectedJob?.moreDetails && <h3 className="secondary-title">More Details</h3>}
        <p className="main-description" id="moreDetails"></p>
      </div>}

      {user?.model != "Company" && user?.model != "Institution" && <ApplySection nested={nested} loading={loading} selectedJob={selectedJob} />}
      {user?.model != "Company" && user?.model != "Institution" && <JobMatcher loading={loading} selectedJob={selectedJob} />}
      <CompanyInfo loading={loading} selectedJob={selectedJob} />
      {selectedJob?.major?._id && <SimilarJobs loading={loading} nested={nested} selectedJobMajorId={selectedJob?.major?._id} selectedJobId={selectedJob?._id} />}

      {loading ?
        <div className={'animate-pulse'}>
          <div className="flex justify-center space-x-4">
            <div className="h-4 bg-gray-200 rounded col-span-1 w-[200px]  mb-2"></div>
            <div className="h-4 bg-gray-200 rounded col-span-1 w-[200px]  mb-2"></div>
          </div>
        </div>
        :
        (user?.model != "Company" && user?.model != "Institution") &&
        <div
          className={[
            styles["actions"],
            "grid grid-rows-1 grid-flow-col gap-4",
          ].join(" ")}
        >  <div className="flex">
            <SavingJob id={selectedJob._id} />
            <p className="black m-auto text-orange ml-2 ">Save this job</p>
          </div>

          {
            selectedJob?.applicationType !== 'external' ?
              !selectedJob.applied &&
              <>
                <strong className="mx-4">or</strong>
                <div className="flex rounded-action custom-action">
                  <CustomizedButtons text="Apply now" click={clickHandler} />
                </div>
              </>
              : ''
          }
        </div>
      }
    </>
  );
};

export default JobDetails;
