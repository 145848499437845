import { Avatar, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ScienceIcon from '@mui/icons-material/Science';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import styles from './reviews.module.scss'
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { listAllCompanyReviews } from '../../../../services/reviewsService';
import ReviewsElement from '../../../Student/Reviews/components/Reviews';
import PopUpSendMesage from '../../../../Components/PopUp';
import NoDataMessage from '../../../../Components/NoDataMessage';
interface ReviewsElementProps {

}
export default function Reviews(props: ReviewsElementProps) {
    const token = useSelector((state: any) => state.auth.token);
    const user = useSelector((state: any) => state.auth.user);
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [totlaPages, setTotlaPages] = useState(0)
    const parms = useParams()
    const { id } = parms
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page - 1)
    }

    const geAllReviews = async () => {
        setLoading(true)
        const response = await listAllCompanyReviews(id?id:user._id, currentPage, 2)

        if (response) {
            setList(response.data.data.queryResult)
            setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 2))
            setLoading(false)

        }
    }
  

    useEffect(() => {
        if (token) {
            geAllReviews()
        }
    }, [token, currentPage]);
    return (
        <div>

            {loading ?
                <><ReviewLoading />
                    <ReviewLoading />
                </>
                :
                list.length>0?
                <ReviewsElement list={list} type="company" />:
                <NoDataMessage/>
            }
            {totlaPages > 1 &&
                <div className="flex justify-end my-6">

                    <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                </div>
            }
        </div>
    )
}
const ReviewLoading = (props: any) => {
    const { nested } = props

    return (
        <div className="border  border-[#efeeee] rounded-md p-4 w-full mx-auto h-full">
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="h-2 bg-gray-200 rounded"></div>
                    <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">

                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </div>
                    <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-gray-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};