import { Avatar, Card, CardMedia, Container, Pagination, Typography } from "@mui/material";
import { data } from "jquery";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { useNavigate } from 'react-router';
import NoDataMessage from "../../../Components/NoDataMessage";
import { listAllStudents, listAllStudentsByMajor } from "../../../services/students/companies";

interface StudentsProps {
    major?: string
    institution?: string
}
export default function Students(props: StudentsProps) {
    const { major, institution } = props
    const token = useSelector((state: any) => state.auth.token);
    const user = useSelector((state: any) => state.auth.user);
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(0)
    const [totlaPages, setTotlaPages] = useState(0)
    const navigateTo = useNavigate()
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page - 1)
    }

    const getCompaniesList = async () => {
        setLoading(true)
        const response = major ? await listAllStudentsByMajor({ id: major, currentPage: currentPage }) : await listAllStudents({ currentPage: currentPage })
        if (response) {
            setCompanies(response.data.data.queryResult)
            setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 21))
            setLoading(false)
        }
    }

    useEffect(() => {
        if (token) {
            getCompaniesList()
        }
    }, [token, currentPage,major]);

    return (

        <>
            {!loading ?
                <div>
                    {companies && companies.length > 0 ?
                        <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">

                            {companies.map((user: any, i) => (

                                <Card className="student-card" onClick={() => navigateTo(`/professional/profile/${user?._id}`)}>
                                    <div className="flex">
                                        <Avatar className='mr-4'
                                            src={user?.profileImage} sx={{ width: 50, height: 50, bgcolor: "#F26400" }} aria-label="avatar">
                                            <a className='capitalize text-white'>
                                                {user?.subModel?.firstName?.charAt(0).toUpperCase()}{user?.subModel?.lastName?.charAt(0).toUpperCase()}</a>
                                        </Avatar>

                                        <div>
                                            <h3 className="text-[18px] font-[500]"> {user?.subModel?.firstName} {user?.subModel?.lastName}</h3>
                                            <div className="flex items-baseline">
                                                {user?.subModel?.areaOfInterest &&
                                                 <span className="font-[500] text-[14px] ">Area of interest:
                                                  <span className="font-normal text-[14px]"> {user?.subModel?.areaOfInterest?.nameEn}</span>
                                                  </span>}
                                               
                                            </div>
                                            <div className="flex items-baseline">
                                                {user?.subModel?.major?.length > 0 && <span className="font-[500] text-[14px] mr-2">Major(s):</span>}
                                                <ul>
                                                    {user?.subModel?.major?.map((major: any) => {
                                                        return (
                                                            <li>
                                                                <span className="text-[14px]">
                                                                    {major?.nameEn}
                                                                </span>
                                                            </li>

                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                           
                                        </div>

                                    </div>
                                </Card>
                            ))
                            }
                        </div>
                        :
                        <NoDataMessage />
                    }

                </div>
                :
                <div className="py-8 grid gap-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                    {[...Array(21)].map((item: any) => {
                        return (
                            <div className="border  border-[#efeeee] rounded-md p-4 w-full mx-auto h-full">
                                <div className="animate-pulse block space-x-4">
                                    <div className="flex space-y-6 py-1">

                                        <div className="rounded-full bg-gray-200 h-10 w-10 "></div>
                                        <div className="space-y-3 w-[90%] ml-4">
                                            <div className="grid grid-cols-3 gap-4 ">

                                                <div className="h-2 bg-gray-200 rounded col-span-1"></div>
                                            </div>
                                            <div className="h-2 bg-gray-200 rounded"></div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            }
      
                <div className="flex justify-end my-6">

                    <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                </div>
            

        </>


    );
}
