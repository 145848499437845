import axios from "../axios";
import { BASEURL } from "../environments/environment";

export const getCalenderData = (id: string) => {
  return axios
    .get(`${BASEURL}meeting/calendar?userId=${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const CreateMeetingWithUser = (id: any, data: any) => {
  return axios
    .post(`${BASEURL}meeting?userId=${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const cancelMeeting = (id: string, data: any) => {
  return axios
    .patch(`${BASEURL}meeting/status/${id}`, data)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
