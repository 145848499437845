import { Chip, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from './card.module.scss'
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from "../../../../../services/authService";
import { addAlertInfo } from "../../../../../store/alertSlice";
interface SkillsCardProps {
  label: string;
  itemsList: Array<any>
  type: string
}
export default function SkillsCard(props: SkillsCardProps) {
  const dispatch = useDispatch()
  const user = useSelector((state: any) => state.auth.user);
  const { label, itemsList, type } = props;
  const [keyword, setKeyword] = useState('')
  const [list, setList] = useState<Array<string>>([...itemsList])
  const [loadingbtns, setLoadingbtns] = useState<any>({});
  const formData = new FormData()
  const addToList = (keyValue: string) => {
    if(keyValue==='Enter')
    {
      if (keyword) {
        let temp = list
        temp.push(keyword)
        setList(temp)
        setKeyword('')
        updateUserProfile(type)
      }
    }
 
  }

  const RemoveItem = (index: number) => {
    let temp = list
    temp.splice(index, 1)
    setList(temp)
    setTimeout(() => {
      updateUserProfile(type)
    }, 400);
   
  }

  const updateUserProfile = async (key: string) => {
    dispatch(addAlertInfo(
      {
        show: false,
      }
    ))
    setLoadingbtns((_data: any) => ({ ..._data, [key]: true }))
    let data = {}
    if (type === 'skills') {

      formData.append("skills", JSON.stringify(list));
    }
  else  if (type === 'proficientLanguage') {
      formData.append("proficientLanguage", JSON.stringify(list));
    }
    else if (type === 'fluentLanguage') {
      formData.append("fluentLanguage", JSON.stringify(list));
    }
    
    const response = await UpdateUserProfile(user._id, formData)
    if (response.status === 200) {

      setLoadingbtns((_data: any) => ({ ..._data, [key]: false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Data updated successfully',
        }
      ))
    }
    else {
      setLoadingbtns((_data: any) => ({ ..._data, key: false }))
      dispatch(addAlertInfo(
        {
          show: true,
          message: response.data.error,
          type: 'error'
        }
      ))
    }
  }

  return (
    <div className=" border-[1px] p-4 rounded my-6 ">
      <p className="font-semibold text-[18px] ">{label}</p>
      <div className="flex items-center mt-4">
        <TextField
          fullWidth
          id="outlined-basic"
          variant="outlined"
          hiddenLabel
          placeholder={`Enter ${label}`}
          size="small"
          onChange={e => setKeyword(e.target.value)}
          onKeyDown={(e:any)=>addToList(e.key)}
          value={keyword}
        />
      </div>
      {list && list.length > 0 &&

        <div className={[styles['selected-jobs'], 'mt-2'].join(' ')}>
          <p className="description my-2">You are currently open to the below, you can add from + and delete by clicking on -</p>

          <Stack direction="row" gap={1} className="flex-wrap">
            {
              list.map((item: any, index: number) => {
                return (
                  <Chip label={item.nameEn ? item.nameEn : item}
                    onClick={() => RemoveItem(index)}
                    onDelete={() => RemoveItem(index)}
                    deleteIcon={<RemoveIcon />} />
                )
              })}
          </Stack>
        </div>
      }
      {/* <div className='custom-action  my-2 flex gap-x-4'>
        {list.length > 0 &&
          <CustomizedButtons text="Cancel" click={emptyList} />
        }
        <CustomizedButtons text="Add" click={addToList} loading={loadingbtns[type]}/> */}
      {/* </div> */}
    </div>
  );
}
