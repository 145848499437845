import React from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useSelector } from "react-redux";
export default function PersonsalInfoCard() {
  const user = useSelector((state: any) => state.auth.user);

  return (

    <div className=" text-sm border-[1px] p-4 rounded my-6 text-[#222222]">
      <h4 className="list-title">Personal Information</h4>
      <p className="description my-2">
      <VisibilityIcon fontSize="small"/> This section is only visible to you
      </p>
      <p className="font-base">Primary email address</p>
      <p className="font-extralight mb-2">{user.email}</p>
      <p className="font-base  ">Secondary email address</p>
      <p className="font-extralight mb-2">{user?.subModel?.secondaryEmail}</p>
      <p>Gender</p>
      <p className="font-extralight mb-2">{user?.subModel?.gender}</p>
    </div>
  );
}
