import { Avatar } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import styles from "./employer-profile.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { getUserInfo } from "../../../services/userService";
import NoDataMessage from "../../../Components/NoDataMessage";
export default function StaffProfile() {
  const userInfo = useSelector((state: any) => state.auth.user);
  const token = useSelector((state: any) => state.auth.token);
  const [user, setUserInfo] = useState<any>()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleSendMesage = () => {
    setOpen(true)
  }

  function handleClick(href: string) {
    navigate(href);
  }
  const parms = useParams()
  const { id } = parms

  const getUserData = async () => {
    const response = await getUserInfo(id)
    if (response.status === 200) {
      setLoading(false)
      setUserInfo(response.data.data)
    }
    else {

    }
  }

  useEffect(() => {
    if (token && !id) {
      setUserInfo(userInfo)
      setLoading(false)
    }
  }, [token])

  useEffect(() => {
    if (id) {
      getUserData()

    }
  }, [])
  return (
    <Container>
      {!loading ?
        <div className="page">
          <div className="m-b-50 main-b-border pb-6">
            <h2 className="title">Profile</h2>
          </div>
          <div className="flex gap-20 mt-8  responsive-profile-col">
            <div className="block text-center info">
              <Avatar
                sx={{ width: 120, height: 120, marginBottom: "20px", marginRight: "auto", marginLeft: "auto" }}
                src={user?.subModel?.logo}
              />
              <h3 className="secondary-title ">{user?.subModel?.name}</h3>
              <span className="colored m-b-10 block">
                Company
              </span>
            </div>
            <div className={styles['profile-info']}>

              <form>

                <div className="form-item">
                  <label>Employer email</label>
                  <a href={user?.email}
                    className="colored main-description block">{user?.email}</a>
                </div>
                {/* <div className="form-item">
                <label>Secondary email</label>
                <a href="mailto:ahmedabc123@company.com" className="colored main-description block">ahmedabc123@company.com</a>
              </div> */}
                {/* <div className="form-item">
                <label>Title</label>
                <p className="main-description">Recruitment Officer</p>
              </div> */}
                <div className="form-item ">
                  <label>Phone #</label>
                  <p className="main-description">{user?.subModel?.phoneNumber}</p>
                </div>
                <div className="form-item">

                {id &&
                  //  <p className="flex items-baseline">to schedule an appointment with {user?.subModel?.user.firstName} 
                   <a className="main-link    block small-text" onClick={() => handleSendMesage()}>Send a message</a>
                 
                   }
                </div>


              </form>
            </div>

          </div>

        </div>
        :
        <NoDataMessage />
      }
    </Container>
  );
}
