
import Header from './Header';
import styles from './home.module.scss'
import Dashboard from '../Dashboard';

interface HomeProps {

}

const Home = (props: HomeProps) => {
  return (
    <div className={styles['home-page']}>
     <Header/>
     <Dashboard />
     
    </div>
  );
};

export default Home;