export const validatePassword = (password: any) => {
    if (!password) {
        return 'This field is required'
    }
    if (password.length < 12) {
        return 'Password is too short - should be 12 characters minimum'
    } else if (password.search(/[a-z]/) < 0) {
        return 'Password must contain at least one lowercase letter'
    } else if (password.search(/[A-Z]/) < 0) {
        return 'Password must contain at least one uppercase letter'
    } else if (password.search(/[0-9]/) < 0) {
        return 'Password must contain at least one number'
    }
    else if (password.search(/[^\w]/) < 0) {
        return 'Password must contain at least one symbol'
    }
    else {
        return false
    }
}
export const validatePasswordTrue = (password: any) => {
    if (!password) {
        return false
    }
    if (password.length < 12) {
        return false
    } else if (password.search(/[a-z]/) < 0) {
        return false
    } else if (password.search(/[A-Z]/) < 0) {
        return false
    } else if (password.search(/[0-9]/) < 0) {
        return false
    }
    else if (password.search(/[^\w]/) < 0) {
        return false
    }
    else {
        return true
    }
}