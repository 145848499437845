import { Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Table from '../../../../Components/Table'
import { getAllNotificationTypes } from '../../../../services/notificationService'
import { useSelector } from 'react-redux';
import NotificationsTable from './table';
import CustomizedButtons from '../../../../Components/CustomButton';

export default function NotificationsPreferences() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [list,setList]=useState([])
  const listNotificationTypes=async ()=>{
    const response = await getAllNotificationTypes(user.model)
    setList(response.data.data)
  }
  
  useEffect(() => {
    if (token ) {
      listNotificationTypes()
    }
  }, [token])

  return (
    <Container> 

       <div className="m-b-50 main-b-border pb-6 header-underline">
        <div className='title'>Notifications Preferences</div>
          <p className='main-description'>edqan allows you to receive notifications based on you preferences. Choose how you want to be notified.
           Please note you will receive an email for notifications, so to avoid 
          lots of emails choose the ones that are important to you.</p>
          </div>
          <div>
          
          <NotificationsTable  columns={['Notification']}  alignment={['left']} list={list}/>
        
        </div>
    </Container>
  )
}
