import { Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import MentorCard from "./MentorCard";
import { Grid, Pagination } from "@mui/material";
import NoDataMessage from "../../../Components/NoDataMessage";
import { listAllMentorsToconnect } from "../../../services/mentors";
import { useSelector } from "react-redux";
import SelectInput from "../../../Components/SelectInput";
import { listAllMajor } from "../../../services/majorsServise";
import { ListAllIndustry } from "../../../services/industry";
import { listAllInstitutions, ListAlltitutions } from "../../../services/institutionService";
import { CardLoadingSkeleton } from "../../../Components/CardsList/Post";
import { listAllAreaInterest } from "../../../services/areaInterestservice";

export default function ConnectMentors() {
  const token = useSelector((state: any) => state.auth.token);
  const [rateValue, setRateValue] = useState(0);
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totlaPages, setTotlaPages] = useState(0);
  const [majors, setMajors] = useState<Array<any>>([]);
  const [selectedMajor, setSelectedMajor] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingMajors, setLoadingMajors] = useState(true);
  const [majorFilter, setMajorFilter] = useState<any>(null);
  const [industryFilter, setIndustryFilter] = useState<any>(null);
  const [almaFilter, setAlamFilter] = useState<any>(null);
  const [areaInterestFilter, setAreaInterestFilter] = useState<any>(null);

  const [allAreaInterest, setAllAreaInterest] = useState([]);
  const [loadingAreaInterest, setloadingAreaInterest] = useState(true);
  const [allInstitutions, setAllInstitutions] = useState<Array<any>>([]);
  const [loadingInstitutions, setloadingInstitutions] = useState(true);

  const [industry, setAllIndustry] = useState([]);
  const [loadingIndustry, setloadingIndustry] = useState(true);
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1);
  };
  const getAllMentors = async () => {
    setLoading(true);
    let majorFilterParm = "";
    if (majorFilter) {
      majorFilterParm = `&major=${majorFilter.id}`;
    }
    let industryFilterParm = "";
    if (industryFilter) {
      industryFilterParm = `&industry=${industryFilter.id}`;
    }
    let almaFilterParm = "";
    if (almaFilter) {
      almaFilterParm = `&almaMater=${almaFilter.id}`;
    }
    let areaInterestFilterParm = "";
    if (areaInterestFilter) {
      areaInterestFilterParm = `&areaOfInterest=${areaInterestFilter.id}`;
    }

    const response = await listAllMentorsToconnect(currentPage, 21, majorFilterParm, industryFilterParm, almaFilterParm, areaInterestFilterParm);
    if (response.status === 200) {
      setList(response.data.data.queryResult);
      setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 21));
      setLoading(false);
    }
  };
  useEffect(() => {
    if (token) {
      getAllMentors();
    }
  }, [token, currentPage, majorFilter, industryFilter, almaFilter, areaInterestFilter]);

  const getAllMajors = async () => {
    const response = await listAllMajor();
    if (response) {
      const majors = response.data.data.queryResult.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setMajors(majors);
      setLoadingMajors(false);
    }
  };
  // list All Area Interest
  const getAllAreaInterest = async () => {
    const response = await listAllAreaInterest();
    if (response) {
      const allAreaInterest = response.data.data.queryResult.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setAllAreaInterest(allAreaInterest);
      setloadingAreaInterest(false);
    }
  };
  const getAllIndustry = async () => {
    const response = await ListAllIndustry();
    if (response) {
      const majors = response.data.data.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setAllIndustry(majors);
      setloadingIndustry(false);
    }
  };
  const getAllInstitutions = async () => {
    const response = await ListAlltitutions();
    if (response) {
      const majors = response.data.data.queryResult.map((item: any) => ({ label: item?.subModel?.name, id: item?.subModel?.user?._id }));
      setAllInstitutions(majors);
      setloadingInstitutions(false);
    }
  };

  useEffect(() => {
    if (token) {
      getAllMajors();
      getAllIndustry();
      getAllInstitutions();
      getAllAreaInterest();
    }
  }, [token]);
  console.log(list);
  return (
    <Container>
      <div className="header-underline">
        <h3 className="title ">Connect with other Mentors</h3>
        <p className="main-description ">
          Here you can connect with mentors, learn from others in the community and collaborate on projects. These mentors accept messages from other
          mentors.
        </p>
      </div>
      <div className="block md:flex ">
        <div className="mb-2 md:mb-0 md:mr-5 w-full md:w-1/4">
          <SelectInput
            label="Filter by major"
            value={majorFilter}
            options={majors}
            loading={loadingMajors}
            Change={(e: any) => setMajorFilter(e.value)}
            required={false}
          />
        </div>
        <div className="mb-2 md:mb-0 md:mr-5 w-full md:w-1/4">
          <SelectInput
            label="Filter by Area of interest"
            value={areaInterestFilter}
            options={allAreaInterest}
            loading={loadingAreaInterest}
            Change={(e: any) => setAreaInterestFilter(e.value)}
            required={false}
          />
        </div>
        <div className="mb-2 md:mb-0 md:mr-5 w-full md:w-1/4">
          <SelectInput
            label="Filter by industry"
            value={industryFilter}
            options={industry}
            loading={loadingIndustry}
            Change={(e: any) => setIndustryFilter(e.value)}
            required={false}
          />
        </div>
        <div className="mb-2 md:mb-0 md:mr-5 w-full md:w-1/4">
          <SelectInput
            label="Filter by Alma mater"
            value={almaFilter}
            options={allInstitutions}
            loading={loadingInstitutions}
            Change={(e: any) => setAlamFilter(e.value)}
            required={false}
          />
        </div>
      </div>

      <div>
        <div className="mt-10">
          {loading ? (
            <Grid container spacing={3}>
              {[...Array(21)].map((item: any) => {
                return (
                  <Grid item md={4} sm={12} xs={12}>
                    <CardLoadingSkeleton />
                  </Grid>
                );
              })}
            </Grid>
          ) : list.length > 0 ? (
            <Grid container spacing={3} className="block md:flex">
              {list.map((item: any) => {
                return (
                  <Grid item md={4} sm={12} xs={12}>
                    <MentorCard data={item} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <NoDataMessage />
          )}
        </div>

        <div className="flex justify-end my-6">
          <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      </div>
    </Container>
  );
}
