import { Grid } from "@mui/material";
import styles from "./signUp.module.scss";
import SideBanner from "./SideBanner";
import LoginForm from "./LoginForm";
import Footer from "../../../Layout/Footer";
interface SignUpProps {}

const SignUp = (props: SignUpProps) => {
  return (
    <div className={styles["signUp-page"]}>
      <Grid container className={styles["signUp-page-row"]}>
        <Grid item xs={3} className={styles["left-col"]}>
          <SideBanner title="Sign up" />
        </Grid>
        <Grid
          item
          lg={9}
          md={9}
          sm={12}
          xs={12}
          className={styles["right-col"]}
        >
          <LoginForm />
        </Grid>
      </Grid>
      <div >
      {/* <Footer noMargin={true}/> */}
      </div>
    
    </div>
  );
};

export default SignUp;
