import { Chip, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from './card.module.scss'
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserProfile } from "../../../../../services/authService";
import { addAlertInfo } from "../../../../../store/alertSlice";
import NoDataMessage from "../../../../../Components/NoDataMessage";
interface SkillsCardProps {
  label: string;
  itemsList: Array<any>
  type: string
}
export default function SkillsCard(props: SkillsCardProps) {
  const { label, itemsList, type } = props;
  const [list, setList] = useState<Array<string>>([...itemsList])
  return (
    <div className=" border-[1px] p-4 rounded my-6 ">
      <p className="font-semibold text-[18px] ">{label}</p>
     
      {list && list.length > 0 ?

        <div className={[styles['selected-jobs'], 'mt-2'].join(' ')}>
     
          <Stack direction="row" gap={1} className="flex-wrap">
            {
              list.map((item: any, index: number) => {
                return (
                  <Chip label={item.nameEn ? item.nameEn : item}
                    />
                )
              })}
          </Stack>
        </div>:
        <NoDataMessage/>
      }

    </div>
  );
}
