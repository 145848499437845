import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token:'',
    user: {}
  },
  reducers: {
    addAuthInfo: (state, action) => {
      localStorage.setItem('userToken',action.payload.token)
      localStorage.setItem('userInfo',JSON.stringify(action.payload.user))
      return action.payload;
    },
  },
});

export const { addAuthInfo } = authSlice.actions;

export default authSlice.reducer;
