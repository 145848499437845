import React from "react";
import './popup.scss';
import { Dialog, DialogTitle, DialogContent, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router";
interface PopupProp {
    open: boolean
    setOpenPop: React.Dispatch<React.SetStateAction<boolean>>;
}
// const closePopup = (DialogModal (false));
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DialogModalEmployer = ({ open, setOpenPop }: PopupProp) => {
    const handleClose = () => {
        setOpenPop(false)
    }
    const navigate = useNavigate();
    return (

        <Dialog className="dialog" open={open} >
            <DialogTitle >
                <div className="title">
                    <div><b>Maximize your recruitment experience:</b></div>
                    <div><label onClick={handleClose} id="closePopup"> &times;</label></div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    1. Fill in more information about your company.<br />
                    2. Create job posts.<br />
                    3. Discover talented students/professionals, and much more.<br />
                    4. Gain valuable insights.<br />
                </Typography>
                <br />
                <Typography><b>Quick actions:</b></Typography>
                <div className="buttons">
                    <Button id="button1" size="small" className="btn" onClick={() => navigate("/company/add-information?tap=0")}>
                        Update company’s public profile
                    </Button>
                    <Button id="button2" size="small" className="btn" onClick={() => navigate("/company/add-information?tap=2")}>
                        Create job posts
                    </Button>
                    <Button id="button3" size="small" className="btn" onClick={() => navigate("/professionals-list")}>
                        Discover talent
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};
export default DialogModalEmployer;
