import { Checkbox, Container, FormControl, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckBoxList from "../../../Components/CheckBoxList/CheckBoxList";
import CustomizedButtons from "../../../Components/CustomButton";
import DateTextField from "../../../Components/DateTextField";
import KeywordsTextField from "../../../Components/KeywordsTextField";
import InterestsSection from "./InterestsSection";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import InterestCard from "../Profile/components/InterestsCard";
import SelectInput from "../../../Components/SelectInput";
import { ListAllJobRole, ListAllWorkType } from "../../../services/jobsService";
import { useDispatch, useSelector } from "react-redux";
import { listAllCities, listAllCountries } from "../../../services/locationService";
import { ListAllIndustry } from "../../../services/industry";
import { saveCareerInterest } from "../../../services/careerInterest";
import { Dayjs } from "dayjs";
import { addAlertInfo } from "../../../store/alertSlice";
export default function CareerInterests() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  // careerInterest
  // const [graduationDate, setGraduationDate] = React.useState<Dayjs | null>(null);
  const [jobTypeList, setjobTypeList] = useState([]);
  const [loadingjobType, setLoadingjobType] = useState(true);

  const [jobRoleList, setJobRoleList] = useState([]);
  const [loadingJobRole, setLoadingJobRole] = useState(true);
  const [cities, setCities] = useState([]);
  const [data, setData] = useState<any>({});
  const [allIndustry, setAllIndustry] = useState([]);

  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingIndustry, setLoadingIndustry] = useState(false);
  const [loadingbtn, setLoadingbtn] = useState(false);
  const dispatch = useDispatch();
  const setListReselt = (name: string, value: any) => {
    let temp = data[name] ? data[name].map((item: any) => (item._id ? item._id : item)) : [];
    if (temp.includes(value)) {
      temp.splice(temp[value], 1);
    } else {
      temp.push(value);
    }
    setData((_data: any) => ({ ..._data, [name]: temp }));
  };

  const getAlljobType = async () => {
    const response = await ListAllWorkType();
    if (response) {
      const data = response.data.data.queryResult.map((item: any) => ({ name: item.nameEn, id: item._id }));
      setjobTypeList(data);
      setLoadingjobType(false);
    }
  };
  const getAllJobRole = async () => {
    const response = await ListAllJobRole();
    if (response) {
      const data = response.data.data.queryResult.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setJobRoleList(data);
      setLoadingJobRole(false);
    }
  };
  const getAllCities = async () => {
    setLoadingCities(true);
    const response = await listAllCountries();
    if (response) {
      const cities = response.data.data.queryResult.map((item: any) => ({ label: item.nameEn, id: item._id }));
      setCities(cities);
      setLoadingCities(false);
    }
  };
  const getAllIndustry = async () => {
    setLoadingCities(true);
    const response = await ListAllIndustry();
    if (response) {
      const data = response.data.data;
      setAllIndustry(data);
      setLoadingIndustry(false);
    }
  };
  const handleSelectChange = (e: any) => {
    const isArray = Array.isArray(e.value);

    if (isArray) {
      setData((_data: any) => ({ ..._data, [e.name]: e?.value ? e.value.map((item: any) => (item.id ? item.id : item)) : null }));
    } else {
      setData((_data: any) => ({ ..._data, [e.name]: e?.value ? e.value.id : null }));
    }

  };

  const handlClick = async () => {
    dispatch(
      addAlertInfo({
        show: false,
      })
    );
    setLoadingbtn(true);

    const payloadData = {
      country: data?.country?.map((item: any) => (item?._id ? item?._id : item)),
      jobRole: data?.jobRole?.map((item: any) => (item?._id ? item?._id : item)),
      subIndustry: data?.subIndustry?.map((item: any) => (item?._id ? item?._id : item)),
      workType: data?.workType?.map((item: any) => (item?._id ? item?._id : item)),
    };

    const response = await saveCareerInterest(user._id, { careerInterest: payloadData });

    if (response.status === 200) {
      setLoadingbtn(false);
      dispatch(
        addAlertInfo({
          show: true,
          message: "Updated successfully",
        })
      );
    } else {
      setLoadingbtn(false);
      dispatch(
        addAlertInfo({
          show: true,
          message: response.data.error,
          type: "error",
        })
      );
    }
  };
  useEffect(() => {
    if (token) {
      getAlljobType();
      getAllJobRole();
      getAllCities();
      getAllIndustry();
      if (user?.subModel?.careerInterest) {
        Object.keys(user?.subModel?.careerInterest).forEach(function (key, index) {
          setData((_data: any) => ({ ..._data, [key]: user?.subModel?.careerInterest[key] }));
        });
      }
    }
  }, [token]);

  return (
    <Container>
      <div className="header-underline">
        <h3 className="title">Career Interests</h3>
      </div>
      {token ? (
        <form>
          <div className="form-item m-b-20">
            <label>
              We want to help you find the right job, so tell us more about what you want
              <br />
              <p className="description colored-gray">Please select from the below</p>
            </label>
            <div className="CheckBoxList m-b-20">
              {loadingjobType ? (
                <div className="animate-pulse block space-y-4">
                  <div className="h-2 bg-gray-200 rounded col-span-1 w-[200px]"></div>
                  <div className="h-2 bg-gray-200 rounded col-span-1 w-[200px]"></div>
                  <div className="h-2 bg-gray-200 rounded col-span-1 w-[200px]"></div>
                  <div className="h-2 bg-gray-200 rounded col-span-1 w-[200px]"></div>
                </div>
              ) : (
                <CheckBoxList list={jobTypeList} setListReselt={setListReselt} selectedList={data?.workType} styling="row" name="workType" />
              )}{" "}
            </div>
          </div>
          <div></div>

          <div className="form-item m-b-20">
            {jobRoleList.map((item: any) => {
              return item.name;
            })}
            <SelectInput
              name="jobRole"
              multiple={true}
              label="Enter the job roles you are interested in"
              placeholder="Select Job role"
              options={jobRoleList}
              required={false}
              loading={loadingJobRole}
              Change={handleSelectChange}
              defaultValue={user?.subModel?.careerInterest?.jobRole.map((item: any) => ({ label: item.nameEn, id: item._id }))}
            />
          </div>
          <div className="form-item m-b-20">
            <SelectInput
              name="country"
              required={false}
              label="Enter the countries in the world you would like to live in"
              options={cities}
              Change={handleSelectChange}
              multiple={true}
              loading={loadingCities}
              defaultValue={user?.subModel?.careerInterest?.country?.map((item: any) => ({ label: item.nameEn, id: item._id }))}
            />
          </div>
          <div className="form-item m-b-20">
            <label>
              Enter the industries you are interesting in
              <br />
              <p className="description colored-gray">Please select from the below</p>
            </label>

            <div className="grid  gap-4 grid-cols-2 lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-3 ">
              {allIndustry.map((industry: any) => {
                return (
                  <>
                    {industry?.subIndustrys?.length > 0 ? (
                      <InterestsSection
                        setListReselt={setListReselt}
                        selectedList={data.subIndustry}
                        title={industry?.nameEn}
                        list={industry.subIndustrys.map((item: any) => ({ name: item.nameEn, id: item._id }))}
                      />
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </div>
          </div>

          <div className="actions-end  mt-6">
            <CustomizedButtons text="Save career interests" click={handlClick} loading={loadingbtn} />
          </div>
        </form>
      ) : (
        ""
      )}
    </Container>
  );
}
