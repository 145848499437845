import React, { useEffect, useState } from "react";
import EducationElement from "../components/EducationElement";
import ProjectElement from "../components/ProjectElement";
import SectionTitle from "../components/SectionTitle";
import WorkElement from "../components/WorkElement";

import { Chip, Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { UpdateUserProfile } from "../../../../services/authService";
import { addAlertInfo } from "../../../../store/alertSlice";
import { getUserInfo } from "../../../../services/userService";

import NoDataMessage from "../../../../Components/NoDataMessage";
import moment from "moment";
import { useParams } from "react-router-dom";

export default function RightContent() {

  const [loading, setLoading] = useState(false);
  const [user, setUserInfo] = useState<any>()
  const [list, setList] = useState<Array<any>>([])
  const [listCourses, setListCourses] = useState<Array<any>>([])

  const parms = useParams()
  const { id } = parms

  const getUserData = async () => {
    const response = await getUserInfo(id)
    if (response.status === 200) {
      setLoading(false)
      setUserInfo(response.data.data)
    }
    else {

    }
  }
  useEffect(() => {
    if (id) {
      getUserData()

    }
  }, [])


 
  useEffect(() => {
    if (user?.subModel) {
      setListCourses([...user?.subModel?.courses])
      setList([...user?.subModel?.organizations])
    }

  }, [user])

  return (
    <>
      {loading ?
        '' :
        <div>
          <div className="m-b-50">
            <SectionTitle title={`About ${user?.subModel?.firstName}`} editable={false} />
            <p className="main-description">{user?.subModel?.brief}</p>
          </div>

          <div className="m-b-50 main-b-border pb-6">
            <SectionTitle title="Education" editable={false} />
            {user?.subModel?.education.length ?
              user?.subModel?.education.map((education: any, index: any) => {
                return (
                  <>
                    <div className="flex justify-between">
                      <EducationElement item={education} />
                    </div>
                  </>         )
              }) :
              <NoDataMessage text="No education added yet" />}
          </div>
          <div className="m-b-50 main-b-border pb-6">
            <SectionTitle title="Work & Volunteer Experience" editable={false} />
            {user?.subModel?.volunteers.length ?
              user?.subModel?.volunteers.map((education: any, index: number) => {
                return (
                  <>
                    <div className="flex justify-between">
                      <WorkElement item={education} />
                    </div>
                  </>

                )
              })
              : <NoDataMessage text="No work or volunteer experience added yet" />}

          </div>
          <div className="m-b-50 main-b-border pb-6">
            <SectionTitle title="Committees/Organizations" editable={false} />

            {list.length > 0 &&
              <div className={['mt-2'].join(' ')}>
                <Stack direction="row" gap={1} className="flex-wrap">
                  {
                    list.map((item: string, index: number) => {
                      return (
                        <Chip label={item}
                        />
                      )
                    })}
                </Stack>
              </div>
            }

          </div>
          <div className="m-b-50">
            <SectionTitle title="Courses completed so far" editable={false} />
            <p className="font-light text-[15px] my-1">
              {listCourses.length ?
                listCourses.map((item: any, index: number) => {
                  return (
                    <div className="flex justify-between" key={index}>
                      <div className="mb-4">
                        <p>
                          {item?.name}
                        </p>
                        <a href={item.url} target="_blank" rel="noreferrer" className="main-link text-small">{item.url}</a>
                      </div>

                    </div>
                  )
                })
                :
                'No courses added yet'
              }
            </p>
          </div>
          <div className="m-b-50">
            <SectionTitle title={`Notable Projects/Products ${user?.subModel?.firstName} worked on`} />
            {user?.subModel?.projects.length ?
              user?.subModel?.projects.map((education: any, index: number) => {
                return (
                  <>
                    <div className="flex justify-between">
                      <ProjectElement item={education} />
                    </div>

                  </>
                )
              })
              :
              <NoDataMessage text="No projects experience added yet" />}
          </div>
        </div>
      }
    </>
  );
}
