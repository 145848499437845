

import { Box, Container, Grid, Pagination, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getNumberOfInstitutionUsersViews, getNumberOfUsersFollowedInstitution, getNumberOfUsersHiredInstitution, latestInteractionsWithEmployers } from '../../../services/analyticsService';

// icons 
import VisibilityIcon from '@mui/icons-material/Visibility';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import { DatePicker } from '@mui/x-date-pickers';
import { NumberBox } from '../../../Components/AnalysicBox/numberBox';
import moment from 'moment';
import TaskIcon from '@mui/icons-material/Task';
import LatestInteractionsWithEmployers from './components/latest-orders';
import Moment from 'react-moment';
import { useNavigate } from 'react-router';

export default function InstitutionInteractions() {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  const getData = async () => {
    const response = await latestInteractionsWithEmployers(user._id, currentPage)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token,currentPage]);
  const getApplicationStatus = (status: string) => {

    if (status == '0') return 'Pending'
    if (status == '1') return 'Hired'
    if (status == '2') return 'Declined'
  }
  return (
    <div className="m-t-50 m-b-50">
      <Container>

        <h3 className='title header-underline'>Latest interactions with employers</h3>



        <Table>
          <TableHead>
            <TableRow>

              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                
                  </TableSortLabel>
                </Tooltip>  */}
                Student/Professional
              </TableCell>
              {/* <TableCell>
              
            </TableCell> */}
              {/* <TableCell>
              Employer Name
            </TableCell> */}
              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                  
                  </TableSortLabel>
                </Tooltip>   */}
                Employer Name
              </TableCell>
              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                  
                  </TableSortLabel>
                </Tooltip>   */}
                Job Posting
              </TableCell>
              {/* <TableCell>
              Job Posting
            </TableCell> */}
              <TableCell>

                Application Status
              </TableCell>

              <TableCell sortDirection="desc">
                {/* <Tooltip
                  enterDelay={300}
                  title="Sort"
                >
                  <TableSortLabel
                    active
                    direction="desc"
                  >
                 
                  </TableSortLabel>
                </Tooltip>   */}
                Latest update
              </TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item: any) => (
              <TableRow
                hover
                key={item._id}
              >

                <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/professional/profile/${item?.user?._id}`)}>
                    {item?.user?.subModel?.firstName} {item?.user?.subModel?.lastName}
                  </a>

                </TableCell>
                <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/company/profile/${item?.company?._id}`)}>
                    {item?.company?.subModel?.name}
                  </a>

                </TableCell>
                <TableCell>
                  <a className='main-link' onClick={() => navigate(`/job-details/${item.job?._id}`)}>{item?.job?.name}</a>
                </TableCell>
                <TableCell>
                  <div className={`${getApplicationStatus(item?.status)}_label text-center`}>
                    {getApplicationStatus(item?.status)}
                  </div>

                </TableCell>
                <TableCell>
                  <Moment format="MMMM DD, yyyy">{item?.updatedAt}</Moment>

                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className="flex justify-end my-6">

          <Pagination count={totalPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      </Container>
    </div>
  )
}
