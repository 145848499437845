import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const listAllDocuments = (id:any,filter:any,status='') => {
  return axios
    .get(`${BASEURL}userAttachment/user/${id}?attachmentType=${filter}${status?`&status=${status}`:''}`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};

export const AddUserAttachment = (userId:string,data:any) => {
  return axios
    .post(`${BASEURL}userAttachment/${userId}` ,data)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};
export const UpdateUserAttachment = (Id:string,data:any) => {
  return axios
    .patch(`${BASEURL}userAttachment/${Id}` ,data)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};


export const deleteDocument = (id:any) => {
  return axios
    .delete(`${BASEURL}userAttachment/${id}`)
    .then((res:any) => res)
    .catch((err:any) => err?.response);
};


