import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import styles from './tabs.module.scss'

import { useSelector } from "react-redux";

import { Container } from "@mui/material";
import Students from "./Taps/allStudents";
import SelectInput from "../../Components/SelectInput";
import { useState } from "react";
import { listAllMajor } from "../../services/majorsServise";
import { listAllInstitutions } from "../../services/institutionService";
import InstitutionStudents from "./Taps/allInstitutionStudents";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AllStudentsList() {
  const token = useSelector((state: any) => state.auth.token);
  const initialValue = parseInt(window.location.search.replace('?active=', ''))
  const [value, setValue] = React.useState(initialValue ? initialValue : 0);
  const [loadingMajors, setLoadingMajors] = useState(true)
  const [majorFilter, setMajorFilter] = useState<any>(null)
  const [majors, setMajors] = useState<Array<any>>([])
  const userInfo = useSelector((state: any) => state.auth.user);
  const [almaFilter, setAlamFilter] = useState<any>(null)

  const [allInstitutions, setAllInstitutions] = useState<Array<any>>([])
  const [loadingInstitutions, setloadingInstitutions] = useState(true)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const getAllMajors = async () => {
    const response = await listAllMajor()
    if (response) {
      const majors = response.data.data.queryResult.map((item: any) => ({ 'label': item.nameEn, 'id': item._id }))
      setMajors(majors)
      setLoadingMajors(false)
    }
  }
  const getAllInstitutions = async () => {
    const response = await listAllInstitutions()
    if (response) {
      const majors = response.data.data.queryResult.map((item: any) => ({ 'label': item.subModel?.name, 'id': item._id }))
      setAllInstitutions(majors)
      setloadingInstitutions(false)
    }
  }
  React.useEffect(() => {
    if (token) {
      getAllMajors()
      getAllInstitutions()
    }
  }, [token]);
  return (
    <div className="page">
      <Container>

        <Box sx={{ width: "100%" }}>
          <Box>
            <div className="header-underline">
              <h3 className='title'>Students/Professionals</h3>
              <p className='main-description'>See all professionals or find students by educational institution</p>
            </div>
            <Tabs
              className="tabs"
              variant="scrollable"
              allowScrollButtonsMobile
              sx={{
                '& .MuiTabs-indicator': { backgroundColor: '#F26400 ', color: "#F26400" },
                '& .Mui-selected': { color: "#F26400" },
              }}
              value={value} onChange={handleChange}
            >
              <Tab className="tab" label="Students/Professionals" {...a11yProps(0)} />
              <Tab className="tab" label="Students by Educational Institutions" {...a11yProps(1)} />
              {/* <Tab className="tab" label="Employers that hire remote" {...a11yProps(userInfo.subModel?.institution?.name ? 2 : 1)} /> */}
              {/* <Tab className="tab" label="All Employers" {...a11yProps(userInfo.subModel?.institution?.name?3:2)} /> */}
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <div className='mr-5 w-full md:w-1/4'>
              <SelectInput
                label='Filter by major'
                value={majorFilter}
                options={majors}
                loading={loadingMajors}
                Change={(e: any) => setMajorFilter(e.value)}
                required={false}

              />
            </div>
            <Students major={majorFilter?.id}/>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <div className='mr-5 w-full md:w-1/4'>
              <SelectInput
                label='Educational Institution'
                value={almaFilter}
                options={allInstitutions}
                loading={loadingInstitutions}
                Change={(e: any) => setAlamFilter(e.value)}

                required={false} />
            </div>
            <InstitutionStudents institution={almaFilter?.id} />
          </TabPanel>

        </Box>
      </Container>
    </div>
  );
}
export const CardLoadingSkeleton = () => {
  return (
    <div className="border  border-[#efeeee] rounded-md p-4 w-full mx-auto h-full">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-gray-200 rounded"></div>
          <div className="rounded-full bg-gray-200 h-10 w-10"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">

              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>

        </div>
      </div>
    </div>
  )
}