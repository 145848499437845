import { createSlice } from "@reduxjs/toolkit";

export const savedJobsSlice = createSlice({
  name: "savedJobs",
  initialState: {
    savedJobs: null,
  },
  reducers: {
    addDataSaved: (state, action) => {
      return action.payload;
    },
  },
});

export const { addDataSaved } = savedJobsSlice.actions;

export default savedJobsSlice.reducer;
