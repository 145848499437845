import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { ChangeEvent, useEffect, useState } from "react";
import CustomizedButtons from "../../../Components/CustomButton";
import SelectInput from "../../../Components/SelectInput";

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { listAllDocuments } from "../../../services/documentsService";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import InputField from "../../../Components/Input";
import FormErrorMessage from "../../../Components/FormErrorMessage";
import { ApplayToJob } from "../../../services/jobsService";
import { addAlertInfo } from "../../../store/alertSlice";

interface PopUpProps {
  show: boolean;
  setOpen?: any;
  loading: any
  selectedJob: any
  setApplied?: any
}
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  borderRadius: '16px',
  boxShadow: 0,
  p: 4,
};
const btnstyle = {
  width: 'auto',
  borderColor: "#F26400",
  height: "52px",
  color: "#F26400",
  border: "2px solid",
  boxShadow: 'none',
  backgroundColor: 'transparent',
  fontWeight: '600',
  fontSize: "14px",
  // padding: '12px 28px',
  padding: '10px',
  borderRadius: '8px',
  texttransform: 'none',
  '&:hover': {
    color: "white",
    backgroundColor: "#F26400",
  },

}
export default function JobApply(props: PopUpProps) {
  const { show, setOpen, loading, selectedJob, setApplied } = props;
  const userInfo = useSelector((state: any) => state.auth.user);
  const formData = new FormData();
  const dispatch = useDispatch()

  const token = useSelector((state: any) => state.auth.token);
  const [cvfile, setcvFile] = useState<File>();
  const [coverLetterfile, setCoverLetterfile] = useState<File>();
  const [cvsList, setCvsList] = useState([])
  const [coverLetterList, setCoverLetterList] = useState([])

  const [loadingCvs, setLoadingCvs] = useState(true)
  const [loadingCoverLetter, setLoadingCoverLetter] = useState(true)
  const [applicationData, setApplicationData] = useState<any>()
  const [questionsAnswers, setQuestionsAnswers] = useState([])
  const [errors, setError] = useState<any>([])
  const [loadingBtn, setLoadingBtn] = useState(false)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {

    if (e.target.files) {
     if(e.target.files[0]?.type.includes('pdf')){
      setCoverLetterfile(e.target.files[0]);
      setApplicationData((_data: any) => ({ ..._data, 'existingCoverLetter': null }));
     }
     else{
      dispatch(addAlertInfo(
        {
          show: true,
          message: 'Invalid file format. Please upload a PDF document.',
          type: 'error'
        }
      ))
     }
     
    }
  };
  const handleCvFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      if(e.target.files[0]?.type.includes('pdf')){
        setcvFile(e.target.files[0]);
        setApplicationData((_data: any) => ({ ..._data, 'existingCv': null }));
       }
       else{
        dispatch(addAlertInfo(
          {
            show: true,
            message: 'Invalid file format. Please upload a PDF document.',
            type: 'error'
          }
        ))
       }
  
    }
  };
  const getCvsList = async () => {
    const response = await listAllDocuments(userInfo._id, 'cv', 'approved')
    if (response) {
      setCvsList(response.data.data.map((item: any) => ({ 'label': item.name, 'id': item._id })))
      setLoadingCvs(false)

    }
  }
  const getCoverLetterList = async () => {
    const response = await listAllDocuments(userInfo._id, 'coverLetter', 'approved')
    if (response) {
      setCoverLetterList(response.data.data.map((item: any) => ({ 'label': item.name, 'id': item._id })))
      setLoadingCoverLetter(false)
    }

  }

  const handleSelectChange = (e: any) => {
    if (e.name == 'existingCoverLetter') {
      setCoverLetterfile(undefined)
    }
    else if (e.name == 'existingCv') (
      setcvFile(undefined)
    )
    setApplicationData((_data: any) => ({ ..._data, [e.name]: e?.value?.id ? e.value : null }));

  }

  const handleApply = async () => {

    formData.append("job", selectedJob._id);

    if (Object.keys(questionsAnswers).length !== selectedJob.questions.length) {
      setError((_data: any) => ({ ..._data, 'question': 'Please answer all the questions' }));
    }
    if (!(applicationData?.existingCv || cvfile)) {
      setError((_data: any) => ({ ..._data, 'cv': 'Please Select or upload your Cv' }));
    }
    if (!(applicationData?.existingCoverLetter || coverLetterfile)) {
      setError((_data: any) => ({ ..._data, 'cover': 'Please Select or upload your cover Letter' }));
    }
    if (Object.keys(questionsAnswers).length === selectedJob.questions.length &&
      (applicationData?.existingCv || cvfile) &&
      (applicationData?.existingCoverLetter || coverLetterfile)) {
      setLoadingBtn(true)
      let arr: any = []
      for (let i = 0; i < selectedJob.questions.length; i++) {
        arr.push(
          {
            "question": selectedJob.questions[i].question,
            "answer": questionsAnswers[i]
          }
        )
        if (i === selectedJob.questions.length - 1) {

          formData.append("questionsAnswers", JSON.stringify(arr))
        }
      }

      applicationData?.existingCv && formData.append("existingCv", applicationData?.existingCv?.id);
      applicationData?.existingCoverLetter && formData.append("existingCoverLetter", applicationData?.existingCoverLetter?.id);
      cvfile && formData.append("cvPath", cvfile);
      coverLetterfile && formData.append("coverLetterPath", coverLetterfile);
      const response = await ApplayToJob(userInfo._id, formData)
      if (response.status === 201) {
        setLoadingBtn(false)
        setOpen(false)
        setApplied && setApplied(true)
        dispatch(addAlertInfo(
          {
            show: true,
            message: selectedJob?.company?.subModel?.jobApplicationMessages?.received
          }
        ))
      }
      else {
        setLoadingBtn(false)
        dispatch(addAlertInfo(
          {
            show: true,
            message: response.data.error,
            type: 'error'
          }
        ))
      }
    }

  }
  useEffect(() => {
    if (token) {
      getCvsList()
      getCoverLetterList()
    }
  }, [token]);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box sx={style}>
        <div className="flex justify-end " onClick={handleClose}>
          <CloseIcon className="cursor-pointer" />
        </div>
        <div>
          <h4 className="colored title">Apply for the job post</h4>
          <div className="font-light text-[15px] my-2">
            <p className="main-description text-underline">Details below on what application to {selectedJob.name} requires. You can only add PDF documents.</p>
          </div>
          <div className="m-b-20 responsive-block">

            <label className="font-medium">1. Upload cover letter</label>
            <div className="flex w-full items-center justify-between align-center">
              <div className="w-1/2">

                <SelectInput
                  name="existingCoverLetter"
                  options={coverLetterList}
                  required={false}
                  loading={loadingCoverLetter}
                  value={applicationData?.existingCoverLetter || null}
                  Change={handleSelectChange}
                  placeholder="select from your documents"
                />

              </div>
              <p className="mx-4">Or</p>
              <Button
                className="responsive-m-b-10 w-auto"
                sx={{ textTransform: 'none', width: 170 }}
                variant="outlined"
                component="label"
                style={btnstyle}
              >
                Upload from your device
                <input hidden type="file" accept="application/pdf" onChange={handleFileChange} />
              </Button>

            </div>
            {coverLetterfile &&
              <div className="mb-2 p-1 bg-light-orange rounded-xl w-[fit-content] main-orange-border text-sm mt-2">
                <FilePresentIcon className="mr-2" />
                {coverLetterfile.name}
                <button onClick={() => setCoverLetterfile(undefined)}>
                  <CloseIcon className="ml-4" sx={{ color: '#f27342', fontSize: 12 }} /></button></div>
            }
            {errors?.cover && (
              <span className='text-[#ff000e] text-sm'>{errors?.cover}</span>
            )}
          </div>

          <div className="m-b-20">

            <div className="flex items-end justify-between  responsive-block">

              <div className="w-1/2">

                <SelectInput
                  label="2. Attach your CV"
                  name="existingCv"
                  options={cvsList}
                  value={applicationData?.existingCv || null}
                  required={false}
                  loading={loadingCvs}
                  Change={handleSelectChange}
                  placeholder="select from your documents" />

              </div>
              {/* {!(applicationData?.existingCv || cvfile) && <p className="mx-4">Or</p>} */}
              <p className="mx-4">Or</p>
              <Button
                className="w-auto"
                variant="outlined"
                component="label"
                style={btnstyle}
                sx={{ textTransform: 'none', width: 170 }}
              >
                Upload from your device
                <input hidden type="file" accept="application/pdf" onChange={handleCvFileChange} />
              </Button>

            </div>
            {cvfile &&
              <div className="mb-2 p-1 bg-light-orange rounded-xl w-[fit-content] main-orange-border text-sm mt-2">
                <FilePresentIcon className="mr-2" />
                {cvfile.name}
                <button onClick={() => setcvFile(undefined)}>
                  <CloseIcon className="ml-4" sx={{ color: '#f27342', fontSize: 12 }} /></button></div>
            }
            {errors?.cv && (
              <span className='text-[#ff000e] text-sm'>{errors?.cv}</span>

            )}

          </div>

          {selectedJob.questions.length > 0 &&
            <div className="m-b-20">

              <h3 className="secondary-title">
                3. Questions for company
              </h3>
              {selectedJob.questions.map((question: any, index: number) => {
                return (
                  <div className="text-[16px] mb-2">
                    <p className="mb-2">{question.question}</p>
                    {question.questionType === 'choice' &&
                      <RadioGroup row onChange={e => setQuestionsAnswers((_data: any) => ({ ..._data, [index]: question?.questionChoices[e.target.value] }))}>
                        {question?.questionChoices.map((ans: any,index:number) => {
                          return (
                            <FormControlLabel
                              value={index}
                              control={<Radio size="small" />}
                              label={ans}
                            />
                          )
                        })}


                      </RadioGroup>
                    }
                    {question.questionType === 'text' &&
                      <div className="w-full">
                        <InputField
                          textArea
                          rows={5}
                          name='answer'
                          required={true}
                          placeholder='Answer'
                          Change={(e: any) => setQuestionsAnswers((_data: any) => ({ ..._data, [index]: e.target.value }))}
                        />
                      </div>
                    }
                  </div>
                )
              })}
              {errors?.question && (
                <span className='text-[#ff000e] text-sm'>{errors?.question}</span>

              )}

            </div>
          }
          <div className="flex justify-end">
            <CustomizedButtons text="Submit" click={handleApply} loading={loadingBtn} />
          </div>
        </div>

      </Box>
    </Modal>

  );
}
