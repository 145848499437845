import React, { useEffect, useState } from "react";
import { Avatar, Box, Card, CardContent, CardHeader, Grid, Pagination, Tab, Tabs, Typography } from "@mui/material";
import { Container } from "@mui/system";
import NoDataMessage from "../../../Components/NoDataMessage";
import { UpdateJopApplication, getAllJobApplications } from "../../../services/jobApplicationsService";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import PopUpSendMesage from "../../../Components/PopUp";
import CustomButton from "../../../Components/CustomButton";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { addAlertInfo } from "../../../store/alertSlice";
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


export default function JobApplications(props: any) {
    const token = useSelector((state: any) => state.auth.token);
    const [list, setList] = useState<any>([])
    const [listApproved, setListApproved] = useState<any>([])
    const [listNotApproved, setListNotApproved] = useState<any>([])
    const navigate = useNavigate()
    const parms = useParams()
    const { id } = parms
    const [loading, setLoading] = useState(true)
    const [loadingApproved, setLoadingApproved] = useState(true)
    const [loadingNotApproved, setLoadingNotApproved] = useState(true)
    const dispatch = useDispatch()
    const [loadingDecline, setLoadingDecline] = React.useState(false)
    const [loadingApprove, setLoadingApprove] = React.useState(false)
    const [loadingPending, setLoadingPending] = React.useState(false)
    const [totlaPages, setTotlaPages] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [totlaPagesApproved, setTotlaPagesApproved] = useState(0)
    const [totlaPagesNotApproved, setTotlaPagesNotApproved] = useState(0)
    const [currentPageApproved, setCurrentPageApproved] = useState(0)
    const user = useSelector((state: any) => state.auth.user);
    const [value, setValue] = React.useState(0);

    const listAllJobApplications = async () => {
        const response = await getAllJobApplications(id, currentPage, '0')
        if (response) {
            setLoading(false)
            setList(response.data.data.queryResult)
            setTotlaPages(Math.ceil(response.data.data.wholeModelDataCount / 4))
        }
    }
    const listAllJobApplicationsApproved = async () => {
        const response = await getAllJobApplications(id, currentPage, '1')
        if (response) {
            setLoadingApproved(false)
            setListApproved(response.data.data.queryResult)
            setTotlaPagesApproved(Math.ceil(response.data.data.wholeModelDataCount / 3))
        }
    }
    const listAllJobApplicationsNotApproved = async () => {
        const response = await getAllJobApplications(id, currentPage, '2')
        if (response) {
            setLoadingNotApproved(false)
            setListNotApproved(response.data.data.queryResult)
            setTotlaPagesNotApproved(Math.ceil(response.data.data.wholeModelDataCount / 3))
        }
    }
    const listAll = () => {
        listAllJobApplications()
        listAllJobApplicationsApproved()
        listAllJobApplicationsNotApproved()
    }
    useEffect(() => {
        if (token) {
            listAll()
        }

    }, [token])
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleCLick = async (id: any, status: number) => {
        dispatch(addAlertInfo(
            {
                show: false,

            }
        ))
        if (status === 1) {
            setLoadingApprove(true)
        }
        else if(status===2) {
            setLoadingDecline(true)
        }
        else{
            setLoadingPending(true)
        }

        const response = await UpdateJopApplication(id, status)
        if (response.status === 200) {

            if (status === 1) {
                setLoadingApprove(false)
            }
            else if(status===2) {
                setLoadingDecline(false)
            }
            else{
                setLoadingPending(false)
            }
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: 'Done'
                }
            ))
            listAll()

        }
        else {
            setLoadingDecline(false)
            setLoading(false)
            dispatch(addAlertInfo(
                {
                    show: true,
                    message: response.data.error,
                    type: 'error'
                }
            ))
        }

    }
    const handleDecline = async (appId: string) => {
      const data= await  handleCLick(appId, 2)
      setValue(2)
    }
    const handlePending = async (appId: string) => {
        const data= await  handleCLick(appId, 0)
        setValue(0)
    }
    const handleApprove = async (appId: string) => {
        const data= await handleCLick(appId, 1)
        setValue(1)
    }
    const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page - 1)
    }
    const handlePaginationChangeApproved = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPageApproved(page - 1)
    }
    return (
        <Container>
            <h3 className='title header-underline'>{list[0]?.job?.name} Applications</h3>
            <Tabs
                className="tabs"
                variant="scrollable"
                allowScrollButtonsMobile
                sx={{
                    '& .MuiTabs-indicator': { backgroundColor: '#F26400 ', color: "#F26400" },
                    '& .Mui-selected': { color: "#F26400" },
                }}
                value={value} onChange={handleChange}
            >
                <Tab className="tab" label="Pending Applications" {...a11yProps(0)} />
                <Tab className="tab" label="Selected Applications" {...a11yProps(1)} />
                <Tab className="tab" label="Not Selected Applications" {...a11yProps(2)} />
            </Tabs>

            <TabPanel value={value} index={0}>
                <div className="gap-4 space-y-4 columns-1">
                    {list.map((data: any) => {
                        return (
                            <Card className='post bg-light-green border-green'>
                                <div  >
                                    <div className='flex justify-between cursor-pointer w-fit' >
                                        <CardHeader
                                            onClick={() => navigate(`/professional/profile/${data?.user?._id}`)}
                                            avatar={
                                                data?.user?.profileImage ?
                                                    <Avatar sx={{ width: 60, height: 60 }}
                                                    className="object-contain" 
                                                        aria-label="avatar" src={data?.user?.profileImage}>

                                                    </Avatar>:
                                                     <Avatar className="object-contain" 
                                                     sx={{ width: 60, height: 60, bgcolor: "#F26400" }} aria-label="avatar">
                                                         {data?.user?.subModel?.firstName.charAt(0)}{data?.user?.subModel?.lastName.charAt(0)}
                                                     </Avatar>

                                            }

                                            title={`${data?.user?.subModel?.firstName} ${data?.user?.subModel?.middleName||""} ${data?.user?.subModel?.lastName}`}
                                            subheader={data?.about}
                                        />

                                    </div>
                                    <div className="block">
                                        <div className="flex flex-col">
                                            <a href={data?.existingCv?data?.existingCv: data?.cvPath} className="colored" target="_blank"><InsertLinkIcon className="mr-2" />{data?.user?.subModel?.firstName}'s CV</a>
                                            <a href={data?.existingCoverLetter?data?.existingCoverLetter: data?.coverLetterPath} className="colored cursor-pointer" target="_blank"><InsertLinkIcon className="mr-2" />{data?.user?.subModel?.firstName}'s Cover Letter</a>


                                        </div>
                                        {data?.questionsAnswers?.length > 0 &&
                                            <ul className="mt-4">
                                                {data?.questionsAnswers?.map((question: any) => {
                                                    return (
                                                        <li className="block border p-2 mb-2 rounded-xl">
                                                            <span className="block">Q: {question.question} </span>
                                                            <span className="block">A: {question.answer} </span>
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                        }

                                    </div>


                                    <div className='custom-action custom-action-fill gap-2'>
                                        <div className='decline'>
                                            <CustomButton text={'Decline'} loading={loadingDecline} click={() => handleDecline(data._id)} />
                                        </div>
                                        <div className='approve'>
                                            <CustomButton text={'Approve'} loading={loadingApprove} click={() => handleApprove(data._id)} />
                                        </div>

                                    </div>
                                </div>

                            </Card>
                        )
                    })}

                </div>
                {!loading && list.length === 0 &&
                    <NoDataMessage />}
                {totlaPages > 1 &&
                    <div className="flex justify-end my-6">
                        <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className="gap-4 space-y-4 columns-1">
                    {listApproved.map((data: any) => {
                        return (
                            <Card className='post bg-light-green border-green'>
                                <div  >
                                    <div className='flex justify-between cursor-pointer w-fit' >
                                        <CardHeader
                                            onClick={() => navigate(`/professional/profile/${data?.user?._id}`)}
                                            avatar={
                                                data?.user?.profileImage ?                       
                                                <Avatar sx={{ width: 60, height: 60 }}
                                                className="object-contain" 
                                                    aria-label="avatar" src={data?.user?.profileImage}>

                                                </Avatar>:
                                                 <Avatar className="object-contain" 
                                                 sx={{ width: 60, height: 60, bgcolor: "#F26400" }} aria-label="avatar">
                                                     {data?.user?.subModel?.firstName.charAt(0)}{data?.user?.subModel?.lastName.charAt(0)}
                                                 </Avatar>

                                            }

                                            title={`${data?.user?.subModel?.firstName} ${data?.user?.subModel?.middleName||""} ${data?.user?.subModel?.lastName}`}
                                            subheader={data?.about}
                                        />

                                    </div>
                                    <div className="block">
                                        <div className="flex flex-col">
                                            
                                        <a href={data?.existingCv?data?.existingCv: data?.cvPath} className="colored" target="_blank"><InsertLinkIcon className="mr-2" />{data?.user?.subModel?.firstName}'s CV</a>
                                            <a href={data?.existingCoverLetter?data?.existingCoverLetter: data?.coverLetterPath} className="colored cursor-pointer" target="_blank"><InsertLinkIcon className="mr-2" />{data?.user?.subModel?.firstName}'s Cover Letter</a>
                                        </div>
                                        {data?.questionsAnswers?.length > 0 &&
                                            <ul className="mt-4">
                                                {data?.questionsAnswers?.map((question: any) => {
                                                    return (
                                                        <li className="block border p-2 mb-2 rounded-xl">
                                                            <span className="block">Q: {question.question} </span>
                                                            <span className="block">A: {question.answer} </span>
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                        }

                                    </div>
                                    <div className='custom-action custom-action-fill gap-2'>
                                        <div className='info'>
                                            <CustomButton text={'Send to pending list'} loading={loadingPending} click={() => handlePending(data._id)} />
                                        </div>
                                        <div className='decline'>
                                            <CustomButton text={'Decline'} loading={loadingDecline} click={() => handleDecline(data._id)} />
                                        </div>
                                    </div>


                                </div>

                            </Card>
                        )
                    })}
                    
                </div>
                {!loadingApprove && listApproved.length === 0 &&
                    <NoDataMessage />}
                {totlaPagesApproved > 1 &&
                    <div className="flex justify-end my-6">
                        <Pagination count={totlaPagesApproved} variant="outlined" shape="rounded" onChange={handlePaginationChangeApproved} />
                    </div>
                }
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className="gap-4 space-y-4 columns-1">
                    {listNotApproved.map((data: any) => {
                        return (
                            <Card className='post bg-light-green border-green'>
                                <div  >
                                    <div className='flex justify-between cursor-pointer w-fit' >
                                        <CardHeader
                                            onClick={() => navigate(`/professional/profile/${data?.user?._id}`)}
                                            avatar={
                                                data?.user?.profileImage ?
                         
                                                <Avatar sx={{ width: 60, height: 60 }}
                                                className="object-contain" 
                                                    aria-label="avatar" src={data?.user?.profileImage}>

                                                </Avatar>:
                                                 <Avatar className="object-contain" 
                                                 sx={{ width: 60, height: 60, bgcolor: "#F26400" }} aria-label="avatar">
                                                     {data?.user?.subModel?.firstName.charAt(0)}{data?.user?.subModel?.lastName.charAt(0)}
                                                 </Avatar>

                                            }

                                            title={`${data?.user?.subModel?.firstName} ${data?.user?.subModel?.middleName||""} ${data?.user?.subModel?.lastName}`}
                                            subheader={data?.about}
                                        />

                                    </div>
                                    <div className="block">
                                        <div className="flex flex-col">
                                            <a href={data?.existingCv || data?.cvPath} className="colored" target="_blank"><InsertLinkIcon className="mr-2" />{data?.user?.subModel?.firstName}'s' CV</a>
                                            <a href={data?.existingCoverLetter || data?.coverLetterPath} className="colored" target="_blank"><InsertLinkIcon className="mr-2" />{data?.user?.subModel?.firstName}'s' Cover Letter</a>


                                        </div>
                                        {data?.questionsAnswers?.length > 0 &&
                                            <ul className="mt-4">
                                                {data?.questionsAnswers?.map((question: any) => {
                                                    return (
                                                        <li className="block border p-2 mb-2 rounded-xl">
                                                            <span className="block">Q: {question.question} </span>
                                                            <span className="block">A: {question.answer} </span>
                                                        </li>
                                                    )
                                                })}

                                            </ul>
                                        }

                                    </div>


                                    <div className='custom-action custom-action-fill gap-2'>
                                        <div className='info'>
                                            <CustomButton text={'Send to pending list'} loading={loadingPending} click={() => handlePending(data._id)} />
                                        </div>
                                        <div className='approve'>
                                            <CustomButton text={'Approve'} loading={loadingApprove} click={() => handleApprove(data._id)} />
                                        </div>

                                    </div>
                                </div>

                            </Card>
                        )
                    })}

                </div>
                {!loading && list.length === 0 &&
                    <NoDataMessage />}
                {totlaPages > 1 &&
                    <div className="flex justify-end my-6">
                        <Pagination count={totlaPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
                    </div>
                }
            </TabPanel>


        </Container >
    );
}
