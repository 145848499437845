import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import { useNavigate } from 'react-router';
import { Box, Grid, Modal, Rating } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
export default function BasicTable(props: any) {
  const { data, status } = props
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [openMenteeRate, setOpenMenteeRate] = React.useState(false)
  
  const [selectedData, setSelectedData] = React.useState<any>({})

  const handleClose = () => setOpen(false);
  const handleCloseMenteeRate = () => setOpenMenteeRate(false);
  const handleNavigation = (id: any) => {
    navigate(`/professionals/mentor-profile/${id}`)
  }
  const handleNavigationToRating = (id: any) => {
    navigate(`/professionals/mentor-rating/${id}`)
  }

  function formatTime(timeString: any) {
    const [hourString, minute] = timeString.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM");
  }
  return (
    <div className='main-table w-full'>
      {open &&
        <SeeMentorReview show={open} handleClose={handleClose} data={selectedData} />
      }
        {openMenteeRate &&
        <SeeMenteeReview show={openMenteeRate} handleClose={handleCloseMenteeRate} data={selectedData} />
      }
      
      <Table aria-label="simple table" className='table'>

        <TableHead>
          <TableRow >
            <TableCell ><span className='list-title'>Name of Mentor</span></TableCell>
            <TableCell ><span className='list-title'>Date</span></TableCell>
            <TableCell align='center'><span className='list-title'>Mentorship Type</span></TableCell>
            {status === 'completed' && <TableCell ><span className='list-title text-center block'>Review Given to Mentor</span></TableCell>
            }
            {status === 'completed' && <TableCell ><span className='list-title text-center block'>Review Given to You by Mentor</span></TableCell>
            }
            {/* <TableCell ><span className='list-title'>Your Review to Mentee</span></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any) => (
            <TableRow
              key={row._id}
              sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
            >

              <TableCell scope="row">
                <a className='colored cursor-pointer' onClick={() => handleNavigation(row?.user2?.user?._id)} >
                  {row?.user2?.user?.subModel?.firstName}{' '}{row?.user2?.user?.subModel?.middleName||''}{' '}{row?.user2?.user?.subModel?.lastName}
                </a>

              </TableCell>
              <TableCell >
                <div className='justify-between flex'>
                  <Moment format="dddd, MMM DD, YYYY ">{row?.startTime}</Moment>
                  <Moment format="hh:mm A">{row?.startTime}</Moment>
                  {/* {row?.startTime && <span>{formatTime(row?.startTime)}</span>} */}
                </div>

              </TableCell>

              <TableCell align='center' >
                <span className='capitalize'>{row?.meetingType}</span>
              </TableCell>

              {status === 'completed' &&
                <TableCell >
                  <div className='text-center'>
                    {row?.mentorShipSession?.menteeRating?.rate ?
                      <div className='block'>
                        <div className='block'>
                          <Rating name="read-only"
                            value={row?.mentorShipSession?.menteeRating?.rate}
                            size="large"
                            readOnly
                            precision={0.5}
                          />
                        </div>
                        <a className='colored cursor-pointer block' onClick={() => 
                        {
                          setSelectedData(row);
                          setOpenMenteeRate(true)
                        }
                        }>See Review</a>
                      </div>


                      :
                      <a className='colored cursor-pointer' onClick={() => navigate(`/professionals/mentor-rating/${row?._id}?username=${row?.user2?.user?.subModel?.firstName} ${row?.user2?.user?.subModel?.middleName||''} ${row?.user2?.user?.subModel?.lastName}`)
                      }>Add Review</a>}
                  </div>
                </TableCell>}
              {status === 'completed' &&
                <TableCell >
                  <div className='text-center'>
                    {row?.mentorShipSession?.mentorRating?.rate ?
                      <div className='block'>
                        <div className='block'>
                          <Rating name="read-only"
                            value={row?.mentorShipSession?.mentorRating?.rate}
                            size="large"
                            readOnly
                            precision={0.5}
                          />
                        </div>
                        <a className='colored cursor-pointer block' onClick={() =>    {
                          setSelectedData(row);
                          setOpen(true)
                        }
                        }>See Review</a>
                      </div>


                      :
                      <a
                      >Not Reviewed yet</a>}
                  </div>
                </TableCell>}
            </TableRow>
          ))}
        </TableBody>

      </Table>
    </div>


  );
}

function SeeMentorReview(props: any) {
  const { show, handleClose, data } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: '90vh',
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: '16px',
    boxShadow: 0,
    p: 4,
  };
 
  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style}>
          <div className="flex justify-end " onClick={handleClose}>
            <CloseIcon className="cursor-pointer" />

          </div>
          <p className="font-semibold text-lg text-orange">Review details</p>

          <div className="flex my-6">

            <div className="w-full">
              <Grid container>
                <Grid item md={12}>
                  <div className='form-item '>
                    <label>Mentor name : <span className='font-[300] colored'>{data?.user2?.user?.subModel?.firstName} {data?.user2?.user?.subModel?.middleName || ""} {data?.user2?.user?.subModel?.lastName}</span></label>

                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className='form-item '>
                    <label>Meeting Date : <span className='font-[300]'><Moment format="MMMM DD, yyyy">{data?.date}</Moment></span></label>

                  </div>
                </Grid>


                <Grid item md={6}>
                  <div className='form-item w-100 text-end'>
                    <label>Meeting Type : <span className='font-[300]'>{data?.meetingType}</span></label>

                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className='form-item '>
                    <label>Rating</label>
                    <Rating name="read-only"
                      value={data?.mentorShipSession?.mentorRating?.rate}
                      size="large"
                      readOnly
                      precision={0.5}
                    />
                  </div>
                </Grid>
                <div className='text-underline h-1 w-full'></div>
                <Grid item md={12}>
                  <div className='form-item '>
                    <label>What {data?.user2?.user?.subModel?.firstName} liked and learned?</label>
                    <p className='font-[300]'>{data?.mentorShipSession?.mentorRating?.whatYouLiked || <span className='text-red-500'>  Not provided</span>}</p>
                  </div>
                </Grid>
                <div className='text-underline h-1 w-full'></div>

                {/* <Grid item md={12}>
                  <div className='form-item '>
                    <label>What areas of improvement did {data?.user2?.user?.subModel?.firstName} suggest?</label>
                    <p className='font-[300]'>{data?.mentorShipSession?.mentorRating?.improvementSuggestion || <span className='text-red-500'>  Not provided</span>}</p>
                  </div>
                </Grid> */}



              </Grid>


            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
}


function SeeMenteeReview(props: any) {
  const { show, handleClose, data } = props;
  const userData = useSelector((state: any) => state.auth.user);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: '90vh',
    overflow: "auto",
    bgcolor: "background.paper",
    borderRadius: '16px',
    boxShadow: 0,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"

      >
        <Box sx={style}>
          <div className="flex justify-end " onClick={handleClose}>
            <CloseIcon className="cursor-pointer" />

          </div>
          <p className="font-semibold text-lg text-orange">Review details</p>

          <div className="flex my-6">

            <div className="w-full">
              <Grid container>
              <Grid item md={12}>
                  <div className="form-item ">
                    <label>
                    Mentee's Name :{" "}
                      <span className="font-[300] colored">
                        {userData?.subModel?.firstName} {userData?.subModel?.middleName || ""}{" "}
                        {userData?.subModel?.lastName}
                      </span>
                    </label>
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className='form-item '>
                    <label>Mentor name : <span className='font-[300] colored'>{data?.user2?.user?.subModel?.firstName} {data?.user2?.user?.subModel?.middleName || ""} {data?.user2?.user?.subModel?.lastName}</span></label>

                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className='form-item '>
                    <label>Meeting Date : <span className='font-[300]'><Moment format="MMMM DD, yyyy">{data?.date}</Moment></span></label>

                  </div>
                </Grid>


                <Grid item md={6}>
                  <div className='form-item w-100 text-end'>
                    <label>Meeting Type : <span className='font-[300]'>{data?.meetingType}</span></label>

                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className='form-item '>
                    <label>Your Rating</label>
                    <Rating name="read-only"
                      value={data?.mentorShipSession?.menteeRating?.rate}
                      size="large"
                      readOnly
                      precision={0.5}
                    />
                  </div>
                </Grid>
                <div className='text-underline h-1 w-full'></div>
                <Grid item md={12}>
                  <div className='form-item '>
                    <label>What you liked and learned?</label>
                    <p className='font-[300]'>{data?.mentorShipSession?.menteeRating?.whatYouLiked || <span className='text-red-500'>  Not provided</span>}</p>
                  </div>
                </Grid>
                <div className='text-underline h-1 w-full'></div>

                {/* <Grid item md={12}>
                  <div className='form-item '>
                    <label>What areas of improvement do you suggest?</label>
                    <p className='font-[300]'>{data?.mentorShipSession?.menteeRating?.improvementSuggestion || <span className='text-red-500'>  Not provided</span>}</p>
                  </div>
                </Grid> */}



              </Grid>


            </div>
          </div>

        </Box>
      </Modal>
    </div>
  );
}