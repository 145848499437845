import { formatDistanceToNow, subHours } from 'date-fns';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { latestInteractionsWithEmployers, StudentRecentlyHired } from '../../../../services/analyticsService';
import NoDataMessage from '../../../../Components/NoDataMessage';




export default function StudentsRecentlyHired(props: any) {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const getData = async () => {
    const response = await StudentRecentlyHired(user._id,0,4)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 10))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token]);
  const getApplicationStatus = (status: string) => {

    if (status == '0') return 'Pending'
    if (status == '1') return 'Hired'
    if (status == '2') return 'Declined'
  }
return(
  <Card {...props} className='post h-full'>
  <CardHeader
    subtitle={`${data.length} in total`}
    title={<p className='main-description'>Students recently hired</p>}
  />
  <Divider />
  <List>
    {data.map((item:any, i) => (
      <ListItem
        divider={i < data.length - 1}
        key={item?.id}
        onClick={() => navigate(`/professional/profile/${item?.user?._id}`)}
        className='cursor-pointer'
      >
       <Avatar className='mr-4'
              src={item?.user?.profileImage||item?.user?.subModel?.logo} sx={{ width: 50, height: 50, bgcolor: "#F26400" }} aria-label="avatar">
              <a className='capitalize text-white'>
                {item?.user?.subModel?.firstName?.charAt(0)?.toUpperCase()||item?.user?.firstName?.charAt(0)?.toUpperCase()}{item?.user?.subModel?.lastName?.charAt(0)?.toUpperCase()||user?.lastName?.charAt(0)?.toUpperCase()} {item?.user?.subModel?.name?.charAt(0)}</a>
            </Avatar>


        <ListItemText
          primary={item?.user?.subModel?.firstName+' '+item?.user?.subModel?.lastName}
          secondary={item?.job?.name}
        />

      </ListItem>
    ))}
    {data.length==0&&<NoDataMessage/>}
  </List>

  {/* <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      p: 2
    }}
  >
    <a
      className='colored'
    >
      View all<ArrowRightIcon />
    </a>
  </Box> */}
</Card>
)
}
