import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router';
import PopUpSendMesage from '../../../../Components/PopUp';
import { useState } from 'react';
interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}
interface MentorCardProps {
    data: any
}
export default function MentorCard(props: MentorCardProps) {
    const navigate = useNavigate()
    const { data } = props
    const [open, setOpen] = useState(false);
    const handleSendMesage = (id: any) => {
        setOpen(true)
    }

    return (
        <Card className='post bg-light-green border-green h-full'>
            {open && <PopUpSendMesage
                user={data} show={open}
                setOpen={setOpen}
                resiverName={`${data?.firstName} 
              ${data?.middleName||''} 
              ${data?.lastName}`}
                resiverEmail={data.currentTitle}
                id={data?.user?._id} />}
            <div className='h-full relative' >
                <div className='flex justify-between cursor-pointer' onClick={() => navigate(`/mentor/profile/${data?.user?._id}`)}>
                    <CardHeader
                        avatar={
                            !data?.user?.profileImage ?
                                <Avatar sx={{ width: 60, height: 60, bgcolor: "#F26400" }} aria-label="avatar">
                                    {data?.firstName?.charAt(0)?.toUpperCase()}{data?.lastName?.charAt(0)?.toUpperCase()}
                                </Avatar>
                                :
                                <Avatar sx={{ width: 60, height: 60 }}
                                    aria-label="avatar" src={data?.user?.profileImage}>
                                </Avatar>
                        }
                        title={`${data?.firstName} ${data?.middleName||''} ${data?.lastName}`}
                    />
                </div>
             
                        <p className={'mentor-card-text main-description '}> {data?.about}</p>
                   <div className='h-6'></div>
                     
                <div className='absolute bottom-0 right-0'>
                      <div className='custom-action custom-action-fill gap-2'>
                    <a className='main-link text-small' onClick={() => handleSendMesage(data)}>Send message</a>
                </div>
                </div>
              
               
            </div>
        </Card>
    );
}