import { Container } from '@mui/system'
import React from 'react'
import StepsTabs from './Tabs'

export default function MentoringSection() {
  return (
    
   <Container>
     <div>
        <StepsTabs />
    </div>
   </Container>
  )
}
