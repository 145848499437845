import axios from '../axios';
import {BASEURL} from '../environments/environment'

// const token = localStorage.getItem("userToken");

export const listDidYouGetOffer = () => {
    return axios
      .get(`${BASEURL}didYouGetOffer` )
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  export const listResourcesFindingJob = () => {
    return axios
      .get(`${BASEURL}resourcesFindingJob` )
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  