import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import TabThree from "./TabThree";
import styles from "./tabs.module.scss";
import { useSelector } from "react-redux";
import { listAllMentorSessionsForMentor } from "../../../../services/mentorshipService";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [pendingSession, setPendingSession] = React.useState([]);
  const [completedSession, setCompletedSession] = React.useState([]);
  const [ongoingSession, setOngoingSession] = React.useState([]);
  const [declinedSessin, setDeclinedSessin] = React.useState([]);
  const [loadingPendingSession, setLoadingPendingSession] = React.useState(true);
  const [loadingCompletedSession, setLoadingCompletedSession] = React.useState(true);
  const [loadingOngoingSession, setLoadingOngoingSession] = React.useState(true);
  const [loadingDeclinedSessin, setLoadingDeclinedSessin] = React.useState(true);

  const getAllPendingSessions = async () => {
    const response = await listAllMentorSessionsForMentor(user?._id, "pending");
    if (response) {
      setPendingSession(response.data.data.queryResult);
      setLoadingPendingSession(false);
    }
  };
  const getAllCompletedSessions = async () => {
    const response = await listAllMentorSessionsForMentor(user?._id, "completed");
    if (response) {
      setCompletedSession(response.data.data.queryResult);
      setLoadingCompletedSession(false);
    }
  };
  const getAllOngoingSessions = async () => {
    const response = await listAllMentorSessionsForMentor(user?._id, "ongoing");
    if (response) {
      setOngoingSession(response.data.data.queryResult);
      setLoadingOngoingSession(false);
    }
  };
  const getAllDeclinedSessions = async () => {
    const response = await listAllMentorSessionsForMentor(user?._id, "declined");
    if (response) {
      setDeclinedSessin(response.data.data.queryResult);
      setLoadingDeclinedSessin(false);
    }
  };
  const listAll = () => {
    getAllPendingSessions();
    getAllCompletedSessions();
    getAllOngoingSessions();
    getAllDeclinedSessions();
  };
  React.useEffect(() => {
    if (token) {
      listAll();
    }
  }, [token]);

  const [value, setValue] = React.useState(0);
  const subtitles = ["Pending Your Approval to Mentor", "Ongoing Mentorships", "Mentorships Completed", "Declined Mentorships"];
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className="flex justify-between  header-underline">
        <div>
          <h3 className="title"> Mentorship Program - {subtitles[value]} </h3>
          {value === 2 && <p className="main-description">These are a list of mentees requesting your mentorship. Requests shown by most recent.</p>}
        </div>
      </div>
      <Box className="desktop-view">
        <Tabs
          className="tabs"
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "#F26400 ", color: "#F26400" },
            "& .Mui-selected": { color: "#F26400" },
          }}
          value={value}
          onChange={handleChange}
        >
          <Tab className="tab" label="Pending Your Approval" {...a11yProps(2)} />
          <Tab className="tab" label="Ongoing Mentorships" {...a11yProps(0)} />
          <Tab className="tab" label="Mentorships Completed" {...a11yProps(1)} />
          <Tab className="tab" label="Declined Mentorships" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <Box className="mobile-view">
        <Tabs
          sx={{
            "& .MuiTabs-indicator": { backgroundColor: "#F26400 ", color: "#F26400" },
            "& .Mui-selected": { color: "#F26400" },
          }}
          variant="scrollable"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          className="tabs"
        >
          <Tab className="tab" label="Pending Your Approval" {...a11yProps(2)} />
          <Tab className="tab" label="Ongoing Mentorships" {...a11yProps(0)} />
          <Tab className="tab" label="Mentorships Completed" {...a11yProps(1)} />
          <Tab className="tab" label="Declined Mentorships" {...a11yProps(3)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        <TabThree list={pendingSession} listAll={listAll} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabOne list={ongoingSession} type="ongoing" listAll={listAll} setValue={setValue} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabOne list={completedSession} type="completed" listAll={listAll} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TabOne list={declinedSessin} />
      </TabPanel>
    </Box>
  );
}
