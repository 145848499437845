import axios from "../axios";
import { BASEURL } from "../environments/environment";

export const listNotifications = (id:string,status=null) => {
  const stutesParm=status?`&status=0`:''
    return axios
      .get(`${BASEURL}userNotifications?userId=${id}${stutesParm}`)
      .then((res: any) => res)
      .catch((err: any) => err?.response);
  };
  export const readAllNotifications = (id:string) => {
      return axios
        .patch(`${BASEURL}userNotifications/read/${id}`)
        .then((res: any) => res)
        .catch((err: any) => err?.response);
    };

    export const getAllNotificationTypes = (model:string) => {
      return axios
        .get(`${BASEURL}notificationType/model/${model}`)
        .then((res: any) => res)
        .catch((err: any) => err?.response)
    }
    export const getAllNotificationMethods = () => {
      return axios
        .get(`${BASEURL}notificationMethod`)
        .then((res: any) => res)
        .catch((err: any) => err?.response)
    }
    
