import { Avatar, Box, Card, CardContent, Grid, Rating, Typography } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoneyIcon from '@mui/icons-material/Money';
import { useSelector } from 'react-redux';

// export const NumberBox = (props: any) => (
export function NumberBox(props: any) {
  const user = useSelector((state: any) => state.auth.user);
  return (

    <Card
      // sx={{ height: '100%' }}
      {...props}
      className="analysis-card bg-light-green border-green h-full "
    >
      <div className='p-0'>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>

            <Typography
              color="textPrimary"
              variant="h4"
            >
              {props.value}
            </Typography>
          </Grid>
          <Grid item>
            {props.icon ? <Avatar
              sx={{
                backgroundColor: props.color,
                height: 56,
                width: 56
              }}
            >
              {props.icon}
            </Avatar> :
              <Rating name="read-only"
                value={parseFloat(user?.subModel?.rating)}
                size="large"
                readOnly
                precision={0.5}
              />}

          </Grid>
        </Grid>
        <h6
          color="textSecondary"
          className='mt-4'

        >
          {props.text}
        </h6>
      </div>
    </Card>
  )
}
