
import React, { useEffect } from "react";

interface HeaderProps {
  institution?:any
}

export default function BackgroundInfo(props:HeaderProps) {
  const {institution}=props
  useEffect(() => {
   
      let backgroundID = document.getElementById('background') as HTMLElement
      backgroundID.innerHTML = institution?.subModel?.background ||''


    
  }, [])
  return (
    <div className="flex justify-between flex-col md:flex-row text-grey ">
      <div className="basis-12/12">
        <p className="title  ">
          Background Information on School
        </p>
        <p  id="background">
        </p>
      </div>
      {/* <div className="basis-3/12 ">
        <div className="flex my-6 gap-x-4 items-center">
          <Avatar src="/broken-image.jpg" sx={{ width: 50, height: 50 }} />
          <p className="font-medium">
            Career Advising <p className="text-sm font-light">Profile Page</p>
          </p>
        </div>
        <div className="flex my-6 gap-x-4 items-center">
          <Avatar src="/broken-image.jpg" sx={{ width: 50, height: 50 }} />
          <p className="font-medium">
            Ahmed Amin <p className="text-sm font-light">Recruitment Officer</p>
          </p>
        </div>
        <div className="flex my-6 gap-x-4 items-center">
          <Avatar src="/broken-image.jpg" sx={{ width: 50, height: 50 }} />
          <p className="font-medium">
            Ahmed Amin <p className="text-sm font-light">Recruitment Officer</p>
          </p>
        </div>
        <div className="flex my-6 gap-x-4 items-center">
          <Avatar src="/broken-image.jpg" sx={{ width: 50, height: 50 }} />
          <p className="font-medium">
            Ahmed Amin <p className="text-sm font-light">Recruitment Officer</p>
          </p>
        </div>
      </div> */}
    </div>
  );
}
