import axios from '../axios';
import {BASEURL} from '../environments/environment'

export const ListAllIndustry = () => {
    return axios
      .get(`${BASEURL}industry`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  export const ListAllSubIndustry = () => {
    return axios
      .get(`${BASEURL}subIndustry`)
      .then((res:any) => res)
      .catch((err:any) => err?.response);
  };
  
  