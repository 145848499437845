import { Avatar, Chip, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomizedButtons from "../../../../Components/CustomButton";
import InterestCard from "../components/InterestsCard";
import SkillsCard from "../components/SkillsCard";
import { useNavigate, useParams } from "react-router";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import { useSelector, useStore } from "react-redux";
import Moment from "react-moment";
import LanguageCard from "../components/LanguageCard";
import { getUserInfo } from "../../../../services/userService";
import { listAllDocuments } from "../../../../services/documentsService";
import NoDataMessage from "../../../../Components/NoDataMessage";
import PopUpSendMesage from "../../../../Components/PopUp";
export default function LeftContent() {
  // const user = useSelector((state: any) => state.auth.user);
  const [loading, setLoading] = useState(true)
  const [user, setUserInfo] = useState<any>()
  const [cvsList, setCvsList] = useState([])
  const [coverLetterList, setCoverLetterList] = useState([])
  const [othersDocumentsList, setOthersDocumentsList] = useState([])
  const [open, setOpen] = React.useState(false);
  const userData = useSelector((state: any) => state.auth.user);
  const handleSendMesage = () => {

    setOpen(true)
  }
  const parms = useParams()
  const { id } = parms

  const getUserData = async () => {
    const response = await getUserInfo(id)
    if (response.status === 200) {
      setLoading(false)
      setUserInfo(response.data.data)
    }
    else {

    }
  }
  const getCvsList = async () => {
    const response = await listAllDocuments(id, 'cv', 'approved')
    if (response) {
      setCvsList(response.data.data)


    }
  }
  const getCoverLetterList = async () => {
    const response = await listAllDocuments(id, 'coverLetter', 'approved')
    if (response) {
      setCoverLetterList(response.data.data)
    }

  }
  const getOtherist = async () => {
    const response = await listAllDocuments(id, 'other', 'approved')
    if (response) {
      setOthersDocumentsList(response.data.data)
    }

  }
  useEffect(() => {
    if (id) {
      getUserData()
      getCvsList()
      getCoverLetterList()
      getOtherist()

    }
  }, [])


  return (
    <>
      {open &&
        <PopUpSendMesage
          user={user}
          show={open}
          setOpen={setOpen} resiverName={`${user?.subModel?.firstName} 
              ${user?.subModel?.lastName}`}
          resiverEmail={user?.email}
          id={user?._id} />}
      <div ></div>
      {user?.subModel && !loading &&
        <div className=" pr-4 main-r-border border-r-[1px]">
          <div className="flex border-[1.25px] p-4 rounded items-center ">

            <Avatar className='mr-4'
              src={user?.subModel?.profileImage || user?.profileImage} sx={{ width: 50, height: 50, bgcolor: "#F26400" }} aria-label="avatar">
              <a className='capitalize text-white'>
                {user?.subModel.firstName?.charAt(0)}{user?.subModel.lastName?.charAt(0)}</a>
            </Avatar>
            <div>
              <p className="secondary-title capitalize">{user?.subModel?.firstName} {user?.subModel?.middleName || ''} {user?.subModel?.lastName}</p>
              {user?.subModel?.enrolledInHighSchool ?
                <p className="font-light mb-1 text-sm">Major(s): {user?.subModel?.major.map((item: any, index: number) => {
                  return (
                    <>
                      {item?.nameEn}{index != user?.subModel?.major.length - 1 ? ', ' : ''}
                    </>

                  )
                })}</p> :
                <p className="font-light mb-1 text-sm">Area of interest:{' '}
                  {user?.subModel?.areaOfInterest?.nameEn}
                </p>}
              {user?.subModel?.graduationDate && <p className="font-light text-sm mb-1">Graduation Date: {" "}
                <Moment format="MMMM YYYY">
                  {user?.subModel?.graduationDate}
                </Moment></p>}
              {user?.subModel?.gpa && parseInt(user?.subModel?.gpa) != 0 && <p className="font-light text-sm">GPA {user?.subModel?.gpa}</p>}
            </div>
          </div>


          <SkillsCard label="Skills" itemsList={user?.subModel?.skills} type="skills" />
          <LanguageCard label="Language Fluency" itemsList={user?.subModel?.fluentLanguage} type="fluentLanguage" />
          <LanguageCard label="Language Proficiency" itemsList={user?.subModel?.proficientLanguage} type="proficientLanguage" />
          <div className="border-[1.25px] p-4 rounded my-6 ">
            <h4 className="list-title mb-2 flex justify-between">

              Documents
              <DocumentScannerIcon />
            </h4>
            <Stack direction="row" gap={1} className="flex-wrap">
              {cvsList?.length === 0 && coverLetterList?.length == 0 ?
                <NoDataMessage /> : ''}
              {
                cvsList.filter((item: any) => item?.visibility == true).map((item: any, index: number) => {
                  return (
                    <a href={item.attachment} target="blank" className="main-link">{item.name}</a>
                  )
                })
              }
              {
                coverLetterList.filter((item: any) => item?.visibility == true).map((item: any, index: number) => {
                  return (
                    <a href={item.attachment} target="blank" className="main-link">{item.name}</a>

                  )
                })
              }
              {
                othersDocumentsList.filter((item: any) => item?.visibility == true).map((item: any, index: number) => {
                  return (
                    <a href={item.attachment} target="blank" className="main-link">{item.name}</a>
                  )
                })
              }
            </Stack>

          </div>
          {userData?._id != id &&
            <div className={`actions-center rounded-action custom-action-fill w-full`}>
              <CustomizedButtons
                text={'Message'} click={() => handleSendMesage()} />
            </div>}
        </div>
      }
    </>

  );
}
