import { Autocomplete, TextField } from '@mui/material';
import React from 'react'

interface SelectInputProps {
  label?: string
  name?: string;
  options: any;
  multiple?: boolean;
  disablePortal?: boolean;
  required: boolean;
  labelHint?: string;
  placeholder?: string
  Change?: any
  loading?: boolean
  formik?: any
  submitClicked?: boolean
  defaultValue?: any,
  error?: boolean
  value?: any
  disabled?: boolean
  errorMessage?:any
  disableClearable?:any
}

export default function SelectInput(props: SelectInputProps) {
  const { disabled, label, options, multiple, Change, required, labelHint, disablePortal, placeholder, name, loading, formik, submitClicked, defaultValue, error, value,errorMessage,disableClearable=false } = props

  const handleOnChange = (event: any, value: any) => {
    const data = { 'event': event, 'value': value, 'name': name }
    Change(data)

  };


  return (
    <>

      <div className='input-label'>
        {label && <label >{required ? <span className='star'>*</span> : ''}{label}</label>}
        {labelHint && <p >{labelHint}</p>}
      </div>
      {/* <div className='rounded-[10px]' style={{ border: name && formik && formik.errors[name] && "1px solid #ef4444" }}> */}

        <Autocomplete
        disableClearable={disableClearable}
          disabled={disabled}
          loading={loading}
          autoComplete={true}
          multiple={multiple}
          disablePortal={disablePortal}
          onChange={handleOnChange}
          fullWidth
          size="small"
          id="selectField"
          options={options}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          sx={{ minHeight: '46px', borderRadius: '8px' }}
          defaultValue={defaultValue}
          value={value}
          getOptionLabel={(option: any) => option.label || option.name || option}
          renderInput={(params) => 
          <TextField  error={error}  {...params} name={name} margin="normal" placeholder={`${placeholder ? placeholder : 'select'}`}

          />}
        />
      {/* </div> */}
      {error  &&
        <span className='text-red-500 text-sm'>{errorMessage||'This field is required'}</span>} 
      {/* {name && formik && submitClicked && formik.errors[name] && (
        // <span className='text-red-500 text-sm'>{formik.errors[name]}</span>
        <span className='text-red-500 text-sm'>This field id required</span>
      )}
      {error &&
        <span className='text-red-500 text-sm'>This field id required</span>} */}
    </>
  )
}
