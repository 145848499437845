

import { Container, Pagination, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Moment from 'react-moment';
import { useNavigate } from 'react-router';
import { latestStudentActiveSessions } from '../../../../services/analyticsService';

export default function ActiveSessionsForStudent() {
  const navigate = useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [totalPages, setTotalPages] = useState(0)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const handlePaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page - 1)
  }
  const getData = async () => {
    const response = await latestStudentActiveSessions(user._id, currentPage,20)
    if (response) {
      // queryResult
      setData(response.data.data.queryResult)
      setTotalPages(Math.ceil(response.data.data.wholeModelDataCount / 20))
      // setData((_data: any) => ({ ..._data, 'numberOfUsersHiredInstitution': response?.data?.data[0]?.userCount}))
    }
  }
  useEffect(() => {
    if (token) {
      getData()
    }
  }, [token,currentPage]);
  const getApplicationStatus = (status: string) => {

    if (status == '0') return 'Pending'
    if (status == '1') return 'Hired'
    if (status == '2') return 'Declined'
  }
  return (
    <div className="m-t-50 m-b-50">
      <Container>

      <h3 className='title header-underline'>Latest Active Mentorship Sessions</h3>


      <Table>
            <TableHead>
              <TableRow>

                <TableCell sortDirection="desc">
                  Mentorship Type
                </TableCell>
                <TableCell sortDirection="desc">
                  Mentor
                </TableCell>
      
                <TableCell>
                  Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {data.map((item: any) => (
                <TableRow
                  hover
                  key={item._id}
                >

                  <TableCell>
                    {item?.mentorShipSession?.mentorShipType?.nameEn}
                  </TableCell>
                  <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/mentor/profile/${item?.user2?.user?._id}`)}>
                   {item?.user2?.user?.subModel?.firstName} {item?.user2?.user?.subModel?.lastName}
                 </a>

                  </TableCell>
                  {/* <TableCell>
                  <a className='cursor-pointer' onClick={() => navigate(`/mentor/profile/${item?.user2?.user?._id}`)}>
                    {item?.user2?.user?.subModel?.firstName} {item?.user2?.user?.subModel?.lastName}
                  </a>

                </TableCell> */}
                  <TableCell>
                    <Moment format="MMMM DD, yyyy">{item?.date}</Moment>
                  </TableCell>


                </TableRow>
              ))}
            </TableBody>
          </Table>
        <div className="flex justify-end my-6">
          <Pagination count={totalPages} variant="outlined" shape="rounded" onChange={handlePaginationChange} />
        </div>
      </Container>
    </div>
  )
}
