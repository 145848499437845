import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabOne from "./TabOne";
import TabTwo from "./TabTwo";
import TabThree from "./TabThree";
import styles from './tabs.module.scss'
import TabFour from "./TabFour";
import { useSelector } from "react-redux";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const initialValue = parseInt(window.location.search.replace('?active=', ''))
  const [value, setValue] = React.useState(initialValue ? initialValue : 0);

  const userInfo = useSelector((state: any) => state.auth.user);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <div className="header-underline">
          <h3 className="title">Employers</h3>
        </div>
        <Tabs
          className="tabs"
          variant="scrollable"
          allowScrollButtonsMobile
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: '#F26400 ', color: "#F26400" },
            '& .Mui-selected': { color: "#F26400" },
          }}
          value={value} onChange={handleChange}
        >
             {(userInfo.subModel?.institution?._id || userInfo?.model != 'Student') &&
            <Tab className="tab" label="Companies that hire from your school" {...a11yProps(0)} />}
          <Tab className="tab" label="Employers in your country" {...a11yProps(userInfo.subModel?.institution?._id || userInfo?.model != 'Student' ? 1 : 0)} />
          <Tab className="tab" label="Employers that hire remote" {...a11yProps(userInfo.subModel?.institution?._id || userInfo?.model != 'Student' ? 2 : 1)} />
          <Tab className="tab" label="All Employers" {...a11yProps(userInfo.subModel?.institution?._id || userInfo?.model != 'Student'  ? 3 : 2)} />
        </Tabs>
      </Box>
      {(userInfo.subModel?.institution?._id || userInfo?.model != 'Student') &&
        <TabPanel value={value} index={0}>
          <TabOne />
        </TabPanel>
      }
      <TabPanel value={value} index={userInfo.subModel?.institution?._id || userInfo?.model != 'Student'  ? 1 : 0}>
        <TabTwo />
      </TabPanel>
      <TabPanel value={value} index={userInfo.subModel?.institution?._id || userInfo?.model != 'Student' ? 2 : 1}>
        <TabThree />
      </TabPanel>
      <TabPanel value={value} index={userInfo.subModel?.institution?._id || userInfo?.model != 'Student' ? 3 : 2}>
        <TabFour />
      </TabPanel>
    </Box>
  );
}
export const CardLoadingSkeleton = () => {
  return (
    <div className="border  border-[#efeeee] rounded-md p-4 w-full mx-auto h-full">
      <div className="animate-pulse flex space-x-4">
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-gray-200 rounded"></div>
          <div className="rounded-full bg-gray-200 h-10 w-10"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">

              <div className="h-2 bg-gray-200 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-200 rounded"></div>
          </div>

        </div>
      </div>
    </div>
  )
}