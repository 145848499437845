import axios from '../axios';
import { BASEURL } from "../environments/environment";

// const token = localStorage.getItem("userToken");

export const listAllApplication = (userId:string,currentPage:number,searchWord: any, stutesFilter: any,date:any) => {
  // ?status=pending&job=dd&page=0&pageSize=10

  let jobSearch = searchWord ? `&job=${searchWord}` : "";
  let stutesFilterSearch = (stutesFilter !=null) ? `&status=${stutesFilter}`: "";

  return axios
    .get(`${BASEURL}application/user/${userId}?page=${currentPage}&pageSize=4${jobSearch}${stutesFilterSearch}${date}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

export const SingleInstitutions = (id: string) => {
  return axios
    .get(`${BASEURL}institution/${id}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const getAllJobApplications = (id: any,currentPage:any,status:any) => {
  const statusParm =status?`&status=${status}`:''
  return axios
    .get(`${BASEURL}application/job/${id}?page=${currentPage}&pageSize=4${statusParm}`)
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};
export const UpdateJopApplication = (id: string,status:any) => {
  return axios
    .patch(`${BASEURL}application/${id}`,{'status':status})
    .then((res: any) => res)
    .catch((err: any) => err?.response);
};

