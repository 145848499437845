import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import CustomizedButtons from '../../../Components/CustomButton';
import { useDispatch } from 'react-redux';
import { addAlertInfo } from '../../../store/alertSlice';
import { useState } from 'react';
import { cancelMeeting } from '../../../services/calenderService';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: '16px',
  boxShadow: 0,
  p: 4,
};

export default function MeetingModal(props: any) {
  const dispatch = useDispatch()
  const [openCanale, setOpenCanale] = useState(false);
  const handleCloseCanale = () => {
    setOpenCanale(false)
  }
  const handleOpenCanale = () => {
    setOpenCanale(true)
  }
  const Copy = (text: any) => {
    navigator?.clipboard?.writeText(text);
    dispatch(addAlertInfo(
      {
        show: true,
        message: 'Session link copied to clipboard',
      }
    ))
    props.handleClose()
  }
  const closeAll = () => {
    handleCloseCanale()
    props.handleClose()
  }
  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-end " onClick={props.handleClose}>
            <CloseIcon className="cursor-pointer" />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Start your {props.title}

          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {props.link ?
              <div className='block md:flex justify-center'>
                <div className='custom-action mb-2 md:mb-0' style={{ justifyContent: "center", gap: "4px" }}>
                  <CustomizedButtons text="Copy session link" icon={<ContentCopyIcon className='ml-2' />} click={() => Copy(props.link)} />
               </div>
                <div className='custom-action ml-0 md:ml-[4px] mb-2 md:mb-0' style={{ justifyContent: "center", gap: "4px" }}>
                  <CustomizedButtons text="Join session now" click={() => window.open(props.link)} />

                  {/* <a className='main-link break-words' href={props.link} target='_blank' rel="noreferrer">{props.link}</a> */}
                </div>
                <div className='custom-action-fill ml-0 md:ml-[4px] mb-2 md:mb-0' style={{ justifyContent: "center", gap: "4px" }}>

                  <CustomizedButtons text="Cancel meeting" click={() => {
                    setOpenCanale(true)

                  }} />
                  {/* <a className='main-link break-words' href={props.link} target='_blank' rel="noreferrer">{props.link}</a> */}
                </div>
              </div>

              : "Meeting url not available"}

          </Typography>
          {openCanale && <CanaledMeetingModal show={openCanale} handleClose={closeAll} title={props?.title} id={props?.id} getAllPendingSessions={props.getAllPendingSessions} meetingType={props.meetingType} />}
        </Box>
      </Modal>
    </div>
  );
}

export function CanaledMeetingModal(props: any) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const cancelTheMeeting = async () => {
    setLoading(true)
    const res = await cancelMeeting(props.id, {
      "status": "declined"
    })
    if (res.status == 200) {
      setLoading(false)

      props.getAllPendingSessions()
      dispatch(addAlertInfo(
        {
          show: true,
          message: `Your sessions canceled successfully`
        }
      ))

      setTimeout(() => {
        props.handleClose()
      }, 500);
    }
    else {
      setLoading(false)
      dispatch(addAlertInfo(
        {
          show: true,
          message: res.data.error,
          type: 'error'
        }
      ))
      setTimeout(() => {
        props.handleClose()
      }, 500);
    }

  }

  return (
    <div>
      <Modal
        open={props.show}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex justify-end " onClick={props.handleClose}>
            <CloseIcon className="cursor-pointer" />
          </div>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <p>Are you sure you want to cancel<span> {' '}{props?.title}</span>?</p>
            <p className='text-sm colored mt-2'>You can send the recipient a message from your inbox, providing an explanation for the cancellation and suggesting a rescheduling if needed.</p>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='flex justify-center'>
              <div className='custom-action' style={{ justifyContent: "center", gap: "4px" }}>
                <CustomizedButtons text="Close" click={() => props.handleClose()} />
              </div>
              <div className='custom-action-fill ml-[4px]' style={{ justifyContent: "center", gap: "4px" }}>
                <CustomizedButtons text="Cancel meeting" click={() => cancelTheMeeting()} loading={loading} />
              </div>
            </div>

          </Typography>
        </Box>
      </Modal>
    </div>
  );
}