import React, { useEffect, useState } from "react";

import BasicTable from "./components";
import { listAllMentorSessions } from "../../../../../services/mentorshipService";
import { useSelector } from "react-redux";
import { TableLoadingSkeleton } from "../../../../../Components/Loaders/TableLoader";
import { Pagination } from "@mui/material";
import NoDataMessageImage from "../../../../../Components/NoDataMessageWithImage";
import { useNavigate } from "react-router";

export default function TabTwo() {
const navigate =useNavigate()
  const token = useSelector((state: any) => state.auth.token);
  const user = useSelector((state: any) => state.auth.user);
  const [pendingSession, setPendingSession] = useState([])
  const [completedSession, setCompletedSession] = useState([])
  const [ongoingSession, setOngoingSession] = useState([])
  const [loadingPendingSession, setLoadingPendingSession] = useState(true)
  const [loadingCompletedSession, setLoadingCompletedSession] = useState(true)
  const [loadingOngoingSession, setLoadingOngoingSession] = useState(true)
  const [pagePendingSession, setPagePendingSession] = useState(0)
  const [pageCompletedSession, setPageCompletedSession] = useState(0)
  const [pageOngoingSession, setPageOngoingSession] = useState(0)

  const [totlaPages1, setTotlaPages1] = useState(0)
  const [totlaPages2, setTotlaPages2] = useState(0)
  const [totlaPages3, setTotlaPages3] = useState(0)
const navigateTo=(url:string)=>{
  navigate(url)
}

  const getAllPendingSessions = async () => {
    setLoadingPendingSession(true)
    const response = await listAllMentorSessions(user?._id,'pending', pagePendingSession)
    if (response) {
      setPendingSession(response.data.data.queryResult)
      setLoadingPendingSession(false)
      setTotlaPages1(Math.ceil(response.data.data.wholeModelDataCount / 10))
    }
  }
  const getAllCompletedSessions = async () => {
    setLoadingCompletedSession(true)
    const response = await listAllMentorSessions(user?._id,'completed', pageCompletedSession)
    if (response) {
      setCompletedSession(response.data.data.queryResult)
      setLoadingCompletedSession(false)
      setTotlaPages2(Math.ceil(response.data.data.wholeModelDataCount / 10))
    }
  }
  const getAllOngoingSessions = async () => {
    setLoadingOngoingSession(true)
    const response = await listAllMentorSessions(user?._id,'ongoing', pageOngoingSession)
    if (response) {
      setOngoingSession(response.data.data.queryResult)
      setLoadingOngoingSession(false)
      setTotlaPages3(Math.ceil(response.data.data.wholeModelDataCount / 10))
    }
  }

  const handlePaginationChange1 = (event: React.ChangeEvent<unknown>, page: number) => {
    setPagePendingSession(page - 1)
  }
  const handlePaginationChange2 = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageCompletedSession(page - 1)
  }
  const handlePaginationChange3 = (event: React.ChangeEvent<unknown>, page: number) => {
    setPageOngoingSession(page - 1)
  }

  useEffect(() => {
    if (token) {
      getAllPendingSessions()

    }
  }, [token, pagePendingSession]);
  useEffect(() => {
    if (token) {
      getAllOngoingSessions()
    }
  }, [token, pageOngoingSession]);
  useEffect(() => {
    if (token) {
      getAllCompletedSessions()
    }
  }, [token, pageCompletedSession]);
  return (
    <div >
      <div className="mt-4">
        <h5 className="secondary-title">Upcoming Sessions
        </h5>

        {loadingOngoingSession ?
            <TableLoadingSkeleton /> :
            ongoingSession.length > 0 ?
                <BasicTable data={ongoingSession} /> :
                <NoDataMessageImage />
        }

        <div className="flex justify-end my-6">

          <Pagination count={totlaPages3} variant="outlined" shape="rounded" onChange={handlePaginationChange3} />
        </div>

      </div>

      <div className="mt-4">
        <h5 className="secondary-title">Pending Approval Sessions
        </h5>
        {/* {pendingSession.length > 0 &&
          <BasicTable data={pendingSession} />
        } */}
        {loadingPendingSession ?
            <TableLoadingSkeleton /> :
            pendingSession.length > 0 ?
                <BasicTable data={pendingSession} /> :
                <NoDataMessageImage />
        }

        <div className="flex justify-end my-6">

          <Pagination count={totlaPages1} variant="outlined" shape="rounded" onChange={handlePaginationChange1} />
        </div>

      </div>

      <div className="mt-4">
        <div className="flex justify-between mb-4">
          <h5 className="secondary-title"> Completed Sessions
          </h5>

        </div>

        {loadingCompletedSession ?
          <TableLoadingSkeleton /> :
          completedSession.length > 0 ?
            <BasicTable data={completedSession} status='completed' /> :
            <NoDataMessageImage />
        }
   
          <div className="flex justify-end my-6">

            <Pagination count={totlaPages2} variant="outlined" shape="rounded" onChange={handlePaginationChange2} />
          </div>
        
      </div>


      <div className="mt-4">
        <p className="main-description">For all scheduled appointments, please see your <a className="main-link" onClick={()=>navigateTo('/calendar')}>calendar</a>.</p>
      </div>


    </div>
  );
}
